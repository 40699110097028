import { Container } from "./style";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";
SwiperCore.use([Autoplay, EffectFade, Pagination]);

export function Banner({ banners, setIsContantModalOpen }) {
  return (
    <Container>
      <Swiper
        slidesPerView={1}
        className="swiper-container"
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        effect="fade"
        loop={true}
        pagination={{
          clickable: true,
        }}
      >
        {banners.map((banner, idx) => {
          return (
            <SwiperSlide key={idx} className="swiper-wrapper">
              <div
                className="banner"
                style={{
                  background: `linear-gradient(90deg, ${
                    banner.backgroundColorPrimary
                      ? banner.backgroundColorPrimary
                      : "transparent"
                  } 0%, ${
                    banner.backgroundColorSecondary
                      ? banner.backgroundColorSecondary
                      : "transparent"
                  } 100%)`,
                  height: banner.image ? "auto" : "400px",
                  minHeight: "100px",
                }}
                onClick={() => {
                  if (banner.linkType || banner.link) {
                    if (banner.linkType === "contact") {
                      setIsContantModalOpen(true);
                    } else {
                      window.open(banner.link, "_blank");
                    }
                  }
                }}
              >
                {banner.image && <img src={banner.image} alt="" />}
                <div className="banner-content">
                  {banner.title && (
                    <h1
                      className="banner-title"
                      style={{
                        color: banner.textColor ? banner.textColor : "#fff",
                      }}
                    >
                      {banner.title}
                    </h1>
                  )}
                  {banner.subtitle && (
                    <p
                      className="banner-subtitle"
                      style={{
                        color: banner.textColor ? banner.textColor : "#fff",
                      }}
                    >
                      {banner.subtitle}
                    </p>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
}
