import { useQuery } from 'react-query'
import { api } from '../api'
// import { toast } from 'react-toastify'
// import { useAuth } from '../../context/AuthContext'
// import { useHistory } from 'react-router-dom'


export async function getAllOrders() {
    
    const response = await api.get('/getOrders/' + localStorage.getItem('@Inoveclube:token'), {
        headers: {
            token: localStorage.getItem('@Inoveclube:token')
        }
    })

    const genericDatas = response.data

    const lastOrder = genericDatas.shift()

    return {
        genericDatas,
        lastOrder
    }
}


export function useOrderNotification() {
    // const { signOut } = useAuth()
    // const history = useHistory()

    return useQuery(
        'orders',
        getAllOrders,
        {
            //staleTime: 1000 * 30, //demorando 30 segundos para atualizar a funcao
            refetchInterval: 1000 * 30, // 30 seconds
            refetchIntervalInBackground: true,
            onError: () => {
 
            }
        }
    )
}