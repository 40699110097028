import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9900;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
	position: fixed;
	z-index: 9920;
	background-color: var(--gray-100);
	border-radius: 8px;
	padding: 2rem;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-height: 80%;
	overflow-x: auto;
	position: relative;
	animation: modal 0.2s linear;

	.close {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		font-size: 2.3rem;
		padding: 0.3rem;
		color: var(--gray-400);
		transition: color 0.2s linear;

		:hover {
			cursor: pointer;
			opacity: 0.6;
			color: var(--gray-100);
			background-color: var(--gray-400);
			border-radius: 8px;
		}
	}

	@keyframes modal {
		from {
			opacity: 0;
			transform: translateY(50px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

export const Title = styled.h1`
	text-align: center;
	font-size: 1.5rem;
	margin-bottom: 1rem;
	padding: 0 1rem;
`;

export const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9910;
`;
