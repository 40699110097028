import { useMenu } from '../../../context/MenuContext';
import { Container } from './style'



export function ImageModal() {
    const { imageModal, handleSetImageModal } = useMenu();

    return (
        <>

            <Container style={{ display: imageModal.show ? 'flex' : 'none' }} onClick={() => handleSetImageModal({ show: false })}>
                <button onClick={() => handleSetImageModal({ show: false })}>X</button>
                <img src={imageModal.src} alt="food" style={{ width: '300px' }} />
            </Container>

        </>
    );
}

