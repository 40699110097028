import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../../../../services/api';
import { useShoppingContext } from '../../../../../shopping/shoppingContext';

import { Container } from './style';

export function InputToSetCoupon({ shoppingButtonSelected, setCouponSelectedDatas }) {
    const [couponText, setCouponText] = useState('')
    const [loader, setLoader] = useState(false)

    const { isOpenUserCouponModal, setIsOpenUserCouponModal } = useShoppingContext()

    function verifyingCouponDatas(datas) {
        if(shoppingButtonSelected === 'takeaway' && datas.rule === 1){
            toast.info('ℹ️ Não é possível aplicar desconto no FRETE em pedidos para Retirada')
            return
        }

        if(datas.idCoupon === undefined){
            toast.info(`ℹ Cupom não disponível`)
            return
        }

        const couponDatas = {
            idCoupon: datas.idCoupon,
            description: datas.description,
            rule: datas.rule,
            typeDiscount: datas.typeDiscount,
            discountValue: datas.discountValue,
            minValue: datas.minValue,
            productsSelectedsToLink: datas.productsLinkedToCoupon
        }

        setCouponSelectedDatas(couponDatas)
        localStorage.setItem('@Inoveclube:cupom', JSON.stringify(couponDatas))
        setIsOpenUserCouponModal(false)
    }
    

    async function setCouponDatas() {
        setLoader(true)

        try{
            await api.get(`/getCoupon/${localStorage.getItem('@Inoveclube:nameBusiness')}/${couponText}/${localStorage.getItem('@Inoveclube:token')}`).then(response => {
                verifyingCouponDatas(response.data)
            })

            setLoader(false)

        }catch{
            setLoader(false)
            toast.error('Erro ao buscar cupom')
        }
      
    }


    useEffect(() =>{
        if (isOpenUserCouponModal === false){
            setCouponText('')
        }
            
    }, [isOpenUserCouponModal])



    return(
        <Container loader={loader}>
            <div className="inputLabel">
                <label>Tem um código de cupom? Digite abaixo</label>
                <input  
                    value={couponText.toUpperCase()}
                    onChange={event => setCouponText(event.target.value)}
                />
            </div>

            <div 
                className="button"
                onClick={() => setCouponDatas()}
            >
                {loader ? 
                    'aguarde...'
                :
                    'Resgatar'
                }
            </div>
        </Container>
    )
}
