import { useEffect } from 'react'
import { HeaderUserSession } from '../../components/pagesComponents/headerUserSession'
import { CardAddress } from '../../components/pagesComponents/addressPage'
import { useAddress } from '../../services/hooks/useAddress'
import { useHistory } from 'react-router'
import { useRegister } from '../../context/RegisterContext'
import { GeneralLoader } from '../../components/loaders/generalLoader'
import { BsPlusCircleFill } from 'react-icons/bs'

import { Container, Content, AddressesNotFound } from './style'


export function Address() {
    const history = useHistory()
    const { handleUpdateAddress } = useRegister()
    const { isLoading, data } = useAddress()
    

    useEffect(() =>{
        handleUpdateAddress({initialState: false, address: {}}) 
        localStorage.setItem('@Inoveclube:updateAddress', JSON.stringify({initialState: false, address: {}}) )
        localStorage.removeItem('@Inoveclube:active?')
    },[handleUpdateAddress])

    
    return(
        <Container>
            <HeaderUserSession route="/profile"/>
            <Content>
            {isLoading === false ?
                    data.length > 0 ?
                        data.map((address, indexAddress, personAddress) => (
                            <CardAddress 
                                key={address.idAddress} 
                                indexAddress={indexAddress}
                                personAddress={personAddress}
                                address={address}
                                handleUpdateAddress={handleUpdateAddress}
                            />
                        )) : <AddressesNotFound>Nenhum endereço cadastrado</AddressesNotFound>
                    : 
                    <GeneralLoader />}

                {
                    localStorage.getItem('@Inoveclube:wholesale') === 'false' && 
                    <div className="logButton">
                        <BsPlusCircleFill onClick={() => {
                                    localStorage.setItem('@Inoveclube:active?', true)
                                    history.push('/registeraddress')             
                                }
                            }
                        />
                    </div>
                }

            </Content>

        </Container>
    )
}
