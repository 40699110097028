import styled from "styled-components";

export const Container = styled.div`
	min-width: 320px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	.advice {
		text-align: center;
		font-weight: 600;
		margin-bottom: 1rem;
	}

	.spaced {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.flex {
		display: flex;
	}

	.underline {
		background-color: var(--brand-600);
		border-radius: 8px;
		border: none;
		font-size: 0.9rem;
		color: var(--gray-100);
	}

	.button {
		margin-top: 1rem;
	}
`;
