import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 1px 0;
    background: green;
    color: #FFF;
    font-size: 0.5rem;
    text-align: center;

    position: fixed;
    top: 0;
    z-index: 99;
`
