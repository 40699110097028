import { api } from "../../../../services/api";

export async function getAmountOfScheduledOrders(companiesId) {
  const { data } = await api.get(`/getScheduledOrdersAmount`, {
    params: {
      companies: companiesId.join(","),
    },
  });

  return data.scheduledOrdersAmount;
}
