import { useEffect, useState } from "react";
import { Banner } from "./components/banner";
import { Filters } from "./components/filters";
import { Footer } from "./components/footer";
import { Navbar } from "./components/navbar";
import { Stores } from "./components/stores";
import { Container, LoadingWrapper } from "./style";
import { api } from "../../../services/api";
import { MainLoader } from "../../../components/loaders/mainLoader";
import { ContactModal } from "./components/contactModal";
import { toast } from "react-toastify";
import { ModalToFilterUserLocation } from "../../../components/pagesComponents/mainPage/modalToFilterUserLocation";
import { SpaceLoader } from "../../../components/loaders/spaceLoader";

export function ModularPage({ subDomain }) {
	const [loading, setLoading] = useState(true);
	const [isContactModalOpen, setIsContantModalOpen] = useState(false);
	const [siteData, setSiteData] = useState({});
	const [filters, setFilters] = useState([]);
	const [idSelectedFilter, setIdSelectedFilter] = useState(-1);
	const [stores, setStores] = useState([]);
	const [nextPage, setNextPage] = useState(1);
	const [ownerEmail, setOwnerEmail] = useState();
	const [sectionLoading, setSectionLoading] = useState(false);
	const [modalToFilterUserLocationOpen, setModalToFilterUserLocationOpen] =
		useState(true);
	const [storesIsLoading, setStoresIsLoading] = useState(false);

	const getStores = async (idSelectedFilter, page) => {
		setIdSelectedFilter(idSelectedFilter);
		setStoresIsLoading(true);
		try {
			const responseStores = await api.get(
				`/getStores/${siteData.idBusinessPark}/${siteData.idFranchise}/${idSelectedFilter}/${page}`,
			);
			const { storesData, nextPageData } = responseStores.data;
			if (page > 1) setStores([...stores, ...storesData]);
			else setStores(storesData);
			setNextPage(nextPageData);
			setSectionLoading("");
		} catch (error) {
			console.log(error);
		} finally {
			setModalToFilterUserLocationOpen(false);
			setStoresIsLoading(false);
		}
	};

	useEffect(() => {
		const getSiteData = async () => {
			try {
				const responseSiteData = await api.get(`/getSiteData/${subDomain}`);
				const { siteData } = responseSiteData.data;
				document.querySelector("link[rel~='icon']").href =
					`${siteData.header.logo}`;
				document.title = `${window.location.host.split(".")[0]} - iNove Clube`;
				setSiteData(siteData);
				const responseFilters = await api.get(
					`/getFilters/${siteData.idBusinessPark}/${siteData.idFranchise}`,
				);
				const { filtersData } = responseFilters.data;
				setFilters(filtersData);
				const location = JSON.parse(
					localStorage.getItem("@Inoveclube:location"),
				);
				if (location) {
					const responseStores = await api.get(
						`/getStores/${siteData.idBusinessPark}/${siteData.idFranchise}/${location.state}/1`,
					);
					const { storesData, nextPageData } = responseStores.data;
					setStores(storesData);
					setNextPage(nextPageData);
					setIdSelectedFilter(location.state);
					setModalToFilterUserLocationOpen(location.state ? false : true);
				}
				setLoading(false);
			} catch (error) {
				console.log(error);
				toast.error("Página não encontrada!");
				// window.open("https://inoveclube.com/", "_self");
			}
		};
		getSiteData();
	}, [subDomain]);

	if (loading) return <MainLoader title="Carregando..." />;

	return (
		<Container>
			{modalToFilterUserLocationOpen ? (
				<ModalToFilterUserLocation
					fetchSectors={getStores}
					setModalToFilterUserLocationOpen={setModalToFilterUserLocationOpen}
					modular={true}
				/>
			) : (
				<></>
			)}
			{isContactModalOpen && (
				<ContactModal
					setIsContantModalOpen={setIsContantModalOpen}
					backgroundColor={siteData.header.backgroundColor}
					ownerEmail={ownerEmail}
				/>
			)}
			<Navbar
				header={siteData.header}
				setIsContantModalOpen={setIsContantModalOpen}
				setOwnerEmail={setOwnerEmail}
			/>
			<Banner
				banners={siteData.banners}
				setIsContantModalOpen={setIsContantModalOpen}
			/>
			<Filters
				idSelectedFilter={idSelectedFilter}
				filters={filters}
				getStores={getStores}
				setSectionLoading={setSectionLoading}
				type={siteData.idBusinessPark ? "businessPark" : "franchise"}
			/>
			{storesIsLoading ? (
				<LoadingWrapper>
					<SpaceLoader />
				</LoadingWrapper>
			) : (
				<Stores
					stores={stores}
					nextPage={nextPage}
					getStores={getStores}
					idSelectedFilter={idSelectedFilter}
					backgroundColor={siteData.header.backgroundColor}
					textColor={siteData.header.textColor}
					sectionLoading={sectionLoading}
					setSectionLoading={setSectionLoading}
					type={siteData.idBusinessPark ? "businessPark" : "franchise"}
				/>
			)}
			<Footer
				footer={siteData.footer}
				setIsContantModalOpen={setIsContantModalOpen}
				setOwnerEmail={setOwnerEmail}
			/>
		</Container>
	);
}
