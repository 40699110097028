import { useState, useEffect, useCallback } from "react";
import { SpaceLoader } from "../../../loaders/spaceLoader";
import { BiSearch } from "react-icons/bi";
import { toast } from "react-toastify";
import { CitiesToUserChoose } from "./citiesToUserChoose";

import { Container, Title, CitiesListAndSearch } from "./style";

export function ModalToFilterUserLocation({
	fetchSectors,
	setModalToFilterUserLocationOpen,
	modular
}) {
	const [searchTerm, setSearchTerm] = useState("");
	const [isSearchingLocation, setIsSearchingLocation] = useState(false);
	const [latlgn, setLatlgn] = useState(null);
	const [neighborhood, setNeighborhood] = useState(null);
	const [state, setState] = useState(null);

	const apiCageSearchCep = useCallback(async (position) => {
		let lat = position.coords.latitude;
		let long = position.coords.longitude;

		setIsSearchingLocation(true);

		await fetch(
			`https://api.opencagedata.com/geocode/v1/json?q=${lat}+${long}&key=${process.env.REACT_APP_OPENCAGE_KEY}`
		)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				setState(json.results[0].components.state_code);
				setLatlgn(json.results[0].geometry);
				setNeighborhood(json.results[0].components.suburb);
				setSearchTerm(json.results[0].components.city);
				setIsSearchingLocation(false);
			})
			.catch((error) => {
				setIsSearchingLocation(false);
				toast.error("Algo deu errado, procure manualmente sua localização");
			});
	}, []);

	useEffect(() => {
		if (!localStorage.getItem("@Inoveclube:location"))
			navigator.geolocation.getCurrentPosition(
				(position) => {
					apiCageSearchCep(position);
				},
				() =>
					localStorage.getItem("@Inoveclube:location")
						? ""
						: toast.warning("⚠️ Não foi possível buscar a localização"),
				{ enableHighAccuracy: true, maximumAge: 30000, timeout: 5000 }
			);
	}, [apiCageSearchCep]);

	return (
		<Container>
			{localStorage.getItem("@Inoveclube:location") && (
				<span onClick={() => setModalToFilterUserLocationOpen(false)}>
					&#10006;
				</span>
			)}

			<Title>
				<strong>Escolha sua cidade</strong>
			</Title>

			<CitiesListAndSearch>
				<div className="inputSearch">
					{isSearchingLocation ? (
						<SpaceLoader colorLoader="#fff5ff" />
					) : (
						<BiSearch />
					)}
					<input
						type="text"
						placeholder="Qual sua cidade?"
						value={searchTerm}
						onChange={(event) => setSearchTerm(event.target.value)}
					/>
				</div>

				<CitiesToUserChoose
					searchTerm={searchTerm}
					fetchSectors={fetchSectors}
					latlgn={latlgn}
					neighborhood={neighborhood}
					state={state}
					modular={modular}
				/>
			</CitiesListAndSearch>
		</Container>
	);
}
