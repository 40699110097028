import { useState } from 'react';
import {createContext, useContext} from 'react'


export const ShoppingContext = createContext();


export function ShoppingProvider({ children }){

    const [isOpenUserCouponModal, setIsOpenUserCouponModal] = useState(false)


    return(
        <ShoppingContext.Provider 
            value={ { 
                isOpenUserCouponModal, 
                setIsOpenUserCouponModal
            } }
        >
            { children }
        </ShoppingContext.Provider>
    )
}


export function useShoppingContext() {
   const context = useContext(ShoppingContext)

   if(!context) {
    throw new Error('useShoppingContext must be used within an Provider');
  } 

   return context
}
