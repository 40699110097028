import { StoreCard } from '../storeCard';

import { Container } from './style';

export function CitiesAndStores({ datasSelectedState }) {
    return (
        <Container>
            {
                datasSelectedState.cities.map(city => (
                    <div>
                        <h4>{city.city}</h4>

                        <div className="teste">
                            {datasSelectedState.business.map(store => (
                                store.city === city.city ? 
                                <StoreCard 
                                    key={store.idBusiness}
                                    storeDatas={store}
                                /> 
                                : <></>
                            ))
                        
                            }
                         </div>
                    </div>
                ))

            }
        </Container>

    )
}
