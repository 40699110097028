import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;

    position: fixed;
    top: 0;
    right: 0;

    display: none;
    align-items: center;
    justify-content: center;

    ${props => props.isOpenUserCouponModal && css`
        display: flex;
    `}
`


export const Content = styled.div`
    height: 95%;
    width: 95%;
    position: relative;

    max-width: 1200px;
    background: #FFF;
    border-radius: 5px;

    display: flex;
    flex-direction: column;

    .scrollArea{
        flex: auto;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            background-color: #FFF;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #C3C3C3;
            border-radius: 4px;
        }

        scrollbar-width: thin;
        scrollbar-color: #C3C3C3;
        scrollbar-track-color: #FFF;
    }
`

export const CouponsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25rem;
    margin: 1rem auto 0 auto;

    @media (max-width: 460px){
        width: 80%;
    }
`