import styled, { css } from "styled-components";

export const Container = styled.button`
	padding: 1rem;
	background: ${(props) => (props.background ? props.background : "#4A007A")};
	color: ${(props) => (props.color ? props.color : "#FFF")};
	border-radius: 0.5rem;
	transition: filter 0.2s ease;
	border: none;
	width: 100%;
	max-height: 40px;
	font-size: 1.2rem;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	svg {
		font-size: 1.1rem;
		margin-right: 0.5rem;
	}

	&:hover {
		filter: brightness(0.9);
	}

	@media (min-width: 960px) {
		cursor: pointer;
	}

	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}

	${(props) =>
		props.size &&
		css`
			height: ${props.size}px;
		`}

	${(props) =>
		props.maxWidth &&
		css`
			max-width: ${props.maxWidth}px;
		`}
`;
