import styled from "styled-components";

export const Container = styled.div`
    height: 100%;

    .content{
        margin-bottom: 2rem;
    }

    .marginDiv{
        height: 50px;
        width: 100%;
        background: #FFF;
    }

    .logButton{
        position: fixed;
        bottom: 0;
        background: #FFF;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        
        /* svg{
            min-height: 3rem;
            font-size: 3rem;
            color: var(--colorButton);
            cursor: pointer;
            margin-bottom: 5px;
        } */

        button{
            width: 100%;
            max-width: 200px;
            margin-bottom: 10px;
            min-height: 2rem;
            font-size: 1.1rem;
            padding: 1rem;
            background-color: var(--colorButton);
            color: #fff;
            border: 0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }
`;

export const ButtonPix = styled.div`
    border: 0.5px solid #D3D3D3;
    border-radius: 5px;
    width: 95%;
    max-width: 550px;
    padding: 1rem;
    margin: 1rem auto;
    cursor: pointer;

    display: flex;
    align-items: center;

    p{
        color: #000;
        font-size: 0.9rem;
    }

    svg{
        color: var(--colorButton);
        margin-right: 15px;
        font-size: 1.5rem;
    }
`;
