import { apiUpdate } from "../../../../../services/update";

export async function saveTicket(data) {
  const ticket = {
    client: {
      id: 100,
    },
    sectorSelected: 12,
    responsible: 0,
    description: `
      Solicitação de lojista via iNove Clube: ${data.message} - Nome: ${data.name}
      - Company name: ${data.companyName} - E-mail: ${data.email} - Telefone: ${data.phone}
      - Estado: ${data.state} - Quantidade de funcionários: ${data.employee}
      - Faturamento mensal: ${data.invoicing} - Instagram: ${data.instagram}
      - Município: ${data.cdMunicipio}
    `,
    prioritySelected: 1,
    unity: "dd",
    maxTime: 10,
    cause: 3,
    dsEmpresa: data.companyName,
    isInoveSystem: true,
    listWebLinkDrive: [],
  };

  await apiUpdate.post("/saveTicket", ticket);
}
