import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	padding: 0 1rem;
	h2 {
		text-align: center;
		padding: 1.5rem 0 1.3rem 0;
		font-size: 1.4rem;
	}
	.swiper-container {
		margin: 0 auto;
		max-width: 1200px;
		display: flex;
		gap: 1rem;
		.swiper-wrapper {
			&.selected {
				transform: scale(1.15);
			}
			width: 5rem;
			height: 6.5rem;
			.slide {
				transition: transform 0.15s ease-out;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 0.5rem;
				img {
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
					border-radius: 8px;
					width: 5rem;
					height: 4rem;
				}
				.title {
					text-align: center;
					font-size: 0.8rem;
					text-transform: uppercase;
					font-weight: 400;
				}
			}
		}
	}

	@media (min-width: 768px) {
		padding: 0 3rem;
		.swiper-wrapper {
			.slide {
				cursor: pointer;
				&:hover {
					transform: scale(1.05);
				}
				&:active {
					transform: scale(1);
				}
			}
		}
	}
`;
