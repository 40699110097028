import React,{useState} from 'react';

import { FaAngleDown } from 'react-icons/fa';
import { Container, Content, Header, Main, ContainerRules } from './styles';

export function DrawModal({businessDatas, active, draw, navigate = () => {}, message, submessage, goodLuck=""}) {
   

    const [isActive, setIsActive] = useState(active);

    const [openRules,setOpenRules] = useState(false);

    function handleClick(){
        setIsActive(false);
        navigate();
    }

    if(!draw){
        return null
    }

  return (
    <Container active={isActive}>
        <Content
           colorBg={businessDatas?.colorBgHeader} 
           colorFont={businessDatas?.colorLabel}
           active={isActive}
        >   
            <Header>
               {draw.linkPost && <img src={draw.linkPost} alt={draw.dsSorteio} />}
            </Header>

            <Main
                colorBg={businessDatas.colorBgHeader} 
                colorFont={businessDatas.colorLabel}
            >
                <div className="header">
                    <strong>{draw.dsSorteio}</strong>
                    <span>Nº {draw.idSorteio}</span>
                </div>

                <ContainerRules
                     colorFont={businessDatas.colorBgHeader}
                     openRules={openRules}
                >
                    <div className="details">
                        <span>{message} valendo <b>{draw.dsProduto}</b>. {submessage}</span>
                        <span className='obs'>*Não se esqueça de visualizar as regras!</span>

                        <button onClick={() => setOpenRules(!openRules)}>Ver regras <FaAngleDown/></button>
                    </div>

                    <div className="rules">
                        <textarea
                            value={draw?.regras}
                            disabled
                        />
                    </div>
                </ContainerRules>

                <button onClick={handleClick} className='button'>Continuar</button>
            </Main>
        </Content>
    </Container>
  )
}
