import styled from "styled-components"

export const Container = styled.div`
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const Content = styled.div`
    width: 100%;
    max-width: 600px;
    height: 250px;
    padding: 1rem 0;
    background: #FFF;
    border-radius: 10px;
    margin: 0 1px;
    color: #535354;
    overflow-y: auto;

    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1.5rem;

        svg{
            font-size: 3rem;
            color: #F6BE00;
        }

        h3{
            font-weight: bold;
        }
    }

    h4{
        text-align: center;
        margin-bottom: 0.5rem;
    }


    .listNeighborhood{
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            min-width: 200px;

            p{
                color: green;
                font-weight: 900;
            }

            & + div {
                margin-top: 1rem;
            }

            svg{
                font-size: 1.5rem;
                margin-right: 0.5rem;
            }
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #FFF;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #C3C3C3;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: #C3C3C3;
    scrollbar-track-color: #FFF;
`