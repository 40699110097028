import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 90vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .utilArea {
        max-width: 700px;
        margin: 0 auto;
        width: 80%;
        z-index: 99;
        background: #FFF;
        padding: 2rem 1rem;
        border-radius: 30px;
        min-height: 300px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }

`