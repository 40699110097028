import { useState, useEffect } from "react";

import { ModularPage } from "./modularPage";
import { MainPage } from "./mainPage";

export function Main() {
	const [loading, setLoading] = useState(true);
	const [subDomain, setSubDomain] = useState(null);

	useEffect(() => {
		// Obtém a URL completa do navegador
		let currentURL = window.location.hostname;

		///https://www.inoveclube.com
		if (currentURL.includes("www")) {
			///https://inoveclube.com
			currentURL = currentURL.replace("www.", "");
		}

		//[inoveclube, com]
		const urlParts = currentURL.split(".");

		if (urlParts.length >= 3) {
			// // O subdomínio é a primeira parte da URL
			setSubDomain(urlParts[0]);
		}

		setLoading(false);
	}, []);

	if (loading) return null;
	if (subDomain) return <ModularPage subDomain={subDomain} />;

	return <MainPage />;
}
