import { useContext, createContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { api } from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isChecked, setIsChecked] = useState(localStorage.getItem('@Inoveclube:checked') ? JSON.parse(localStorage.getItem('@Inoveclube:checked')) : true)
    const history = useHistory()
   
    const handleInputChecked = useCallback(() =>{
        setIsChecked(!isChecked)
    }, [isChecked])

    const [data, setData] = useState(() => {
        const token = localStorage.getItem('@Inoveclube:token')
        const userName = localStorage.getItem('@Inoveclube:userName')
        const adm = localStorage.getItem('@Inoveclube:adm')
        const wholesale = localStorage.getItem('@Inoveclube:wholesale')

        if (token && userName && adm && wholesale) {
            return {
                token,
                userName,
                adm, 
                wholesale
            }
        }

        else if (token && userName && adm) {
            return {
                token,
                userName,
                adm
            }
        }

        else if (token && userName && wholesale) {
            return {
                token,
                userName,
                wholesale
            }
        }

        else if(token && userName) {
            return {
                token,
                userName,
            }
        }                      

        return {};
    });

    const signIn = useCallback(async ({ userLog, password }) => {
        const response = await api.post('/login', {
            userLog,
            password,
            isChecked
        });

        if (response.data?.blockedUser) {
            throw new Error('blockedUser')
        }

        const { token, userName, adm, wholesale } = response.data;
        
        localStorage.setItem('@Inoveclube:token', token);
        localStorage.setItem('@Inoveclube:userName', userName)
        localStorage.setItem('@Inoveclube:wholesale', wholesale)

        if(adm){
            localStorage.setItem('@Inoveclube:adm', adm)
            setData({
                token,
                userName,
                adm,
                wholesale 
            });
            return
        }

        if(wholesale) {
            setData({
                token,
                userName,
                wholesale 
            });
            return
        }

        setData({
            token,
            userName
        })


    }, [isChecked]);


    const signOut = useCallback(() => {
    
        localStorage.removeItem('@Inoveclube:token')
        localStorage.removeItem('@Inoveclube:userName')
        localStorage.removeItem('@Inoveclube:adm')
        localStorage.removeItem('@Inoveclube:wholesale')
        localStorage.removeItem('@Inoveclube:onlinepmtd')
        setData({})
        history.push('/login')

    }, [history]);


    return (
        <AuthContext.Provider value={{ 
            token: data.token, 
            isChecked, 
            signIn, 
            signOut, 
            handleInputChecked, 
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);

    if(!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}
