import styled, {css} from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.25);
    z-index: 1001;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all 0.3s;
    overflow: hidden;
    opacity: 0;

    ${props => props.isOpen && css`
        transform: translateY(0);
        opacity: 1;
    `}

    ${props => !props.isOpen && css`
        transform: translateY(-5%);
        opacity: 0;
    `}
`;

export const Content = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const StatusList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 10px;

    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s;

        cursor: pointer;

        button{
            width: 20px;
            height: 20px;
            padding: 3px;
            border: 1px solid #888;
            background: none;
            border-radius: 4px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &:hover{
            background: #f2f2f2;
        }
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    border-top: 1px solid #888;

    .buttons{
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        button{
            flex: 1;
            max-width: 200px;
            height: 35px;
            background: #000;
            color: #fff;
            border-radius: 1px solid #000;
        }

        .cancel{
            background: none;
            color: #000;
            font-weight: bold;
        }
    }
`;