import { BsTicketPerforated } from "react-icons/bs";
import { GiPresent } from "react-icons/gi";

import { Container } from "./style";

export function Business({ business }) {
	return (
		<Container
			onClick={() => {
				window.open(
					"https://inoveclube.com/menu/" + business.nameBusiness,
					"_blank"
				);
			}}
		>
			<div className="image">
				<img
					src={business.linkProfile}
					alt={business.nameBusiness}
					loading="lazy"
				/>
				{business.lojaAberta ? (
					<></>
				) : (
					<div>
						<p>Fechado</p>
					</div>
				)}
			</div>

			<div className="text">
				<p className="name">{business.name}</p>
				<p className="sector">{business.sector}</p>
				<div>
					<span>
						{business.minTimeDelivery} - {business.maxTimeDelivery} min
					</span>
					{business.hasCupom ? <BsTicketPerforated className="green" /> : <></>}
					{business.hasFideliti ? <GiPresent className="green" /> : <></>}
				</div>
			</div>
		</Container>
	);
}
