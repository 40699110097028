import { useState } from "react";
import { useOrderManagerContext } from "../../context";
import { CloseCash } from "./closeCash";
import { Container, Modal, Title, List, ListItem, Button } from "./style";

export function CloseCashRegister() {
  const {
    companies,
    showCloseCashRegister,
    setShowCloseCashRegister,
    cashRegisterIsLoading,
    handleGetOrders,
  } = useOrderManagerContext();

  const [currentState, setCurrentState] = useState("list");
  const [currentCompany, setCurrentCompany] = useState(null);

  function handleClose() {
    handleGetOrders();
    setShowCloseCashRegister(false);
  }

  function handleGoBack() {
    setCurrentState("list");
    setCurrentCompany(null);
  }

  return showCloseCashRegister ? (
    <Container>
      <Modal className="scroll">
        <Title>Fechamento de caixa</Title>
        {currentState === "closeCash" ? (
          <CloseCash
            company={currentCompany}
            handleGoBack={handleGoBack}
            setCurrentState={setCurrentState}
          />
        ) : (
          <>
            <List>
              {companies.map((company) => (
                <ListItem key={company.cdEmpresa}>
                  <div>
                    <p className="bold">{company.dsEmpresa}</p>
                    <p>CNPJ: {company.cnpj}</p>
                  </div>
                  <Button
                    onClick={() => {
                      setCurrentCompany(company);
                      setCurrentState("closeCash");
                    }}
                    disabled={cashRegisterIsLoading || !company.cashRegisterOpen}
                  >
                    {company.cashRegisterOpen ? "Fechar caixa" : "Caixa fechado"}
                  </Button>
                </ListItem>
              ))}
            </List>
            <Button className="large" onClick={handleClose} disabled={cashRegisterIsLoading}>
              Concluir
            </Button>
          </>
        )}
      </Modal>
    </Container>
  ) : null;
}
