import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { api } from '../../../../services/api'
import { Header } from './header'
import { UserCoupon } from './userCoupon'
import { GeneralLoader } from '../../../loaders/generalLoader'
import { useAuth } from '../../../../context/AuthContext'
import { useShoppingContext } from '../../../../shopping/shoppingContext'
import { ModalCouponsEmpty } from './modalCouponsEmpty'
import { InputToSetCoupon } from './inputToSetCoupon'
import moment from 'moment'

import { Container, Content, CouponsContainer } from './style'


export function UserCouponsModal({ setCouponSelectedDatas, shoppingButtonSelected }) {
    const [userCoupons, setUserCoupons] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const { isOpenUserCouponModal, setIsOpenUserCouponModal } = useShoppingContext()
    const nameBusiness = localStorage.getItem('@Inoveclube:nameBusiness')
    const token = localStorage.getItem('@Inoveclube:token')

    const history = useHistory()
    const { signOut } = useAuth()

    useEffect(() => {
      async function fetchData(){
        try{
            await api.get(`/getAvaliableCoupons/${nameBusiness}/${token}`, {
                headers: {
                    token: localStorage.getItem('@Inoveclube:token')
                }
            }).then(response => {
                //console.log(response.data)
                const couponListFormatted = response.data.map(coupon => {
                    return {
                        ...coupon,
                        endDate: coupon.endDate !== '' 
                            ? moment(new Date(coupon.endDate.slice(0, -1))).format("DD/MM/YYYY HH:mm")
                            : null
                    }
                })

                setUserCoupons(couponListFormatted)
            })
            
            setIsLoading(false)

        }catch(error){
            if(error.response.status === 401 || error.response.status === 500){
                signOut()
                toast.warning('Sua sessão foi expirada')
            }else{
                toast.error(error.response.data.message)
                history.push('/profile')
            }
        }
      }

      fetchData()

    },[history, signOut, nameBusiness, token])
        

    return (
        <Container isOpenUserCouponModal={isOpenUserCouponModal}>

            <Content>

                <Header 
                    setIsOpenUserCouponModal={setIsOpenUserCouponModal}
                />

                <InputToSetCoupon 
                    shoppingButtonSelected={shoppingButtonSelected} 
                    setCouponSelectedDatas={setCouponSelectedDatas}
                />

                {isLoading === false && userCoupons.length === 0 ?
                    <ModalCouponsEmpty />
                    :
                    <div className="scrollArea">
                        <CouponsContainer>
                            {   
                                isLoading ? 
                                <GeneralLoader />
                                :
                                userCoupons.map((coupon) => (
                                
                                    <UserCoupon 
                                        key={coupon.idCoupon}
                                        datas={coupon}
                                        setCouponSelectedDatas={setCouponSelectedDatas}
                                        shoppingButtonSelected={shoppingButtonSelected}
                                    />
                                
                                ))
                            }
                        </CouponsContainer>
                    </div>
                }

            </Content>

        </Container>
    )
}
