import { Button } from '../../../Button'
import { FaEnvelopeOpenText, FaRegIdBadge } from 'react-icons/fa'

import { Container } from './style'


export function ButtonsToChooseRecover({ setRecoverMethod }) {

    return (
        <Container>
            <div className='utilArea'>

                <Button
                    icon={<FaRegIdBadge />}
                    onClick={() => setRecoverMethod('cpf')}
                >
                    Recuperar pelo CPF
                </Button>

                <Button
                    icon={<FaEnvelopeOpenText />}
                    onClick={() => setRecoverMethod('email')}
                >
                    Recuperar por e-mail
                </Button>

            </div>
        </Container>
    )
}