import styled from "styled-components";

export const Container = styled.div`
    border-bottom: 0.5px solid gray;
    width: 90%;
    margin: 0 auto;
    color: gray;
    padding: 20px 0;

    div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: color 0.3s;

        div{
            flex: 2.4;
            color: var(--colorButton);
            svg{
                flex: 0.1;
                font-size: 30px;
                margin-right: 20px;
            }
            p{
                flex: 2.3;
            }             
            

            @media (max-width: 600px){
                p{
                    flex: 2.3;
                    
                }

                svg{
                    flex: 0.2;
                }
            }
        }
        .button-edit{
            flex: 0.6;
            text-align: right;
            &:hover{
                text-decoration: underline;
                filter: brightness(0.7);
            }
        }

    }

    @media (max-width: 600px){
        p{
            font-size: 13px;
        }
    }
`