import { Header } from '../../../components/pagesComponents/individualMarketPlacePage/header'
import { SideBar } from '../../../components/pagesComponents/individualMarketPlacePage/sideBar'
import { ContainerInformations } from '../../../components/pagesComponents/individualMarketPlacePage/containerInformations'
import { DotTypingLoader } from '../../../components/loaders/dotTypingLoader'
import { useIndividualMarketPlace } from '../individualMarketPlaceContext'
import { useEffect } from 'react'

import { Container, SideBarAndContent, LoaderContainer } from './style'

export function MarketContent() {
    const{ isLoading, storesBusinessDatas} = useIndividualMarketPlace()
   
    useEffect(() => {
        document.getElementById("favicon").href = storesBusinessDatas.linkProfile
        document.title = storesBusinessDatas.name
    }, [storesBusinessDatas])
    
    

    if(isLoading === false && storesBusinessDatas.idBusiness !== undefined){
        return(
            <Container>
                  
                <Header />

                <SideBarAndContent>

                    <SideBar />


                    <ContainerInformations/>

                </SideBarAndContent>
            
            </Container>
        )
    }
    else if(isLoading === false && storesBusinessDatas.idBusiness === undefined){
        return <h1>404 not found</h1>
    }
    else{
        return(  
            <LoaderContainer>
                <DotTypingLoader color="#000"/>
            </LoaderContainer>
        )
    }
   
}
