import styled from 'styled-components'

export const Container = styled.header`
    padding: 1rem 0;
    position: relative;
    background: ${props => props.colorBgHeader ? props.colorBgHeader : '#000'};

    color: ${props => props.colorLabel ? props.colorLabel : '#FFF'};

    img{
        max-height: 5rem;
        border-radius: 0.5rem;
        margin-right: 1rem;
    }

    @media (max-width: 365px){
        img {
            height: 4rem;
        }
    }
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;

    height: 100%;
`

export const TotalNumberStores = styled.div`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 2.4rem;
    width: 2.4rem;
    border: 2px solid ${props => props.colorLabel ? props.colorLabel : '#FFF'};
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p:first-child{
        font-size: 0.9rem;
        font-weight: 900;
    }

    p:nth-child(2){
        font-size: 0.6rem;
    }

`

export const ContainerNameInput = styled.div`
    flex: auto;
    overflow: hidden;
    height: 5rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;

    h1{
        font-size: 1.6rem;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        white-space: nowrap;
    }

    .inputSearchCity{
        background: #FFF;
        display: flex;
        align-items: center;
        max-height: 2rem;
        border-radius: 0.2rem;
        flex: auto;
        
        input{
            flex: auto;
            height: 90%;
            border: none;
            border-radius: 0.15rem;
            margin-left: 0.15rem;
        }
    }

    @media (max-width: 365px){
        h1 {
            font-size: 1.3rem;
        }

        .inputSearchCity{
            width: 100%;
        }
    }
`

export const ButtonSearch = styled.div`
    background: ${props => props.colorBgHeader ? props.colorBgHeader + 'CC' : '#000'};
    height: 100%;
    padding: 0 1rem;
    margin-left: 0.2rem;
    border-radius: 0 0.15rem 0.15rem 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        color: ${props => props.colorLabel ? props.colorLabel : '#FFF'};
        min-width: 1rem;
    }

    @media (min-width: 960px){
        cursor: pointer;
    }
`