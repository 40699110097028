import { forwardRef } from "react";
import { BsCreditCard } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { RiBankLine } from "react-icons/ri";
import { MdAttachMoney } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { formatMoney } from "../../../../Utils/formatMoney";

import { Container } from "./style";

const listIconsDeliveryPayment = [
	{ type: 0, icon: <FaMoneyBillAlt /> },
	{ type: 2, icon: <BsCreditCard /> },
	{ type: 9, icon: <RiBankLine /> },
];

const buttonBase = ({ changeValue, paymentMethodSelected, ...rest }, ref) => {
	const colorBreath = localStorage.getItem("@Inoveclube:colorButton")
		? localStorage.getItem("@Inoveclube:colorButton")
		: "#4A007A";

	function deliveryIcon() {
		const indexToFindIcon = listIconsDeliveryPayment.findIndex(
			(elem) => elem.type === paymentMethodSelected.icon,
		);

		return indexToFindIcon !== -1 ? (
			listIconsDeliveryPayment[indexToFindIcon].icon
		) : (
			<MdAttachMoney />
		);
	}

	function onlineIcon() {
		return `/images/${paymentMethodSelected.icon}.png`;
	}

	function checkingIcon() {
		let icon;

		if (paymentMethodSelected.type === "delivery") icon = deliveryIcon();
		else icon = onlineIcon();

		return icon;
	}

	let attributes =
		paymentMethodSelected === null
			? { icon: <MdAttachMoney />, description: "Forma de pagamento" }
			: { icon: checkingIcon(), description: paymentMethodSelected.description };

	return (
		<Container colorBreath={colorBreath} ref={ref} {...rest}>
			<div className="iconAndPaymentText">
				{paymentMethodSelected === null || paymentMethodSelected.type === "delivery" ? (
					attributes.icon
				) : paymentMethodSelected.icon === 10 ? (
					<MdAttachMoney />
				) : (
					<img src={attributes.icon} alt="icone de pagamento" />
				)}

				<div>
					<p>{attributes.description}</p>
					{changeValue > 0 && (
						<p className="change">Troco para: {formatMoney(changeValue)}</p>
					)}
				</div>
			</div>

			<span>
				<IoIosArrowForward />
			</span>
		</Container>
	);
};

export const ButtonToSelectPaymentForm = forwardRef(buttonBase);
