import { EditProfileForm } from '../../components/pagesComponents/editProfilePage/EditProfileForm'
import { EditPasswordForm } from '../../components/pagesComponents/editProfilePage/EditPasswordForm'
import { CircleStyleArea } from '../../components/CircleStyleArea'
import { useCallback, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import { Container, Header } from './style'


export function EditProfile() {
    const [isPasswordFormVisible, setIsPasswordVisible] = useState(false)

    const handleChangePasswordFormVisible = useCallback(() => {
        setIsPasswordVisible(!isPasswordFormVisible)
    }, [isPasswordFormVisible])


    return(
        <Container> 
            <Header>
                <Link to="/profile"><AiOutlineArrowLeft/></Link>
                <h1>Editar perfil</h1>
            </Header>

            <CircleStyleArea />

            {
                isPasswordFormVisible ?
                <EditPasswordForm 
                    handleChangePasswordFormVisible={handleChangePasswordFormVisible}
                />
                :
                <EditProfileForm 
                    handleChangePasswordFormVisible={handleChangePasswordFormVisible}
                />
            }
        </Container>
    )
}
