import styled from 'styled-components'

export const Container = styled.div`

     width: 100%;
     margin-bottom: 4rem;

     .stickyPosition{
        position: sticky;
        top: 139px;
        background: #FFF;
     }

`


export const ContainerSelectButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 1rem 0 0.5rem 0;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 1228px){
        width: 95%;
    }

    @media (max-width: 960px){
        &::-webkit-scrollbar {
            display: none;
        }
        scrollbar-width: none
    }

    &::-webkit-scrollbar-track {
        background-color: #D3D3D3;
    }

    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: gray;
    scrollbar-track-color: #D3D3D3;
`
