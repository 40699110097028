import { useEffect, useState, useRef } from 'react'
import { FaTicketAlt } from 'react-icons/fa'
import { formatMoney } from '../../../../Utils/formatMoney'

import { Container } from './style'


export function CouponBanner({ couponValue, couponExistis }) {
    const [transition, setTransition] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)

    const elementRef = useRef()

    const valueFormated =  formatMoney(couponValue)

    function handleScroll() {
        if(window.scrollY > 10)
            setOpenMessage(false)
    }

    useEffect(() => {
        setTimeout(() => {
            setTransition(true)
        }, 800)

        setTimeout(() => {
            setOpenMessage(true)
        }, 1200)

        setTimeout(() => {
            setOpenMessage(false)
        }, 4200)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', handleScroll);
        }, 4200)
    }, [])
  

    return(
        <Container 
            transition={transition}
            openMessage={openMessage}
            ref={elementRef}
            couponExistis={couponExistis}
            onClick={() => {
                setOpenMessage(!openMessage)
            }}
        >
            <FaTicketAlt />
            {
                couponValue === 0 ?
                <div>
                    <p>Cupom disponível! Na tela de finalização</p>
                    <p>procure a área de cupons e veja quais</p>
                    <p>estão disponíveis.</p>
                </div> 
                :
                <div>
                    <p>Cupons disponíveis a partir de {valueFormated}.</p>
                    <p>Na tela de finalização procure a área de</p>
                    <p>cupons e veja quais estão disponíveis.</p>
                </div>
            }
        </Container>
    )
}
