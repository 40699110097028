import { api } from "../../../../services/api";

export async function getCompanies(token) {
	const { data: companies } = await api.get("/getBusinessByPeople", {
		headers: { token },
	});

	const { data: cashRegisterStatusResponse } = await api.get(
		"/getCashRegisterStatus",
		{
			params: {
				companies: companies.map((company) => company.cdEmpresa),
			},
		},
	);

	if (!cashRegisterStatusResponse.companiesCashRegisterStatus) {
		throw new Error("Erro ao buscar status do caixa!");
	}

	const companiesWithCashRegisterStatus = companies.map((company, index) => {
		return {
			...company,
			cashRegisterOpen:
				cashRegisterStatusResponse.companiesCashRegisterStatus.find(
					(element) => element.cdEmpresa === company.cdEmpresa,
				).isCashRegisterOpen,
			selected: cashRegisterStatusResponse.companiesCashRegisterStatus.find(
				(element) => element.cdEmpresa === company.cdEmpresa,
			).isCashRegisterOpen,
			openingDate:
				cashRegisterStatusResponse.companiesCashRegisterStatus[index]
					.openingDate,
		};
	});

	if (companies.length === 0) {
		throw new Error("Nenhuma empresa vinculada ao usuário!");
	}

	return companiesWithCashRegisterStatus;
}
