import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 800;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Content = styled.div`
	width: 100%;
	max-width: 400px;
	background-color: #fff;
	padding: 33px;
	border-radius: 5px;
	box-shadow: rgb(0 0 0 / 46%) 0px 5px 10px;
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	margin-bottom: 1rem;

	.title {
		display: flex;
		flex-direction: column;
		gap: 5px;

		h2 {
			color: #393939;
		}
	}

	span {
		position: absolute;
		top: 10px;
		right: 10px;

		display: flex;
		align-items: center;
		justify-content: center;

		height: 40px;
		width: 40px;
		color: rgb(135, 134, 139);
		font-size: 1.3rem;
		border-radius: 5px;
		transition: all 0.3s ease 0s;

		&:hover {
			background: rgb(200, 200, 200);
			color: rgb(255, 255, 255);
			cursor: pointer;
		}
	}
`;

export const Main = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	gap: 20px;

	.phrase {
		text-align: center;
	}
`;

export const QrCodePixContainer = styled.div`
	width: 100%;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const QrCodePixTextContainer = styled.button`
	width: 100%;
	background: none;
	display: flex;
	align-items: center;
	margin: 0 1rem;
	border: 1px dotted var(--mainTheme);
	text-align: center;
	padding: 1rem;
	gap: 10px;
	color: var(--mainTheme);

	span {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-transform: uppercase;
	}
`;
