import { api } from "../../../../services/api";

export async function getVaults({ company }) {
  try {
    const { data } = await api.get(`/cofre/${company}`);
    return data.vault;
  } catch (error) {
    throw error;
  }
}
