import styled, { css } from 'styled-components'

export const Container = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);

`

export const CardTerm = styled.div`
    height: 530px;
    width: 400px;
    background: #FFF;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: relative;

    svg{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.25rem;
        color: gray;
        cursor: pointer;
    }

    header{
        border-bottom: 1px solid #d3d3d3;
        width: 95%;
        display: flex;
        justify-content: center;

        P{
            font-size: 1.5rem;
            color: var(--colorButton);
        }
    }

    div{
        display: flex;
        align-items: center;
        span{
            margin-left: 4px;
            color: black;
            font-weight: 500;
        }
    }
    
    .logButton{
        height: 50px;
        width: 150px;
        background-color: var(--colorButton);
        color: var(--colorLabel);
        margin-bottom: 15px;
        font-weight: 500;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);

        ${props => (props.acceptTermsChecked === false) && css`
            opacity: 0.5;
            pointer-events: none;
        `}

        @media (max-height: 600px){
            height: 40px;

        }
    }

    @media (max-width: 400px){
        width: 95%;
    }
    
`


export const CardText = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        padding: 10px;
        text-align: justify;
        margin: 0 auto;
        font-size: 0.8rem !important;
        width: 95%;
        height: 100%;
        overflow: auto;
        background: #d3d3d3;
        border-radius: 0.3rem;
    }

    
`