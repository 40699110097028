import { BiUser } from 'react-icons/bi'

import { Container } from './style';

export function FirstUserSection() {
    return(
        <Container>
            <div className="centered-content">
                <div>
                    <BiUser />
                </div>
                <p>{localStorage.getItem('@Inoveclube:userName')}</p> 
            </div>
        </Container>
    )
}
