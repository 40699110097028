import { useOrderManagerContext } from "../../context";
import { InputCheck } from "../../../../components/Forms/InputCheck";
import { Container, Modal, Title, Button, List, ListItem } from "./style";
import { SpaceLoader } from "../../../../components/loaders/spaceLoader";
import { useState } from "react";
import { ChoseAmount } from "./choseAmount";

export function OpenCashRegister() {
  const {
    showOpenCashRegister,
    companies,
    handleSelectCompany,
    cashRegisterIsLoading,
    companiesIsLoading,
    handleFinishCashRegisterManagement,
  } = useOrderManagerContext();

  const [currentState, setCurrentState] = useState("list");
  const [currentCompany, setCurrentCompany] = useState(null);

  function handleGoBack() {
    setCurrentState("list");
    setCurrentCompany(null);
  }

  return showOpenCashRegister ? (
    <Container>
      <Modal className="scroll">
        <Title>Gerenciamento de Caixa</Title>
        {currentState === "choseAmount" ? (
          <ChoseAmount company={currentCompany} handleGoBack={handleGoBack} />
        ) : companiesIsLoading ? (
          <SpaceLoader />
        ) : (
          <>
            <Button
              className="right"
              onClick={() => setCurrentState("choseAmount")}
              disabled={
                cashRegisterIsLoading || companies.some((company) => company.cashRegisterOpen)
              }
            >
              Abrir todos os caixas
            </Button>
            <List>
              {companies.map((company) => (
                <ListItem key={company.cdEmpresa}>
                  <div className="wrapper">
                    <InputCheck
                      checked={company.selected}
                      onClick={() => {
                        if (!company.cashRegisterOpen || cashRegisterIsLoading) return;
                        handleSelectCompany(company.cdEmpresa);
                      }}
                      disabled={!company.cashRegisterOpen || cashRegisterIsLoading}
                    />
                    <div>
                      <p className="bold">{company.dsEmpresa}</p>
                      <p>CNPJ: {company.cnpj}</p>
                    </div>
                  </div>
                  <Button
                    disabled={company.cashRegisterOpen || cashRegisterIsLoading}
                    onClick={() => {
                      setCurrentCompany(company);
                      setCurrentState("choseAmount");
                    }}
                  >
                    {company.cashRegisterOpen ? "Caixa aberto" : "Abrir caixa"}
                  </Button>
                </ListItem>
              ))}
            </List>
            <Button
              className="large"
              disabled={cashRegisterIsLoading || companiesIsLoading}
              onClick={handleFinishCashRegisterManagement}
            >
              Entrar
            </Button>
          </>
        )}
      </Modal>
    </Container>
  ) : null;
}
