import { useIndividualMarketPlace } from '../../../../pages/individualMarketPlace/individualMarketPlaceContext'

import { Container, FlagContainer } from "./style"

export function SideBar() {
    const { 
            storesBusinessDatas, 
            selectedCountry,
            setSelectedCountry,
            setDatasSelectedState, 
            setSearchResults
    } = useIndividualMarketPlace()

    return(
        <Container colorBgHeader={storesBusinessDatas.colorBgHeader} colorLabel={storesBusinessDatas.colorLabel}>

            {
                storesBusinessDatas.countries.map((country, index) => (
                    <FlagContainer 
                        key={index}
                        active={selectedCountry === index} 
                        onClick={() => {
                            setSelectedCountry(index)
                            setDatasSelectedState(null)
                            setSearchResults({ business: [] })
                        }}
                    >
                        <img src={country.flag} alt={country.country} />
                        <p>{country.country}</p>
                    </FlagContainer>
                ))
            }

        </Container>
    )
}
