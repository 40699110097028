import styled, { css } from "styled-components"

export const Container = styled.div`
    width: 100%;
    position: relative;
    border: 1.5px solid gray;
    border-radius: 5px;
    transition: border 0.2s ease 0s;
    box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);

    input{
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        height: 45px;
        border: none;
        padding: 0 37px;
        border-radius: 5px;

        ${props => props.iconNotExists && css`
            padding: 0 10px;
        `}
    }

    & > svg{
        color: gray;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        transition: color 0.2s ease 0s;
        font-size: 0.9rem;
    }

    div{
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: ${props => props.color};
        font-size: 18px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
    }


    &:focus-within{
        border: 1.5px solid ${props => props.color};

        & > svg{
            color: ${props => props.color};
        }
    }
`