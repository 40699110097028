import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 90%;
    margin: auto;
    
`;

export const ContainerComments = styled.div`
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color:  #565656;
    background: rgb(230, 230, 230);
    padding: 0.5rem 1.2rem;
    border-radius: 5px 5px 0 0;

    .comments-text{
        h3{
            color: #454545;
            margin-bottom: 3px;
        }
    }

    
    @keyframes blinkingObligate{
        0%{background:  #FF000033}
        25%{background: #FF000066}
        50%{background: #FF000099}
        75%{background: #FF0000CC}
        100%{background: #FF0000}       
    }

    
    @keyframes blinkingText{
        0%{color:  #FF000033}
        25%{color: #FF000066}
        50%{color: #FF000099}
        75%{color: #FF0000CC}
        100%{color: #FF0000}         
    }


    ${props => props.active && css`
        .obligate{
            animation-duration: 1s;
            animation-name: blinkingObligate;
            animation-iteration-count: 3;
        }

        .text{
            animation-duration: 1s;
            animation-name: blinkingText;
            animation-iteration-count: 3;
        }
       
    `}
`

export const MaxQtt = styled.div`
    text-align: center;
    min-height: 3rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg{
        font-size: 1.7rem;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease;
        color: green;
    }

    .text{
        font-weight: 900;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }

    .obligate{
        font-size: 11px;
        background-color: #454545;
        color: white;
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 3px;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }


    ${props => props.checked && css`
        .text, .obligate{
            opacity: 0;
        }

        svg{
            opacity: 1;
        }
    `}
`