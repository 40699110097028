import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 98%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 20px 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;

    .iconAndPaymentText {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-left: 3px;
    }

    .change {
        font-size: 0.75rem;
        margin-top: 5px;
    }

    img{
        width: 2rem;
    }

    span{
        margin-right: 5px;
    }

    svg {
        color: var(--colorButton);
        font-size: 1.5rem;
    }


    ${props => props.breathButton && css`
        animation-duration: 0.65s;
        animation-name: breathbutton;
        animation-iteration-count: 7;

        @keyframes breathbutton{
            0%{background:  ${props => props.colorBreath + '03'}}
            25%{background: ${props => props.colorBreath + '1A'} }
            50%{background: ${props => props.colorBreath + '1A'} }
            75%{background: ${props => props.colorBreath + '26'} }
            100%{background: ${props => props.colorBreath + '33'} }       
        }
    `}
    

    ${props => (props.isEnableInputSelectPayment === false) && css`
        opacity: 0.5;
        pointer-events: none;
    `}
`
