import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;

    div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: color 0.3s;
        color: gray;
        
        div{
            svg{
                font-size: 30px;
                margin-right: 20px;
                color: var(--colorButton);
            }
        }
        svg{
            font-size: 40px;
            color: var(--colorButton);
        }
    }
`