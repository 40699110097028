import { AiOutlinePlus } from 'react-icons/ai'
import { BsCircle, BsFillCircleFill, BsHouseFill } from 'react-icons/bs'
import { FaListUl } from 'react-icons/fa'

import { Container, Content, Header, NeighborhoodsList, ButtonArea, ButtonAddress } from './style'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

export function NeighborhoodsAlowedModal({ neighborhoodsAlowed, setNeighborhoodsAlowedModal, setAddressShoppingModalOpened }) {
    const [selectedAddress, setSelectedAddress] = useState({ index: -1, address: {} });
  
    const history = useHistory();

    return(
        <Container >
            <div className="aroundContent">
                <Content>
                    <Header>
                        <BsHouseFill />
                        <div>
                            <p>AONDE VOCÊ ESTÁ?</p>
                            <p className="title">A EMPRESA NÃO ATENDE SUA LOCALIDADE</p>
                        </div>
                        <span onClick={() => setNeighborhoodsAlowedModal(false)}>&#10006;</span>
                    </Header>

                    <NeighborhoodsList>

                        <div>
                        {
                            neighborhoodsAlowed.map( (neighborhood, index)=>(
                                (selectedAddress.index === index) ?
                                <ButtonAddress selected={selectedAddress.index === index}>
                                    <BsFillCircleFill />
                                    <p key={neighborhood.idNeighborhood}>{neighborhood.neighborhood}, {neighborhood.city} - {neighborhood.state}</p>
                                </ButtonAddress>
                                :
                                <ButtonAddress onClick={() => { setSelectedAddress({ index: index, address: neighborhood }) }}>
                                    <BsCircle />
                                    <p key={neighborhood.idNeighborhood}>{neighborhood.neighborhood}, {neighborhood.city} - {neighborhood.state}</p>
                                </ButtonAddress>
                            ))
                        }
                        </div>
                       
                    </NeighborhoodsList>

                    <ButtonArea>
                        {selectedAddress.index !== -1 ? 
                        <div onClick={ () => {
                                 localStorage.setItem('@Inoveclube:saveAllowedAddress', JSON.stringify({
                                    state: selectedAddress.address.state,
                                    idCity: selectedAddress.address.idCity,
                                    neighborhood: selectedAddress.address.neighborhood,
                                    street: '',
                                    number: '',
                                    zipCode: '',
                                    complement: '',
                                    reference: ''

                                }))
                                localStorage.setItem('@Inoveclube:shopping', true)
                                history.push('/registeraddress')
                            }} className="registerAddress">
                                <AiOutlinePlus />
                                <p>Cadastrar Endereço</p>
                            </div>
                        : <></>}


                        <div onClick={() => {
                            setNeighborhoodsAlowedModal(false)
                            setAddressShoppingModalOpened(true)
                        }} className="listAddressButton">
                            <FaListUl className="listIcon"/>
                            <p>Listar meus endereços</p>
                        </div>
                        
                    </ButtonArea>
                </Content>
            </div>
        </Container>
    )
}