// import { Button, Input } from "../../GlobalComponents";

export function Ui() {
	return (
		<>
			{/* <Input />
			<Button>Button</Button> */}
		</>
	);
}
