import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 90vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .utilArea {
        max-width: 700px;
        margin: 0 auto;
        width: 95%;
        z-index: 99;
        background: #FFF;
        padding: 2rem;
        border-radius: 30px;
        min-height: 300px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    form {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;

        strong {
            color: var(--mainTheme);

            display: flex;
            align-items: center;
            gap: 0.2rem;
            cursor: pointer;

            svg {
                font-size: 1.125rem;
            }
        }
    }
`
