import { useState, useEffect, useRef } from 'react'
import { formatMoney } from '../../../../../../Utils/formatMoney'

import { Container } from './style'


export function CouponRulesText({ datas, openTextRules }) {

    const [heightRules , setHeightRules] = useState('0px')

    const rulesTextRef = useRef(null)

    useEffect(() => {
        setHeightRules(openTextRules === true ? `${rulesTextRef.current.scrollHeight}px` : '0px')
    }, [openTextRules])


    return(
        <Container 
            heightRules={heightRules}
            openTextRules={openTextRules}
            ref={rulesTextRef}
        >
            <strong>Regras</strong>
            <p>
               *{
                    datas.typeDiscount === 0 
                    ? formatMoney(datas.discountValue)
                    :
                        `${datas.discountValue}%`
                } de desconto no valor {
                    datas.rule === 0 ?
                    'dos produtos'
                    :
                    datas.rule === 1 ?
                    'do frete'
                    :
                    'total'
                }
            </p>

            <p>
                {
                    datas.minValue === 0 ?
                    ''
                    :
                    `*Válido para pedidos a partir de ${ 
                        formatMoney(datas.minValue)
                    } (sem considerar a taxa de entrega)`
                }
            </p>
            
            <p>
                {
                    datas.onlyOnlinePay && '*Válido para somente pagamento online'
                }
            </p>

            { !!datas.productsSelectedsToLink.length &&
                <>
                    <span>*Válido se um desses produtos estiver no carrinho: </span>
                    <ul className="listItem">
                        {
                            datas.productsSelectedsToLink.map(product => (
                                <li key={product.idProduct}>
                                    - {product.nameProduct}
                                </li>
                            ))
                        }
                    </ul>
                </>
            }
            
            { datas.endDate &&
                <p>
                    *Válido até: {datas.endDate}
                </p>
            }
          
        </Container>
    )
}
