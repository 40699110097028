import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
	width: 100%;

	select {
		padding: 0.5rem;
		border: 1px solid #ccc;
		border-radius: 0.5rem;
		color: #000;
	}
`;

export const Label = styled.label``;

export const SelectWrapper = styled.select`
	width: 100%;
`;

export const Option = styled.option`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
