import { Container, Item } from "./styles";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import { formatMoney } from "../../../../Utils/formatMoney";

SwiperCore.use([Navigation]);

export function SuggestedProductsList({ products, handleAddItemCart, businessDatas }) {
	return (
		<Container>
			<h3>Peça também</h3>

			<Swiper slidesPerView={"auto"} spaceBetween={10}>
				{products &&
					products.map((item) => (
						<SwiperSlide
							key={item.cdProdutoSugerido}
							className="swiper-wrapper"
						>
							<Item role="button" onClick={() => handleAddItemCart(item)}>
								<div className="img-container">
									<img
										src={item.linkImagem}
										alt={item.produtoSugerido}
									/>
								</div>

								<div className="details">
									<span>
										{formatMoney(
											(item.vlPromocao && item.vlPromocao > 0
												? item.vlPromocao
												: item.vlPreco),
												businessDatas.formatoMoeda
										)}
									</span>

									<span>{item.produtoSugerido}</span>
								</div>
							</Item>
						</SwiperSlide>
					))}
			</Swiper>
		</Container>
	);
}
