import { Container, Label, SelectWrapper, Option } from "./style";

/**
 * @param {options} => Array of objects with the options to be displayed in the select
 * @param {name} => Name of the select
 * @param {onChange} => Function to be called when the select value changes
 * @param {value} => Value of the select
 * @param {label} => Label of the select
 * @param {description} => Description of the option to be displayed
 * @param {placeholder} => Placeholder of the select
 * @param {optionValue} => Value of the option to be selected
 * @param {required} => Boolean to define if the select is required
 **/

export function Select({
  options,
  name,
  onChange,
  value,
  label,
  description,
  placeholder,
  optionValue,
  required,
}) {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <SelectWrapper
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={required}
        defaultValue=""
      >
        <Option value="" disabled>
          {placeholder}
        </Option>
        {options.map((option) => (
          <Option value={option[optionValue]} key={option[optionValue]}>
            {option[description]}
          </Option>
        ))}
      </SelectWrapper>
    </Container>
  );
}
