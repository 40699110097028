import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media print {
    display: none;
  }
`;

export const CardsContainer = styled.div`
  overflow: auto;
  display: flex;
  height: 100%;
  max-height: 90vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardsWrapper = styled.div`
  margin: 1rem;
  flex: 1;
  background-color: var(--gray-50);
  border-radius: 8px;
  min-height: 20rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  :first-child {
    .wrapper-header {
      background-color: #eab308;
    }
  }
  :nth-child(2) {
    .wrapper-header {
      background-color: #a855f7;
    }
  }
  :nth-child(3) {
    .wrapper-header {
      background-color: #3b82f6;
    }
  }
  .wrapper-header {
    display: flex;
    justify-content: center;
    gap: 1rem;
    h2,
    span {
      border-radius: 8px;
      font-size: 1.1rem;
      color: white;
      font-weight: 500;
    }
    padding: 1rem;
    text-align: center;
  }
  .wrapper-body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    overflow: auto;
    -webkit-font-smoothing: antialiased;
    user-select: none;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 8px;
    }
    scrollbar-width: thin;
    scrollbar-color: #c3c3c3;
    scrollbar-track-color: #fff;
    .skeleton-loader {
      height: 10rem;
      background-color: var(--gray-200);
      width: 100%;
      border-radius: 8px;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      @keyframes pulse {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
    }
  }
`;
