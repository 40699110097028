import styled, { css } from "styled-components";

export const Container = styled.div`
    margin-top: 0.1rem;
    color: #FFF5FF;
    background: linear-gradient( 315deg, rgba(0,83,220,0.9), rgba(122,5,167,0.9) );
    border-radius: 0.7rem;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);

    display: flex;
    flex-direction: column;


    strong{
        text-align: center;
        margin-bottom: 0.6rem;
        font-weight: 500;
        font-size: ${props => props.openTextRules ? '1.2rem' : 0};
        padding-top: 0.5rem;
    }


    p{
        text-align: left;
        font-size: ${props => props.openTextRules ? '0.9rem' : 0};
        padding: 0 0.5rem;
        padding-bottom: 0.5rem;
    }

    span {
        text-align: left;
        font-size: ${props => props.openTextRules ? '0.9rem' : 0};
        padding: 0 0.5rem;
    }

    .listItem {
        font-size: 0.75rem;
        padding-left: 1rem;
        list-style: none;
        margin-bottom: 0.5rem;
    }

    transition: all 0.2s;

    ${props => props.heightRules && css`
        height: ${props.heightRules};
    `}
`

