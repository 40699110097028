import styled from 'styled-components'

export const Container = styled.div`
    h1{
        margin-bottom: 2px;
    }
`

export const ContentLastUpdate = styled.div`
    background: #FFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 1rem auto;
`

export const ContentHistory = styled.div`
    background: #FFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    .historyLine{
        width: 90%;
        margin: 1rem auto;
        color: #6b6e6c;

        display: flex;
        align-items: center;

        h2{
            margin: 0 10px;
        }

        div{
            height: 2px;
            flex: auto;
            background: #6b6e6c;
        }
    }
`

export const Header = styled.header`
   display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`;

export const FiltersContainer = styled.div`
    position: relative;
    margin: 1rem 0;
    padding: 1rem;

    .filters{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        position: absolute;
        right: 1rem;
        top: 0;
    }

    .button{
        width: 100%;
        max-width: 150px;
        height: 36px;
        color: #000;
        font-weight: bold;
        border: 2px solid #000;
        background: none;
        font-size: 1rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        svg{
            transition: transform 0.5s;
        }

        &.active{
           svg{
                transform: rotate(180deg);
           }
        }
    }
`;


export const OrdersNotFound = styled.p`
    font-weight: 500;
    padding: 30px;
    margin: 0 auto;
`;

