import { InputCheck } from "../Forms/InputCheck";
import { Container, Label } from "./style";

/**
 *
 * @param {label} => Information to show on the label
 * @param {checked} => Information to information if the checkbox is checked or not
 * @param {onChange} => Function to handle the change of the checkbox
 * @param {value} => Value of the checkbox
 * @param {disabled} => Information to disable the checkbox
 * @param {side} => Position of the checkbox
 * @param {children} => Information to show on the label
 */

export function RadioWithLabel({
	label = "checkbox",
	checked = false,
	onChange = () => {},
	value = "",
	disabled = false,
	side,
	children,
}) {
	return (
		<Container
			onClick={() => {
				if (disabled) return;
				onChange();
			}}
			disabled={disabled}
		>
			<Label side={side}>
				<InputCheck checked={checked} disabled={disabled} value={value} />
				<div className="label">
					<span>{label}</span>
					{children}
				</div>
			</Label>
		</Container>
	);
}
