import {
	BiCurrentLocation,
	BiFoodMenu,
	BiEdit,
	BiLogOut,
	BiCreditCard,
	BiGridAlt,
} from "react-icons/bi";
import { SlPresent } from "react-icons/sl";
import { LinkButton } from "./linkButton";
import { useAuth } from "../../../../context/AuthContext";

import { Container } from "./style";

export function SecondUserSection() {
	const { signOut } = useAuth();

	return (
		<Container>
			<LinkButton icon={<BiFoodMenu />} title="Meus pedidos" route="orders" />

			<LinkButton
				icon={<BiCurrentLocation />}
				title="Meus endereços"
				route="address"
			/>

			<LinkButton
				icon={<BiCreditCard />}
				title="Meus cartões"
				route="cards"
				chevron={true}
				logOut={false}
			/>

			<LinkButton
				icon={<SlPresent />}
				title="Fidelidade"
				route="fidelity"
				chevron={true}
				logOut={false}
			/>

			<LinkButton icon={<BiEdit />} title="Editar Perfil" route="editprofile" />

			{localStorage.getItem("@Inoveclube:adm") ? (
				<LinkButton
					icon={<BiGridAlt />}
					title="Painel Administrativo"
					route="adm"
				/>
			) : (
				<></>
			)}

			<LinkButton
				icon={<BiLogOut />}
				title="Sair"
				route="login"
				chevron={false}
				clickFunction={signOut}
				logOut={true}
			/>
		</Container>
	);
}
