import { FaTicketAlt } from 'react-icons/fa'
import { IoIosArrowForward } from 'react-icons/io'
import { MdCancel } from 'react-icons/md'
import { useShoppingContext } from '../../../../shopping/shoppingContext';

import { Container, CouponTitle, ChooseCoupon } from './style';


export function CouponsButton({ couponSelectedDatas, setCouponSelectedDatas, couponClear }) {

  const { setIsOpenUserCouponModal } = useShoppingContext()


  function handleClickButtonCoupon() {
    couponSelectedDatas.idCoupon === -1 ? setIsOpenUserCouponModal(true) 
      : 
      setCouponSelectedDatas(couponClear)
      localStorage.removeItem(`@Inoveclube:cupom${localStorage.getItem('@Inoveclube:nameBusiness')}`)
  }


  return(
      <Container onClick={() => handleClickButtonCoupon()}>

        <CouponTitle turnIcon={couponSelectedDatas.idCoupon !== -1}>
          <FaTicketAlt />
          <p>
            {couponSelectedDatas.description !== '' ? couponSelectedDatas.description : 'Cupons'}
          </p>
        </CouponTitle>

        <ChooseCoupon remove={couponSelectedDatas.idCoupon !== -1}>

          { couponSelectedDatas.idCoupon === -1 ? <IoIosArrowForward /> : <MdCancel /> }

        </ChooseCoupon>

      </Container>
  )
}

