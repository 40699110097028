import { useHistory } from 'react-router';
import { BiCurrentLocation } from 'react-icons/bi'

import { Container } from './style';


export function CardAddress({ 
    address, 
    handleUpdateAddress, 
    indexAddress, 
    personAddress 
}) {
    const history = useHistory()

    return(
        <Container>
            <div>
                <div>
                    <BiCurrentLocation />
                    <p>{address.street}, {address.number}, {address.neighborhood}, {address.city}, {address.state}</p>
                </div>

                {
                    localStorage.getItem('@Inoveclube:wholesale') === 'false' && 
                    <p
                        className="button-edit"
                        onClick={() => {
                            handleUpdateAddress({initialState: true, address: personAddress[indexAddress]}) 
                            localStorage.setItem('@Inoveclube:updateAddress', JSON.stringify({initialState: true, address: personAddress[indexAddress]}) )
                            localStorage.setItem('@Inoveclube:active?', true)
                            history.push('/registeraddress')
                        }
                        }
                    >
                        Editar
                    </p>
                }

            </div>
        </Container>
    )
}
