import React from 'react'
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'

import { useAuth } from '../context/AuthContext';

export const PrivateRoute = ({component: Component, ...rest }) => {
    const { token } = useAuth();

    return (
        <ReactDOMRoute 
            {...rest} 
            render={({ location }) => {
                return token ? (
                    <Component />
                ) : (
                    <Redirect 
                        to={{ 
                            pathname: '/login',
                            state: { from: location }
                        }} 
                    />
                );
            }}
        
        />
    )
}

