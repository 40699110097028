import { useRef, useEffect } from 'react'
import { Container } from './style'

export function SelectButton({ groupName, active, handleClickSelectButton, index, colorButton, setIndexSelected, setListPositionButton }) {
    const buttonRef = useRef(null);

    useEffect(() =>{
        setListPositionButton(buttonRef)
    }, [setListPositionButton])

    return(
        <Container 
            active={active} 
            colorButton={colorButton}
            ref={buttonRef}
            onClick={() => {
                handleClickSelectButton(index)
                setIndexSelected(index)
            }} 
        >
            <pre>{groupName}</pre>
            <div className="line"></div>
        </Container>
    )
}