import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--colorTransversalBar);
    transform: skew(40deg) translateX(-5%);
    @media (max-width: 1024px){
        transform: skew(30deg) translateX(-5%);
    }
    @media (max-width: 800px){
        display: none;
    }
`