import { CardsContainer, CardsWrapper, Container } from "./style";

export function OrdersLoading() {
  return (
    <Container>
      <CardsContainer>
        <CardsWrapper>
          <header className="wrapper-header">
            <h2>Aprovados</h2>
          </header>
          <div className="wrapper-body">
            <div className="skeleton-loader"></div>
            <div className="skeleton-loader"></div>
          </div>
        </CardsWrapper>
        <CardsWrapper>
          <header className="wrapper-header">
            <h2>Preparando</h2>
          </header>
          <div className="wrapper-body">
            <div className="skeleton-loader"></div>
            <div className="skeleton-loader"></div>
          </div>
        </CardsWrapper>
        <CardsWrapper>
          <header className="wrapper-header">
            <h2>Entregando</h2>
          </header>
          <div className="wrapper-body">
            <div className="skeleton-loader"></div>
            <div className="skeleton-loader"></div>
          </div>
        </CardsWrapper>
      </CardsContainer>
    </Container>
  );
}
