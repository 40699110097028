import styled from "styled-components";

export const Container = styled.div`
  width: 80vw;
  max-width: 500px;
  
  .wrap{
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 400px;
  }
    
  .submit {
    bottom: -100px;
    left: 0;
  }

  @media (max-width: 720px) {
    /* width: 100%; */
    max-width: 700px;
  }
`;

export const Form = styled.form`
  label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }
  
  input, select, textarea {
    width: 100%;
    padding: 10px;
    margin: 2px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;
