import { useQuery } from 'react-query'
import { inovePagarme } from '../pagarmeApi'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom'


export async function getAllCards() {
    const token = localStorage.getItem('@Inoveclube:token')

    const response = await inovePagarme.get(`/getCards/${token}`)

    const debitCardList = response.data.filter(card => card.type === 'debit_card')
    const creditCardList = response.data.filter(card => card.type === 'credit_card')

    return {
        debitCardList,
        creditCardList
    }
}


export function useCard() {
    const { signOut } = useAuth()
    const history = useHistory()

    return useQuery(
        'cards', 
        getAllCards,
        {
            staleTime: 1000 * 60 * 60, // 60 minutes
            refetchOnWindowFocus: false,
            onError: () => {
                signOut()
                history.push('/login')
                toast.warning('Sua sessão expirou, faça login novamente')
            }
        },
    )
}