import styled, { css } from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease;
    opacity: 0;

    ${(props) =>
			props.saleCouponOpened &&
			css`
        opacity: 1;
        z-index: 999;
    `}

    ${(props) =>
			(props.closeModal === false) &&
			css`
        display: none;
    `}
`;

export const Content = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;

    svg{
        font-size: 2rem;
        color: #FFF;
    }

    img{
        height: 30rem;
    }

    .title{
        position: absolute;
        top: 9rem;
        left: 50%;
        transform: translateX(-50%);

        font-size: 1.3rem;
        font-weight: 900;
        overflow: hidden;
        white-space: nowrap;
        color: #C89BE0;

        ${(props) =>
					props.animation &&
					css`
            -webkit-animation: titleJump 0.5s ease-in 1;
            animation: titleJump 0.5s ease-in 1;
        `}
    }

    .couponName{
        position: absolute;
        top: 13rem;
        left: 50%;
        transform: translateX(-50%);
       
        font-size: 2rem;
        font-weight: 900;
        color: #FFF;

       
        ${(props) =>
					props.animation &&
					css`
            -webkit-animation: nameJump 0.5s ease-in 1;
            animation: nameJump 0.5s ease-in 1;
        `}

        text-shadow: 0 1px 0 #FFF, 
               0 2px 0 #f9f9f9,
               0 3px 0 #eee,
               0 4px 0 gray,
               0 5px 0 gray,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);
    }

    .rules{
        position: absolute;
        bottom: 7.5rem;
        right: 8.5rem;
        font-size: 0.8rem;
        color: #FFF;
        font-weight: 500;

        p:first-child{
            margin-bottom: 1rem;
        }

        
        ${(props) =>
					props.animation &&
					css`
            -webkit-animation: rulesJump 0.5s ease-in 1;
            animation: rulesJump 0.5s ease-in 1;
        `}
    }

    .discount{
        position: absolute;
        bottom: 1rem;
        right: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

      
        ${(props) =>
					props.animation &&
					css`
            -webkit-animation: discountJump 0.5s ease-in 1;
            animation: discountJump 0.5s ease-in 1;
        `}

        p{
            font-size: 1.6rem;
            color: var(--mainTheme);
            font-weight: 900;
            text-shadow: 1.2px 1.2px rgba(0, 0, 0, 0.5);
            z-index: 99;
        }
      
        background: #FFF;
        width: 5rem;
        height: 5rem;
        text-align: center;
        border-radius: 6px;
        
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 5rem;
            width: 5rem;
            background: #FFF;
            border-radius: 6px;
        }

        &:before {
            transform: rotate(30deg);
        }

        &:after {
            transform: rotate(60deg);
        }
    }


    @media (max-width: 450px){
        img{
            height: 24rem;
        }

        .title{
            top: 6.2rem;
            font-size: 1.2rem;
        }

        .couponName{
            top: 8.5rem;
        }

        .discount{
            bottom: 0;
            right: 4.5rem;
        }

        .rules{
            right: 6rem;
            font-size: 0.7rem;
            bottom: 5.5rem;
        }
    }

    ${(props) =>
			props.saleCouponOpened &&
			css`
        -webkit-animation: pop 0.4s ease-in 1;
        animation: pop 0.4s ease-in 1;
    `}


    @keyframes titleJump {
        0%{transform: translate(-50%, 0)}
        10%{transform: translate(-50%, -30px) rotate(-10deg)}
        20%{transform: translate(-50%, -40px) rotate(-25deg)}
        30%{transform: translate(-50%, -50px) rotate(-30deg)}
        40%{transform: translate(-50%, -55px) rotate(-37deg)}
        50%{transform: translate(-50%, -65px) rotate(-45deg)}
        60%{transform: translate(-50%, -40px) rotate(-40deg)}
        70%{transform:  translate(-50%, -35px) rotate(-29deg)}
        80%{transform: translate(-50%, -10px) rotate(-15deg)}
        90%{transform: translate(-50%, -5px) rotate(-10deg)}
        100%{transform: translate(-50%, 0) rotate(0)}
    }

    @keyframes nameJump {
        0%{transform: translate(-50%, 0)}
        10%{transform: translate(-50%, -10px) rotate(5deg)}
        20%{transform: translate(-50%, -20px) rotate(12deg)}
        30%{transform: translate(-50%, -35px) rotate(15deg)}
        40%{transform: translate(-50%, -38px) rotate(28deg)}
        50%{transform: translate(-50%, -30px) rotate(37deg)}
        60%{transform: translate(-50%, -25px) rotate(30deg)}
        70%{transform:  translate(-50%, -15px) rotate(25deg)}
        80%{transform: translate(-50%, -10px) rotate(15deg)}
        90%{transform: translate(-50%, -5px) rotate(10deg)}
        100%{transform: translate(-50%, 0) rotate(0)}
    }

    @keyframes rulesJump {
        0%{transform: translateY(0)}
        50%{transform: translateY(-10px)}
        60%{transform: translateY(-9px)}
        70%{transform: translateY(-5px)}
        80%{transform: translateY(-4px)}
        90%{transform: translateY(-3px)}
        100%{transform: translateY(0)}
    }

    @keyframes discountJump {
        0%{transform: translateY(0)}
        50%{transform: translateY(-30px)}
        60%{transform: translateY(-20px)}
        70%{transform: translateY(-15px)}
        80%{transform: translateY(-10px)}
        90%{transform: translateY(-5px)}
        100%{transform: translateY(0)}
    }

    @keyframes pop {
       0%{transform: scale(0)}
       5%{transform: scale(0.05)}
       10%{transform: scale(0.07)}
       15%{transform: scale(0.1)}
       20%{transform: scale(0.2)}
       25%{transform: scale(0.3)}
       30%{transform: scale(0.4)}
       35%{transform: scale(0.5)}
       40%{transform: scale(0.6)}
       45%{transform: scale(0.7)}
       50%{transform: scale(0.9)}
       55%{transform: scale(1)}
       60%{transform: scale(1.3)}
       65%{transform: scale(1.4)}
       70%{transform: scale(1.5)}
       75%{transform: scale(1.6)}
       80%{transform: scale(1.5)}
       85%{transform: scale(1.4)}
       90%{transform: scale(1.3)}
       10%{transform: scale(1.2)}
       100%{transform: scale(1)}
    }
`;
