import { useEffect, useState } from 'react'
import { CouponRulesText } from './couponRulesText'
import { IoMdArrowDropright } from 'react-icons/io'
import { useShoppingContext } from '../../../../../shopping/shoppingContext';
import { toast } from 'react-toastify';

import { Container, Content, ContentCoupom, BallLeft, Text } from './style';


export function UserCoupon({ datas, setCouponSelectedDatas, shoppingButtonSelected }) {
    const [openTextRules, setOpenTextRules] = useState(false)

    const { isOpenUserCouponModal, setIsOpenUserCouponModal } = useShoppingContext()

    function setCouponDatas() {
        if(shoppingButtonSelected === 'takeaway' && datas.rule === 1){
            toast.info('Não é possível aplicar desconto no FRETE em pedidos para Retirada')
            return
        }

        setCouponSelectedDatas(datas)
        localStorage.setItem(`@Inoveclube:cupom${localStorage.getItem('@Inoveclube:nameBusiness')}`, JSON.stringify(datas))
        setIsOpenUserCouponModal(false)
    }

    useEffect(() => {
        if(isOpenUserCouponModal === false)
            setOpenTextRules(false)
    }, [isOpenUserCouponModal])
  

    return(
        <Container>
            
            <Content>

                <BallLeft />
  

                <ContentCoupom onClick={() => setCouponDatas()}>

                    <Text>
                        <strong>
                            {datas.description}
                        </strong>
                    </Text>

                </ContentCoupom>

                <div 
                    onClick={() => setOpenTextRules(!openTextRules)}
                    className="buttonSelect"
                >
                    <p>
                        REGRAS
                        <IoMdArrowDropright />
                    </p>
                </div>


            </Content>

            
            <CouponRulesText 
                datas={datas}
                openTextRules={openTextRules}
            />

        </Container>
    )
}
