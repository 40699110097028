import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.7);
`

export const Header = styled.div`
    border-bottom: 1px solid #D3D3D3;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0 5px 0;

    div{
        width: 90%;
        font-weight: 500;

        display: flex;
        justify-content: center;

        p{
            font-size: 0.9rem;
        }
    }


    span{
        font-size: 1.5rem;
        border-left: 1px solid #D3D3D3;
        padding: 0 4px 0 4px;
        color: #000;
        cursor: pointer;
    }
`

export const Content = styled.div`
    height: 400px;
    width: 300px;
    background: #FFF;
    border-radius: 10px;

    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    
`

export const Options = styled.div`
    overflow-y: auto;
    width: 100%;
    margin-bottom: 6px;

    &::-webkit-scrollbar-track {
            background-color: #D3D3D3;
            border-radius: 4px;
    }

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: gray;
    scrollbar-track-color: #D3D3D3;

    .containerOption{
        width: 100%;
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #D3D3D3;
        cursor: pointer;

        p{
            width: 98%;
            margin: 2px auto 1px auto; 
        }
    }

    .option{
        width: 98%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;

        p{
            font-size: 1rem;
        }

        svg{
            font-size: 1.7rem;
            color: #808080;
        }


        div{
            display: flex;
            align-items: center;

            span{
                margin-right: 0.5rem;
                font-size: 0.9rem;
            }
        }


    }
`

