import { useAuth } from "../../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { CgGoogle, CgFacebook } from "react-icons/cg";
import { BiUser, BiLockAlt } from "react-icons/bi";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { Container, Logo, ButtonsContainer } from "./style";

export function LoginContent() {
	const [userLog, setUserLog] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [loadingAuth, setLoadingAuth] = useState(false);

	const { signIn, handleInputChecked, isChecked } = useAuth();
	const history = useHistory();
	const customId = 100;

	useEffect(() => {
		if (
			localStorage.getItem("@Inoveclube:token") &&
			JSON.parse(localStorage.getItem("@Inoveclube:checked"))
		) {
			history.push("/profile");
		}
	}, [history]);

	const submitHandler = useCallback(
		async (event) => {
			event.preventDefault();
			setLoadingAuth(true);

			try {
				await signIn({ userLog, password });
				localStorage.setItem("@Inoveclube:checked", isChecked);
				localStorage.removeItem("@Inoveclube:signUp");

				if (localStorage.getItem("@Inoveclube:menu")) {
					history.push("/shopping");
					return;
				}

				if (localStorage.getItem("@Inoveclube:adm")) {
					history.push("/adm");
					return;
				}

				if (localStorage.getItem("@Inoveclube:wholesale") === "true") {
					history.push(
						`/menu/${localStorage.getItem("@Inoveclube:nameBusiness")}`,
					);
					return;
				}

				if (localStorage.getItem("@Inoveclube:nameBusiness")) {
					history.push("/profile");
					return;
				} else history.push("/");
			} catch (error) {
				if (error.message === "blockedUser") {
					toast.error("Falha no login. Código UB02.");
					return;
				}
				toast.error("Usuário ou senha incorretos", { toastId: customId });
			} finally {
				setLoadingAuth(false);
			}
		},
		[userLog, password, signIn, history, isChecked],
	);

	return (
		<Container onSubmit={submitHandler}>
			<Logo />

			<div className="input-log">
				<BiUser />
				<input
					type="text"
					placeholder="Email, CPF ou CNPJ"
					value={userLog}
					onChange={(e) => setUserLog(e.target.value)}
					autoFocus
				></input>
			</div>

			<div className="input-log noMargin">
				<BiLockAlt />
				<input
					type={showPassword ? "text" : "password"}
					placeholder="Senha"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				></input>
				{showPassword ? (
					<MdVisibility
						onClick={() => setShowPassword(false)}
						className="visibility"
					/>
				) : (
					<MdVisibilityOff
						onClick={() => setShowPassword(true)}
						className="visibilityOff"
					/>
				)}
			</div>

			<div className="checkContent" onClick={() => handleInputChecked()}>
				<input type="checkbox" checked={isChecked} readOnly></input>
				<p>Continuar Conectado</p>
			</div>

			<Link to="/recover" className="span">
				<span>Esqueci minha senha</span>
			</Link>

			<ButtonsContainer>
				<button type="submit" className="logButton" disabled={loadingAuth}>
					{loadingAuth ? "CARREGANDO..." : "ACESSAR"}
				</button>

				<Link to="/register">
					<button className="logButton empty">CADASTRAR</button>
				</Link>

				{/* <p>Ou acesse com</p> */}
				<div className="buttons">
					<div className="facebookButton">
						<CgFacebook /> Facebook
					</div>
					<div className="googleButton">
						<CgGoogle /> Google
					</div>
				</div>
			</ButtonsContainer>
		</Container>
	);
}
