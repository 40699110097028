import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai'

import giftbox from '../../../images/giftbox.png'

import { Container, Content } from './style';

export function SaleCoupon({ saleCouponVisible, saleCoupon }) {
    const [closeModal, setCloseModal] = useState(true)
    const [animation, setAnimation] = useState(false)

    function closeSaleCouponModal() {
        setCloseModal(false)
    }

    useEffect(() => {
        if(saleCouponVisible)
            setTimeout(() => {
                setAnimation(true)
            }, 200)
    }, [saleCouponVisible])

    
    if (closeModal)
        document.body.style.overflow = "hidden"
    else
        document.body.style.overflow = "auto"

    return(
        <Container 
            saleCouponOpened={saleCouponVisible}
            closeModal={closeModal}
            onClick={closeSaleCouponModal}
        >
            <Content 
                saleCouponOpened={saleCouponVisible}
                animation={animation}
            >
                <img 
                    src={giftbox} 
                    alt="gitbox"
                />
                <p className="title">Cupom de desconto</p>

                <p className="couponName">{saleCoupon.description}</p>


                <div className="rules">
                    <p>Aplique o cupom na tela de finalização</p>
                    <p>*Consulte as regras</p>
                </div>

                <div className="discount">   
                    <p>{saleCoupon.typeDiscount === 0 ? 'R$' : ''} {Number.isInteger(saleCoupon.discountValue) ? saleCoupon.discountValue : Number(saleCoupon.discountValue).toFixed(2)} {saleCoupon.typeDiscount === 1 ? '%' : ''}</p>
                    <p>OFF</p>
                </div>

                <AiOutlineCloseCircle />
            </Content>
        </Container>
    )
}
