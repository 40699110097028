import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

  h3 {
    text-align: center;
  }
`;
