import styled from 'styled-components'

export const Container = styled.div`
    width: 95%;
    margin: 2rem auto 0 auto;

    .teste{
        display: flex;
        flex-wrap: wrap;
    }

    h4{
        font-size: 1.2rem;
    }
`
