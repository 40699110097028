import React,{useState,useEffect} from 'react';

import { Container,DayOfWeek,ContentCoupons } from './styles';
import moment from 'moment';

export function Hours({firstRound, secondRound}) {

   const [daysOpen,setDaysOpen] = useState({});
    
    useEffect(() => {
        const storage = localStorage.getItem('@Inoveclube:daysOpen');
        setDaysOpen(JSON.parse(storage));
    },[])

    function verifyingWorkingTime(timeOpen, timeClose, timeOpen2, timeClose2, text) { 
        if(timeOpen !== 'null' && timeOpen && timeClose){
            if(secondRound && (timeOpen2 !== timeClose2)){
                   return `${text} ${timeOpen} a ${timeClose} - ${timeOpen2} a ${timeClose2}`;
            }
            else{
                return `${text} ${timeOpen} a ${timeClose}`;
            }
        }
        else{
           return `${text} - Fechado`
        }        
    }

    const monday =  daysOpen.segNaoAbre ? 'SEG Fechado' :  verifyingWorkingTime(
        firstRound.openMon, 
        firstRound.closeMon, 
        secondRound?.openMon, 
        secondRound?.closeMon, 
        'SEG'
    )

    const tuesday = daysOpen.terNaoAbre ? 'TER Fechado' :  verifyingWorkingTime(
        firstRound.openTue, 
        firstRound.closeTue, 
        secondRound?.openTue, 
        secondRound?.closeTue, 
        'TER'
    )

    const wednesday = daysOpen.quaNaoAbre ? 'QUA Fechado' : verifyingWorkingTime(
        firstRound.openWed, 
        firstRound.closeWed,
        secondRound?.openWed, 
        secondRound?.closeWed,  
        'QUA'
    )

    const thursday = daysOpen.quiNaoAbre ? 'QUI Fechado' : verifyingWorkingTime(
        firstRound.openThu, 
        firstRound.closeThu,
        secondRound?.openThu, 
        secondRound?.closeThu,  
        'QUI'
    )

    const friday = daysOpen.sexNaoAbre ? 'SEX Fechado' :  verifyingWorkingTime(
        firstRound.openFri, 
        firstRound.closeFri,
        secondRound?.openFri, 
        secondRound?.closeFri,          
        'SEX'
    )

    const saturday = daysOpen.sabNaoAbre ? 'SAB Fechado' : verifyingWorkingTime(
        firstRound.openSat, 
        firstRound.closeSat,
        secondRound?.openSat, 
        secondRound?.closeSat,  
        'SAB'
    )

    const sunday = daysOpen.domNaoAbre ? 'DOM Fechado' :verifyingWorkingTime(
        firstRound.openSun, 
        firstRound.closeSun,
        secondRound?.openSun, 
        secondRound?.closeSun,  
        'DOM'
    )

    return (
        <Container>
            <h4>Horário de funcionamento</h4>

            <ContentCoupons>
                <DayOfWeek
                    active={moment().isoWeekday() === 1}
                >
                    <p>{monday}</p>
                </DayOfWeek>

                <DayOfWeek
                    active={moment().isoWeekday() === 2}
                >
                    <p>{tuesday}</p>
                </DayOfWeek>

                <DayOfWeek
                    active={moment().isoWeekday() === 3}
                >
                    <p>{wednesday}</p>
                </DayOfWeek>

                <DayOfWeek
                    active={moment().isoWeekday() === 4}
                >
                    <p>{thursday}</p>
                </DayOfWeek>

                <DayOfWeek
                    active={moment().isoWeekday() === 5}
                >
                    <p>{friday}</p>
                </DayOfWeek>

                <DayOfWeek
                    active={moment().isoWeekday() === 6}
                >
                    <p>{saturday}</p>
                </DayOfWeek>

                <DayOfWeek
                    active={moment().isoWeekday() === 7}
                >
                    <p>{sunday}</p>
                </DayOfWeek>
            </ContentCoupons>
        </Container>
    )
}
