import { ImSad } from 'react-icons/im'

import { Container } from './style';

export function ModalCouponsEmpty() {
  return(
      <Container>
            <p>Nenhum cupom disponível</p>
            <ImSad />
      </Container>
  )
}
