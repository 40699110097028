import { Button } from "../../../../../../components/Button";
import { InputCheck } from "../../../../../../components/Forms/InputCheck";
import { SmallModal } from "../../../../../../components/smallModal";
import { Container, Advice } from "./style";

export function ChoseDay({
	availableDays,
	toClose,
	day,
	setDay,
	setCurrentStep,
	setSchedulingDate,
}) {
	// Functions
	function verifyDisableDay({ day }) {
		return !availableDays.some((availableDay) => availableDay.weekDay === day);
	}

	function handleChoseDate({ day }) {
		if (!verifyDisableDay({ day: day })) {
			setSchedulingDate("change");
			setDay(day);
		}
	}

	// ==== Return ====
	return (
		<SmallModal title="Agendamento de pedido" toClose={toClose}>
			<Container>
				<Advice>
					Loja fechada no momento <br />
					Selecione um dia para agendar seu pedido
				</Advice>
				<ul>
					<li>
						<InputCheck
							checked={day === "sunday" && !verifyDisableDay({ day: "sunday" })}
							disabled={verifyDisableDay({ day: "sunday" })}
							onClick={() => handleChoseDate({ day: "sunday" })}
						/>
						Domingo
					</li>
					<li>
						<InputCheck
							checked={day === "monday" && !verifyDisableDay({ day: "monday" })}
							disabled={verifyDisableDay({ day: "monday" })}
							onClick={() => handleChoseDate({ day: "monday" })}
						/>
						Segunda
					</li>
					<li>
						<InputCheck
							checked={day === "tuesday" && !verifyDisableDay({ day: "tuesday" })}
							disabled={verifyDisableDay({ day: "tuesday" })}
							onClick={() => handleChoseDate({ day: "tuesday" })}
						/>
						Terça
					</li>
					<li>
						<InputCheck
							checked={day === "wednesday" && !verifyDisableDay({ day: "wednesday" })}
							disabled={verifyDisableDay({ day: "wednesday" })}
							onClick={() => handleChoseDate({ day: "wednesday" })}
						/>
						Quarta
					</li>
					<li>
						<InputCheck
							checked={day === "thursday" && !verifyDisableDay({ day: "thursday" })}
							disabled={verifyDisableDay({ day: "thursday" })}
							onClick={() => handleChoseDate({ day: "thursday" })}
						/>
						Quinta
					</li>
					<li>
						<InputCheck
							checked={day === "friday" && !verifyDisableDay({ day: "friday" })}
							disabled={verifyDisableDay({ day: "friday" })}
							onClick={() => handleChoseDate({ day: "friday" })}
						/>
						Sexta
					</li>
					<li>
						<InputCheck
							checked={day === "saturday" && !verifyDisableDay({ day: "saturday" })}
							disabled={verifyDisableDay({ day: "saturday" })}
							onClick={() => handleChoseDate({ day: "saturday" })}
						/>
						Sábado
					</li>
				</ul>
				<Button
					className="button"
					disabled={!day}
					onClick={() => {
						if (day) {
							setCurrentStep("time");
						}
					}}
				>
					Continuar
				</Button>
			</Container>
		</SmallModal>
	);
}
