import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    border-bottom: 0.5px solid gray;
    color: gray;

    p{
        text-align: center;
    }
    
    .centered-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0px;
        svg{
            font-size: 60px;
            color: gray;
        }
        div{
            border-radius: 50%;
            border: 4px solid var(--colorButton);
            padding: 0.5rem;
            margin-bottom: 0.2rem;
        }
    }
`