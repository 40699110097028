import { useQuery } from 'react-query'
import { api } from '../api'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom'


export async function getAllOrders(types) {
    
    const response = await api.get('/getOrders/' + localStorage.getItem('@Inoveclube:token'), {
        headers: {
            token: localStorage.getItem('@Inoveclube:token')
        },
        params:{
            types: types.join()
        }
    })

    const data = response.data 

    const lastOrder = data.shift()

    const genericDatas = data.map((item) => {
        return{
            ...item,
            qrCodeUrl: item.status === 'Payment confirmed' ? null : item.qrCodeUrl
        }
    });

    return {
        genericDatas,
        lastOrder
    }
}


export function useOrder(type = []) {
    const { signOut } = useAuth()
    const history = useHistory()

    return useQuery(
        ['orders', type],
        () => getAllOrders(type),
        {
            //staleTime: 1000 * 30, //demorando 30 segundos para atualizar a funcao
            refetchInterval: 1000 * 30, // 30 seconds
            refetchIntervalInBackground: true,
            onError: () => {
                signOut()
                history.push('/login')
                toast.warning('Sua sessão expirou, faça login novamente')
            }
        }
    )
}