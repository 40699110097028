import { useState, useCallback } from "react"
import { Container } from "./style"
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"
import { formatMoney } from "../../../../../Utils/formatMoney"
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io"
import { useModalItemManager } from "../../../../ItemModalContext"
import { useEffect } from "react"

export function Option({
	obsDescription,
	image,
	cdOption,
	price,
	objectE,
	counterOptionsQuantity,
	setCounterOptionsQuantity,
	uniqueSelected,
	setUniqueSelected,
	businessDatas
}) {
	const [qtde, setQtd] = useState(0)
	const { addOption } = useModalItemManager()

	const unique = objectE.maxQuantity === 1 && !objectE.unlimited

	const addCount = useCallback(() => {
		let qtd = qtde + 1
		if (addOption({ obsDescription, price, cdOption, qtd, objectE }, 1)) {
			if (unique) setUniqueSelected(cdOption)
			setQtd(qtd)
			setCounterOptionsQuantity(counterOptionsQuantity + 1)
		}
	}, [
		qtde,
		setQtd,
		obsDescription,
		price,
		cdOption,
		addOption,
		objectE,
		counterOptionsQuantity,
		setCounterOptionsQuantity,
		setUniqueSelected,
		unique,
	])

	const subCount = useCallback(() => {
		let qtd = qtde
		if (qtd > 0) {
			qtd--
		} else {
			qtd = 0
		}
		if (addOption({ obsDescription, price, cdOption, qtd, objectE }, -1)) {
			setQtd(qtd)

			if (qtde > 0) setCounterOptionsQuantity(counterOptionsQuantity - 1)
		}
	}, [
		qtde,
		setQtd,
		obsDescription,
		price,
		cdOption,
		addOption,
		objectE,
		counterOptionsQuantity,
		setCounterOptionsQuantity,
	])

	useEffect(() => {
		if (uniqueSelected !== cdOption && unique && qtde > 0) {
			setQtd(0)
			setCounterOptionsQuantity(counterOptionsQuantity - 1)
		}
	}, [
		uniqueSelected,
		unique,
		qtde,
		counterOptionsQuantity,
		cdOption,
		setCounterOptionsQuantity,
	])

	return (
		<Container qtde={qtde}>
			<p
				className="clickable description"
				onClick={() => {
					unique && qtde === 1 ? subCount() : addCount()
				}}
			>
				{image ? <img src={image} alt="" /> : null} 
				{obsDescription}
			</p>

			{price > 0 ? (
				<p
					className="price clickable"
					onClick={() => {
						unique && qtde === 1 ? subCount() : addCount()
					}}
				>
					+{formatMoney(price, businessDatas.formatoMoeda)}
				</p>
			) : (
				<></>
			)}

			{unique ? (
				qtde === 1 ? (
					<IoIosRadioButtonOn
						className="radioButtonOn"
						onClick={() => subCount()}
					/>
				) : (
					<IoIosRadioButtonOff onClick={() => addCount()} />
				)
			) : (
				<div className="num-counter">
					<AiOutlineMinus
						className="minus clickable"
						onClick={() => {
							subCount()
						}}
					/>
					<span>{qtde}</span>
					<AiOutlinePlus
						className="plus clickable"
						onClick={() => {
							addCount()
						}}
					/>
				</div>
			)}
		</Container>
	)
}
