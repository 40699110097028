import moment from "moment/moment";
import { ToPrint } from "./styles";
import { Fragment } from "react";
import { formatMoney } from "../../../../Utils/formatMoney";
import { useOrderManagerContext } from "../../context";

export function PrintOrder() {
	const { companies, currentOrder, currentOrderInfos, orderIsPrinting } =
		useOrderManagerContext();
	// condition to return print content
	if (orderIsPrinting) {
		if (currentOrder === null || currentOrderInfos === null) {
			return null;
		} else if (currentOrder.dsStatus === "A" || currentOrder.dsStatus === "P") {
			return (
				<ToPrint>
					<div className="heading">
						<span className="center">
							N&deg; {currentOrder.nrOrcamentoDia}
						</span>
						{currentOrder.tipoPedido === "B" && (
							<div>
								<span>Pedido:</span>
								<span>{currentOrder.idOrcamento}</span>
							</div>
						)}
						<div>
							<span>Atendente:</span>
							<span>{companies[0].nmPessoa}</span>
						</div>
						<div>
							<span>Origem:</span>
							<span>Inove Clube</span>
						</div>
						<div>
							<span>Emissão:</span>
							<span>
								{moment(currentOrder.dtEmissao).format(
									"DD-MM-YYYY, h:mm:ss"
								)}
							</span>
						</div>
						<div>
							<span className="emphasis">
								{currentOrderInfos.typeShoppingDescription}:
							</span>
							<span>{currentOrder.nmPessoa}</span>
						</div>
					</div>
					<div className="content">
						<div className="grid">
							<span>QTD</span>
							<span>Descrição</span>
						</div>
						{currentOrderInfos.products.map((product, idx) => {
							return (
								<Fragment key={idx}>
									<div className="grid">
										<span>{product.quantity}</span>
										<span>{product.name}</span>
									</div>
									{product.obs !== "" && (
										<div className="grid spaced">
											<span>obs: </span>
											<span> {product.obs}</span>
										</div>
									)}
									{product.additionals.map((element, idx) => {
										return (
											<div className="grid spaced" key={idx}>
												<span>{element.quantity}</span>
												<span>{element.name}</span>
											</div>
										);
									})}
								</Fragment>
							);
						})}
						<strong>
							{currentOrder.consumirNaLoja
								? "Pedido para consumir na loja"
								: "Pedido para retirada"}
						</strong>
					</div>
				</ToPrint>
			);
		} else {
			return (
				<ToPrint>
					<div className="infos">
						<span className="emphasis">{currentOrder.dsEmpresa}</span>
						<span>{currentOrder.cnpj}</span>
						<div>
							<span>{currentOrder.dsEndereco}, </span>
							<span>{currentOrder.dsNumero}, </span>
							<span>{currentOrder.dsBairro} - </span>
							<span>{currentOrder.dsCidade} - </span>
							<span>{currentOrder.dsUf}</span>
						</div>
						<span>TEL: {currentOrder.dsTelefone}</span>
					</div>
					<span className="emphasis-borded">
						{currentOrderInfos.typeShoppingDescription}
					</span>
					{currentOrder.tipoPedido === "B" && (
						<span className="emphasis-borded">
							N&deg; {currentOrder.nrOrcamentoDia}
						</span>
					)}
					<div className="infos">
						<span>Pedido: {currentOrder.idOrcamento}</span>
						<span>Atendente: {companies[0].nmPessoa}</span>
						<span>Origem: InoveClube</span>
						<span>
							Emissão:{" "}
							{moment(currentOrder.dtEmissao)
								.utc()
								.format("DD/MM/YYYY, hh:mm:ss")}
						</span>
						<span>Nome: {currentOrder.nmPessoa}</span>
						<span>Fone: {currentOrder.telefone}</span>
						{currentOrderInfos.typeShopping === "DELIVERY" ? (
							<>
								<span>Endereço: {currentOrderInfos.address.street}</span>
								<span>Número: {currentOrderInfos.address.number}</span>
								<span>Bairro: {currentOrderInfos.address.neighborhood}</span>
								<span>CEP: {currentOrderInfos.address.zipCode}</span>
								<span>
									Complemento: {currentOrderInfos.address.complement}
								</span>
								<span>Próximo: {currentOrderInfos.address.reference}</span>
								<span>
									Forma de Pagamento:{" "}
									{currentOrderInfos.paymentMethod.description}
								</span>
								{currentOrderInfos.paymentMethod.changeValue !== 0 ? (
									<span>
										Valor do pagamento:{" "}
										{currentOrderInfos.paymentMethod.changeValue}
									</span>
								) : null}
							</>
						) : null}
					</div>
					<div className="grid-head border">
						<span>COD</span>
						<span>Descrição</span>
						<span>QTD</span>
						<span>V.UNI</span>
						<span>Desc</span>
						<span>Total</span>
					</div>
					{currentOrderInfos.products.map((product, idx) => {
						return (
							<Fragment key={idx}>
								<div className="grid-body">
									<span>{product.idProduct}</span>
									<span>{product.name}</span>
									<span>{product.quantity}</span>
									<span>{product.currentPrice.toFixed(2)}</span>
									<span>{product.discount.toFixed(2)}</span>
									<span>{product.total.toFixed(2)}</span>
								</div>
								{product.obs !== "" && (
									<div className="grid-body">
										<span></span>
										<span>obs: </span>
										<span> {product.obs}</span>
									</div>
								)}
								{product.additionals.map((element, idx) => {
									return (
										<div className="grid-body" key={idx}>
											<span></span>
											<span>{element.name}</span>
											<span>{element.quantity}</span>
											<span>{element.price.toFixed(2)}</span>
										</div>
									);
								})}
							</Fragment>
						);
					})}
					<div className="prices">
						{currentOrderInfos.typeShopping === "DELIVERY" ? (
							<div>
								<span>Taxa Entrega</span>
								<span>----------&gt;&gt;</span>
								<span>{formatMoney(currentOrderInfos.feeDelivery)}</span>
							</div>
						) : null}
						<div>
							<span>itens</span>
							<span>----------&gt;&gt;</span>
							<span>{formatMoney(currentOrderInfos.subtotal)}</span>
						</div>
						<div>
							<span>Serviços</span>
							<span>----------&gt;&gt;</span>
							<span>{formatMoney(currentOrderInfos.additionalFees)}</span>
						</div>
						<div>
							<span>Total</span>
							<span>----------&gt;&gt;</span>
							<span>{formatMoney(currentOrderInfos.total)}</span>
						</div>
						{currentOrderInfos.paymentMethod.changeValue !== 0 ? (
							<div>
								<span>Troco</span>
								<span>----------&gt;&gt;</span>
								<span>
									{formatMoney(
										currentOrderInfos.paymentMethod.changeValue -
											currentOrderInfos.total
									)}
								</span>
							</div>
						) : null}
						<div>
							<span>QTD Itens</span>
							<span>
								{currentOrderInfos.products.reduce(
									(soma, valor) => soma + valor.quantity,
									0
								)}
							</span>
						</div>
					</div>
					<div className="payment">
						{currentOrderInfos.typeShopping === "DELIVERY" ? null : (
							<span className="signature">Assinatura do cliente</span>
						)}
						<span>Formas de pagamento</span>
						<div>
							<span>{currentOrderInfos.paymentMethod.description} </span>
							<span> {formatMoney(currentOrderInfos.total)}</span>
						</div>
					</div>
					<div className="footer">
						<span>Documento sem valor fiscal</span>
						<span>Sistema para seu negócio</span>
						<span>(79) 9 9898-5004</span>
						<span>@inovesystem</span>
					</div>
				</ToPrint>
			);
		}
	} else {
		return null;
	}
}
