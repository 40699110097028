import { useEffect, useRef, useState, useCallback } from "react";
import { Group } from "./Group";
import { SelectButton } from "./SelectButton";
import { ImageModal } from "../Modal/ImageModal";
import { ItemModal } from "../Modal/ItemModal";

import { Container, ContainerSelectButtons } from "./style";
import { useMenu } from "../../context/MenuContext";
import { useModalItemManager } from "../ItemModalContext";

export function MenuTwo({
	productsWithGroups,
	businessDatas,
	handleSetListBuying,
	listBuying,
	searchResults,
	searchTerm,
	infoOpen,
}) {
	let listPositionGroup = [];
	let listPositionButton = [];
	let position = 0;

	const [indexSelected, setIndexSelected] = useState(0);
	const ref = useRef(null);

	const {
		item,
		imageModal,
		isBuyModalOpen,
		handleSetIsBuyModalOpen,
		handleSetItem,
		handleSetImageModal,
	} = useMenu();
	const {
		handleSetTextAdditional,
		handleSetSizeButtonSelected,
		handleSetSelectedOptions,
		handleSetListAssemblyItensSelected,
	} = useModalItemManager();

	if (
		imageModal.show === true ||
		item.show === true ||
		isBuyModalOpen === true ||
		infoOpen
	) {
		document.body.style.overflow = "hidden";
	} else if (
		imageModal.show === false ||
		item.show === false ||
		isBuyModalOpen === false ||
		infoOpen === false
	) {
		document.body.style.overflow = "auto";
	}

	function setListPositionGroup(groupRef) {
		listPositionGroup.push(groupRef);
	}

	function setListPositionButton(buttonRef) {
		listPositionButton.push(buttonRef);
	}

	//console.log(productsWithGroups)

	function handleScroll() {
		position = window.pageYOffset;

		for (let i = 0; i < listPositionGroup.length; i++) {
			let offsetTop = listPositionGroup[i]?.current?.offsetTop - 197;
			if (i === listPositionGroup.length - 1 && offsetTop <= position) {
				setIndexSelected(i);
				ref.current?.scrollTo({
					top: 0,
					left: listPositionButton[i].current?.offsetLeft,
					behavior: "smooth",
				});
			} else if (
				offsetTop <= position &&
				position < listPositionGroup[i + 1]?.current.offsetTop - 197
			) {
				setIndexSelected(i);
				ref.current?.scrollTo({
					top: 0,
					left:
						listPositionButton[i].current?.offsetLeft >
						window.screen.width / 2
							? listPositionButton[i].current?.offsetLeft
							: 0,
					behavior: "smooth",
				});
				break;
			}
		}
	}

	function handleClickSelectButton(index) {
		window.scroll({
			top: listPositionGroup[index].current.offsetTop - 190,
			behavior: "smooth",
		});
	}

	const [mouseEvent, setMouseEvent] = useState({
		isScrolling: false,
		clientX: 0,
		scrollX: 0,
	});

	function mouseDown(e) {
		if (ref && ref.current && !ref.current.contains(e.target)) return;
		e.preventDefault();
		setMouseEvent({
			...mouseEvent,
			isScrolling: true,
			clientX: e.clientX,
		});
	}

	function mouseUp(e) {
		if (ref && ref.current && !ref.current.contains(e.target)) return;
		e.preventDefault();
		setMouseEvent({
			...mouseEvent,
			isScrolling: false,
		});
	}

	function mouseMove(e) {
		if (ref && ref.current && !ref.current.contains(e.target)) return;
		e.preventDefault();

		const { clientX, isScrolling, scrollX } = mouseEvent;

		if (isScrolling) {
			const dx = e.clientX - clientX;
			ref.current.scrollLeft = scrollX - dx;
			let sX = scrollX - dx;
			let cX = e.clientX;
			setMouseEvent({
				...mouseEvent,
				scrollX: sX,
				clientX: cX,
			});
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", mouseDown);
		document.addEventListener("mouseup", mouseUp);
		document.addEventListener("mousemove", mouseMove);

		return () => {
			document.removeEventListener("mousedown", mouseDown);
			document.removeEventListener("mouseup", mouseUp);
			document.removeEventListener("mousemove", mouseMove);
		};
	});

	const detectHistory = useCallback(() => {
		handleSetIsBuyModalOpen(false);
		handleSetImageModal({ show: false });
		handleSetItem({ code: 0, show: false });

		handleSetSizeButtonSelected(0);
		handleSetTextAdditional("");
		handleSetSelectedOptions([]);
		handleSetListAssemblyItensSelected([]);
	}, [
		handleSetImageModal,
		handleSetIsBuyModalOpen,
		handleSetItem,
		handleSetListAssemblyItensSelected,
		handleSetSelectedOptions,
		handleSetSizeButtonSelected,
		handleSetTextAdditional,
	]);

	useEffect(() => {
		if (isBuyModalOpen || imageModal.show || item.show) {
			window.history.pushState({ id: 1 }, null, null);
		}
	}, [isBuyModalOpen, imageModal, item]);

	useEffect(() => {
		if (isBuyModalOpen || imageModal.show || item.show) {
			window.addEventListener("popstate", detectHistory);
		}
	}, [detectHistory, isBuyModalOpen, imageModal, item]);

	if (searchTerm === "") {
		window.addEventListener("scroll", handleScroll);
	} else {
		window.removeEventListener("scroll", handleScroll);
	}

	return (
		<>
			<ImageModal />

			<ItemModal
				businessDatas={businessDatas}
				listBuying={listBuying}
				handleSetListBuying={handleSetListBuying}
			/>

			<Container>
				{searchResults.length > 0 && searchTerm !== "" ? (
					<></>
				) : (
					<div className="stickyPosition">
						<ContainerSelectButtons
							ref={ref}
							onMouseDown={mouseDown}
							onMouseUp={mouseUp}
							onMouseMove={mouseMove}
						>
							{productsWithGroups.map((groups, index) => (
								<SelectButton
									key={groups.idGroup}
									active={indexSelected === index}
									setListPositionButton={setListPositionButton}
									setIndexSelected={setIndexSelected}
									colorButton={businessDatas.colorButton}
									index={index}
									handleClickSelectButton={handleClickSelectButton}
									groupName={groups.name}
								/>
							))}
						</ContainerSelectButtons>
					</div>
				)}

				{productsWithGroups.map((groups, index) => (
					<Group
						key={index}
						title={groups.name}
						products={groups.products}
						setListPositionGroup={setListPositionGroup}
						businessDatas={businessDatas}
						searchResults={searchResults}
						searchTerm={searchTerm}
					/>
				))}
			</Container>
		</>
	);
}
