import styled, { css } from "styled-components";

export const Container = styled.div`
    padding: 0.7rem;
    background: #50a171D9;
    color: #FFF;
    font-size: 1.8rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 4px 8px rgb(255 255 255 / 600%);
    transform: translateX(120%);
    transition: transform 0.3s ease;
    border: 3px solid #FFF;
    
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 99;

    position: fixed;
    top: 140px;
    right: 3px;

    div{
        position: absolute;
        left: 0;
        bottom: -50%;
        background: #FFF;
        font-size: 12.5px;
        color: #50a171;
        padding: 0.5rem;
        width: 250px;
        text-align: left;
        border: 2px solid #50a171;
        border-radius: 0.3rem;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: translateX(100%);
        box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 4px 8px rgb(255 255 255 / 600%);

        ${props => props.openMessage && css`
            opacity: 1;
            transform: translateX(-104%);
        `}

        &::after{
            content: '';
            position: absolute;
            top: 2px;
            right: 0;
            transform: translateX(100%);
            border: 9px solid transparent;
            border-left-color: #50a171;
        }
    }

    ${props => props.transition && css`
        transform: translateX(0);
    `}

    ${props => props.couponExistis && css`
        display: flex;
    `}
`