import { IoWarning } from 'react-icons/io5'
import { MdRadioButtonUnchecked } from 'react-icons/md'

import { Container, Content } from './style';


export function NeighborhoodAlert({ warningNeighborhoods, setWarningNeighborhoods, setNeighborhood }) {
    function handleClose() {
        setWarningNeighborhoods({ list: [], open: false })
    }

    if(!warningNeighborhoods.open)
        return <></>

    return(
        <Container onClick={handleClose}>
            <Content>
                <div className="header">
                    <IoWarning />
                    <h3>Bairro não encontrado</h3>
                </div>

                {
                    warningNeighborhoods.list.length > 0 ?
                    <>
                        <h4>Você quis dizer:</h4>

                        <div className="listNeighborhood">
                            {
                                warningNeighborhoods.list.map((neighborhood, index) => (
                                    <div
                                        key={index}
                                        onClick={() => setNeighborhood(neighborhood)}
                                    >
                                        <MdRadioButtonUnchecked />
                                        <p>{neighborhood}</p>
                                    </div>
                                ))
                            } 
                        </div>
                    </>
                    :
                    <h4>Verifique se o bairro digitado está correto.</h4>
                }
            </Content>
        </Container>
    )
}
