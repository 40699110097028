import styled from "styled-components"

export const Container = styled.div`
	input {
		width: 100%;
		padding: 1rem;
		border: 1px solid var(--gray-200);
		border-radius: 8px;
	}
	.button {
		margin-top: 1rem;
		background-color: var(--green-500);
	}
`
