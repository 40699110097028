import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fafafa;

`

export const SideBarAndContent = styled.div`
    flex: auto;

    display: grid;

    grid-template-columns: 3rem 9fr;
    grid-template-rows: 1fr;

    overflow-y: auto;
`

export const LoaderContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: #FFF;

    display: flex;
    align-items: center;
    justify-content: center;
`