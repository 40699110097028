import { Container } from "./style";
import { SpaceLoader } from "../../../../../components/loaders/spaceLoader";
import { GiPresent } from "react-icons/gi";
import { BsTicketPerforated } from "react-icons/bs";
import { useEffect, useState } from "react";

export function Stores({
	stores,
	nextPage,
	idSelectedFilter,
	getStores,
	backgroundColor,
	textColor,
	sectionLoading,
	setSectionLoading,
	type,
}) {
	const [citys, setCitys] = useState([]);
	useEffect(() => {
		const unicCitys = [];
		stores.forEach((store) => {
			if (!unicCitys.includes(store.city)) unicCitys.push(store.city);
		});
		setCitys(unicCitys);
	}, [stores]);

	return (
		<Container>
			<div className={`${sectionLoading === "stores" ? "pulse-loading" : ""}`}>
				{citys.map((element, idx) => (
					<div key={idx} className="wrapper">
						<h3>{element}</h3>
						<div className="content">
							{stores.map((store, idx) => {
								if (store.city === element) {
									return (
										<a
											href={`https://www.inoveclube.com/menu/${store.url}`}
											className="card"
											key={idx}
										>
											{store.image ? (
												<img
													className="img"
													src={store.image}
													alt=""
												/>
											) : (
												<div className="img" />
											)}
											<div className="info">
												<h2 className="title">{store.title}</h2>
												<div className="wrapper">
													<span className="category">
														{store.category}
													</span>
													<div className="icons-wrapper">
														{store.hasCupom ? (
															<BsTicketPerforated className="green" />
														) : (
															<></>
														)}
														{store.hasFideliti ? (
															<GiPresent className="green" />
														) : (
															<></>
														)}
													</div>
												</div>
												<div>
													<span className="time">
														Entrega: {store.minDeliveryTime} -{" "}
														{store.maxDeliveryTime} min
													</span>
												</div>
											</div>
										</a>
									);
								} else {
									return null;
								}
							})}
						</div>
					</div>
				))}
				{nextPage && (
					<button
						className="load-more"
						onClick={() => {
							setSectionLoading("nextPage");
							getStores(idSelectedFilter, nextPage);
						}}
						style={{
							backgroundColor: backgroundColor
								? backgroundColor
								: "var(--gray-200)",
							color: textColor ? textColor : "var(--gray-700)",
						}}
					>
						{sectionLoading === "nextPage" ? (
							<SpaceLoader />
						) : (
							"Carregar mais"
						)}
					</button>
				)}
			</div>
		</Container>
	);
}
