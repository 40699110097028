import styled, { css } from "styled-components";

export const Container = styled.div` 
    height: 100%;
    width: 100%;
    background: #FFF;
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    h1{
        margin-bottom: 2px;
    }

    .contentRadioButtons{
        width: 100%;

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        ${props => props.fullWidth && css`
            margin: 0 auto;
            max-width: 600px;
            grid-template-columns: repeat(1, 1fr);
        `}
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`

export const RadioButton = styled.div`
    text-align: center;
    padding: 1rem;
    font-weight: 500;
    color: gray;
    position: relative;
    font-size: 0.9rem;
    margin: 0 0.4rem; 

    @media (min-width: 960px) {
        cursor: pointer;
    }

    &::after{
        content: '';
        height: 3px;
        border-radius: 0 0 3px 3px;
        width: 100%;
        position: absolute;
        bottom: 1px;
        left: 0;
        background: var(--colorButton);
        display: none;
    }

    ${props => props.active && css`
        color: var(--colorButton);

        &::after{
            display: block;
        }
    `}
`

export const Content = styled.div`
    flex: auto;
    overflow-y: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;

    &::-webkit-scrollbar-track {
        background-color: #D3D3D3;
        border-radius: 4px;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: gray;
    scrollbar-track-color: #D3D3D3;
`
