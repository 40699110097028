import './style.css'

export function MainLoader({ title }){
    return(       
        <div className="loader-container">
            <div className="loader"></div>
            <span className="label-loader">{title}</span>
        </div>
    )
}
