import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	padding: 1rem;
	border-top: 1px solid var(--gray-200);
	.content {
		margin: 0 auto;
		max-width: 1200px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			width: 5rem;
			height: 5rem;
			background-color: var(--gray-200);
			border-radius: 8px;
		}
		.navegation {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			ul {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				align-items: center;
				list-style: none;
				.modal-link {
					cursor: pointer;
				}
			}
		}
	}
	.inove {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 2rem;
		gap: 0.5rem;
		color: var(--gray-200);
		img {
			width: 4rem;
		}
	}

	@media (min-width: 768px) {
		.content {
			padding: 0 2rem;
			.navegation {
				flex-direction: column;
				align-items: end;
				gap: 1rem;
				ul {
					flex-direction: row;
					gap: 2rem;
					a,
					.modal-link {
						transition: opacity 0.15s ease-out;
						&:hover {
							opacity: 0.75;
						}
						&:active {
							opacity: 0.5;
						}
					}
				}
			}
		}
	}
`;
