import { BiCheckCircle } from 'react-icons/bi'
import { formatMoney } from '../../../../Utils/formatMoney'

import { Header, Container, Content, Options } from './style'

export function AssemblyModal({ composition, setAssemblyModalOpened, handleSetAssembledItens, businessDatas  }) {

    
    return(
        <Container>
            <Content>
                <Header>
                    <div>
                        <p>Selecione um item</p>
                    </div>
                    <span onClick={() => setAssemblyModalOpened(false)}>&#10005;</span>
                </Header>
                <Options>
                {
                        composition.map(elem =>(
                            <div 
                                key={elem.idProduct} 
                                className="containerOption"
                                onClick={async () =>{
                                    await handleSetAssembledItens( {idProduct: elem.idProduct, name: elem.name, price: elem.price, filled: true})
                                    setAssemblyModalOpened(false) 
                                }}
                            >
                                <div className="option">
                                    <p>{elem.name}</p>
                                    <div>
                                        <span>
                                            { formatMoney(elem.price, businessDatas.formatoMoeda) }
                                        </span>
                                        <BiCheckCircle />
                                    </div>
                                </div>
                                <p>{elem.additionalInformation}</p>
                            </div>
                        ))
                    }
                </Options>
            </Content>
        </Container>
    )

}