import styled, {keyframes} from "styled-components";

const animationSvg = keyframes`
    0%{
        transform: rotate(0);
    }
    25%{
        transform: rotate(4deg);
    }
    50%{
        transform: rotate(0deg);
    }
    75%{
        transform: rotate(-4deg);
    }
    100%{
        transform: rotate(0);
    }
`;

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
`;

export const Content = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
    width: 100%;
    max-width: 400px;
    min-height: 20rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 4px;

    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFF;
    position: relative;
`;

export const Main = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    p{
        font-size: 1.1rem;
    }

    .containerButtons{
        width: 100%;
        display: flex;
        align-items: center;
        color:  #FFF;
        gap: 1rem;
        
        button{
            flex: 1;
            border: none;
            padding: 20px;
            background: var(--colorButton);
            color: var(--colorButtonBottom);
            border-radius: 0.3rem;
            opacity: 0.7;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            svg{
                font-size: 1.8rem;

                animation: ${animationSvg} ease-in-out 1s infinite;
            }
        }
        
        @media(max-width: 400px){
            flex-direction: column;

            button{
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #393939;

    button{
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #393939;
        font-size: 1.1rem;
    }
`;


