import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    width: 100%;
    background: #FFF;
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    h1{
        margin-bottom: 2px;
    }

    .scrollingArea{
        flex: auto;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            background-color: #D3D3D3;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 4px;
        }

        scrollbar-width: thin;
        scrollbar-color: gray;
        scrollbar-track-color: #D3D3D3;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`

export const Content = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .fill{
        width: 100%;
        min-height: 3rem;
    }

    .buttonContainer{
        position: fixed;
        bottom: 0;
        background: #FFF;
        width: 90%;
        display: flex;
        justify-content: center;
        svg{
            min-height: 3rem;
            font-size: 3rem;
            color: var(--colorButton);
            cursor: pointer;
            margin-bottom: 5px;
        }
    }

    .cardAddress{
        width: 70%;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        margin: 20px 0 20px 0;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: gray;

        .textAddress{
            width: 80%;
            padding: 2rem 0 2rem 0;
            display: flex;
            align-items: center;

            svg{
                min-width: 10%;
                font-size: 1.5rem;
                margin: 0 0.5rem 0 0.5rem;
            }
        }

        .editAddress{
            width: 20%;
            height: 100%;
            font-size: 1.5rem;
            color: gray;

            display: flex;
            justify-content: center;
        }

        span{
            font-size: 1rem;
            font-weight: 500;
        }

        p{
            font-size: 1rem;
            color: gray;
        }

        @media (max-width: 500px){
            svg{
                font-size: 1.4rem;
                margin-right: 10px;
                margin-left: 5px;
            }
        }
        
    }

    .selected{
        border: 2px solid var(--colorButton);
        border-radius: 0.3rem;
       

        svg{
            color: var(--colorButton);
        }

        p{
            color: var(--colorButton);
        }

        span{
            color: var(--colorButton);
        }
    }

`