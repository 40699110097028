import { useState } from 'react';

import { SiWhatsapp, SiInstagram, SiFacebook } from 'react-icons/si'

import { Container, Content, ButtonsNavigate} from './style';
import { Contacts } from './Contacts';
import {Hours} from './Hours';

export function ModalBusinessInformations({ open, closeModal, firstRound, secondRound }) {
    const [typeInformations,setTypeInformations] = useState('contacts')

    return(
        <Container open={open}>
            <Content color={localStorage.getItem('@Inoveclube:colorButton')}>
                <span onClick={() => closeModal(false)}>
                    &#10006;
                </span>
                
                <ButtonsNavigate color={localStorage.getItem('@Inoveclube:colorButton')}>
                    <button 
                        className={typeInformations === 'contacts' ? 'active' : ''}
                        onClick={() => setTypeInformations('contacts')}
                    >
                        Contatos
                    </button>
                    
                    <button 
                        className={typeInformations === 'hours' ? 'active' : ''}
                        onClick={() => setTypeInformations('hours')}
                    >
                        Horários
                    </button>
                </ButtonsNavigate>


                {
                    typeInformations === 'contacts' ?
                    <Contacts/>
                    :
                    <Hours
                        firstRound={firstRound}
                        secondRound={secondRound}
                    />
                }
              

                <div className="socialMedias">
                    <a 
                        href={`https://api.whatsapp.com/send?phone=+55${JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp}`}
                        style={{display: JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp !== '' ? '' : 'none'}}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SiWhatsapp />
                    </a>
                   
                    <a 
                        href={`https://www.instagram.com/${JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.instagram}/`}
                        style={{display: JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.instagram !== '' ? '' : 'none'}}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SiInstagram />
                    </a>

                    <a
                        href={`https://www.facebook.com/${JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.facebook}`}
                        style={{display: JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.facebook !== '' ? '' : 'none'}}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SiFacebook />
                    </a>

                </div>
            </Content>
        </Container>
    )
}
