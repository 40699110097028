import { api } from "../../../../services/api";

export async function getPaymentMethods({ cdEmpresa }) {
  try {
    const { data } = await api.get(`/getPaymentMethod/${cdEmpresa}`);
    return { paymentMethods: data.paymentMethods };
  } catch (error) {
    throw error;
  }
}
