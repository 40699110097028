import { TransversalBar } from '../../components/pagesComponents/transversalBar'
import { RegisterAddressPersonPage } from '../../components/pagesComponents/registerAddressPersonPage'
import { queryClient } from '../../services/queryClient'
import { useEffect } from 'react'
import { Container } from './style'


export function RegisterAddressPerson() {
    
    useEffect(() => {
        queryClient.invalidateQueries('address')
    }, [])

    return(
        <Container>
            <TransversalBar />
            <RegisterAddressPersonPage />
        </Container>
    )
}
