import { useState } from 'react'
import { ButtonsToChooseRecover } from '../../components/pagesComponents/RecoverPage/ButtonsToChooseRecover'
import { RecoverByEmail } from '../../components/pagesComponents/RecoverPage/RecoverByEmail'
import { RecoverByCPF } from '../../components/pagesComponents/RecoverPage/RecoverByCPF'
import { CircleStyleArea } from '../../components/CircleStyleArea'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import { Container, Header } from './style'


export function Recover() {
    const [recoverMethod, setRecoverMethod] = useState('')

    
    return(
        <Container>
            <Header>
                {
                    recoverMethod === '' ?
                    <Link to="/login">
                        <AiOutlineArrowLeft/>
                    </Link>
                    :
                    <AiOutlineArrowLeft 
                        onClick={() => setRecoverMethod('')}
                    />
                }

                <h1>Voltar</h1>
            </Header>

            <CircleStyleArea />

            {
                recoverMethod === ''
                ? <ButtonsToChooseRecover setRecoverMethod={setRecoverMethod} />
                : recoverMethod === 'email'
                ? <RecoverByEmail />
                : <RecoverByCPF />
            }
        </Container>
    )
}
