import styled from "styled-components"

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`

export const ContentWrap = styled.div`
	flex: 1;
`

export const ButtonWrap = styled.div`
	display: flex;
	gap: 0.5rem;
	margin-top: 1.2rem;
	& :first-child {
		flex: 1;
		background-color: var(--brand-red-600);
	}
`
