import styled, { css } from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);

    .aroundContent{
        display: flex;
        align-items: center;
        flex: auto;
        height: 100%;
        width: 100%;
    }
`

export const Content = styled.div`
    margin: 0 auto;
    height: 35rem;
    width: 30rem;
    background: #FFF;
    display: flex;
    flex-direction: column;
    padding: 5px;

    @media (max-width: 800px){
        height: 90%;
        width: 90%;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
        font-size: 1.3rem;
        color: gray;
        cursor: pointer;
        padding: 0 0.5rem 0 1rem;
    }

    div{
        text-align: center;
    }

    svg{
        margin: 0rem 0.2rem 0rem 0.2rem;
        font-size: 2.5rem;
        color: green;
    }

    p{
        font-size: 1.2rem;
        font-weight: 700;
    }

    .title{
            font-weight: 300;
            font-size: 0.7rem;
    }
`

export const NeighborhoodsList = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: auto;
    overflow-y: auto;

`



export const ButtonAddress = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;

    ${props => props.selected && css`
        color: var(--colorButton);
    `}

    & + &{
        margin-top: 1rem;
    }

    svg{
        margin-right: 0.5rem;
    }
`



export const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    div{
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--colorButton);
        color: var(--colorLabel);
        padding: 0.7rem 1rem 0.7rem 1rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        cursor: pointer;
        min-width: 12rem;

        .listIcon{
            margin: 0 0.2rem 0 0.2rem
        }

        svg{
            font-size: 1.1rem;
            margin: 0 0.1rem 0 0.1rem;
        }
    }

    .listAddressButton{
        margin-top: 0.5rem;
        opacity: 0.7;
        background: var(--colorLabel);
        color: var(--colorButton);
        border: 1px solid var(--colorButton);
    }

    .registerAddress{
        animation-duration: 0.1s;
        animation-name: popButton;
        animation-iteration-count: 1;
        margin-bottom: 0.5rem;
    }

    @keyframes popButton{
        0%{transform: scale(0.1)}
        20%{transform: scale(0.2)}
        30%{transform: scale(0.3)}
        40%{transform: scale(0.4)}
        50%{transform: scale(0.5)}
        60%{transform: scale(0.6)}
        70%{transform: scale(0.7)}
        80%{transform: scale(0.8)}
        90%{transform: scale(0.9)}
        100%{transform: scale(1)}
    }

`