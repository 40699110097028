import styled, { css, keyframes } from 'styled-components';

const animationColorOrder = keyframes`
    from{
        color:  #014d01;
    }
    to{
        color:  #018201;
    }
`;

const animationBall = keyframes`
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.3);
    }
    100%{
        transform: scale(1);
    }
`;

function createCSS() {
    let styles = '';
  
    for (let i = 0; i < 100; i += 1) {
       styles += `
        ${i}% {background-image: linear-gradient(to right, green 0%,  green ${i}%, #D3D3D3 ${i}%, #D3D3D3 100%);}
       `
    }
  
    return css`${styles}`;
  }


export const Container = styled.div`
    width: 90%;
    margin: 2px auto;
    padding: 2rem 0;
    border-bottom: ${props => props.noLine ? 'none' : '0.5px solid gray'};
    position: relative;
    .info{
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        font-size: 1.2rem;
        color: #2c2c2c;
        cursor: pointer;
    }

    .container{
        position: relative;
        width: 100%;
        
        @media (min-width: 960px){
            cursor: pointer;
        }

        .container-info{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;

            div:first-child{
                height: 3rem;
                width: 3rem;
                padding: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 0.35rem;
                box-shadow: 0 1px 2px 1px rgb(0 0 0 / 30%);
                ${props => props.linkImage && css`
                    background-image: url("${props.linkImage}");
                `}

            }

            .businessDatas{
                height: 100%;
                font-size: 0.9rem;


                p{
                    color: gray; 
                    text-align: left;
                    max-width: 200px;
                    flex-wrap: wrap;
                    & + p{
                        margin-top: 0.1rem;
                    }
                }
            }
        }

        .orderNumberDay{
            margin: 0.7rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: gray;

            strong{
                animation: ${animationColorOrder} 0.8s ease-in-out infinite;
            }

            p{
                background-color: #E5E4E299;
                padding: 0.2rem 0.4rem;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                
                strong{
                    font-size: 1.5rem;
                }

                span{
                    font-size: 0.8rem;
                }
            }
        }

        .timeDelivery{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.7rem;
            font-size: 0.8rem;
            color: gray;

            svg{
                margin-right: 0.2rem;
            }
        }

        .button-viewPix{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            
            button{
                background-color: transparent;
                border: 1px solid  #888;
                color:  #888;
                font-weight: 600;
                padding: 5px;
                border-radius: 4px;
            }
        }

        .container-status{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem 0 1rem 0;
        
            .container-balls{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 2rem;

                .status{
                    display: flex;
                    align-items: center;
                    div{
                        position: relative
                    }

                    .status-title-refused{
                        position: absolute;
                        font-size: 0.65rem;
                        width: 240%;
                        text-align: center;
                        bottom: -30px;
                        left: -22px;
                    }

                    .status-title{
                        position: absolute;
                        font-size: 0.65rem;
                        width: 248%;
                        text-align: center;
                        bottom: -30px;
                        left: -22px;
                    }

                    .status-date{
                        position: absolute;
                        font-size: 0.65rem;
                        width: 150%;
                        text-align: center;
                        left: -10px;
                        bottom: -3.9rem;
                        color: gray;
                    }

                    .ball{
                        font-size: 2rem;
                        color: gray;

                        &.animated{
                            color: #000;
                            animation: ${animationBall} ease-in-out 0.5s infinite
                        }
                    }

    
                    .progress{
                        background: green;
                        width: 6rem;
                        height: 4px;
                    }
                    .waiting{
                        background: #D3D3D3;
                        width: 6rem;
                        height: 4px;
                    }
                    .stoped{
                        background: #C22200;
                        width: 6rem;
                        height: 4px;
                    }

                    .animated-div{
                        animation-duration: 1s;
                        animation-name: slidein;
                        animation-iteration-count: infinite;
                        
                        height: 4px; 
                        width: 6rem;
                    }

                    @keyframes slidein{
                        ${createCSS()}
                        100% {background-image: linear-gradient(to right,  green 0%,  green 100%, #D3D3D3 0%, #D3D3D3 0%);} 
                    }

            

                    .confirmed{
                        color: #006400;
                    }
                    .refused{
                        color: #C22200;
                    }

                    @media (max-width: 600px){
                        .progress{
                            width: 3rem;
                        }
                        .waiting{
                            width: 3rem;
                        }
                        .stoped{
                            width: 3rem;
                        }

                        .animated-div{
                            width: 3rem;
                        }
                    }

                    @media (max-width: 350px){
                        .progress{
                            width: 3rem;
                        }
                        .waiting{
                            width: 3rem;
                        }
                        .stoped{
                            width: 3rem;
                        }

                        .animated-div{
                            width: 3rem;
                        }
                    }

                    @media (max-width: 290px){
                        .status-title{
                            font-size: 0.62rem;
                            width: 230%;
                        }

                        .status-date{
                            font-size: 0.62rem;
                        }
                    }
            }

                
            }
        }


    }
    .button-container{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;

            button{
                border: 1px solid #535354;
                background: none;
                color: #535354;
                font-size: 14px;
                padding: 6px;
                border-radius: 7px;
            }
    }
    .reasonToCancel{
            margin-top: 50px;
            text-align: center;
            font-size: 0.9rem;
            color: #C22200;
    }
`