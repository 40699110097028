import styled, { css } from "styled-components";

function createCSS() {
    let styles = '';
  
    for (let i = 0; i < 90; i += 1) {
       styles += `
        ${i}% {background-image: linear-gradient(to right, rgba(0, 128, 0, 0.4) 0%,  rgba(0, 128, 0,  0.4) ${i}%, #D3D3D3 ${i}%, #D3D3D3 100%);}
       `
    }
  
    return css`${styles}`;
  }


export const Container = styled.div`
    padding: 20px 0;
    width: 98%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

    .grayBackground{
        width: 100%;
        padding: 0.8rem 0;
        background: #E5E4E2;   

        p{
            width: 90%;
            margin: 0 auto;
            text-align: left;
            font-size: 0.8rem;
            color: gray;
            margin-bottom: 0.2rem;
            font-weight: 500;

            span{
                font-weight: 900;
            }
        }
    }
`

export const ProgressBar = styled.div`
    height: 6px;
    width: 90%;
    margin: 0 auto;

    display: flex;

    .alreadyFilledIn{
        width: ${props => props.filled ? `${props.filled}%` : 0};
        background: #008000;
        border-radius: 5px 0 0 5px;
    }

    .incompleted{
        flex: auto;
        border-radius: 0 5px 5px 0;
        animation-duration: 1.5s;
        animation-name: slidein;
        animation-iteration-count: infinite;  
    }

    @keyframes slidein{
        ${createCSS()}
        90% {background-image: linear-gradient(to right,  rgba(0, 128, 0, 0.4) 0%,  rgba(0, 128, 0, 0.3) 90%, #D3D3D3 90%, #D3D3D3 100%);} 
        92% {background-image: linear-gradient(to right,  rgba(0, 128, 0, 0.4) 0%,  rgba(0, 128, 0, 0.3) 92%, #D3D3D3 92%, #D3D3D3 100%);} 
        94% {background-image: linear-gradient(to right,  rgba(0, 128, 0, 0.4) 0%,  rgba(0, 128, 0, 0.3) 94%, #D3D3D3 94%, #D3D3D3 100%);} 
        96% {background-image: linear-gradient(to right,  rgba(0, 128, 0, 0.2) 0%,  rgba(0, 128, 0, 0.2) 96%, #D3D3D3 96%, #D3D3D3 100%);} 
        98% {background-image: linear-gradient(to right,  rgba(0, 128, 0, 0.2) 0%,  rgba(0, 128, 0, 0.2) 98%, #D3D3D3 98%, #D3D3D3 100%);} 
        100% {background-image: linear-gradient(to right,  rgba(0, 128, 0, 0.2) 0%,  rgba(0, 128, 0, 0.2) 100%, #D3D3D3 0%, #D3D3D3 0%);} 
    }
`