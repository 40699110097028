import React from 'react'
import { ImPhone } from 'react-icons/im'
import { FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';

import {Container} from './styles';

export function Contacts() {
    return (
        <Container>
            <div className="contacts">
                <p className='title'>Para dúvidas entrar em contato com os números abaixo: </p>
                
                { 
                    localStorage.getItem('@Inoveclube:numberBusiness') &&
                    <div className="contact">
                        <a
                            href={"tel:" + localStorage.getItem('@Inoveclube:numberBusiness')}
                            className="linkInfo"
                        >
                            <p>
                                <ImPhone /> {localStorage.getItem('@Inoveclube:numberBusiness')} 
                            </p>
                        </a>

                        <a 
                            className='button'
                            href={"tel:" + localStorage.getItem('@Inoveclube:numberBusiness')}
                        >Ligar</a>
                    </div>
                }

                {  JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp &&
                    <div className="contact">
                        <a
                            href={`https://wa.me/55${JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp}`}
                            style={{display: JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp !== '' ? '' : 'none'}}
                            target="_blank"
                            rel="noreferrer"
                            className="linkInfo"
                        >
                            <p>
                                <FaWhatsapp /> {JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp}
                            </p>
                        </a>

                        <a 
                            className='button'
                            href={`https://wa.me/55${JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp}`}
                            style={{display: JSON.parse(localStorage.getItem('@Inoveclube:socialMedias'))?.whatsapp !== '' ? '' : 'none'}}
                            target="_blank"
                            rel="noreferrer"
                        >Abrir Whatsapp</a>
                    </div>
                }
            </div>

            {
                localStorage.getItem('@Inoveclube:street') &&
                <div className="address">
                    <p className='title'>Endereço: </p>
                    <a
                        href={localStorage.getItem('@Inoveclube:maps')}
                        className="linkInfo"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <p>
                            <FaMapMarkerAlt /> {localStorage.getItem('@Inoveclube:street')}
                        </p>
                    </a>
                </div>
            }
        </Container>
    )
}
