import { useState, useEffect } from 'react';
import { useRegister } from '../../../context/RegisterContext';
import { Link, useHistory } from 'react-router-dom'

import { Container, Header, InputLog, InputContainer, Logo, ButtonsContainer } from './style';
import { toast } from 'react-toastify';
import { BiUser, BiLockAlt, BiEnvelope, BiPhone, BiCalendar } from 'react-icons/bi'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import { BirthdateInput, TelInput } from '../../inputMasks'
import { ModalAcceptTerms } from './modalAcceptTerms';
import { GeneralLoader } from '../../loaders/generalLoader';

import { api } from '../../../services/api';
import moment from 'moment'

export function RegisterDatasPersonContent({ dataParams }) {
  const { handleFirstPageRegisterData, modalAcceptOpen, handleModalAcceptOpen } = useRegister();
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cpf, setCpf] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [acceptTerms, setAcceptTerms] = useState({ idTerm: '' });

  const [showPassword, setShowPassword] = useState(false);
  const [missingToFillInput, setMissingToFillInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const [disabledPhoneNumber, setDisabledPhoneNumber] = useState(false);
  const [nameBusiness, setNameBusiness] = useState(null);

  const [nextStep, setNextStep] = useState(1);
  const [cpfVerify, setCpfVerify] = useState('');

  const stringSplit = birthDate.split('')
  const dateFormat = `${stringSplit[6] + stringSplit[7] + stringSplit[8]
    + stringSplit[9] + '-' + stringSplit[3]
    + stringSplit[4] + '-' + stringSplit[0] + stringSplit[1]}`

  const passwordLength = password.split('').length


  useEffect(() => {
    api.get('/getTerm').then(response => setAcceptTerms(response.data))
  }, [])

  useEffect(() => {
    async function verifyLogin() {
      const [nameBusiness, phone] = dataParams.split('-');
      setNameBusiness(nameBusiness);

      if (phone) {
        setPhoneNumber(phone);
        setDisabledPhoneNumber(true);

        setLoading(true)
        await api.get(`/getPersonByPhone/${phone}`)
          .then(async (response) => {
            if (response.data.results && response.data.results.length > 0) {
              const idPerson = response.data.results[0].cdPessoa;

              await api.put('/updatePersonOriginBusiness', {
                idPerson,
                nameBusiness
              });

              history.push('/fidelity')
              return
            }
          })
          .finally(() => setLoading(false))
      }
    }

    if (dataParams) {
      verifyLogin();
    }
  }, [dataParams, history]);

  useEffect(() => {
    if (passwordLength === 10)
      toast.error("Você atingiu o tamanho máximo da senha: 10")
  }, [passwordLength])

  function verifyDate() {
    if (new Date().getFullYear() < parseInt((stringSplit[6] + stringSplit[7] + stringSplit[8] + stringSplit[9]), 10)
      && new Date().getMonth() + 1 < parseInt((stringSplit[3] + stringSplit[4]), 10)
      && new Date().getDate() < parseInt((stringSplit[0] + stringSplit[1]), 10))
      return true
    else
      return false
  }


  async function savePersonData() {
    await handleFirstPageRegisterData({
      name,
      email,
      phoneNumber,
      cpf,
      birthDate: dateFormat,
      password,
      idTerm: acceptTerms.idTerm,
      nameBusiness: nameBusiness === null ? undefined : nameBusiness
    })
    localStorage.setItem('@Inoveclube:userName', name)
    handleModalAcceptOpen()
  }

  async function verifyCpf(){
    try {
      if(cpfVerify.trim() === ''){
        toast.info('Preencha todos os campos!');
        return
      }

      const response = await api.get(`/getPersonByCpfOrEmail/${cpfVerify}`);
      const {results} = response.data;
      
      if(results && results.length > 0){
        const idPerson = results[0].cdPessoa;

        await api.put('/updatePersonOriginBusiness', {
          idPerson,
          nameBusiness
        });

        history.push('/fidelity');
        return
      }

      setCpf(cpfVerify);
      setNextStep(2);
    } 
    catch (error) {
      toast.error(error.message);
    }
  }

  if (loading) {
    return (
      <>
        <GeneralLoader />
      </>
    )
  }


  return (
    <Container>
      <Header>
        <Link to="/login"><AiOutlineArrowLeft /></Link>
        <h1>Preencha os dados</h1>
      </Header>

      {
        (dataParams && nextStep === 1) ? (
          <InputContainer>
            <Logo></Logo>

            <InputLog alertUserToFillInput={name === '' && missingToFillInput}>
                <BiUser />
                <input
                  // type="tel"
                  placeholder="CPF ou EMAIL"
                  value={cpfVerify}
                  onChange={event => setCpfVerify(event.target.value)}
                />
            </InputLog>

            <ButtonsContainer>
              <button className="logButton g-recaptcha" onClick={verifyCpf}>Próximo</button>
            </ButtonsContainer>
          </InputContainer>
        )
          :
          (
            <InputContainer>
              <Logo></Logo>

              <InputLog alertUserToFillInput={name === '' && missingToFillInput}>
                <BiUser />
                <input
                  type="text"
                  placeholder="Nome completo"
                  value={name.toUpperCase()}
                  maxLength={40}
                  onChange={event => setName(event.target.value)}
                >
                </input>
              </InputLog>

              <InputLog alertUserToFillInput={email === '' && missingToFillInput}>
                <BiEnvelope />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  maxLength={60}
                  onChange={event => setEmail(event.target.value)}
                >
                </input>
              </InputLog>

              <InputLog alertUserToFillInput={phoneNumber === '' && missingToFillInput}>
                <BiPhone />
                <TelInput
                  type="tel"
                  placeholder="Celular"
                  name="TelInput"
                  disabled={disabledPhoneNumber}
                  value={phoneNumber}
                  onChange={event => setPhoneNumber(event.target.value)}
                >
                </TelInput>
              </InputLog>

              <InputLog alertUserToFillInput={cpf === '' && missingToFillInput}>
                <BiUser />
                <input
                  type="tel"
                  placeholder="CPF"
                  maxLength={14}
                  name="CpfInput"
                  disabled={cpfVerify.length > 0}
                  value={cpf}
                  onChange={event => setCpf(event.target.value)}
                />
              </InputLog>

              <InputLog alertUserToFillInput={birthDate === '' && missingToFillInput}>
                <BiCalendar />
                <BirthdateInput
                  type="tel"
                  placeholder="Data de nascimento"
                  name="BirthdateInput"
                  value={birthDate}
                  onChange={event => setBirthDate(event.target.value)}
                >
                </BirthdateInput>
              </InputLog>

              <InputLog alertUserToFillInput={password === '' && missingToFillInput}>
                <BiLockAlt />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Senha"
                  value={password}
                  maxLength={10}
                  onChange={event => setPassword(event.target.value)}
                >
                </input>
                {showPassword ?
                  <MdVisibility onClick={() => setShowPassword(false)} className="visibility" />
                  :
                  <MdVisibilityOff onClick={() => setShowPassword(true)} className="visibilityOff" />
                }
              </InputLog>

              <InputLog alertUserToFillInput={confirmPassword === '' && missingToFillInput}>
                <BiLockAlt />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Repita a senha"
                  value={confirmPassword}
                  maxLength={10}
                  onChange={event => setConfirmPassword(event.target.value)}
                >
                </input>
              </InputLog>

              <ButtonsContainer>
                <button
                  className="logButton g-recaptcha"
                  data-sitekey="6LcmFyAcAAAAANYtWCTkXQDnnGB7aVoSAisllMfC"
                  data-callback="handleSubmit"
                  data-size="invisible"
                  SameSite="secure"
                  onClick={() => {
                    localStorage.removeItem('@Inoveclube:token')
                    localStorage.removeItem('@Inoveclube:userName')
                    if (name === "" || email === "" || birthDate === "" || password === "" || cpf === "" || confirmPassword === "") {
                      setMissingToFillInput(true)
                      toast.error("Preencha os campos obrigatórios")
                    }

                    else if (new Date().getFullYear() - parseInt((stringSplit[6] + stringSplit[7] + stringSplit[8] + stringSplit[9]), 10) > 120)
                      toast.error("Insira uma data válida")

                    else if (verifyDate())
                      toast.error("Insira uma data válida")

                    else if (password !== password.replace(/[^\x00-\x7F]/g, ""))
                      toast.error("Não são permitidos caracteres especiais")

                    else if (moment(dateFormat, 'YYYY-MM-DD', true).isValid() === false)
                      toast.error("Insira uma data válida")

                    else if (password !== confirmPassword)
                      toast.error("As senhas não coincidem");

                    else if (passwordLength > 10)
                      toast.error("Tamanho máximo da senha: 10")

                    else
                      handleModalAcceptOpen();
                  }}
                >
                  CADASTRAR
                </button>
              </ButtonsContainer>
            </InputContainer>
          )
      }

      {modalAcceptOpen ?
        <ModalAcceptTerms handleModalAcceptOpen={handleModalAcceptOpen} savePersonData={savePersonData} acceptTerms={acceptTerms} />
        : <></>
      }
    </Container>
  )
}
