import { formatMoney } from '../../../../Utils/formatMoney'

import { Container, ProgressBar } from './style'


export function ProgressBarCoupon({ total, minValue, progressCouponBar }) {
    const value = formatMoney(minValue - total)

    return(
        <>
            {
                progressCouponBar ?
                <Container>

                    <div className="grayBackground">
                        <p>Faltam <span>{value}</span> para usar o cupom</p>
                        <ProgressBar filled={(total / minValue) * 100}>
                            <div className="alreadyFilledIn" />
                            <div className="incompleted" />
                        </ProgressBar>

                    </div>

                </Container>
                :
                <></>
            }
        </>
    )
}
