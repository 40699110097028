import styled, { css } from "styled-components";

export const Container = styled.section`
	width: 100%;
	min-height: 75vh;
	.swiper-wrapper {
		max-width: max-content;
	}
	.advice {
		text-align: center;
		margin: 1rem 0;
	}

	.close-icon {
		position: absolute;
		top: -5px;
		right: 0;
		color: red;
		font-size: 1.2rem;
	}

	.btn-wrapper {
		display: flex;
		align-items: center;
		gap: 2rem;
		font-size: 1.5rem;
		button {
			font-weight: 500;
			background-color: var(--brand-red-500);
			color: white;
			padding: 1rem 2rem;
			border-radius: 25px;
			border: unset;
			transition: all 0.1s ease-in-out;
			&:hover {
				transition: all 0.1s ease-in-out;
				transform: scale(1.05);
				background-color: var(--brand-red-600);
			}
			&.selected {
				transform: scale(1.05);
				background-color: var(--brand-red-600);
			}
		}
	}

	.filters-swiperSlide {
		white-space: nowrap;
		padding: 0.5rem 1rem;
		border-radius: 25px;
		border: unset;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		background-color: var(--gray-100);
		max-width: max-content;
		cursor: pointer;
		transition: all 0.1s ease-in-out;
		&:hover {
			transform: scale(1.05);
			background-color: var(--brand-red-200);
		}
	}

	.selected {
		transform: scale(1.05);
		background-color: var(--brand-red-300);
		&:hover {
			background-color: var(--brand-red-300);
		}
	}

	.none {
		width: 100%;
		text-align: center;
		padding: 1rem;
	}
`;

export const Button = styled.button`
	width: 100%;
	background: var(--mainTheme);
	padding: 1rem 0;
	border: none;
	font-size: 1rem;
	margin-top: 1rem;
	color: var(--mainTheme);
	font-weight: bold;
	border-radius: 4px;
	transition: all 0.1s;

	position: relative;
	transform: translateZ(0);
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;

	&:hover {
		color: #fff;
	}

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #fff;
		transform: scaleX(1);
		transform-origin: 50%;
		border-radius: 4px;
		transition: transform 0.1s ease-out;
		border: 1px solid #f5f0eb;
	}

	&:hover:before {
		transform: scaleX(0);
	}

	${(props) =>
		props.loadingMoreBusiness &&
		css`
			opacity: 0.5;
			color: #fff;

			&:before {
				display: none;
			}
		`}
`;

export const Sector = styled.div`
	width: 7rem;
	height: 4rem;
	background-image: url("${(props) => props.img}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	transition: transform 0.1s ease;
	display: flex;

	.img-wrapper {
		margin: 0 auto;
		width: 5rem;
		border-radius: 10px;
		overflow: hidden;
	}

	p {
		position: absolute;
		bottom: -1.1rem;
		width: 100%;
		text-align: center;
		font-size: 0.7rem;
		color: #717171;
	}

	&:hover {
		transform: scale(1.05);
	}
`;

export const Businesses = styled.div`
	margin-top: 1.5rem;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 0.5rem;
	width: 100%;
`;
