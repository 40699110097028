import { useState, useEffect } from "react";
import { FaUserAlt, FaEnvelope, FaPhoneAlt, FaAngleRight } from "react-icons/fa";
import { useMutation } from "react-query";
import { queryClient } from "../../../../services/queryClient";
import { useAuth } from "../../../../context/AuthContext";
import { usePerson } from "../../../../services/hooks/usePerson";
import { InputGeneric } from "../../../Forms/InputGeneric";
import { InputDate } from "../../../Forms/InputDate";
import { SpaceLoader } from "../../../loaders/spaceLoader";
import { api } from "../../../../services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Button } from "../../../Button";
import moment from "moment";

import { Container } from "./style";

export function EditProfileForm({ handleChangePasswordFormVisible }) {
	const { data, isLoading } = usePerson();
	const { signOut } = useAuth();
	const history = useHistory();

	const [userNameEdit, setUserNameEdit] = useState("");
	const [userEmailEdit, setUserEmailEdit] = useState("");
	const [userPhoneEdit, setUserPhoneEdit] = useState("");
	const [userBirthDateEdit, setUserBirthDateEdit] = useState("");

	const editUser = useMutation(
		async (userDatas) => {
			await api.patch("/updatePerson", userDatas, {
				headers: {
					"Content-Type": "application/json",
					token: localStorage.getItem("@Inoveclube:token"),
				},
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("person");
				localStorage.setItem("@Inoveclube:userName", userNameEdit);
				toast.success("Editado com sucesso!");
				history.push("/profile");
			},
			onError: (error) => {
				if (error.response.status === 401 || error.response.status === 500) {
					signOut();
					history.push("/login");
					toast.warning("Sua sessão foi expirada");
				} else {
					toast.error(error.message);
				}
			},
		}
	);

	function validateUserDatas() {
		if (
			userNameEdit.trim() === "" ||
			userEmailEdit.trim() === "" ||
			userPhoneEdit.trim() === "" ||
			userBirthDateEdit.trim() === ""
		)
			return {
				message: "Preencha todos os campos",
				status: false,
			};

		if (
			moment(userBirthDateEdit).isValid() === false ||
			new Date().getFullYear() - moment(userBirthDateEdit).format("YYYY") > 120
		)
			return {
				message: "Insira uma data válida",
				status: false,
			};

		return {
			status: true,
		};
	}

	async function onSubmit(event) {
		event.preventDefault();

		const { message, status } = validateUserDatas();
		const userDatas = {
			name: userNameEdit,
			email: userEmailEdit,
			phoneNumber: userPhoneEdit,
			birthDate: userBirthDateEdit,
		};

		if (!status) {
			toast.error(message);

			return;
		}

		await editUser.mutateAsync(userDatas);
	}

	useEffect(() => {
		setUserNameEdit(data?.name);
		setUserEmailEdit(data?.email);
		setUserPhoneEdit(data?.phoneNumber);
		setUserBirthDateEdit(data?.birthDate);
	}, [data]);

	return (
		<Container>
			<div className="utilArea">
				{isLoading ? (
					<SpaceLoader />
				) : (
					<form onSubmit={onSubmit}>
						<InputGeneric
							value={userNameEdit?.toUpperCase()}
							onChange={(e) => setUserNameEdit(e.target.value)}
							filterNumbers={false}
							icon={<FaUserAlt />}
						/>

						<InputGeneric
							value={userEmailEdit}
							onChange={(e) => setUserEmailEdit(e.target.value)}
							filterNumbers={false}
							icon={<FaEnvelope />}
						/>

						<InputGeneric
							value={userPhoneEdit}
							onChange={(e) => setUserPhoneEdit(e.target.value)}
							mask="(99) 99999-9999"
							icon={<FaPhoneAlt />}
						/>

						<InputDate
							value={userBirthDateEdit}
							onChange={(e) => setUserBirthDateEdit(e.target.value)}
							type="date"
						/>

						<strong onClick={handleChangePasswordFormVisible}>
							Alterar a senha <FaAngleRight />
						</strong>

						<Button type="submit">SALVAR</Button>
					</form>
				)}
			</div>
		</Container>
	);
}
