export function formatMoney(value, symbol) {
	const valueToFormat = value ? value : 0;
	const currentSymbol = symbol && symbol.length > 0 ? symbol : 'BRL'

	try {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: currentSymbol,
			maximumFractionDigits: 2,
		}).format(valueToFormat);
	} catch (error) {
		const formattedValue = new Intl.NumberFormat("pt-BR", {
			style: "decimal",
			maximumFractionDigits: 2,
		}).format(valueToFormat);

		return `${currentSymbol} ${formattedValue}`;
	}
}
