import styled from "styled-components"

export const Container = styled.div`
	ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		li {
			display: flex;
			gap: 0.5rem;
			align-items: center;
		}
	}

	.button {
		margin-top: 1rem;
		background-color: var(--green-500);
	}
`

export const Advice = styled.p`
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 1rem;
`
