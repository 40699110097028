import { useQuery } from 'react-query'
import { api } from '../api'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom'
import moment from 'moment'


export async function getPerson() {
    const token = localStorage.getItem('@Inoveclube:token')

    const response = await api.get(`/getPerson/${token}`, {
        headers: {
            token: localStorage.getItem('@Inoveclube:token')
        }
    })

    return {
        ...response.data,
        birthDate: moment(response.data.birthDate.slice(0, 10)).format('YYYY-MM-DD')
    }
}


export function usePerson() {
    const { signOut } = useAuth()
    const history = useHistory()

    return useQuery(
        'person', 
        getPerson,
        {
            staleTime: 1000 * 60 * 60, // 60 minutes
            refetchOnWindowFocus: false,
            onError: () => {
                signOut()
                history.push('/login')
                toast.warning('Sua sessão expirou, faça login novamente')
            }
        },
    )
}