import styled from "styled-components";

export const Container = styled.div`
    width: 98%;
    position: relative;

    & + &{
        margin-top: 1rem;
    }
`

export const Content = styled.div`
    width: 100%;
    color: #FFF5FF;
    height: 10rem;
    background: linear-gradient( 315deg, rgba(0,83,220,0.9), rgba(122,5,167,1));
    border-radius: 0.7rem;
    position: relative;
   

    display: grid;
    grid-template-columns: 1fr 0.2fr;
    grid-template-rows: 1fr;


    .buttonSelect{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 3px dashed #FFF5FF;

        p{
            display: flex;
            align-items: center;
            font-size: 1rem;
            letter-spacing: 1px;
            font-weight: 700;
            transform: rotate(90deg);
            position: relative;

            svg{
                font-size: 1.2rem;
                position: absolute;
                right: -1.4rem;
            }
        }
    }
`


export const ContentCoupom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Text = styled.div`
    height: 80%;
    width: 80%;
    border: 2px solid #FFF5FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.7rem;

    strong{
        font-size: 1.2rem;
    }

    @media (min-width: 960px){
        cursor: pointer;
    }
`

export const BallLeft = styled.div`
    border-radius: 50%;
    top: 50%;
    position: absolute;
    left: -1.3rem;
    height: 2.4rem;
    width: 2.4rem;
    transform: translateY(-50%);   
    z-index: 20; 

    display: flex;
    overflow: hidden;

    &:before{
        content: '';
        flex: 1;
        background: transparent;
    }

    &:after{
        content: '';
        flex: 1;
        background: #FFF;
    }
`