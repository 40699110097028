import moment from "moment";
import { Button } from "./style";
import { useState } from "react";
import { useEffect } from "react";
import { getPaymentMethods } from "../../requests/companies/getPaymentMethods";
import { toast } from "react-toastify";
import { formatMoney } from "../../../../Utils/formatMoney";
import { useOrderManagerContext } from "../../context";

export function CloseCash({ company, handleGoBack, setCurrentState }) {
  const { handleCloseCashRegister, cashRegisterIsLoading } = useOrderManagerContext();

  const [currentPayments, setCurrentPayments] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formOfPayment = form[0].value;
    const value = form[1].value;
    const name = form[0].options[form[0].selectedIndex].text;
    setCurrentPayments([...currentPayments, { formOfPayment, value, name }]);
  }

  function sumAllPayments() {
    return currentPayments.reduce((acc, payment) => acc + Number(payment.value), 0);
  }

  function handleRemovePayment(index) {
    setCurrentPayments(currentPayments.filter((_, i) => i !== index));
  }

  function handleValidateInput(event) {
    if (cashRegisterIsLoading) return;
    const value = event.target.value;
    // only allow numbers, dot and comma
    if (!/^\d*[.,]?\d*$/.test(value)) {
      event.target.value = value.slice(0, -1);
    } else {
      event.target.value = value.replace(",", ".");
    }
  }

  useEffect(() => {
    getPaymentMethods({ cdEmpresa: company.cdEmpresa })
      .then((paymentMethods) => {
        setPaymentMethods(paymentMethods.paymentMethods);
      })
      .catch(() => {
        toast.error("Algo deu errado. Tente novamente mais tarde.");
      });
  }, [company]);

  return (
    <>
      <div>
        <h3>Período de vendas</h3>
        <div className="flex p-3">
          <p>{moment(company.openingDate).utcOffset(0).format("DD/mm/yyyy HH:mm")}</p>
          <p>a</p>
          <p>{moment().format("DD/mm/yyyy HH:mm")}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <h3>Forma de Pagamento</h3>
        <div className="flex-wrap">
          <select name="formOfPayment" id="formOfPayment" required>
            <option value="" disabled>
              Selecione uma forma de pagamento
            </option>
            {paymentMethods.map((paymentMethod) => (
              <option
                key={paymentMethod.cdFormaPagamento}
                value={paymentMethod.cdFormaPagamento}
              >
                {paymentMethod.dsFormaPagamento}
              </option>
            ))}
          </select>
          <input type="text" required onChange={handleValidateInput} />
          <Button disabled={cashRegisterIsLoading}>Adicionar</Button>
        </div>
      </form>

      <table>
        <thead>
          <tr>
            <th>Forma de Pagamento</th>
            <th>Valor Pago</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentPayments.map((payment, index) => (
            <tr key={index}>
              <td>{payment.name}</td>
              <td>{formatMoney(payment.value)}</td>
              <td>
                <button
                  className="remove"
                  onClick={() => handleRemovePayment(index)}
                  disabled={cashRegisterIsLoading}
                >
                  X
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td>{formatMoney(sumAllPayments())}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>

      <div className="flex">
        <Button
          className="flex-1 green"
          onClick={async () => {
            if (currentPayments.length === 0) {
              toast.warning("Adicione pelo menos um pagamento");
              return;
            }
            await handleCloseCashRegister({
              companyId: company.cdEmpresa,
              installments: currentPayments,
            });
            setCurrentState("initial");
          }}
          disabled={cashRegisterIsLoading}
        >
          Confirmar
        </Button>
        <Button className="flex-1 red" onClick={handleGoBack} disabled={cashRegisterIsLoading}>
          Cancelar
        </Button>
      </div>
    </>
  );
}
