import { useQuery } from 'react-query'
import { api } from '../api'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom'


export async function getAllAddress() {
    const response = await api.get('/getAddressesPerson/' + localStorage.getItem('@Inoveclube:token'), {
        headers: {
            token: localStorage.getItem('@Inoveclube:token')
        }
    })

    return response.data
}


export function useAddress() {
    const { signOut } = useAuth()
    const history = useHistory()


    return useQuery(
        'address', 
        getAllAddress,
        {
            staleTime: 1000 * 60 * 60, // 1 hour
            refetchOnWindowFocus: false,
            onError: () => {
                signOut()
                history.push('/login')
                toast.warning('Sua sessão expirou, faça login novamente')
            }
        },
    )
}