import styled, { css } from "styled-components";

export const Container = styled.div`
    margin: 0.1rem auto;
    color: #393939;
    display: flex;
    align-items: center;

    @media (max-width: 375px){
        flex-direction: column;
    }
    
    .inputLabel{
        position: relative;
        input{
            border: 2px solid var(--colorBorderInput);
            border-radius: 2px;
            padding: 0.5rem;
            font-size: 1.1rem;
            width: 20rem;
        }
    
        label{
            position: absolute;
            transform: translateY(-110%);
            margin-bottom: 0.2rem;
        }
    }

    .button{
        background: var(--colorButton);
        margin: 0.2rem;
        padding: 0.5rem;
        color: var(--colorLabel);

        @media (min-width: 960px){
            cursor: pointer;
        }
    }

    ${props => props.loader && css`
        pointer-events: none;

        .button{
            opacity: 0.5;
        }
    `}
`