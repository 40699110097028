import styled from 'styled-components'

export const Container = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    margin: 2rem 0;
`

export const ListItem = styled.li`
    display: grid;
    grid-template-columns: 1fr 1.2fr;

    transition: transform 0.2s;
    list-style: none;
    padding: 1rem;
    width: 15rem;
    height: 7rem;
    background: ${props => props.colorBgHeader ? props.colorBgHeader : '#000'};
    color: ${props => props.colorLabel ? props.colorLabel : '#FFF'};
    margin: 5px;
    border-radius: 0.75rem;

    .location{
        display: flex;
        align-items: center;
        justify-content: center;

        div{
            height: 3.5rem;
            width: 3.5rem;
            border-radius: 50%;
            font-size: 1.5rem;
            background-image: url("${props => props.link}");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
    
            display: flex;
            align-items: center;
            justify-content: center;

            /* position: relative; */

            /* &::after{
            content: attr(title);
            position: absolute;
            bottom: -1.2rem;
            color: ${props => props.colorLabel ? props.colorLabel : '#FFF'};
            font-size: 0.8rem;
            width: max-content;
        } */
        }
    }

    .state{
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            text-align: center;
            font-size: 1.2rem;
        }
    }

    &:hover{
        transform: scale(1.01);
    }
    

    @media (min-width: 960px){
        cursor: pointer;
    }

    @media (max-width: 365px){
        width: 100%;
    }
`