import { FaUserAlt } from "react-icons/fa";
// import { FaStore } from "react-icons/fa";
// import { PiMagnifyingGlassBold } from "react-icons/pi";
import { BiCurrentLocation, BiChevronRight } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import logo from "../../../images/logo.png";
import logoPurple from "../../../images/logoPurple.png";

import { Container, Content } from "./style";

export function Header({ setModalToFilterUserLocationOpen }) {
	const [scrollPosition, setScrollPosition] = useState(0);

	const cityDescription = JSON.parse(
		localStorage.getItem("@Inoveclube:location")
	).description;
	const history = useHistory();

	let isScrollOnTop = scrollPosition === 0;

	function handleClickToLogin() {
		localStorage.getItem("@Inoveclube:token")
			? history.push("profile")
			: history.push("login");
	}

	function handleScroll() {
		setScrollPosition(window.pageYOffset);
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	});

	return (
		<Container isScrollOnTop={isScrollOnTop}>
			<Content>
				<img src={isScrollOnTop ? logo : logoPurple} alt={"logo inoveclube"} />

				<div className="buttons">
					<div>
						{localStorage.getItem("@Inoveclube:token") ? (
							<button onClick={handleClickToLogin}>
								<FaUserAlt />
								{localStorage.getItem("@Inoveclube:token")
									? "Perfil"
									: "Sou lojista"}
							</button>
						) : (
							<>
								<button onClick={handleClickToLogin}>
									<FaUserAlt />
									Sou cliente
								</button>
								{/* <button onClick={handleClickToLogin}>
									<FaStore />
									Sou lojista
								</button> */}
							</>
						)}
					</div>

					<div
						className="buttonLocation"
						onClick={() => setModalToFilterUserLocationOpen(true)}
					>
						<BiCurrentLocation />
						<p title={cityDescription}>{cityDescription}</p>
						<BiChevronRight />
					</div>
				</div>
				{/* <div className="search-wrapper">
					<div className="search">
						<input
							type="text"
							name=""
							id=""
							placeholder="Busque por item ou loja"
						/>
						<PiMagnifyingGlassBold className="icon" size={18} />
					</div>
					<div className="user-icons">
						<AiFillHeart className="icon" />
						<AiOutlineShoppingCart className="icon" />
					</div>
				</div> */}
			</Content>
		</Container>
	);
}
