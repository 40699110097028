import styled from "styled-components";

export const Container = styled.div`
	width: 100%;

	.swiper-container {
		width: 100%;
	}
	.slide {
		background-color: green;
		width: 100%;
		height: 65vh;
	}

	.cards-wrapper {
		padding: 1rem;
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1rem;
		width: 100%;
		img:nth-child(even) {
			margin-left: auto;
			@media (min-width: 425px) {
				margin-left: unset;
			}
		}
		@media (min-width: 425px) {
			justify-content: center;
		}
		@media (min-width: 1024px) {
			gap: 5rem;
		}
		.card {
			justify-self: flex-start;
			height: 6.8rem;
			border-radius: 1rem;
			cursor: pointer;
			&:nth-child(even) {
				:hover {
					transform: rotate(2deg) scale(1.05);
				}
			}
			&:nth-child(odd) {
				:hover {
					transform: rotate(-2deg) scale(1.05);
				}
			}
		}
	}
	footer {
		padding: 1rem;
		border-top: 1px solid #e5e5e5;
		div {
			display: flex;
			gap: 1rem;
			align-items: center;
			justify-content: center;
			img {
				width: 5rem;
			}
			span {
				font-size: 0.8rem;
				color: gray;
			}
		}
	}
`;

export const Slide = styled.div`
	width: 100%;
	height: 620px;

	.container {
		width: 100%;
		height: 100%;
		padding: 0 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: end;
		gap: 1rem;
		text-align: center;
		font-family: Heebo, sans-serif;
		color: #fff;

		&.action {
			cursor: pointer;
		}

		&.purple {
			background-image: -webkit-linear-gradient(
				139deg,
				rgb(100, 43, 115) 0%,
				rgb(198, 66, 110) 100%
			);
		}

		&.orange {
			background-image: -webkit-linear-gradient(139deg, #d1556b 0%, #fcc95d 100%);
		}

		&.green {
			background-image: -webkit-linear-gradient(139deg, #15a79b 0%, #b1dada 100%);
		}

		.logo {
			width: 10rem;
		}

		.illustration {
			height: 300px;
			animation-fill-mode: both;
			animation-duration: 1s;
			animation-delay: 0.1s;
			animation-iteration-count: 1;
			animation-name: enter;
		}

		.wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.5rem;
			animation-fill-mode: both;
			animation-duration: 1s;
			animation-delay: 0.1s;
			animation-iteration-count: 1;
			animation-name: enter;
			width: 18rem;
			height: 12rem;

			h1 {
				font-size: 1.8rem;
				font-weight: 600;
			}

			p {
				font-size: 1.1rem;
				color: rgba(255, 255, 255, 0.8);
			}
		}

		@keyframes enter {
			0% {
				transform: translateY(20%);
				opacity: 0;
			}
			100% {
				transform: translateY(0%);
				opacity: 1;
			}
		}
	}

	@media (min-width: 768px) {
		height: 400px;
		overflow: hidden;
		border-bottom-right-radius: 200px;
		.container {
			width: 100%;
			height: 100%;
			flex-direction: row;
			align-items: end;
			justify-content: center;
			gap: 3rem;
			text-align: center;
			font-family: Heebo, sans-serif;
			color: #fff;
			.wrapper {
				align-self: center;
				width: 25rem;
				text-align: left;
				h1 {
					font-size: 2.5rem;
				}

				p {
					font-size: 1.1rem;
				}
			}
			.illustration {
				width: 230px;
				height: auto;
				&.phone {
					align-self: center;
				}
			}
		}
	}
	@media (min-width: 1024px) {
		height: 500px;
		border-bottom-right-radius: 300px;
		.container {
			gap: 10rem;
			text-align: center;
			font-family: Heebo, sans-serif;
			color: #fff;
			.illustration {
				width: 300px;
				height: auto;
				&.phone {
					align-self: center;
				}
			}
		}
	}
`;

export const Content = styled.article`
	max-width: 1366px;
	margin: 1rem auto;
	width: 95%;
`;
