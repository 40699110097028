import styled, { css, keyframes } from 'styled-components'

const animationColorOrder = keyframes`
    from{
        color:  #014d01;
    }
    to{
        color:  #018201;
    }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
`

export const Content = styled.div`
    height: 38rem;
    width: 20rem;
    background: #FFF;
    border-radius: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 600px){
        height: 90%;
        width: 90%;
    }

    .orderPlaced{
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            font-size: 0.7rem;
            color: gray;
        }

        span{
            font-size: 0.8rem;
            color: var(--colorButton);
            cursor: pointer;
        }
    }

    .actionsOrderDetails{
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        gap: 1rem;

        div{
            height: 4rem;
            width: 4rem;
            border: 1px solid gray;
            border-radius: 50%;
            position: relative;
            color: var(--colorButton);
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            p{
                font-size: 0.7rem;
                text-align: center;
                font-weight: 500;
            }

            svg{
                position: absolute;
                font-size: 1.1rem;
                top: 0;
                transform: translate(10%, -50%);
                background: #FFF;
            }
        }
    }

    .orderStatus{
        width: 98%;
        margin: 0 auto;
        padding: 0.6rem 0;
        background-color: #E5E4E299;
        margin-top: 0.8rem;
        border-radius: 0.3rem;

        p{
            text-align: left;
        }

        div{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .errorMessage{
            margin-top: 0.5rem;
        }

        svg{
            color:  green;
            font-size: 1.5rem;
            margin-right: 0.2rem;
        }

        .error{
            /* animation: ; */
        }

        p{
            font-size: 0.9rem;
            color: #2C2C2C;
        }
    }

    .typeOfShopping{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 98%;
        margin: 1.1rem auto 0 auto;
        svg{
            font-size: 1.1rem;
            color: var(--colorButton);
            margin-right: 0.3rem;
        }
        p{
            font-size: 0.8rem;
        }
    }
`;

export const OrderDayCaontainer = styled.div`
    width: 98%;
    margin: 0 auto;
    padding: 0.6rem 0;
    background-color: #E5E4E299;
    margin-top: 0.8rem;
    border-radius: 0.3rem;
    padding-top: 0.8rem;

    .divisor{
        width: 98%;
        border: 0.5px solid rgba(255,255,255,0.7);
        margin-bottom: 0.8rem;
    }

    .header{
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        strong{
            font-size: 1.5rem;
            animation: ${animationColorOrder} 0.8s ease-in-out infinite;
        }

        span{
            font-size: 0.8rem;
        }
    }

    .status{
        width: 98%;
        margin: 0 auto;
        font-size: 0.95rem;

        p{
            text-align: left;
        }

        div{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg{
            color:  green;
            font-size: 1.1rem;
            margin-right: 0.2rem;
        }
    }
`;

export const Informations = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    flex: auto;
    width: 98%;
    margin: 0.8rem auto;

    &::-webkit-scrollbar-track {
        background-color: #D3D3D3;
    }

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: gray;
    scrollbar-track-color: #D3D3D3;

    .productsContainer{
        border-top: 0.5px solid rgba(0, 0, 0, 0.2);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        padding: 0.8rem 0;
        .title{
           display: flex;
           justify-content: space-between;
           align-items: center;
        }

        div{
            p{
                font-size: 0.9rem;
                display: flex;
                align-items: center;

                span{
                    font-size: 0.8rem;
                    padding: 1px 4px 1px 4px;
                    margin-right: 0.2rem;
                    border-radius: 0.1rem;
                    background: #E5E4E299;
                    color: #353839;
                }
            }

            .obsStyle{
                font-size: 0.7rem;
                margin-left: 1.35rem;
                margin-bottom: 0.3rem;
                color: gray;
            }

            & + div{
                margin-top: 0.4rem;
            }

        }
    }

    .moneyInformations{
        padding: 0.8rem 0;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        .containerMoneyInformation{
            display: flex;
            justify-content: space-between;
            font-size: 0.9rem;
            font-weight: 400;

            span{
                color: green;
            }
            h3{
                margin-top: 0.5rem;
            }

            & + .containerMoneyInformation{
                margin-top: 0.5rem;
            }
        }

    }

    .paymentContainer{
        padding: 0.8rem 0;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        color: #2C2C2C;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            font-size: 1.1rem;
            color: var(--colorButton);
            margin-right: 0.3rem;
        }
    }

    .addressContainer{
        padding: 0.8rem 0;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        p:first-child{
            font-size: 0.7rem;
            color: gray;
            margin-bottom: 0.4rem;
        }
        span{
           
        }
        P{
            font-size: 0.9rem;
            color: #2C2C2C;
        }

        div{
            color: gray;
            font-size: 0.7rem;
            padding: 0.2rem;
            border-left: 2px solid #D3D3D3;
        }
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0.5rem 1rem 0.5rem;
    position: relative;

    span{
        font-size: 1.3rem;
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 10px;
        color: gray;
    }

    .container-info{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        div:first-child{
            height: 3rem;
            width: 3rem;
            padding: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 0.35rem;
            box-shadow: 0 1px 2px 1px rgb(0 0 0 / 30%);
            ${props => props.linkImage && css`
                background-image: url("${props.linkImage}");
            `}

        }

        .businessDatas{
            height: 100%;
            font-size: 0.8rem;

            div{
                width: 120px;
                height: 0.8rem;
                background: #E5E4E299;
                animation-duration: 1s;
                animation-name: skeleton;
                animation-iteration-count: infinite;
            }

            
            @keyframes skeleton{
                0% { filter: brightness(90%);}
                50% { filter: brightness(80%);}
                100% { filter: brightness(100%);}          
            }

            p{
                color: gray; 

               
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 150px;
                overflow: hidden;
                display: block;
                

                & + p{
                    margin-top: 0.1rem;
                }
            }
        }
    }
`
