import { Container, Content, Header, Main } from "./styles";
import { FaShoppingBasket, FaStoreAlt, FaTimes } from "react-icons/fa";

export function QuestionModal({ sendOrder, onClose }) {
	async function handleClickButton(value) {
		await sendOrder(value);
	}

	return (
		<Container>
			<Content>
				<Header>
					<button onClick={onClose}>
						<FaTimes />
					</button>
				</Header>

				<Main>
					<p>Deseja consumir o pedido na loja?</p>

					<div className="containerButtons">
						<button onClick={() => handleClickButton(true)}>
							<FaStoreAlt /> Consumir na loja
						</button>

						<button onClick={() => handleClickButton(false)}>
							<FaShoppingBasket />
							Para retirada
						</button>
					</div>
				</Main>
			</Content>
		</Container>
	);
}
