import styled from 'styled-components'

export const Container = styled.div`
    margin: 0 5%;
    position: relative;

    .advertise {
        position: absolute;
        bottom: 10px;

        width: 100%;
        text-align: center;
        font-size: 0.9rem;
        color: gray;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;

      h2{
        margin-top: 1rem;
        font-size: 1.2rem;

        &:first-child{
            text-decoration: underline;

            @media (min-width: 960px){
                cursor: pointer;
            }
        }
    }

    strong{
        font-size: 1.1rem;
        margin: 1rem 0.5rem 0 0.5rem; 
    }
`
