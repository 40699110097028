import React from 'react';
import inoveImg from '../../../images/logo.png'

import { Container } from './style';

export function BrandingContainer() {
    //localStorage.getItem('@Inoveclube:imageBusiness')
    const image = localStorage.getItem('@Inoveclube:imageBusiness');


    return(
        <Container>

            <img src={image.toString().toUpperCase() === 'NULL' ? inoveImg : image} alt="imagem logo"/>

        </Container>
    )
}
