import { TransversalBar } from '../../components/pagesComponents/transversalBar'
import { RecoverPasswordContent } from '../../components/pagesComponents/recoverPasswordPage';

import { Container } from './style';

export function RecoverPassword() {
    return(
        <Container>
            <TransversalBar />
            <RecoverPasswordContent />
        </Container>
    )
}
