import { ImQrcode } from 'react-icons/im'

import { Container } from './style'

export function QrCodeScreen({ color, messageType }) {
    return (
        <Container color={color}>
            {
                messageType === 0 ?
                <strong>Pistole o Qrcode na sua <span>Mesa</span></strong>
                :
                <strong>Pistole seu <span>QrCode</span></strong>
            }
            
            <ImQrcode />
        </Container>
    )
}
