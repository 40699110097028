import { useEffect, useRef, useState } from "react"
import { Option } from "./Option/index"
import { FaCheck } from "react-icons/fa"

import { Container, ContainerComments, MaxQtt } from "./style"

export function Additional({
	description,
	image,
	add,
	max,
	min,
	obligate,
	ilimited,
	objectE,
	listAdditionalRefs,
	blinkingObligate,
	businessDatas
}) {
	const additionalRef = useRef(null)

	const [counterOptionsQuantity, setCounterOptionsQuantity] = useState(0)
	const [uniqueSelected, setUniqueSelected] = useState(null)

	useEffect(() => {
		if (obligate && counterOptionsQuantity < min) {
			if (
				listAdditionalRefs.every(
					(add) => add !== additionalRef.current.offsetTop
				)
			)
				listAdditionalRefs.push(additionalRef.current.offsetTop)
		} else if (obligate && counterOptionsQuantity >= min) {
			listAdditionalRefs.splice(
				listAdditionalRefs.indexOf(additionalRef.current.offsetTop),
				1
			)
		}
		// eslint-disable-next-line
	}, [counterOptionsQuantity])

	return (
		<Container>
			<ContainerComments
				ref={additionalRef}
				active={
					listAdditionalRefs[0] === additionalRef.current?.offsetTop &&
					blinkingObligate
				}
			>
				<div className="comments-text">
					<h3>{description}</h3>
					{ilimited ? <p>Ilimitado</p> : <></>}
					{min > 0 ? <p>Quantidade mínima: {min}</p> : <></>}
				</div>

				<MaxQtt
					checked={counterOptionsQuantity >= min && !ilimited && min > 0}
				>
					<FaCheck />

					<>
						{obligate ? (
							<div className="obligate">Obrigatório</div>
						) : (
							<></>
						)}

						{!ilimited ? (
							<p className="text">
								{counterOptionsQuantity} de {max}
							</p>
						) : (
							<></>
						)}
					</>
				</MaxQtt>
			</ContainerComments>

			{add.map((element, index) => (
				<Option
					key={index}
					obsDescription={element.name}
					image={element.image}
					cdOption={element.idAdditional}
					price={element.price}
					objectE={objectE}
					counterOptionsQuantity={counterOptionsQuantity}
					setCounterOptionsQuantity={setCounterOptionsQuantity}
					uniqueSelected={uniqueSelected}
					setUniqueSelected={setUniqueSelected}
					businessDatas={businessDatas}
				/>
			))}
		</Container>
	)
}
