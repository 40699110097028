import { apiUpdate } from "../../../../../services/update";

export async function saveTicket(data) {
  const ticket = {
    client: {
      id: 100,
    },
    sectorSelected: 12,
    responsible: 0,
    description: `
      Solicitação de parceria via iNove Clube: ${data.message} 

      Nome: ${data.name} 

      E-mail: ${data.email} 

      Telefone: ${data.phone} 

      Estado: ${data.state} 

      Município: ${data.cdMunicipio} 
    `,
    prioritySelected: 1,
    unity: "dd",
    maxTime: 10,
    cause: 3,
    dsEmpresa: data.companyName,
    isInoveSystem: true,
    listWebLinkDrive: [],
  };

  await apiUpdate.post("/saveTicket", ticket);
}
