import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.9);
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;

    button{
        position: fixed;
        top: 20px;
        right: 20px;
        color: white;
        background-color: transparent;
        border: none;
        font-size: 30px;
    }

   
`;

