import { useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { DeliveryPayment } from './deliveryPayment'
import { OnlinePayment } from './onlinePayment'
import { ChangeMoneyModal } from './changeMoneyModal'

import { Container, Header, RadioButton, Content } from './style'


export function PaymentMethodsModal({ 
    setPaymentMethodsModalOpened, 
    businessDatas, 
    handleAddPaymentMethod, 
    verifyChange, 
    handleSetChangeValue, 
    changeValue,
    disabledPaymentOffline
}) {
    const [deliveryOrOnlinePayment, setDeliveryOrOnlinePayment] = useState( businessDatas.usePaymentOnline ? 'online' : 'delivery')
    const [changeMoneyModalOpened, setChangeMoneyModalOpened] = useState(false)
    
    return(
        <Container fullWidth={(disabledPaymentOffline || (! businessDatas.paymentsMethods) ||  businessDatas.paymentsMethods.length === 0)}>
            <Header>
                <AiOutlineArrowLeft onClick={() => { setPaymentMethodsModalOpened(false) }}/>
                <h1>Forma de Pagamento</h1>
            </Header>

            {
                businessDatas.usePaymentOnline && 
                <div className="contentRadioButtons">
                    <RadioButton 
                        onClick={() => setDeliveryOrOnlinePayment('online')}
                        active={deliveryOrOnlinePayment === 'online'}
                    >
                        <span>PAGUE PELO SITE</span>
                    </RadioButton>

                    {
                         (!disabledPaymentOffline && businessDatas.paymentsMethods.length > 0) &&  
                         <RadioButton 
                            onClick={() => setDeliveryOrOnlinePayment('delivery')}
                            active={deliveryOrOnlinePayment === 'delivery'}
                         >
                          <span>PAGUE NA ENTREGA</span>
                         </RadioButton>
                    }
                </div>
            }

            <Content>
                {
                    deliveryOrOnlinePayment === 'online' ?
                    <OnlinePayment 
                        handleAddPaymentMethod={handleAddPaymentMethod}
                        setPaymentMethodsModalOpened={setPaymentMethodsModalOpened}
                        handleSetChangeValue={handleSetChangeValue}
                    />
                    :
                    <DeliveryPayment 
                        setPaymentMethodsModalOpened={setPaymentMethodsModalOpened}
                        handleAddPaymentMethod={handleAddPaymentMethod}
                        businessDatas={businessDatas}
                        setChangeMoneyModalOpened={setChangeMoneyModalOpened}
                        handleSetChangeValue={handleSetChangeValue}
                    />
                } 
            </Content>

            {changeMoneyModalOpened ?
                <ChangeMoneyModal 
                    setChangeMoneyModalOpened={setChangeMoneyModalOpened} 
                    setPaymentMethodsModalOpened={setPaymentMethodsModalOpened}
                    verifyChange={verifyChange}
                    handleSetChangeValue={handleSetChangeValue}
                    changeValue={changeValue}
                /> 
                :
                <></>
            }
            
        </Container>
    )
}
