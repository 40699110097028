import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { InputGeneric } from '../../../Forms/InputGeneric'
// import { InputDate } from '../../../Forms/InputDate'
import { MainLoader } from '../../../loaders/mainLoader'
import { Logo } from '../../../Logo' 
import { Button } from '../../../Button'
import { api } from '../../../../services/api'
import { toast } from 'react-toastify'
import moment from 'moment'

import { Container, InputContainer } from './style'


export function RecoverByCPF() {
    const [cpf, setCpf] = useState('')
    const [date, setDate] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    async function handleSendCPF(event) {
        event.preventDefault()
        setIsLoading(true)

        const obj = { 
            cpf, 
            date: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }

        await api.post('/recoverByCPF', obj)
        .then(response => {
            const { token, success } = response.data

            if(success)
                history.push(`/password/${token}`)
            else
                toast.error('Usuário não encontrado')
        })
        .catch((error) => {
            console.log(error)
            toast.error('Algo deu errado, tente novamente')

            return
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    function formatDate(e){
        if(e.target.value.length === 11){
            return
        }

        let v = e.target.value.replace(/\D/g,"");
        v = v.replace(/(\d{2})(\d)/,"$1/$2") 
        v = v.replace(/(\d{2})(\d)/,"$1/$2") 
        
        setDate(v)
    }


    return (
        <Container>
            <form onSubmit={handleSendCPF}>
                <Logo />

                <InputGeneric 
                    placeholder="Digite seu CPF"
                    value={cpf}
                    onChange={event => setCpf(event.target.value)}
                />

                <InputContainer>
                    <label>
                        Data de nascimento:
                    </label>
                    
                    <input
                        type="text"
                        placeholder='dd/mm/yyyyy'
                        name="date"
                        className="inputDate"
                        value={date}
                        onChange={formatDate}
                    />
                </InputContainer>

                <Button>
                    Enviar
                </Button>
            </form>

            {
                isLoading 
                ? <MainLoader title={"Verificando..."}/> 
                : <></>
            } 
        </Container>
    )
}