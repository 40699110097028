import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	padding-bottom: 1.5rem;
	.topbar {
		padding: 1rem;
		width: 100%;
		border-bottom: 1px solid #ccc;
		.link {
			display: flex;
			align-items: center;
			gap: 1rem;
			font-size: 0.7rem;
		}
	}
	.company-container {
		border: 1px solid rgba(149, 157, 165, 0.5);
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 0.5rem;
		width: 80%;
		max-width: 1000px;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 0.3rem;
		.info-wrapper {
			display: flex;
			flex-direction: column;
			gap: 0.4rem;
			span {
				font-weight: 500;
			}
		}
		.item-container {
			display: flex;
			gap: 1.5rem;
			padding: 1rem;
			flex-wrap: wrap;
			justify-content: center;
			.disable {
				opacity: 0.5;
				filter: grayscale(70%);
			}
			.item-wrapper {
				display: flex;
				gap: 1rem;
				width: 18rem;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				padding: 0.7rem;
				border-radius: 1rem;
				.green {
					color: green;
					font-weight: 500;
				}
				span {
					font-weight: 600;
				}
				img {
					width: 4rem;
					height: 4rem;
					border-radius: 0.5rem;
				}
				.product-info {
					flex: 1;
					overflow: hidden;
					.product-name {
						white-space: nowrap;
						text-overflow: ellipsis;
						width: 100%;
						overflow: hidden;
					}
				}
			}
		}
	}
	.alert {
		text-align: center;
		width: 50%;
		min-width: 360px;
		padding: 1rem;
		background-color: var(--brand-300);
		border: 1px solid var(--brand-400);
		border-radius: 0.5rem;
		opacity: 0.8;
	}
`;
