import { useAuth } from "../../context/AuthContext";

import { Link } from "react-router-dom";
import { formatMoney } from "../../Utils/formatMoney";
import { Container } from "./style";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import { api } from "../../services/api";

import { SpaceLoader } from "../../components/loaders/spaceLoader";

export function Fidelity() {
	const { signOut } = useAuth();

	const [companys, setCompanys] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getData = async () => {
			try {
				const token = localStorage.getItem("@Inoveclube:token");
				const { data } = await api.get(`/getFidelities`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setCompanys(data.results);
			} catch (error) {
				if (error.response && error.response.status === 401) {
					toast.warn("Por favor, tente fazer o login novamente.");
					signOut();
					return;
				}
				toast.error("Erro ao carregar os dados");
			} finally {
				setLoading(false);
			}
		};
		getData();
	}, [signOut]);

	return (
		<Container>
			<div className="topbar">
				<Link to="/profile" className="link">
					<FaArrowLeft size={20} />
					<h1>Fidelidade</h1>
				</Link>
			</div>

			{loading ? (
				<SpaceLoader />
			) : companys.length === 0 ? (
				<h2 className="empty">Nenhuma fidelidade vinculada</h2>
			) : (
				companys.map((company, companyIdx) => (
					<div key={companyIdx} className="company-container">
						<h2>{company.business}</h2>
						<div className="info-wrapper">
							<p>
								Saldo em cashback: <span>{formatMoney(company.cashback)}</span>
							</p>
							<p>
								Saldo em pontos: <span>{company.totalPoints}</span>
							</p>
						</div>
						<div className="item-container">
							{company.products.map((product, productIdx) => (
								<div
									key={productIdx}
									className={`item-wrapper ${!product.prizeRedemption && "disable"}`}
								>
									{product.linkImagem === "" || product.linkImagem === null ? (
										<></>
									) : (
										<img src={product.linkImagem} alt="" />
									)}
									<div className="product-info">
										<p className="product-name">{product.product}</p>
										<p className="green">
											<span>{product.points}</span> pontos
										</p>
										{product.prizeRedemption ? (
											<p>Pode ser resgatado!</p>
										) : (
											<p>
												Faltam <span>{product.points - company.totalPoints}</span> para o
												resgate!
											</p>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				))
			)}

			<span className="alert">
				*Pode levar até 72 horas para que os pontos e cashback sejam atualizados.
			</span>
		</Container>
	);
}
