import { useState } from "react"
import { Button } from "../../../Button"
import { FaArrowLeftLong } from "react-icons/fa6"
import { Container, ContentWrap, ButtonWrap } from "./style"

export function Steps({ stepsComponents, onNextStep, onPrevStep, onFinish }) {
	const [currentStep, setCurrentStep] = useState(0)

	function handleNextStep() {
		if (currentStep < stepsComponents.length - 1) {
			setCurrentStep(currentStep + 1)
			if (onNextStep) {
				onNextStep({ currentStep: currentStep + 1 })
			}
		}
		if (currentStep === stepsComponents.length - 1) {
			if (onFinish) {
				onFinish()
			}
		}
	}

	function handlePrevStep() {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1)
			if (onPrevStep) {
				onPrevStep({ currentStep: currentStep - 1 })
			}
		}
	}

	return (
		<Container>
			<ContentWrap>{stepsComponents[currentStep]}</ContentWrap>
			{stepsComponents.length > 1 && (
				<ButtonWrap>
					<Button onClick={() => handlePrevStep()} disabled={currentStep === 0}>
						<FaArrowLeftLong />
					</Button>
					<Button onClick={() => handleNextStep()}>
						{currentStep === stepsComponents.length - 1
							? "Finalizar"
							: "Próximo"}
					</Button>
				</ButtonWrap>
			)}
		</Container>
	)
}
