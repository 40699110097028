import {useMemo} from 'react';
import { useHistory } from 'react-router';
import { useMenu } from '../../../../context/MenuContext';
import { useOrder } from '../../../../services/hooks/useOrder';

import { 
    FaHome, 
    FaShoppingCart, 
    FaUserAlt,  
    FaClipboardList  
} from 'react-icons/fa'

import { Container } from './style';


export function MenuBar({ setInfoOpen }) {
    const history = useHistory()
    const { handleSetIsBuyModalOpen } = useMenu();

    const {data, isLoading} = useOrder();

    const totalOrdersWaitingPayment = useMemo(() => {
        
        if(isLoading  || !data){
            return 0
        }

        const firstOrderWaitingPayment = (data.lastOrder && data.lastOrder.status === 'Waiting Payment') ? data.lastOrder : null;
        const othersOrdersWaitingPayment = (data.genericDatas && data.genericDatas.filter((item) => item.status === 'Waiting Payment')) || [];
        
        const arr = firstOrderWaitingPayment ? [firstOrderWaitingPayment, ...othersOrdersWaitingPayment] : [...othersOrdersWaitingPayment];
        
        return arr.length;
    }, [data, isLoading])

    return(
        <Container>
            <FaHome onClick={() =>   {
                localStorage.getItem('@Inoveclube:type') ?
                window.location.href=`${process.env.REACT_APP_URL}menu/${localStorage.getItem('@Inoveclube:nameBusiness')}/${localStorage.getItem('@Inoveclube:type')}/${localStorage.getItem('@Inoveclube:typeNumber')}`
                :
                window.location.href=`${process.env.REACT_APP_URL}menu/${localStorage.getItem('@Inoveclube:nameBusiness')}` 
            
            }}/>

            <div className="item-bar" onClick={() => { 
                handleSetIsBuyModalOpen(true)
                localStorage.getItem('@Inoveclube:type') ?
                history.push( `/menu/${localStorage.getItem('@Inoveclube:nameBusiness')}/${localStorage.getItem('@Inoveclube:type')}/${localStorage.getItem('@Inoveclube:typeNumber')}`) 
                :
                history.push(`/menu/${localStorage.getItem('@Inoveclube:nameBusiness')}`) 
            }}>
                <div className="items-shopping">{
                    localStorage.getItem('@Inoveclube:listBuying' + localStorage.getItem('@Inoveclube:nameBusiness')) ?
                    JSON.parse(localStorage.getItem('@Inoveclube:listBuying' + localStorage.getItem('@Inoveclube:nameBusiness'))).length 
                    : 0
                }</div> 
                <FaShoppingCart />
            </div>

           <div className='item-bar' onClick={() => history.push('/orders')}>
                {
                    totalOrdersWaitingPayment > 0 && <div className="items-shopping">{totalOrdersWaitingPayment}</div>
                }
                <FaClipboardList/>
           </div>

            <FaUserAlt />
        </Container>
    )
}
