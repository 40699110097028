import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 200;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.modal {
		width: 100%;
		max-width: 500px;
		min-height: 200px;
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		position: absolute;
		z-index: 300;
		.loading-wrapper {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 300;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		legend {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 10px;
			text-align: center;
		}
		div {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			label {
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 5px;
			}
			input,
			textarea {
				padding: 5px;
				border: 1px solid #ccc;
				border-radius: 5px;
				outline: none;
				resize: none;
			}
		}
		button {
			width: 100%;
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 5px;
			outline: none;
			cursor: pointer;
			background-color: var(--brand-red-600);
			color: #fff;
			&:hover {
				background-color: var(--brand-red-700);
			}
		}
	}
`;
