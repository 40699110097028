import { inovePagarme } from '../../../../../services/pagarmeApi'
import { useAuth } from '../../../../../context/AuthContext'
import { SpaceLoader } from '../../../../loaders/spaceLoader'
import { useState, useCallback, useEffect } from 'react'
import {FaPlus} from 'react-icons/fa';
import { useHistory } from 'react-router'
import { UserCard } from '../../../userCard'
import { toast } from 'react-toastify'


import { Container, ButtonPix } from './style';
import { MdAttachMoney } from 'react-icons/md';

import { ModalRegisterCard } from './ModalRegisterCard';


export function OnlinePayment({
    handleAddPaymentMethod,
    setPaymentMethodsModalOpened,
    handleSetChangeValue
}) {
    const [loadingCards, setLoadingCards] = useState(true)
    const [cards, setCards] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const { signOut } = useAuth()

    const history = useHistory()

    const getCards = useCallback(async () => {
        try {
            await inovePagarme.get(`/getCards/${localStorage.getItem('@Inoveclube:token')}`)
                .then(response => {
                    if(response.data.length === 0){
                        setOpenModal(true);
                    }

                    setCards(response.data)
                })
            setLoadingCards(false)
        } catch (error) {           
            if (error.response.status === 401 || error.response.status === 500) {
                signOut()
                history.push('/login');
                toast.warning('Sua sessão foi expirada')
            } else {
                toast.error(error.response.data.message)
                history.push('/profile')
            }
        }
    }, [history, signOut])


    function handleSelectCard(isPix = false, data = undefined) {
        if(isPix){
            let object = { 
                idPayment: 1000,  
                description: 'PIX - ONLINE',
                type: 'online',
                pix: true,
                idCard: '', 
                icon: 10,
            } 
            handleAddPaymentMethod(object)
            setPaymentMethodsModalOpened(false)
            localStorage.setItem('@Inoveclube:onlinepmtd', JSON.stringify(object))
            return
        }

        let object = {
            idPayment: 1000,
            icon: data.brand.toLowerCase(),
            description: `${data.brand} ••••${data.last_digits}`,
            idCard: data.id,
            type: 'online'
        }
        localStorage.setItem('@Inoveclube:onlinepmtd', JSON.stringify(object))

        handleSetChangeValue(0)
        handleAddPaymentMethod(object)
        setPaymentMethodsModalOpened(false)
    }

    useEffect(() => {
      getCards()
    }, [getCards])



    if (loadingCards)
        return <SpaceLoader colorLoader="var(--colorFontsCardsDashboard)" />


    return (
        <Container>
            <ButtonPix onClick={() => handleSelectCard(true)}>
                <MdAttachMoney />
                
                <p>PIX</p>
            </ButtonPix>

            {
                cards.map(card => (
                    <UserCard
                        key={card.id}
                        deleteCardVisible={false}
                        cardDatas={card}
                        cards={cards}
                        setCards={setCards}
                        onClick={() => handleSelectCard(false, card)}
                    />
                ))
            }

            <div className="marginDiv"></div>

            <div className="logButton">
                <button onClick={() => history.push('/cardregister')}>
                    <FaPlus
                        color='#fff'
                    />
                    Adicionar Cartão
                </button>
            </div>

            {
                openModal &&
                <ModalRegisterCard
                    onClose={() => setOpenModal(false)}
                />
            }
        </Container>
    )
}
