import { GiCheckMark } from "react-icons/gi";

import { Container } from "./style";

export function InputCheck({ checked, handleClick, disable = false, ...rest }) {
	return (
		<Container onClick={handleClick} {...rest} disable={disable}>
			{checked && <GiCheckMark />}
		</Container>
	);
}
