import {useParams} from 'react-router-dom';

import { TransversalBar } from '../../components/pagesComponents/transversalBar'
import { RegisterDatasPersonContent } from '../../components/pagesComponents/registerDatasPersonPage';

import { Container } from './style';

export function RegisterDatasPerson() {
    const {data} = useParams();

    return(
        <Container>
            <TransversalBar />
            <RegisterDatasPersonContent 
                dataParams={data}
            />
        </Container>
    )
}
