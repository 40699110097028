import styled from "styled-components"

export const Container = styled.div`
	width: 100%;
	padding: 0.5rem;
	border-radius: 8px;
	opacity: ${(props) => props.disabled && "0.75"};

	:hover {
		background-color: var(--gray-200);
		cursor: pointer;
	}

	.label {
		display: flex;
		flex-direction: column;
		gap: 0.3rem;
		font-size: 0.8rem;

		span:first-child {
			font-size: 1rem;
		}
	}
`

export const Label = styled.label`
	display: flex;
	align-items: center;
	flex-direction: ${(props) => (props.side === "left" ? "row-reverse" : "row")};
	gap: 0.5rem;
`
