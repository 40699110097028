import styled from "styled-components";

export const Container = styled.div`
    text-align: center;
    padding: 1rem 1rem 2rem 1rem;

    h2{
        color: #393939;
        font-size: 2rem;
    }

    span{
        position: absolute;
        top: 10px;
        right: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 40px;
        width: 40px;
        color: rgb(135, 134, 139);
        font-size: 1.3rem;
        border-radius: 5px;
        transition: all 0.3s ease 0s;

        &:hover{
            background: rgb(200, 200, 200);
            color: rgb(255, 255, 255);
            cursor: pointer;
        }
    }
`