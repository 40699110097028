import { useState, useEffect } from "react";
import capitals from "./capitals.json";
import brazilianAllCities from "./brazilianAllCities.json";

import { Container, ContentCity } from "./style";

export function CitiesToUserChoose({
	searchTerm,
	fetchSectors,
	latlgn,
	neighborhood,
	state,
	modular,
}) {
	const listCapitals = capitals;
	const listBrazilianAllCities = brazilianAllCities;

	const [searchResults, setSearchResults] = useState([]);
	let isSearching = searchTerm.split("").length < 3;

	function handleSetUserLocation(cityDatas) {
		let cityDatasLocalStorage = {
			idCity: cityDatas.idCity,
			description: `${cityDatas.city}-${cityDatas.uf}`,
			latlgn: { ...latlgn },
			neighborhood: neighborhood,
			state: state || cityDatas.uf,
		};
		if (modular) {
			fetchSectors(cityDatas.uf);
		} else fetchSectors(cityDatas.idCity);
		localStorage.setItem(
			"@Inoveclube:location",
			JSON.stringify(cityDatasLocalStorage)
		);
	}

	useEffect(() => {
		if (searchTerm.split("").length >= 3) {
			const resultsFromSearch = listBrazilianAllCities.filter((city) =>
				city.city.toLowerCase().includes(searchTerm.toLowerCase())
			);
			setSearchResults(resultsFromSearch);
		}
	}, [listBrazilianAllCities, searchTerm]);

	return (
		<Container>
			{isSearching
				? listCapitals.map((capital, index) => (
						<ContentCity
							key={index}
							onClick={() => handleSetUserLocation(capital)}
						>
							<p>
								{capital.city} - {capital.uf}
							</p>
						</ContentCity>
				  ))
				: searchResults.map((city) => (
						<ContentCity
							key={city.idCity}
							onClick={() => handleSetUserLocation(city)}
						>
							<p>
								{city.city} - {city.uf}
							</p>
						</ContentCity>
				  ))}
		</Container>
	);
}
