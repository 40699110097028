import styled, {keyframes} from "styled-components";

const animetedImage = keyframes`
    0%{
        transform: rotate(-2deg);
    }
    50%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-2deg);
    }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.25);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    img{
        animation: ${animetedImage} ease-in-out 0.5s infinite;
        max-height: 200px;
        object-fit: cover;
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #393939;

    button{
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #393939;
        font-size: 1.1rem;
    }
`;

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .description{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
                
        p{
            text-align: center;
        }
    }

    button{
        width: 100%;
        max-width: 200px;
        background-color: var(--colorButton);
        color: #fff;
        padding: 0.8rem;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
`;
