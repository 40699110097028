import { useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { inovePagarme } from '../../../services/pagarmeApi'
import { DeletingLoader } from '../../loaders/deletingLoader'
import { queryClient } from '../../../services/queryClient'
import { toast } from 'react-toastify'

import { Container } from './style';


export function UserCard({ 
    cardDatas, 
    deleteCardVisible = true,
    ...rest 
}) {
    const [loadingDelete, setLoadingDelete] = useState(false)

    async function clearPaymentMethod () {
        setLoadingDelete(true)
        // console.log(cardDatas)
        // console.log(`/deleteCard/${cardDatas.id}/${localStorage.getItem('@Inoveclube:token')}`)

        const response = await inovePagarme.delete(`/deleteCard/${cardDatas.id}/${localStorage.getItem('@Inoveclube:token')}`)
        const onlinePayment = localStorage.getItem('@Inoveclube:onlinepmtd') 
            ? [JSON.parse(localStorage.getItem('@Inoveclube:onlinepmtd'))]
            : []
            
        const { success, message } = response.data

        if(success){
            const previousCards = queryClient.getQueryData('cards')
            const creditCardList = cardDatas.type === 'credit_card' 
                ? previousCards.creditCardList.filter(card => {
                    return card.id !== cardDatas.id
                })
                : previousCards.creditCardList

            const debitCardList = cardDatas.type === 'debit_card' 
                ? previousCards.debitCardList.filter(card => {
                    return card.id !== cardDatas.id
                })
                : previousCards.debitCardList
            

            const nextCards = {
                creditCardList,
                debitCardList
            }

            queryClient.setQueryData('cards', nextCards)

            if(onlinePayment.some(card => card.idCard === cardDatas.id))
                localStorage.removeItem('@Inoveclube:onlinepmtd')

            toast.success(message)
        }else{
            toast.error(message)
        }

        setLoadingDelete(false)
    }
   

    return(
        <Container 
            deleteCardVisible={deleteCardVisible}
            {...rest}
        >
            <img src={`images/${cardDatas.brand.toLowerCase()}.png`} alt="brand card"/>

            <div className="cardInfo">
                <p>{cardDatas.brand}</p>
                <span>&#8226;&#8226;&#8226;&#8226; {cardDatas.last_digits}</span>
            </div>

                
            {
                loadingDelete ?
                <DeletingLoader />
                :
                deleteCardVisible && <BiTrash onClick={clearPaymentMethod} />
            }
          
        </Container>
    )
}
