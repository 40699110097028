import styled, { css } from 'styled-components'

export const Container = styled.div`

    .headerFill{
        width: 100%;
        height: 61px;
    }

    h1{
        margin-bottom: 2px;
    }
`
export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;
    position: fixed;
    top: 0;
    background: #FFF;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`

export const Content = styled.div`
    max-width: 600px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    .marginDiv{
        height: 77px;
        width: 100%;
        background: #FFF;
    }

`

export const ButtonsContainer = styled.div`
    width: 98%;
    display: flex;  
    align-items: center;
    justify-content: center;
    border-bottom: ${localStorage.getItem('@Inoveclube:type') ? 'none' : '1px solid rgba(0, 0, 0, 0.2)'};

`

export const RadioButtonShopping = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #FFF;
    color:  ${props => props.isActive ? 'var(--colorButton)' : 'black'};
   
    padding: 20px 0 20px 0;
    margin-top: 5px;
    cursor: pointer;

    & + &{
        border-left: 1px solid rgba(0, 0, 0, 0.2);
    }

    p{
        font-size: 1rem;
    }

    svg{
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
    }
   
    @media (max-width: 650px){
        p{
            font-size: 0.9rem;
        }

    }
`

export const InputAddressArea = styled.div`
    width: 98%;
    margin-top: 30px;

    .buttonModalAddress{
            width: 98%;
            margin: 0 auto;
            padding: 12px;
            display: flex;
            align-items: center !important;
            justify-content: center;
            color: gray;
            cursor: pointer;
            ${props => (props.isEnableInputSelectAddress === false) && css`
                opacity: 0.5;
                pointer-events: none;
            `}

            div{
                font-size: 0.9rem;
                max-width: 50%;
                overflow: hidden;
                P{
                    &:first-child{
                        margin-bottom: 2px;
                    }
                }
                

                h4{
                    margin-bottom: 2px;
                    color: #353839;
                }
            }


            svg{
                margin-top: 2px;
                margin-left: 30px;
                color: var(--colorButton);
                font-size: 1.2rem;
            }

            .mapLocation{
                margin-top: 0;
                margin-left: 0;
                margin-right: 30px;
                font-size: 2rem;
            }

           @media (max-width: 500px){
                width: 80%;
            }
    }

    .businessAddress{
            width: 98%;
            margin: 0 auto;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: gray;
            font-size: 0.9rem;

            
            p:first-child{
                margin-bottom: 2px;
            }
            

            h4{
                margin-bottom: 2px;
                color: #353839;
            }

            svg{
                margin-right: 25px;
                color: var(--colorButton);
                font-size: 2rem;
            }

            @media (max-width: 500px){
                width: 80%;
            }
    }
`

export const FixedShoppingInformations = styled.div`
    width: 100%;
    margin-top: 20px;


    .tableProductsShopp{
        width: 98%;
        margin: 0 auto;
        padding: 20px 0 10px 0;
        border-top:  0.5px solid rgba(0, 0, 0, 0.2);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);

        .totalShopping{
            margin-top: 10px;
            font-weight: 500;
        }
        div{

            p{
                font-size: 1rem;
                margin-bottom: 0.4rem;
                display: flex;
                align-items: center;
                
                span{
                    font-size: 0.9rem;
                    padding: 1px 4px 1px 4px;
                    margin-right: 0.2rem;
                    border-radius: 0.1rem;
                    background: #E5E4E299;
                    color: #353839;
                }
            }

            .obsStyle{
                font-size: 0.7rem;
                margin-left: 1.35rem;
                margin-bottom: 0.3rem;
                color: gray;
                word-break: break-word;
            }

        }

    }

    .buttonAddShopping{
        width: 98%;
        margin: 0 auto;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        text-align: center;
        padding: 20px 0 20px 0;
        font-size: 0.9rem;
        color: var(--colorButton);
        cursor: pointer;
    }

    .grayLineDivide{
        width: 98%;
        height: 5px;
        margin: 0.04rem auto;
        background: #E5E4E2;
    }



    .tablePricesShopp{
        width: 93%;
        margin: 10px auto;

        div{

            width: 100%;
            display: flex;
            justify-content: space-between;
            
            & + div{
                margin-top: 10px;
            }

            p{
                font-size: 0.9rem;
                color: gray;
            }

            span{
                color: green;
            }

            h1, h2{
                font-size: 1.1rem;
                margin-top: 10px;
            }

        }
    }

`

export const FinishShoppingArea = styled.div`
    max-width: 600px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 20px 0 10px 0;
    background: #FFF;

    .container{
        display: flex;
        align-items: center;
        width: 98%;
        margin: 0 auto;
    }
        
    .textTotal{
        margin-right: 20px;
        width: 25%;
        p{
            color: gray;
        }

        span{
            font-weight: 500;
            font-size: 1rem;
        }
    }

    .buttonShopp{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        background: var(--colorButton);
        padding: 15px 0 15px 0;
        color: var(--colorButtonBottom);
        border-radius: 0.3rem;
        cursor: pointer;

        ${props => props.buttonFinishedDisable && css`
            opacity: 0.5;
            pointer-events: none;
        `}

        &:disabled{
            background-color: red;
            cursor: not-allowed;
        }
    }
`



export const WarningPage = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--colorButton);
    flex-direction: column;

    div{
        margin-bottom: 20px;
    }

    button{
        border: none;
        padding: 0.9rem;
        border-radius: 0.25rem;

        &:hover{
            filter: brightness(0.9);
        }
    }
    
`;

export const ContainerLoader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
`;