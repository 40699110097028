import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

const schema = z.object({
	number: z.string().min(1, "Número do cartão obrigatório"),
	date: z.string().min(1, "data obrigatória"),
	type: z.string().min(1, "Tipo Obrigatório"),
	name: z.string().min(1, "Nome obrigatório"),
	cvv: z.string().min(3, "cvv inválido"),
});

export function useCardForm() {
	const {
		handleSubmit,
		register,
		formState: { errors },
		control,
	} = useForm({
		resolver: zodResolver(schema),
	});

	const cardCvv = useWatch({ control, name: "cvv" });
	const cardExpirationDate = useWatch({ control, name: "date" });
	const cardHolderName = useWatch({ control, name: "name" });
	const cardNumber = useWatch({ control, name: "number" });

	useEffect(() => {
		if (errors) {
			const errorsArr = Object.keys(errors).map((key) => {
				return errors[key].message;
			});

			if (errorsArr[0]) toast.error(errorsArr[0]);
		}
	}, [errors]);

	return {
		handleSubmit,
		register,
		errors,
		cardCvv,
		cardExpirationDate,
		cardHolderName,
		cardNumber,
	};
}
