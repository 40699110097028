import { useState } from 'react'
import { FiX } from 'react-icons/fi'
import { GeneralLoader } from '../../../loaders/generalLoader'

import { Container, CardTerm, CardText } from './style'

export function ModalAcceptTerms({ handleModalAcceptOpen, savePersonData, acceptTerms }) {
    const [acceptTermsChecked, setAcceptTermsChecked] = useState(false);


    return (
        <Container>
            <CardTerm acceptTermsChecked={acceptTermsChecked}>
                <FiX onClick={() => handleModalAcceptOpen()} />
                <header>
                    <p>Termo de Aceite</p>
                </header>

                <CardText>
                    {acceptTerms.idTerm !== '' ?
                        <p>
                            Termo de Privacidade e Consentimento Livre e Esclarecido<br /><br />

                            Caro(a) usuário(a), ao usar este site, alguns de seus dados pessoais serão tratados. Como forma de garantir sua privacidade enquanto titular dos dados, a seguir, será detalhado como será realizado esse tratamento de dados.
                            Ao final deste termo, ciente de todas as implicações possíveis que decorrerão do tratamento de seus dados pessoais, você estará apto a fornecer o seu consentimento, nos termos do art. 7º, I da Lei 13.709/2018 (Lei Geral de Proteção de dados Pessoais).
                            Seguem detalhadas abaixo, por tópicos, todas as informações referentes ao tratamento de dados do usuário:<br /><br />

                            1- Finalidade específica do tratamento dos dados pessoais coletados
                            O cadastro no presente site coleta os seguintes dados de seus usuários:<br /><br />

                            I.	Nome;<br />
                            II.	CPF;<br />
                            III.	Data de Nascimento;<br />
                            IV.	Telefone;<br />
                            V.	Endereço;<br />
                            VI.	E-mail;<br />
                            VII. Redes sociais.<br /><br />

                            Estes dados poderão ser usados para verificação visando evitar fraudes e para contatar o usuário para ofertas de produtos/serviços de seu interesse e que estejam diretamente relacionados com a utilização deste site.
                            Além dos dados pessoais informados acima o site poderá coletar dados por outros meios como por cookies (definição deles). Qualquer uso de cookies ou mesmo de outras ferramentas de rastreamento pelo site servem para identificar os usuários e lembrar as suas preferências, com único propósito de fornecer os serviços requeridos por eles.
                            O não fornecimentos de determinados dados pessoais pode tornar impossível para este site, uma vez que seu fim precípuo é o fornecimento de serviços e promoções mediante o cadastro do(a) usuário(a) nesta plataforma denominada Inove Club. À luz do art. 9º, §3º, Lei nº 13.709/2018).<br /><br />
                            A) Contato com o usuário - oferecimento de serviços/descontos/promoções - ações de marketing via e-mail fornecido pelo usuário.<br />
                            Ao fornecer o endereço de e-mail cadastrado no site o(a) usuário(a) será adicionado(a). À lista de contatos para envio de mensagens com informações comerciais ou promocionais sobre este site com oferecimento de serviços, descontos ou promoções que se relacionem especificamente com o serviço oferecido.
                            Os dados coletados não serão compartilhados com terceiros para fins de marketing/promoções / descontos sem o prévio consentimento do usuário.<br /><br />
                            B) Informações adicionais sobre a coleta e processamento de dados:<br />
                            1.	Os dados pessoais dos usuários podem ser utilizados para fins jurídicos pelo controlador de dados quando demandado em juízo ou quando necessário para ajuizamento de possível ação jurídica decorrente de uso indevido deste serviço ou de serviços a eles relacionados.<br />
                            2.	 O controlador de dados ainda poderá revelar os dados pessoais mediante solicitação do Poder Público ou para cumprimento de obrigação legal.<br />
                            3.	 Os dados pessoais poderão ser compartilhados para estabelecer, exercer ou defender nossos direitos legais (incluindo o fornecimento de informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito).<br />
                            C) Informações adicionais<br />
                            Além das informações contidas neste Termo de Privacidade, este site poderá fornecer ao usuário informações adicionais e contextuais sobre os serviços específicos ou a coleta e processamento de dados pessoais mediante solicitação de seu consentimento prévio.<br />
                            D) Manutenção do site<br /><br />
                            O presente site e quaisquer serviços de terceiros poderão coletar arquivos que gravam a interação com este site (logs/registros do sistema) ou outros dados pessoais, tais como o endereço de IP (endereço de protocolo de internet que identifica um computador) ou ID (identidade) do usuário, para manutenção do site ou outra forma de operação que se faça necessária à sua atualização e funcionamento.<br /><br />
                            2. Forma e duração do tratamento<br />
                            Os dados coletados serão tratados enquanto perdurar o serviço/produto oferecido.<br />
                            O controlador de dados adotará todas as medidas necessárias para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos dados.<br />
                            O processamento de dados é realizado por computadores e/ou ferramentas de TI (Tecnologia da Informação) habilitadas, seguindo procedimentos organizacionais e meios estritamente relacionados aos fins indicados.<br /><br />

                            3 - Identificação e informações de contato do controlador<br />
                            O controlador desse site é (empresa ou responsável, que poderá ser contactado pelo e-mail (rede social, endereço físico e/ou telefone).<br /><br />

                            4 – Compartilhamento de dados do usuário e a finalidade<br />
                            O controlador poderá compartilhar os dados coletados (colocar os dados e as pessoas e quem terão acesso – os processadores).<br />
                            O controlador é responsável pelos dados compartilhados, assim como os (dizer quem é processador de dados), que somente utilizarão os dados para as finalidades já informadas desde que haja o consentimento prévio do usuário.<br />
                            Além do controlador de dado, em alguns casos, os dados poderão ser acessados por outras pessoas envolvidas na operação do site no âmbito interno da empresa (como por exemplo, administração, vendas, marketing, jurídico, sistema) ou pessoas externas (como por exemplo, fornecedores terceirizados de serviços técnicos, entregadores, provedores de serviços de internet, empresas de Tecnologia da Informação, agências de comunicação) nomeadas, quando necessário, como processadores de dados, o que será feito mediante contratação específica entre estes e o controlador assegurando-se a privacidade do usuário, A lista atualizada destas partes poderá ser solicitada ao controlador a qualquer momento mediante do teor dos contatos fornecidos acima, desde que não atente a questões sigilosas e/ou de segredo empresarial.<br /><br />

                            5 – Dos direitos do titular<br />
                            Em atendimento não previsto no art.18 da Lei nº 13.709/2018, o titular de dados pessoais coletados pelo controlador via este site poderá, a qualquer momento e mediante requisição obter:<br /><br />

                            I - confirmação da existência de tratamento;<br />
                            II – acesso aos dados;<br />
                            III - correção de dados incompletos, inexatos ou desatualizados;<br />
                            IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na legislação vigente;<br />
                            V – portabilidade dos dados a outro fornecedor de produto ou serviço, mediante requisição expressa e resguardados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador;<br />
                            VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei 13.709/2018;<br />
                            VII- informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhados de dados;<br />
                            VIII_ informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;<br />
                            IX- revogação do consentimento, nos termos do §5º do art. 8º da Lei 13.709/2018.<br /><br />

                            6 - Alterações no Termo de Privacidade<br />
                            O controlador de dados poderá efetuar alterações neste Termo de Privacidade a qualquer momento, o que será feito mediante comunicação aos usuários deste site, sendo-lhes requerido novo consentimento. Recomenda-se fortemente que o Termo de Privacidade seja consultado com frequência. Em caso de discordância com qualquer das alterações introduzidas pelo controlador, o usuário deve cessar o uso desse serviço (este site) solicitando ao controlador a exclusão de seus dados pessoais.<br /><br />

                            7 - Definições e referências jurídicas<br />
                            Dados (ou dados pessoais): São as informações relativas a uma pessoa natural identificada ou identificável, ou seja, dados, que mesmo indiretamente, possam levar a identificação de um único só indivíduo.<br />
                            Dados de uso: Quaisquer informações coletadas automaticamente a partir deste serviço (ou serviços de terceiros contratados neste serviço, que podem incluir, mas não se limitam a: endereços de IP’s ou identificações dos computadores (smartphones, tablets, etc.) utilizados pelos usuários que utilizam este site, endereços URI (Identificador Uniforme de Recurso), a data e hora do pedido, o método utilizado para submeter o pedido ao servidor de resposta, o código numérico que indica o status do servidor resposta (resultado positivo, erro, etc.) o país de origem, as características dos navegadores, do sistema operacional utilizado pelo usuário, os vários detalhes de tempo por visita (por exemplo, o tempo gasto em cada página dentro do site) e os detalhes sobre o caminho seguido dentro da aplicação, com especial referência à sequência de páginas visitadas e outros parâmetros sobre o s sistema operacional do dispositivo e/ou ambiente de TI do usuário.<br />
                            Consentimento livre e esclarecido: manifestação espontânea, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada.<br />
                            Usuário: A pessoa que usa este site, podendo coincidir com o titular dos dados; ou ser a pessoa legalmente responsável/ autorizada pelo titular dos dados que estão sendo tratados, como no caso de menores de idade.<br />
                            Titular de dados: A pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.<br />
                            Processador de dados: Pessoa física ou jurídica, administração pública ou qualquer outro órgão, associação ou organização autorizada pelo controlador de dados para o processamento dos dados pessoais em conformidade com este Termo de Privacidade.<br />
                            Controlador de dados: A pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.<br />
                            O controlador de dados, a menos que seja especificado de outra forma, é o proprietário do serviço deste site.<br />
                            Cookies: São pequenos ‘arquivos’ de textos gravados pelo browser (navegador) no aparelho do usuário, visando guardar alguns dados (nomes, logins, senhas, preferências, etc.) para que o internauta não precise digitar novamente quando voltar ao site, bem como objetivam fazer o direcionamento dos anúncios, considerando o interesse e o comportamento do usuário.<br /><br />

                            7 - Disposições finais e consentimento livre e esclarecido<br />
                            Assim, ciente de todas as implicações possíveis que decorrerão do tratamento de seus dados pessoais, ao prosseguir clicando no botão “concordo” o(a) usuário(a) está fornecendo seu consentimento livre e esclarecido para o tratamento de seus dados pessoais tendo em vista as finalidades especificadas neste Termo de Privacidade.<br />
                            Contudo, este Termo de Privacidade foi elaborado em conformidade com o previsto na Lei Geral de Proteção de Dados Pessoais – Lei nº 13.709/2018, que entrou em vigor em 18 de setembro de 2020.<br /><br />



                        </p>
                        : <GeneralLoader />}
                </CardText>

                <div>
                    <input type="checkbox" onChange={() => setAcceptTermsChecked(!acceptTermsChecked)} checked={acceptTermsChecked} />
                    <span>Eu concordo</span>
                </div>

                <button
                    className="logButton"
                    onClick={() => savePersonData()}
                >
                    Prosseguir
                </button>
            </CardTerm>
        </Container>
    )
}