import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: rgb(100, 43, 115);
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;

    span{
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 2rem;
        color: #c39ed0;
        cursor: pointer;
    }

    @media (max-width: 1126px){
        grid-template-columns: 1fr;

        span{
            top: 1px;
            right: 5px;
            font-size: 1.5rem;
        }
    }
`

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #fff5ff;

    @media (max-width: 1126px){
        display: none;
    }
`

export const CitiesListAndSearch = styled.div`
    width: 65%;
    margin-top: 7.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1126px){
        width: 95%;
        margin: 2rem auto 0 auto;
    }

    .inputSearch{
        display: flex;
        align-items: center;
        margin-bottom: 3rem;
        input{
            background: none;
            border: none;
            border-bottom: 1px solid #c39ed0;
            color: #c39ed0;
            font-size: 2rem;
            padding-bottom: 1rem;
            width: 100%;
            margin-left: 2rem;

            &::-webkit-input-placeholder {
                color: #c39ed0;
            }

            &:-moz-placeholder { 
                color: #c39ed0;  
            }

            &::-moz-placeholder {  
                color: #c39ed0;  
            }

            &:-ms-input-placeholder {  
                color: #c39ed0;  
            }
        }
       

        svg{
            font-size: 2.5rem;
            color: #fff5ff;
        }
    }
   
`