import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { WholeSaleRegister } from './WholeSaleRegister'
import { api } from '../../services/api'
import logo from '../../components/images/logo.png'

import { Container } from './style'

export function Wholesale() {
    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        async function fetchData() {
            const response = await api.post('/checkingBusinessWholesale', params, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            localStorage.setItem('@Inoveclube:nameBusiness', params.business)

            if(response.data) {
                if(localStorage.getItem('@Inoveclube:token')){
                    history.push(`/menu/${params.business}`)
                    return
                }

                history.push('/login')
            }
        }

        fetchData()
    }, [params, history])

    
    return(
        <Container>
            <div className="dividing">
                <img src={logo} alt="logo"/>
            </div>
            <WholeSaleRegister params={params}/>
        </Container>
    )
}
