import styled, { css } from 'styled-components';

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 99;

    .scrollingArea{
        flex: auto;
        overflow-y: auto; 

        &::-webkit-scrollbar-track {
            background-color: #D3D3D3;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 4px;
        }

        scrollbar-width: thin;
        scrollbar-color: gray;
        scrollbar-track-color: #D3D3D3;
    }

    .line-additionals{
        max-width: 600px;
        margin: auto;
        width: 100%;
        background-color: rgb(230, 230, 230);
        color:  #565656;
        text-align: center;
        font-size: 13px;
        padding: 4px;
    }


    .item-container{
        display: flex;
        padding: 10px;
        max-width: 600px;
        margin: auto;
        .image{
            height: 70px;
            width: 25%;;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            ${props => props.item && css`
                background-image: url("${props.item}");
            `}
        }

        .text{
            width: 50%;
            padding-left: 10%;
            h3{
                font-size: 13px;
                margin-bottom: 14px;
            }
            span{
                text-align: left;
                font-size: 15px;
                color: #006400 ;
            }
        }

        .num-counter{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 25%;
            span{
                font-weight: bold;
                font-size: 14px;
                padding-bottom: 0;
            }
            svg{
                margin: 3px;
                color: #006400 ;
                font-size: 22px;
                @media (min-width: 800px){
                    cursor: pointer;
                }
            }
        }
    }

        .button-container{
            background-color: white;
            max-width: 600px;
            margin: 10px auto;
            display: flex;
            justify-content: center;
          

            button{
                font-size: 15px;
                opacity: 0.5;
                ${props => props.bgcolor && css`
                    background-color: ${props.bgcolor};
                `}
                ${props => (props.addButtonEnabled === true) && css`
                    opacity: 1;
                `}                
                color: ${props => props.colorLabel};
                border: none;
                width: 90%;
                padding: 15px 0 15px 0;
                border-radius: 4px;
                margin-right: 5px;
                
            }
    }

`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 4px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`;

export const Additionals = styled.div`
    max-width: 600px;
    margin: auto;

    .container-add{
        padding-top: 20px;
        width: 94%;
        margin: auto;

        .input-button{
            border: 1px solid black;
            max-width: 100%;
            overflow: hidden;
            padding: 10px;
            cursor: text;
            color: gray;
        }

        .title-modal{
            color: #454545;
            font-size: 14px;
            font-weight: 500;
        }
    }

`;

export const ContainerSizes = styled.div`
    max-width: 600px;
    margin: auto;
    padding: 5px 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
`

export const ButtonSize = styled.button`
    border: none;
    background: #FFF;
    color: #000;
    padding: 5px;
    margin: 2px;
    font-size: 0.8rem;
    min-width: 6rem;
    border: 1px solid gray;
    border-radius: 0.3rem;

    ${props => props.selected && css`
        border: 2px solid ${props.bgcolor};
    `}
`

export const ContainerToAssemble = styled.div`
    max-width: 600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span{
        font-size: 0.7rem;
        margin: 3px 0 5px 0;
    }
`

export const ButtonAssemblyItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 80%;
    padding: 10px;
    border: 2px solid #8b0000;
    border-radius: 0.4rem;
    margin: 3px 0 3px 0;
    
    ${props => props.filled && css`
        border: 2px solid ${props.bgcolor};
    `}

    p{
        width: 95%;
    }

    svg{
        width: 5%;
    }
`;

export const Pizza = styled.div`
    width: 250px;
    height: 250px;
    background-color: #ddd;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 100%;

    display: flex;
    flex-direction: column;
`;