import React, { useRef } from "react"

import {
	Container,
	Content,
	Header,
	Main,
	QrCodePixContainer,
	QrCodePixTextContainer,
} from "./styles"
import { FaRegCopy } from "react-icons/fa"

import { toast } from "react-toastify"

export function ModalQrCodePix({ onClose, data, noOrderNumber }) {
	const inputRef = useRef(null)

	async function copyText(text) {
		if (!text) {
			return
		}

		try {
			await navigator.clipboard.writeText(text)
			toast.success("Código pix copiado com sucesso!")
		} catch (error) {
			inputRef.current.value = text
			inputRef.current.select()
			document.execCommand("copy")

			toast.success("Código pix copiado com sucesso!")
		}
	}

	if (!data) {
		return null
	}

	return (
		<Container>
			<Content>
				<Header>
					<span role="button" onClick={onClose}>
						&#10006;
					</span>

					<div className="title">
						<h2>Pagamento Pix</h2>
						{!noOrderNumber && <p>Pedido: nº {data?.orderNumber}</p>}
					</div>
				</Header>

				<Main>
					<QrCodePixContainer>
						<img src={data?.qrCodeUrl} alt="QrCode do pix para efetuar pagamento" />
					</QrCodePixContainer>

					<p className="phrase">
						<b>Copie</b> a <b>Chave Pix</b> logo abaixo e <b>Cole</b> no aplicativo do
						seu banco.
					</p>

					<QrCodePixTextContainer onClick={() => copyText(data?.qrCodeText)}>
						<span>{data?.qrCodeText}</span>

						<input
							ref={inputRef}
							type="text"
							value={data?.qrCodeText}
							readOnly
							style={{ position: "absolute", left: "-9999px" }}
						/>

						<FaRegCopy />
					</QrCodePixTextContainer>
				</Main>
			</Content>
		</Container>
	)
}
