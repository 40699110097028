import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./GlobalStyle";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/queryClient";

import { AuthProvider } from "./context/AuthContext";
import { RegisterProvider } from "./context/RegisterContext";
import { MenuProvider } from "./context/MenuContext";
import { ReactQueryDevtools } from "react-query/devtools";

import "./components/pagesComponents/mainPage/SectorsAndBusiness/slider.css";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";

// const Routes = lazy(() => import('./routes'));

export function App() {
	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<RegisterProvider>
						<MenuProvider>
							<Routes />
							<ToastContainer autoClose={4000} closeOnClick />
							<GlobalStyles />
						</MenuProvider>
					</RegisterProvider>
				</AuthProvider>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</BrowserRouter>
	);
}
