import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 150px;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 10px;
    width: 100%;
    position: fixed;
    top: 0;
    ${props => props.colorFont && css`
        color: ${props.colorFont};
    `}

    header{
        height: 130px;
        display: flex;
        padding: 15px;
        ${props => props.colorFont && css`
            color: ${props.colorFont};
        `}
        
        ${props => props.colorBg && css`
            background-color: ${props.colorBg};
        `}

        .logo{
            height: 5rem;
            width: 5rem;
            border-radius: 10px;
            box-shadow:0 1px 2px 1px rgba(0, 0, 0, 0.3);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            ${props => props.logoImage && css`
                background-image: url("${props.logoImage}");
            `}

            @media (max-width: 360px){
                height: 60px;
                width: 60px;
            } 
        }

        .informations{
            display: flex;
            flex: 1;
            justify-content: space-between;
            /* width: 80%; */
            padding-left: 10px;
            font-size: 0.75rem;
            font-weight: bold;

            .address{
                flex: 1;

                h1{
                    font-size: 1.07rem;
                    font-weight: bold;
                    margin-bottom: 3px;

                    @media(max-width: 500px){
                        font-size: 0.8rem
                    }
                }

                p{
                    font-family: sans-serif;

                    & + p{
                        margin-top: 0.2rem;
                    }
                }
            }

            .opened{
                font-size: 0.88rem;
                display: flex;
                flex: 1;
                align-items: flex-end;
                flex-direction: column;

                p{
                    margin-top: 0.2rem;
                }

                .deliveryTime{
                    font-weight: 900;
                    display: flex;
                    align-items: center;

                    svg{
                        margin-right: 0.2rem;
                    }
                }

                .businessHours {
                    cursor: pointer;
                }

                .open {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-family: sans-serif;
                    div{
                        display: inline-block;
                        height: 10px;
                        width: 10px;
                        border: 1px solid white;
                        border-radius: 50%;
                        background-color: limegreen;
                    }

                    h4{
                        padding-left: 3px;
                        white-space: nowrap;
                    }
                }

                .closed {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-family: sans-serif;
                    div{
                        display: inline-block;
                        height: 8px;
                        width: 8px;
                        border: 1px solid white;
                        border-radius: 50%;
                        background-color: red;
                    }

                    h4{
                        padding-left: 3px;
                        white-space: nowrap;
                    }
                }

                .deliveryFee {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 0.5rem;
                    font-weight: 900;
                    position: relative;
                    margin-top: 0.2rem;

                    svg {
                        font-size: 1rem;
                    }

                    &:after {
                        content: 'Referente ao endereço selecionado';
                        padding: 0.5rem;
                        background: #FFF;
                        border-radius: 5px;
                        position: absolute;
                        bottom: 0;
                        display: none;
                        transform: translateY(100%);
                        z-index: 99;
                        color: #000;
                        text-align: center;
                        width: 105%;
                        box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                    }

                    &:hover {
                        &:after {
                            display: block;
                        }
                    }
                }

               p{
                   text-align: right;
                   font-weight: lighter;
                   font-size: 0.75rem;
                   font-family: sans-serif;
               }

               .button{
                    padding-top: 5px;
                    background: none;
                    border: none;
                    ${props => props.colorFont && css`
                        color: ${props.colorFont};
                    `}
                    display: flex;
                    align-items: center;
                }
            }

            @media (min-width: 768px){
                width: 90%;
            }

            @media (max-width: 320px){
                .address{
                    h1{
                        font-size: 0.9rem;
                    }

                    p{
                        font-size: 0.7rem;
                    }
                }

                .opened{
                    font-size: 0.78rem;
                }

                p{
                    font-size: 0.78rem;
                }
            }
          
        }

        .additional{
            @media(max-width: 500px){
                position: absolute;
                left: 15px;
                top: 85px;
                font-size: 0.6rem;
            }
        }
    }
`;

export const Search = styled.div`
    margin: auto;
    width: 95%;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    position: absolute;
    top: 100px;
    left: 2.5%;
    background: white;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
                display: none;
        }

    input{
        flex: 1;
        border: none;
        font-size: 12px;
        outline: none;
    }

    svg{
        color: rgba(0,0,0,0.8);
    }

`;

export const MenuBar = styled.div`
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 600px;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    padding: 10px;
    ${props => props.colorBg && css`
        background-color: ${props.colorBg};
    `}

    svg{
        ${props => props.colorFont && css`
            color: ${props.colorFont};
        `}

        @media (min-width: 800px){
            cursor: pointer;
        }
    }

    .item-bar{
        position: relative;

    }
   
    .items-shopping{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        top: -10px;
        right: -10px;
        height: 20px;
        width: 20px;
        background-color: rgba(0, 255, 0, 0.8);
        color: white;
        border-radius: 50%;
        cursor: pointer;
    }
`;

