import styled from "styled-components";

export const Container = styled.div`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    div{
        width: 95%;
        display: flex;
        align-items: center;
        border: 0.5px solid #D3D3D3;
        padding: 20px;
        margin: 0.5rem 0;
        border-radius: 0.3rem;
        cursor: pointer;

        p{
            color: #000;
            font-size: 0.9rem;
        }

        svg{
            color: var(--colorButton);
            margin-right: 15px;
            font-size: 1.5rem;
        }
    }
`