import { DotTypingLoader } from "../loaders/dotTypingLoader";

import { Container } from "./style";

export function Button({ icon, isLoading, children, size, maxWidth, ...rest }) {
	return (
		<Container isLoading={isLoading} size={size} maxWidth={maxWidth} {...rest}>
			{isLoading ? (
				<DotTypingLoader />
			) : (
				<>
					{icon}
					{children}
				</>
			)}
		</Container>
	);
}
