import styled from 'styled-components'

export const Container = styled.div`
    border: 5px solid #E4E3E2;
    border-radius: 50%;
    border-top: 5px solid var(--colorButton);
    border-bottom: 5px solid var(--colorButton);
    width: 1.5rem;
    height: 1.5rem;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`