import { api } from "../../../../services/api";

export async function getOrders(companiesId) {
  const { data: orders } = await api.get("/getPurchaseRequests", {
    params: {
      companies: companiesId.join(","),
    },
  });

  // Generating and Save "budgetNumber" if necessary
  let allBudgetNumbersForTheDay = orders.map((order) => order.nrOrcamentoDia);

  const newOrdersWithBudgetNumbersForTheDay = orders.map((order) => {
    if (order.nrOrcamentoDia === null && order.tipoPedido === "B") {
      let newBudgetNumber = Math.floor(Math.random() * (999 - 100) + 100);

      while (allBudgetNumbersForTheDay.includes(newBudgetNumber)) {
        newBudgetNumber = Math.floor(Math.random() * (999 - 100) + 100);
      }

      allBudgetNumbersForTheDay.push(newBudgetNumber);

      api
        .post("/updateNrOrcamentoDia", {
          idOrcamento: order.idOrcamento,
          nrOrcamentoDia: newBudgetNumber,
        })
        .catch((error) => {
          console.log(error);
        });

      return {
        ...order,
        nrOrcamentoDia: newBudgetNumber,
      };
    } else {
      return order;
    }
  });

  return newOrdersWithBudgetNumbersForTheDay;
}
