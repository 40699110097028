import { useEffect } from "react";
import { Container } from "./style";
import { useState } from "react";
import inoveLogo from "../../../../../components/images/logo.png";

export function Footer({ footer, setIsContantModalOpen, setOwnerEmail }) {
	const [redirectLinks, setRedirectLinks] = useState([]);
	const [modalLinks, setModalLinks] = useState([]);
	const [socialLinks, setSocialLinks] = useState([]);

	useEffect(() => {
		setRedirectLinks(footer.links.filter((link) => link.type === "external"));
		setModalLinks(footer.links.filter((link) => link.type === "contact"));
		setSocialLinks(footer.links.filter((link) => link.type === "social"));
	}, [footer.links]);

	return (
		<Container
			style={{
				backgroundColor: `${
					footer.backgroundColor ? footer.backgroundColor : "var(--gray-500)"
				}`,
			}}
		>
			<div className="content">
				{footer.logo && <img className="logo" src={footer.logo} alt="" />}
				<div className="navegation">
					<ul>
						{redirectLinks.map((link, idx) => {
							return (
								<li
									key={idx}
									style={{
										color: footer.textColor
											? footer.textColor
											: "var(--gray-300)",
									}}
								>
									<a target="_blank" rel="noreferrer" href={link.url}>
										{link.title}
									</a>
								</li>
							);
						})}
					</ul>
					<ul>
						{socialLinks.map((link, idx) => {
							return (
								<li
									key={idx}
									style={{
										color: footer.textColor
											? footer.textColor
											: "var(--gray-300)",
									}}
								>
									<a
										target="_blank"
										rel="noreferrer"
										href={link.url}
										className="social-link"
									>
										{link.title}
									</a>
								</li>
							);
						})}
					</ul>
					<ul>
						{modalLinks.map((link, idx) => {
							return (
								<li
									key={idx}
									className="modal-link"
									style={{
										color: footer.textColor
											? footer.textColor
											: "var(--gray-300)",
										backgroundColor: `${
											footer.backgroundColor
												? footer.backgroundColor
												: "var(--gray-500)"
										}`,
									}}
									onClick={() => {
										setOwnerEmail(link.url);
										setIsContantModalOpen(true);
									}}
								>
									{link.title}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<a
				href="https://www.inoveclube.com/"
				target="_blank"
				rel="noreferrer"
				className="inove"
			>
				<img src={inoveLogo} alt="" />
				<p
					style={{
						color: footer.textColor ? footer.textColor : "var(--gray-300)",
					}}
				>
					Desenvolvido por: iNove Clube
				</p>
			</a>
		</Container>
	);
}
