import Toggle from "react-toggle";
import { useOrderManagerContext } from "../../context";
import { Button, Container, Modal, Title, List, ListItem } from "./style";

export function ManageStores() {
  const {
    showManageStoresModal,
    companies,
    setShowManageStoresModal,
    storeIsOpening,
    setShowOpenCashRegister,
    handleOpenStore,
    handleGetOrders,
    handleOpenAllStores,
  } = useOrderManagerContext();

  return showManageStoresModal ? (
    <Container>
      <Modal className="scroll">
        <Title>Gerenciamento de lojas</Title>
        <>
          <div className="space-x">
            <Button
              className="right"
              disabled={storeIsOpening}
              onClick={() => {
                setShowOpenCashRegister(true);
                setShowManageStoresModal(false);
              }}
            >
              Gerencias caixas
            </Button>
            <Button
              className="right"
              disabled={!companies.some((company) => !company.lojaAberta) || storeIsOpening}
              onClick={handleOpenAllStores}
            >
              Abrir todas as lojas
            </Button>
          </div>
          <List>
            {companies.map((company) => (
              <ListItem
                key={company.cdEmpresa}
                className={`${company.cashRegisterOpen ? "" : "opacity-50"}`}
              >
                <div className="wrapper">
                  <div>
                    <p className="bold">{company.dsEmpresa}</p>
                    <p>CNPJ: {company.cnpj}</p>
                  </div>
                </div>
                <Toggle
                  checked={company.lojaAberta}
                  onChange={() => handleOpenStore(company.cdEmpresa, !company.lojaAberta)}
                  disabled={!company.cashRegisterOpen || storeIsOpening}
                />
              </ListItem>
            ))}
          </List>
          <Button
            className="large"
            disabled={storeIsOpening}
            onClick={() => {
              handleGetOrders();
              setShowManageStoresModal(false);
            }}
          >
            Confirmar
          </Button>
        </>
      </Modal>
    </Container>
  ) : null;
}
