import styled, { css } from 'styled-components'

export const Container = styled.div`
    background: ${props => props.colorBgHeader ? props.colorBgHeader : '#000'};
    color: ${props => props.colorLabel ? props.colorLabel : '#FFF'};

`

export const FlagContainer = styled.div`
    text-align: center;
    padding: 0.5rem 0;
    transition: all 0.5s;

    img{
        height: 2rem;
    }

    p{
        font-size: 0.65rem;
        font-weight: 900;
    }

    @media (min-width: 960px){
        cursor: pointer;
    }

    ${props => props.active && css`
        background: #fafafa;
        color: ${props => props.colorBgHeader ? props.colorBgHeader : '#000'};
    `}
`