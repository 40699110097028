import { useHistory } from 'react-router';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import { Container } from './style';


export function Header() {
    const history = useHistory()

    return(
        <Container>
             {localStorage.getItem('@Inoveclube:nameBusiness') ?
                    <AiOutlineArrowLeft onClick={() => {
                    localStorage.getItem('@Inoveclube:type') ?
                        history.push( `/menu/${localStorage.getItem('@Inoveclube:nameBusiness')}/${localStorage.getItem('@Inoveclube:type')}/${localStorage.getItem('@Inoveclube:typeNumber')}`) 
                        :
                        history.push(`/menu/${localStorage.getItem('@Inoveclube:nameBusiness')}`) 
                }}
                />
                : 
                <></>
            }
            <h1>PERFIL</h1>
        </Container>
    )
}
