import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    font-size: 2rem;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    svg {
        font-size: 4rem;
    }

    span {
        color: ${props => props.color};
        text-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    }
`
