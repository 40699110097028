import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  h3 {
    padding-bottom: 1rem;
  }
`;

export const Modal = styled.div`
  background-color: var(--gray-100);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 80%;
  overflow-x: auto;
  animation: modal 0.2s linear;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.5rem;
`;

export const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: var(--brand-600);
  color: var(--gray-50);
  border: none;
  font-weight: 500;

  &:hover {
    filter: brightness(0.9);
  }

  &.right {
    align-self: flex-end;
  }

  &.large {
    font-size: 1.2rem;
  }
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 1rem;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .bold {
    font-weight: 500;
  }

  button:last-child {
    margin-left: 1.5rem;
  }
`;

export const Input = styled.input`
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid var(--gray-200);
  width: 100%;
  margin-bottom: 1rem;
`;

export const InputSelect = styled.select`
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid var(--gray-200);
  width: 100%;
  margin-bottom: 1rem;
`;
