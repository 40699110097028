import styled from "styled-components";

export const Container = styled.form`
    display: flex;
    width: 75%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    
    .span{
        font-size: 0.9rem;
        color: var(--colorButton);
        margin: 20px 0 20px 0;
        text-decoration: underline;
        cursor: pointer;
    }

    .input-log{
        display: flex;
        align-items: center;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        width: 100%;

        .visibility{

            @media (min-width: 960px){
                cursor: pointer;
            }
        }

        .visibilityOff{
            color: var(--colorButton);

            @media (min-width: 960px){
                cursor: pointer;
            }
        }
        
        input{
            font-weight: 500;
            font-size: 13px;
            color: black;
            width: 100%;
            border: none;
        }
        svg{
            margin-right: 5px;
            color: gray;
        }
        margin-bottom: 22px;
        padding: 12px;
        border: 1.5px solid var(--colorBorderInput);
        border-radius: 5px;
    }

    .login-label{
        margin-bottom: 20px;
    }

    .checkContent{
        display: flex;
        align-items: center;
        margin: 20px 0 0 0;
        P{
            margin-bottom: 1px;
        }
        input{
            width: 20px;   
        }
    }

    .noMargin{
        margin-bottom: 3px;
    }
`

export const Logo = styled.div`
    height: 8rem;
    width: 8rem;
    background-image: url(${localStorage.getItem('@Inoveclube:imageBusiness') ? localStorage.getItem('@Inoveclube:imageBusiness') : "https://s3.sa-east-1.amazonaws.com/www.inoveclube.com/public/logo.png"});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    border-radius: 0.7rem;
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .logButton{
        height: 50px;
        width: 150px;
        background: var(--colorButton);
        color: var(--colorButtonBottom);
        margin-bottom: 15px;
        font-weight: 500;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
    }

    .empty {
        background-color: white;
        color: gray;
        font-size: 13px;
        border: 2px solid var(--colorButton);
    }

    p{
        margin-bottom: 15px;
    }

    .buttons{
        display: flex;
    }

    svg{
        font-size: 20px;
        margin-right: 5px;
    }

    .facebookButton{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 125px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        margin-right: 10px;
        /* cursor: pointer; */
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        background-color: #1877F2;
        opacity: 0;
    }

    .googleButton{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 125px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        /* cursor: pointer; */
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        background-color: #F14336;
        opacity: 0;
    }

    @media (max-height: 600px){
        .facebookButton {
            height: 40px;
        }
        .googleButton{
            height: 40px;
        }
            
    }
`;