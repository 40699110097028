import styled from 'styled-components'

export const Container = styled.div`
    margin-left: 5%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        background-color: #572663;
        border-radius: 4px;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #894E98;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: #894E98;
    scrollbar-track-color: #572663;
`


export const ContentCity = styled.div`
    color: #c39ed0;
    font-size: 2rem;
    font-weight: 300;
    padding: 2rem;
    transition: background 0.5s;

    &:hover{
        background: #57266380;
    }

    @media (min-width: 960px){
        cursor: pointer;
    }
`