import styled,{css} from "styled-components";

export const Container = styled.div``;

export const ContentCoupons = styled.div`
    flex: auto;
`;


export const DayOfWeek = styled.div`
    text-align: center;
    letter-spacing: 1px;
    padding: 0.5rem;
    color: #535251;
    border-radius: 0.2rem;

    & + & {
        margin-top: 0.5rem;
    }

    ${props => props.active && css`
        border: 1px solid ${localStorage.getItem('@Inoveclube:colorButton')};
        color: ${localStorage.getItem('@Inoveclube:colorButton')};
    `}
`