import { UserCard } from '../../components/pagesComponents/userCard'
import { Link } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
// import { BsPlusCircleFill } from 'react-icons/bs'
import {FaPlus} from 'react-icons/fa';
import { useCard } from '../../services/hooks/useCard'
import { useHistory } from 'react-router-dom'
import { GeneralLoader } from '../../components/loaders/generalLoader'

import { Container, Header } from './style'


export function Cards() {
    const history = useHistory()

    const { data, isLoading } = useCard()
    
    let creditCards = data?.creditCardList
    let debitCards = data?.debitCardList

    function registerNewCard() {
        history.push('/cardregister')
    }

    
    return(
        <Container hasCard={(creditCards?.length > 0 || debitCards?.length > 0)}>

            <Header>
                <Link to="/profile"><AiOutlineArrowLeft /></Link>
                <h1>Meus cartões</h1>
            </Header>

            {
                isLoading ? 
                <GeneralLoader />
                :
                <>
                    {
                        !!creditCards.length &&
                        <div className='containerCards'>
                            <h2>Crédito</h2>
                            {
                                creditCards.map(card => (
                                    <UserCard 
                                        key={card.id}
                                        cardDatas={card}
                                        noDelete={false}
                                    />
                                ))
                            }
                        </div>
                    }
                    
                    {
                        !!debitCards.length &&
                        <div className='containerCards'>
                            <h2>Débito</h2>

                            {
                                debitCards.map(card => (
                                    <UserCard 
                                        key={card.id}
                                        cardDatas={card}
                                        noDelete={false}
                                    />
                                ))
                            }
                        </div>
                    }
                </>
            } 

            <div className="logButton">
                <button onClick={registerNewCard}>
                    <FaPlus
                        color='#fff'
                    />
                    Cadastrar Cartão
                </button>
            </div>

        </Container>
    )
}
