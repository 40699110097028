import styled, { css } from "styled-components";

export const Container = styled.div`

`

export const Logo = styled.div`
    height: 3.5rem;
    width: 3.5rem;
    background-image: url(${localStorage.getItem('@Inoveclube:imageBusiness') ? localStorage.getItem('@Inoveclube:imageBusiness') : "https://s3.sa-east-1.amazonaws.com/www.inoveclube.com/public/logo.png"});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 4px;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    border-radius: 0.3rem;

    @media (max-height: 654px){
        position: initial;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`;


export const InputContainer = styled.div`
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 786px){
        height: 85%;
    }
    @media (max-height: 654px){
        height: 85%;
    }


    .container-divide{
        display: flex;
        align-items: center;
        width: 75%;

        .smaller{
            width: 25%;
            margin-right: 0.3rem;
        }

    }

     
`;

export const InputNumber = styled.input`
        padding: 12px;
        border: 1.5px solid ${props => props.alertUserToFillInput ? 'red' : 'var(--colorButton)'};
        border-radius: 5px;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        font-weight: 500;
        font-size: 13px;
        color: black;
        margin-bottom: 16px;    
`

export const InputZipCode = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
    margin-bottom: 10px;
    border: 1.5px solid ${props => props.alertUserToFillInput ? 'red' : 'var(--colorButton)'};
    border-radius: 0.25rem;
    margin-bottom: 16px; 
    padding: 0;
    width: 75%;

    input{
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        color: black;
        border: none;
        box-shadow: none;
        padding: 12px;
        margin-bottom: 1px;
        margin-left: 1px;
    }

    div{
        background: var(--colorButton);
        border-radius: 0.2rem;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.2rem 0.6rem 0.2rem 0.6rem;
        margin-right: 0.5rem;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        cursor: pointer;
    }
    svg{
        color: #FFF;
        margin: 0;
    }
`

export const InputLog = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
    width: 75%;
    margin-bottom: 16px;
    padding: 12px;
    border: 1.5px solid ${props => props.alertUserToFillInput ? 'red' : 'var(--colorButton)'};
    border-radius: 5px;
    position: relative;

    input{
        font-weight: 500;
        font-size: 13px;
        color: black;
        border: none;
        width: 100%;
    }
    svg{
        margin-right: 5px;
        color: gray;
    }

    
`


export const Dropdown = styled.div`
    width: 75%;
    position: relative;
    select{
        width: 100%;
        background: #FFFF;
        color: black;
        -webkit-appearance: none;
            -moz-appearance: none;
                appearance: none;
        padding: 12px;
        display: flex;
        align-items: center;
        border: 1.5px solid ${props => props.alertUserToFillInput ? 'red' : 'var(--colorButton)'};
        border-radius: 5px;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 16px;
    }

    &:after{
        content: 'expand_more';
        font-family: 'material icons';
        position: absolute;
        right: 5px;
        top: 39%;
        transform: translateY(-50%);
        color: gray;
        pointer-events: none;
    }

    
    ${props => props.cityIsEnabled === false && css`
        opacity: 0.5;
        pointer-events: none;
    `}
    
`


export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .logButton{
        height: 50px;
        width: 150px;
        background-color: var(--colorButton);
        color: var(--colorButtonBottom);
        margin-bottom: 15px;
        font-weight: 500;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
    }


`

export const NeighborhoodsList = styled.div`
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    max-height: 150px;
    width: 100%;
    left: 0;
    right: 0;
    background: #FFF;
    overflow: auto;
    z-index: 99;

    border-radius: 0 0 5px 5px;
    border-bottom: 1.5px solid rgba(128,128,128, 0.3);
    border-left: 1.5px solid rgba(128,128,128, 0.3);
    border-right: 1.5px solid rgba(128,128,128, 0.3);

    &::-webkit-scrollbar-track {
            background-color: #D3D3D3;
            border-radius: 4px;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: gray;
    scrollbar-track-color: #D3D3D3;

    .closeNeighborhoods{
        position: sticky;
        bottom: 0;
        width: 100%;
        text-align: center;
        text-decoration: underline;
        color: blue;
        cursor: pointer;
        padding: 3px 0 3px 0;
        background: #FFF;
    }

    .line{
        width: 96%;
        height: 0.5px;
        margin: 0 auto;
        background: rgba(128,128,128, 0.3);
    }

    .neighborhoods{
        display: flex;
        flex-direction: column;
       
        color: gray;

        .notFound{
            margin: 0 auto;
            padding: 0.5rem 0 0.5rem 0
        }

        div{
            display: flex;
            padding: 0.5rem;
            transition: all 0.2s;

            &:hover{
                background: #D3D3D3;
                cursor: pointer;
            }
        }

    }
`