import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SmallModal } from "../../../smallModal";
import { PhoneInput } from "../../../Forms/phoneInput";
import { Form, Container } from "./styles";
import { getUF } from "./requests/getUF";
import { sendEmail } from "./requests/sendEmail";
import { saveTicket } from "./requests/saveTicket";
import { getCounty } from "./requests/getCounty";
import { Button } from "../../../Button";
import { sendLeadWhatsapp } from "../requests/sendLeadWhatsapp";

//====== Component ======//
export function ShopkeeperContactModal({
  isShopkeeperContactModalOpen,
  setIsShopkeeperContactModalOpen,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [county, setCounty] = useState([]);
  const [uf, setUf] = useState([]);
  const [phone, setPhone] = useState("");

  // Handlers
  function handleCloseModal() {
    setIsShopkeeperContactModalOpen(false);
  }

  // Function
  function loadCounty(uf) {
    getCounty(uf)
      .then(setCounty)
      .catch((error) => {
        console.error(`error::f11dfe8c:: ${error}`);
        toast.error("Erro ao buscar municípios.");
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    // Get the form data
    const dataForm = new FormData(event.target);
    const formData = {
      name: dataForm.get("name"),
      companyName: dataForm.get("companyName"),
      email: dataForm.get("email"),
      // format phone number from (99) 99999-9999 to 99999999999
      phone: dataForm.get("phone").replace(/\D/g, ""),
      // format state from 99-UF to UF
      state: dataForm.get("state").split("-")[1],
      cdMunicipio: dataForm.get("cdMunicipio"),
      employee: dataForm.get("employee"),
      invoicing: dataForm.get("invoicing"),
      instagram: dataForm.get("instagram"),
      message: dataForm.get("message"),
    };

    try {
      await sendEmail(formData);
      await saveTicket(formData);
      await sendLeadWhatsapp(formData, "Quero fazer parte!").catch(() => {})
      handleCloseModal();
      toast.success("Solicitação enviada com sucesso!");
      event.target.reset();
      setPhone("");
    } catch (error) {
      console.error(`error::bd680d6b:: ${error}`);
      toast.error("Erro ao enviar solicitação.");
    } finally {
      setLoading(false);
    }
  }

  // Effect
  useEffect(() => {
    getUF()
      .then(setUf)
      .catch((error) => {
        console.error(`error::548b23d7:: ${error}`);
        toast.error("Erro ao buscar estados.");
      });
  }, []);

  // Null return if modal is not open
  if (!isShopkeeperContactModalOpen) return null;
  // Return
  return (
    <SmallModal title="Quero fazer parte!" toClose={handleCloseModal} loading={loading}>
      <Form onSubmit={handleSubmit}>
        <Container>
          <div className="wrap">
            <div>
              <label htmlFor="name">Nome *</label>
              <input type="text" name="name" id="name" required />
            </div>
            <div>
              <label htmlFor="companyName">Nome da Empresa</label>
              <input type="text" name="companyName" id="companyName" required />
            </div>
            <div>
              <label htmlFor="email">E-mail *</label>
              <input type="email" name="email" id="email" required />
            </div>
            <div>
              <label htmlFor="phone">Telefone *</label>
              <PhoneInput name="phone" id="phone" value={phone} onChange={setPhone} required />
            </div>
            <div>
              <label htmlFor="state">Estado *</label>
              <select
                name="state"
                defaultValue=""
                onChange={(e) => loadCounty(e.target.value)}
                required
              >
                <option value="">Selecione</option>
                {uf.map((item) => (
                  <option key={item.idEstado} value={`${item.idEstado}-${item.dsUf}`}>
                    {item.dsUf}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="cdMunicipio">Cidade *</label>
              <select name="cdMunicipio" defaultValue="" required>
                <option value="">Selecione</option>
                {county.map((item) => (
                  <option key={item.dsMunicipio} value={item.dsMunicipio}>
                    {item.dsMunicipio}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="employee">Quantidade de funcionários</label>
              <select name="employee" id="employee" defaultValue="" required>
                <option value="" disabled>
                  Selecione uma quantidade
                </option>
                <option value="0-10">de 0 a 10</option>
                <option value="11-20">de 11 a 20</option>
                <option value="21-30">de 21 a 50</option>
              </select>
            </div>
            <div>
              <label htmlFor="invoicing">Faturamento mensal</label>
              <select name="invoicing" id="invoicing" defaultValue="" required>
                <option value="" disabled>
                  Selecione um faturamento
                </option>
                <option value="1-10.000">&lt; 10.000</option>
                <option value="11.000-20.000">de 11.000 a 20.000</option>
                <option value="21.000-30.000">de 21.000 a 50.000</option>
                <option value="51.000-100.000">de 51.000 a 100.000</option>
                <option value=">100.000">&gt; 100.000</option>
              </select>
            </div>
            <div>
              <label htmlFor="instagram">Instagram</label>
              <div>
                <input type="text" name="instagram" id="instagram" defaultValue="@" required />
              </div>
            </div>
            <div>
              <label htmlFor="message">Deixe sua mensagem!</label>
              <textarea name="message" id="message" rows={4} />
            </div>
          </div>
          <Button className="submit" type="submit">
            Enviar
          </Button>
        </Container>
      </Form>
    </SmallModal>
  );
}
