import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	min-height: 75vh;
	padding: 1rem;
	padding-bottom: 5rem;
	.wrapper {
		max-width: 1200px;
		margin: 0 auto;
		h3 {
			margin: 1rem 0;
		}
	}
	.content {
		grid-template-columns: 1fr;
		display: grid;
		gap: 1.5rem;
		&.pulse-loading {
			animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
			@keyframes pulse {
				0%,
				100% {
					opacity: 0.5;
				}
				50% {
					opacity: 0.3;
				}
			}
		}
		.card {
			width: 100%;
			height: 7rem;
			border-radius: 8px;
			display: flex;
			gap: 0.3rem;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			transition: transform 0.15s ease-out;
			.img {
				width: 7rem;
				height: 7rem;
				border-radius: 8px;
				background-color: var(--gray-300);
			}
			.info {
				padding: 1rem;
				flex: 1;
				h2 {
					font-size: 0.8rem;
					font-weight: 500;
				}
				p {
					margin-top: 0.3rem;
				}
				.category {
					font-size: 0.8rem;
					text-transform: lowercase;
				}
				.time {
					font-size: 0.8rem;
				}
				.green {
					font-size: 1.2rem;
					color: green;
				}
				.icons-wrapper {
					display: inline-flex;
					align-items: center;
					gap: 0.5rem;
				}
				.wrapper {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
			}
		}
		.load-more {
			width: 100%;
			height: 3rem;
			border-radius: 8px;
			border: none;
			font-weight: 500;
			background-color: var(--gray-200);
		}
	}

	@media (min-width: 768px) {
		.content {
			padding: 0 2rem;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-around;
			grid-template-columns: 1fr 1fr;
			.card {
				&:hover {
					cursor: pointer;
					transform: scale(1.05);
				}
				&:active {
					transform: scale(1);
				}
			}
			.load-more {
				transition: opacity 0.15s ease-out;
				grid-column: 1 / 3;
				&:hover {
					cursor: pointer;
					opacity: 0.75;
				}
				&:active {
					opacity: 0.5;
				}
			}
		}
	}

	@media (min-width: 1024px) {
		.content {
			grid-template-columns: 1fr 1fr 1fr;
			.load-more {
				grid-column: 1 / 4;
			}
		}
	}
`;
