import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    left: -9999px;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${props => props.color};
    color: ${props => props.color};
    box-shadow: 9999px 0 0 0 ${props => props.color};
    animation: dotFalling 1s infinite linear;
    animation-delay: .1s;


    &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &::before {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: ${props => props.color};
        color: ${props => props.color};
        animation: dotFallingBefore 1s infinite linear;
        animation-delay: 0s;
        left: -0.5rem;
    }

    &::after {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: ${props => props.color};
        color: ${props => props.color};
        animation: dotFallingAfter 1s infinite linear;
        animation-delay: .2s;
        right: -0.5rem;
    }

    @keyframes dotFalling {
        0% {
            box-shadow: 9999px -1rem 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 9999px 0 0 0 ${props => props.color};
        }
        100% {
            box-shadow: 9999px -1rem 0 0 rgba(152, 128, 255, 0);
        }
    }

    @keyframes dotFallingBefore {
        0% {
            box-shadow: 9984px -1rem 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 9984px 0 0 0 ${props => props.color};
        }
        100% {
            box-shadow: 9984px 1rem 0 0 rgba(152, 128, 255, 0);
        }
    }

    @keyframes dotFallingAfter {
        0% {
            box-shadow: 10014px -1rem 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 10014px 0 0 0 ${props => props.color};
        }
        100% {
            box-shadow: 10014px 1rem 0 0 rgba(152, 128, 255, 0);
        }
    }

`