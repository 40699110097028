import { Container } from "./style";

export function PhoneInput({ value, onChange, ...rest }) {
  function handleOnChange(e) {
    const { value } = e.target;
    // The input value is formatted to the pattern (99) 99999-9999 or (99) 9999-9999
    if (e.target.value.length > 15) return;
    const formattedValue = value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");

    if (onChange) onChange(formattedValue);
  }

  return <Container value={value} onChange={handleOnChange} readOnly={!onChange} {...rest} />;
}
