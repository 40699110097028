import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    max-width: 400px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
`;

export const Footer = styled.div`
   height: 40px;
   width: 100%;
`;


