import styled, { css } from "styled-components"

export const Container = styled.div`
    position: absolute;
    bottom: 0;
    background: transparent;
    width: 100%;

    transition: transform 0.3s;
    transform: translateY(110%);

    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.open && css`
        transform: translateY(0);
    `}
`

export const Content = styled.div`
    width: 100%;
    max-width: 600px;
    padding: 1.2rem;
    background: #FFF;
    box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 8px 16px rgb(0 0 0 / 15%);
    border-radius: 5px 5px 0 0;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span{
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 1.2rem;
        cursor: pointer;
    }

    h4{
        margin-bottom: 1.3rem;
    }

    .buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        div{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            margin-right: 0.4rem;
    
            svg{
                font-size: 1.5rem;
                color: var(--colorButton);
            }
        }

        p{
            margin-right: 1rem;
        }
    
        button{
            font-size: 1rem;
        }
    }
 
`