function normalizing(word) {
	return word
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "")
		.toLowerCase()
		.split("");
}

export function NeighborhoodSanitization(input) {
	const neighborhoods = JSON.parse(localStorage.getItem("@Inoveclube:neighborhoods"));
	const userNeighborhood = normalizing(input);
	let hits = [];
	let sugestions = [];

	neighborhoods.map((neighborhood) => {
		let quantity = 0;
		let start = 0;
		let listingNeighborhood = normalizing(neighborhood);

		userNeighborhood.map((letter) => {
			const listingNeighborhoodSlice = listingNeighborhood.slice(
				start,
				listingNeighborhood.length
			);

			if (listingNeighborhoodSlice.indexOf(letter) > -1) quantity += 1;

			start += 1;
			return null;
		});
		let hitPercentage = Math.round((quantity / userNeighborhood.length) * 100);

		hits.push(hitPercentage);
		return null;
	});

	hits.map((hit, index) => {
		if (hit >= 80) sugestions.push(neighborhoods[index]);
		return null;
	});

	if (sugestions.length === 0) {
		hits.map((hit, index) => {
			if (hit >= 60) sugestions.push(neighborhoods[index]);
			return null;
		});
	}

	return sugestions;
}
