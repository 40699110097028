import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 65px;
    left: 0;

    background: var(--mainTheme);
    height: 100%;
    width: 50%;
    border-radius: 0 300px 300px 0;

    @media (max-width: 900px) {
        width: 70%
    }

    @media (max-width: 500px) {
        width: 89%;
        border-radius: 0 300px 300px 0;
    }
`
