import { Container } from './style';

export function Header({ setIsOpenUserCouponModal }) {
    

    return(
            <Container>
                <h2>Cupons</h2>
                <span
                    onClick={() => setIsOpenUserCouponModal(false)}
                >
                    &#10006;
                </span>
            </Container>
    )
}
