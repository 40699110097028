import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button{
        border: none;
        border-radius: 0.25rem;
    }

    @media (max-width: 800px){
        grid-template-columns: 1fr;
    }
`
