import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useRegister } from "../../../context/RegisterContext";
import { api } from "../../../services/api";
import { AiOutlineSearch, AiOutlineArrowLeft } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import { ZipInput } from "../../../components/inputMasks";
import { NeighborhoodAlert } from "./NeighborhoodAlert";
import { NeighborhoodSanitization } from "./NeighborhoodSanitization";
// import { MainLoader } from '../../loaders/mainLoader'

import {
	Container,
	Header,
	InputContainer,
	ButtonsContainer,
	Logo,
	NeighborhoodsList,
	InputLog,
	Dropdown,
	InputNumber,
	InputZipCode,
} from "./style";

export function RegisterAddressPersonPage() {
	const {
		handleSecondPageRegisterData,
		updateAddress,
		handleUpdateAddress,
		missingToFillInput,
	} = useRegister();
	const [citiesList, setCitiesList] = useState([]);
	// const [loadingLocation , setLoadingLocation] = useState(false)
	const history = useHistory();

	const [state, setState] = useState(
		updateAddress.initialState === true
			? updateAddress.address.state
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress")).state
			: ""
	);
	const [idCity, setIdCity] = useState(
		updateAddress.initialState === true
			? updateAddress.address.idCity
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress")).idCity
			: ""
	);
	const [neighborhood, setNeighborhood] = useState(
		updateAddress.initialState === true
			? updateAddress.address.neighborhood
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress"))
					.neighborhood
			: ""
	);
	const [street, setStreet] = useState(
		updateAddress.initialState === true
			? updateAddress.address.street
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress")).street
			: ""
	);
	const [number, setNumber] = useState(
		updateAddress.initialState === true
			? updateAddress.address.number
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress")).number
			: ""
	);
	const [zipCode, setZipCode] = useState(
		updateAddress.initialState === true
			? updateAddress.address.zipCode
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress")).zipCode
			: ""
	);
	const [complement, setComplement] = useState(
		updateAddress.initialState === true
			? updateAddress.address.complement
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress"))
					.complement
			: ""
	);
	const [reference, setReference] = useState(
		updateAddress.initialState === true
			? updateAddress.address.reference
			: localStorage.getItem("@Inoveclube:saveAllowedAddress")
			? JSON.parse(localStorage.getItem("@Inoveclube:saveAllowedAddress")).reference
			: ""
	);

	const [cityIsEnabled, setCityIsEnabled] = useState(false);

	const [enableNeighborhoodsTable, setEnableNeighborhoodsTable] = useState(false);
	const [inputNeighborhoodIsFocused, setInputNeighborhoodIsFocused] = useState(false);

	const [neighborhoodsResults, setNeighborhoodsResults] = useState([]);

	const [warningNeighborhoods, setWarningNeighborhoods] = useState({
		list: [],
		open: false,
	});

	const [disabledAddress, setDisabledAddress] = useState(true);
	const [disabledState, setDisabledState] = useState(true);
	const [disabledNeighborhood, setDisabledNeighborhood] = useState(true);
	const [disabledCity, setDisabledCity] = useState(true);

	const handleInputFocus = useCallback(() => {
		setInputNeighborhoodIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setInputNeighborhoodIsFocused(false);
	}, []);

	function verifyingNeighborhoodIsAllowed() {
		if (!localStorage.getItem("@Inoveclube:neighborhoods")) return false;

		if (
			!JSON.parse(localStorage.getItem("@Inoveclube:neighborhoods")).some(
				(ngbhd) => ngbhd === neighborhood
			)
		) {
			const list =
				neighborhoodsResults.length > 0
					? neighborhoodsResults
					: NeighborhoodSanitization(neighborhood);

			setWarningNeighborhoods({
				list,
				open: true,
			});
			return true;
		}
	}

	async function saveAddressData() {
		if (neighborhood === undefined || neighborhood.trim() === "") {
			toast.error("Preencha o bairro");
			return;
		}

		const neighbordhood =
			JSON.parse(localStorage.getItem("@Inoveclube:neighborhoods")) || [];

		if (neighbordhood.length > 0) {
			if (verifyingNeighborhoodIsAllowed()) return;
		}

		const token = localStorage.getItem("@Inoveclube:token");
		if (updateAddress.initialState === true) {
			await handleSecondPageRegisterData({
				state,
				idCity,
				neighborhood,
				street,
				number,
				zipCode,
				complement,
				reference,
				idAddress: updateAddress.address.idAddress,
			});
		} else {
			await handleSecondPageRegisterData({
				state,
				idCity,
				neighborhood,
				street,
				number,

				zipCode,
				complement,
				reference,
				token: token,
			});
		}
	}

	async function handleSearchZipCode(code) {
		setDisabledState(false);
		setDisabledCity(false);
		setDisabledAddress(false);
		setDisabledNeighborhood(false);

		try {
			await api.get("/searchZipCode/" + code).then((response) => {
				if (response.data.state !== undefined) {
					if (response.data.state && response.data.state.length > 0)
						setDisabledState(true);
					if (response.data.city && response.data.city.length > 0)
						setDisabledCity(true);
					if (response.data.address && response.data.address.length > 0)
						setDisabledAddress(true);
					if (
						response.data.neighborhood &&
						response.data.neighborhood.length > 0
					)
						setDisabledNeighborhood(true);

					setState(response.data.state);
					setNeighborhood(response.data.neighborhood);
					setStreet(response.data.address);
					setComplement(response.data.complement);
					setIdCity(response.data.city);
				} else {
					toast.info("CEP não encontrado");
				}
			});
		} catch (error) {
			toast.error(error.response.data.message);
		}
	}

	// const apiCageSearchCep = useCallback(async (position) => {
	//     let lat = position.coords.latitude
	//     let long = position.coords.longitude

	//     setLoadingLocation(true)

	//     await fetch(`https://api.opencagedata.com/geocode/v1/json?key=${process.env.REACT_APP_OPENCAGE_KEY}&q=${lat},${long}`)
	//         .then(response => response.json())
	//         .then(json => {
	//             let zipCode = json.results[0].components.postcode.replace(/\D/g, '')
	//             handleSearchZipCode(zipCode)
	//             setZipCode(zipCode)
	//             setLoadingLocation(false)
	//         })
	//         .catch(() => {
	//             setLoadingLocation(false)
	//             toast.error('Tente novamente')
	//         })
	// }, [])

	useEffect(() => {
		if (warningNeighborhoods.open) handleInputBlur();
		//eslint-disable-next-line
	}, [warningNeighborhoods]);

	useEffect(() => {
		if (state !== "" && citiesList.length > 0) setCityIsEnabled(true);
	}, [state, citiesList]);

	useEffect(() => {
		if (state !== "") {
			api.get("/getCities/" + state).then((response) =>
				setCitiesList(response.data)
			);
		}
	}, [state]);

	useEffect(() => {
		if (
			neighborhood !== "" &&
			localStorage.getItem("@Inoveclube:nameBusiness") &&
			inputNeighborhoodIsFocused === true
		) {
			setEnableNeighborhoodsTable(true);
		} else {
			setEnableNeighborhoodsTable(false);
		}
	}, [neighborhood, inputNeighborhoodIsFocused]);

	useEffect(() => {
		if (localStorage.getItem("@Inoveclube:neighborhoods")) {
			const results = JSON.parse(
				localStorage.getItem("@Inoveclube:neighborhoods")
			).filter((product) =>
				product.toLowerCase().includes(neighborhood.toLowerCase())
			);
			setNeighborhoodsResults(results);
		}
	}, [neighborhood]);

	useEffect(() => {
		if (localStorage.getItem("@Inoveclube:nameBusiness")) {
			setState(localStorage.getItem("@Inoveclube:state"));
			setIdCity(localStorage.getItem("@Inoveclube:idCity"));
		}
	}, []);

	//  useEffect(() => {
	//     if(!('geolocation' in navigator)){
	//         return
	//     }

	//     navigator.geolocation.getCurrentPosition(
	//         (position) => {
	//             apiCageSearchCep(position)
	//         },
	//         () => toast.warning('⚠️ Não foi possível buscar a localização'),
	//         {enableHighAccuracy: true, maximumAge: 30000, timeout: 5000}
	//     )
	// }, [apiCageSearchCep])

	// if(loadingLocation)
	//     return <MainLoader title="Procurando sua localização" />

	return (
		<Container>
			<Header>
				<AiOutlineArrowLeft
					onClick={() => {
						if (
							JSON.parse(localStorage.getItem("@Inoveclube:active?")) ===
							true
						) {
							handleUpdateAddress({ initialState: false, address: {} });
							localStorage.setItem(
								"@Inoveclube:updateAddress",
								JSON.stringify({ initialState: false, address: {} })
							);
							history.push("/address");
						} else if (localStorage.getItem("@Inoveclube:shopping")) {
							history.push("/shopping");
						} else {
							history.push("/login");
						}
					}}
				/>
				<h1>Adicionar endereço</h1>
			</Header>

			<InputContainer>
				<Logo></Logo>

				<InputZipCode alertUserToFillInput={zipCode === "" && missingToFillInput}>
					<ZipInput
						name="zipInput"
						type="text"
						placeholder="CEP"
						value={zipCode}
						onChange={(event) => setZipCode(event.target.value)}
					></ZipInput>
					<div onClick={() => handleSearchZipCode(zipCode)}>
						<AiOutlineSearch />
					</div>
				</InputZipCode>

				<div className="container-divide">
					<Dropdown
						className="smaller"
						alertUserToFillInput={state === "" && missingToFillInput}
					>
						<select
							onChange={(event) => setState(event.target.value)}
							value={state}
							disabled={disabledState}
						>
							<option value="" hidden>
								UF
							</option>
							<option value="AC">AC</option>
							<option value="AL">AL</option>
							<option value="AM">AM</option>
							<option value="AP">AP</option>
							<option value="BA">BA</option>
							<option value="CE">CE</option>
							<option value="DF">DF</option>
							<option value="ES">ES</option>
							<option value="GO">GO</option>
							<option value="MA">MA</option>
							<option value="MG">MG</option>
							<option value="MS">MS</option>
							<option value="MT">MT</option>
							<option value="PA">PA</option>
							<option value="PB">PB</option>
							<option value="PE">PE</option>
							<option value="PI">PI</option>
							<option value="PR">PR</option>
							<option value="RJ">RJ</option>
							<option value="RN">RN</option>
							<option value="RO">RO</option>
							<option value="RR">RR</option>
							<option value="RS">RS</option>
							<option value="SC">SC</option>
							<option value="SE">SE</option>
							<option value="SP">SP</option>
							<option value="TO">TO</option>
						</select>
					</Dropdown>

					<Dropdown
						cityIsEnabled={cityIsEnabled}
						alertUserToFillInput={idCity === "" && missingToFillInput}
					>
						<select
							onChange={(event) => setIdCity(event.target.value)}
							value={idCity}
							disabled={disabledCity}
						>
							<option value="" disabled selected hidden>
								CIDADE
							</option>
							{citiesList.map((city) => (
								<option key={city.idCity} value={city.idCity}>
									{city.city}
								</option>
							))}
						</select>
					</Dropdown>
				</div>

				<div className="container-divide">
					<InputNumber
						className="smaller"
						type="text"
						placeholder="N°"
						value={number}
						maxLength={15}
						alertUserToFillInput={number === "" && missingToFillInput}
						onChange={(event) => setNumber(event.target.value)}
					></InputNumber>

					<InputLog alertUserToFillInput={street === "" && missingToFillInput}>
						<input
							type="text"
							placeholder="ENDEREÇO. Ex: Rua, Avenida"
							value={street.toUpperCase()}
							maxLength={100}
							onChange={(event) => setStreet(event.target.value)}
							disabled={disabledAddress}
						></input>
					</InputLog>
				</div>

				<InputLog
					className="neighborhood"
					alertUserToFillInput={neighborhood === "" && missingToFillInput}
				>
					<input
						type="text"
						placeholder="BAIRRO"
						value={neighborhood.toUpperCase()}
						maxLength={300}
						onFocus={handleInputFocus}
						onChange={(event) => setNeighborhood(event.target.value)}
						disabled={disabledNeighborhood}
					></input>
					{enableNeighborhoodsTable ? (
						<NeighborhoodsList>
							<div className="line"></div>
							<div className="neighborhoods">
								{neighborhoodsResults.length > 0 ? (
									neighborhoodsResults.map((neighborhood, index) => (
										<div
											key={index}
											onClick={() => {
												setNeighborhood(neighborhood);
												handleInputBlur();
											}}
										>
											<BiCurrentLocation />
											<p>{neighborhood}</p>
										</div>
									))
								) : (
									<p className="notFound">
										nenhum resultado correspondente
									</p>
								)}
							</div>
							<div
								className="closeNeighborhoods"
								onClick={() => handleInputBlur()}
							>
								Fechar sugestões
							</div>
						</NeighborhoodsList>
					) : (
						<></>
					)}
				</InputLog>

				<InputLog>
					<input
						type="text"
						placeholder="COMPLEMENTO"
						value={complement.toUpperCase()}
						maxLength={300}
						onChange={(event) => setComplement(event.target.value)}
					></input>
				</InputLog>

				<InputLog>
					<input
						type="text"
						placeholder="REFERÊNCIA"
						value={reference.toUpperCase()}
						maxLength={300}
						onChange={(event) => setReference(event.target.value)}
					></input>
				</InputLog>

				<ButtonsContainer>
					<button
						className="logButton g-recaptcha"
						data-sitekey="6LcmFyAcAAAAANYtWCTkXQDnnGB7aVoSAisllMfC"
						data-callback="handleSubmit"
						data-size="invisible"
						SameSite="secure"
						onClick={() => saveAddressData()}
					>
						SALVAR
					</button>
				</ButtonsContainer>
			</InputContainer>

			<NeighborhoodAlert
				warningNeighborhoods={warningNeighborhoods}
				setWarningNeighborhoods={setWarningNeighborhoods}
				setNeighborhood={setNeighborhood}
			/>
		</Container>
	);
}
