import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	padding-top: 5rem;
	background-color: var(--gray-200);
	margin: 0 auto;
	
	max-width:  320px;
	
	@media screen and (min-width: 375px) {
		max-width:  375px;
	}
	
	@media screen and (min-width: 425px) {
		max-width:  425px;
	}
	
	@media screen and (min-width: 768px) {
		max-width:  768px;
	}
	
	@media screen and (min-width: 1024px) {
		max-width:  1024px;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
		.swiper-pagination-bullet {
			background-color: var(--gray-100);
		}
		.swiper-pagination-bullet-active {
			background-color: var(--gray-200);
		}
		.banner {
			width: 100%;
			cursor: pointer;

			img {
				width: 320px;
				height: 180px;
				object-fit: cover;
				display: block;
				margin: 0 auto;

				@media screen and (min-width: 375px) {
					width: 375px;
					height: 210.94px;
				}

				@media screen and (min-width: 425px) {
					width: 425px;
					height: 239.06px;
				}

				@media screen and (min-width: 768px) {
					width: 768px;
					height: 332px;
				}

				@media screen and (min-width: 1024px) {
					width: 1024px;
					height: 442px;
				}
			}

			.banner-content {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
				padding-bottom: 3rem;
				position: absolute;
				z-index: 10;
				bottom: 0;
				.banner-title {
					font-size: 2rem;
					font-weight: 700;
					text-align: center;
					margin-bottom: 1rem;
				}
				.banner-subtitle {
					font-size: 1.2rem;
					font-weight: 400;
					text-align: center;
					margin-bottom: 1rem;
				}
			}
		}
	}
`;
