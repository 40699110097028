import styled from "styled-components";

export const Container = styled.div`
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 600px;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    padding: 10px;
    background: var(--colorButton);
    

    svg{
        color: var(--colorButtonBottom);

        @media (min-width: 800px){
            cursor: pointer;
        }
    }

    .item-bar{
        position: relative;

    }
   
    .items-shopping{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        top: -10px;
        right: -10px;
        height: 20px;
        width: 20px;
        background-color: rgba(0, 255, 0, 0.8);
        color: white;
        border-radius: 50%;
        cursor: pointer;
    }
`