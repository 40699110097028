import { useEffect,useCallback } from 'react'
import { useIndividualMarketPlace } from '../../../../pages/individualMarketPlace/individualMarketPlaceContext'
import { StatesCardsList } from './statesCardsList'
import { CitiesAndStores } from './citiesAndStores'
import { StoresFromSearchResults } from './storesFromSearchResults'

import { Container, Header } from './style'

export function ContainerInformations() {
    const { 
        datasSelectedCountry, 
        searchResults,
        setSearchResults,
        datasSelectedState,
        setDatasSelectedState,
        handleSelectState
    } = useIndividualMarketPlace()

    const detectHistory = useCallback(() => {
        setDatasSelectedState(null)
      }, [setDatasSelectedState])


    useEffect(() =>{        
        if(datasSelectedState){
            window.history.pushState({id: 1}, null, null );
            // setDatasSelectedState(null)
        }
    }, [datasSelectedState,setDatasSelectedState])

    useEffect(() =>{
        if(datasSelectedState){
            window.addEventListener("popstate", detectHistory)
        }
      }, [datasSelectedState,detectHistory])

    
    return(
        <Container>
            {searchResults.business.length > 0 ?
                <StoresFromSearchResults searchResults={searchResults.business} /> 
                :
                <>
                    <Header>
                        <h2 
                            onClick={() =>   {
                                setDatasSelectedState(null)
                                setSearchResults({ business: [] })
                            }}
                        >
                            {datasSelectedCountry.country}
                        </h2>

                        {
                            datasSelectedState !== null ?
                            <>
                                <strong>&#10095;</strong>
                                <h2>{datasSelectedState.business[0].state} </h2>
                            </>
                            : 
                            <></>
                        }
                    </Header>

                    {datasSelectedState !== null ? 
                        <CitiesAndStores 
                            datasSelectedState={datasSelectedState}
                        />
                        :
                        <StatesCardsList 
                            datasSelectedCountry={datasSelectedCountry}
                            handleSelectState={handleSelectState}
                        />
                    }
                </>
            }

            <a 
                href={`https://www.instagram.com/inovesystem/`}
                className='advertise'
                target="_blank"
                rel="noreferrer"
            >
                desenvolvido por iNove System
            </a>
        </Container>
    )
}