import styled from "styled-components";

export const Container = styled.div`
    border: 0.5px solid #D3D3D3;
    border-radius: 5px;
    width: 95%;
    max-width: 550px;
    padding: 1rem;
    margin: 1rem auto;

    display: flex;
    align-items: center;
    
    cursor: ${props => props.deleteCardVisible ? 'normal' : 'pointer'};

    img{
        width: 2.2rem;
        margin-right: 1rem;
    }

    .cardInfo{
        flex: auto;

        p, span{
            color: #535251;
            font-size: 0.9rem;
        }
    }

    svg{
        font-size: 1.3rem;
        color: var(--colorButton);

        @media (min-width: 960px){
            cursor: pointer;
        }
    }
`