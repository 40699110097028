import { createContext, useState, useCallback, useContext, useEffect } from 'react'
import { useMenu } from '../../context/MenuContext';

export const ItemModalContext = createContext();

export function ItemModalProvider({ children }) {

    const {
        additionalDatas,
        item,
        additionalPriceInProduct
    } = useMenu();

    const [textAdditional, setTextAdditional] = useState('');
    const handleSetTextAdditional = useCallback((text) =>{
        setTextAdditional(text)
    }, [])

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [addButtonEnabled, setAddButtonEnabled] = useState(false);
    const [listAssemblyItensSelected, setListAssemblyItensSelected] = useState([]);
    const [sizeButtonSelected, setSizeButtonSelected] = useState(0);


    const assembledItensFilled = listAssemblyItensSelected.reduce((sumTotal, product) => {
        if (product.filled)
            sumTotal++
        return sumTotal
    }, 0);


    const costOptions = selectedOptions.reduce((sumTotal, element) => {
        return sumTotal + element.price * element.qtd
    }, 0);


    const totalAssemblyItens = listAssemblyItensSelected.reduce((sumTotal, product) => {
        if (item.businessDatas !== undefined) {
            if (item.businessDatas.typeCalcAssembledProduct === "ADD") {
                return sumTotal + product.price
            } else if (item.businessDatas.typeCalcAssembledProduct === "AVG") {
                return sumTotal + product.price / assembledItensFilled
            } else {
                if (sumTotal < product.price) {
                    return sumTotal = product.price
                } else {
                    return sumTotal
                }
            }
        }
        return sumTotal
    }, 0);


    item.assembled && assembledItensFilled > 0 ?
        item.cost = totalAssemblyItens + additionalPriceInProduct + costOptions
        :
        item.cost = additionalPriceInProduct + costOptions


    useEffect(() => {
        if (item.assembled) {
            if (item.sizes.length > 0) {
                let array = Array(item.sizes[sizeButtonSelected].maxQuantity)
                for (var i = 0; i < array.length; i++) {
                    array[i] = { idProduct: 0, name: '', price: 0, filled: false }
                }
                setListAssemblyItensSelected(array);
            }
        }
    }, [item, sizeButtonSelected])


    const handleSetSizeButtonSelected = useCallback((value) => {
        setSizeButtonSelected(value)
    }, [])


    const handleSetListAssemblyItensSelected = useCallback((array) => {
        setListAssemblyItensSelected(array)
    }, [])


    const handleSetSelectedOptions = useCallback((list) => {
        setSelectedOptions(list)
    }, [])




    const canAddOption = useCallback((option, qtd) => {
        let canAdd = false;
        let qtdAdded = 0;

        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                if (selectedOptions[i].objectE.idGroupAdditional === option.objectE.idGroupAdditional) {
                    qtdAdded = qtdAdded + selectedOptions[i].qtd;
                }
            }
        }
        if (option.objectE.unlimited === false) {
            canAdd = ((qtdAdded + (qtd)) <= option.objectE.maxQuantity)
        }
        else
            canAdd = true;
        return canAdd;
    }, [selectedOptions])


    
    const addOption = useCallback((option, qtd) => {
        let arrayOptions = [];

        if (option.qtd > 0) {
            if (selectedOptions.length === 0) {
                arrayOptions.push(option)
                setSelectedOptions(arrayOptions);
                return true;
            }
        }


        if(option.objectE.maxQuantity === 1 && !option.objectE.unlimited && qtd === 1){
            let copySelectedOption = [...selectedOptions]
            const index = copySelectedOption.findIndex(opt => opt.objectE.idGroupAdditional === option.objectE.idGroupAdditional)


            if(index === -1){
                copySelectedOption.push(option)

                setSelectedOptions(copySelectedOption)

                return true
            }
            else{
                copySelectedOption[index] = option

                setSelectedOptions(copySelectedOption)

                return true
            }

        }


        let canAdd = canAddOption(option, qtd);
        if (canAdd === true) {
            let exists = false;
            selectedOptions.map(function (element) {
                if (element.cdOption === option.cdOption) {
                    exists = true;
                }
                return exists;
            })

            if (exists === false) {
                if (option.qtd > 0)
                    arrayOptions.push(option)
            }

            selectedOptions.map(function (element) {
                let opt = {};
                opt.cdOption = element.cdOption;
                opt.obsDescription = element.obsDescription;
                opt.price = element.price;
                opt.objectE = element.objectE;
                if (option.cdOption === element.cdOption) {
                    opt.qtd = option.qtd;
                }
                else {
                    opt.qtd = element.qtd;
                }
                if (opt.qtd > 0) {
                    arrayOptions.push(opt)
                }
                return setSelectedOptions(arrayOptions);
            })
        }
        return canAdd;
    }, [canAddOption, selectedOptions])


    const verifyConditionsADatas = useCallback(() => {

        let canEnable = false;
        setAddButtonEnabled(false);

        if (additionalDatas.length > 0) {

            //verifico se todos as observações required são false
            let found = false;
            for (let i = 0; i <= additionalDatas.length - 1; i++) {
                if (additionalDatas[i].required === true) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                canEnable = true;
            }
            else {
                for (let i = 0; i <= additionalDatas.length - 1; i++) {
                    let found = false;
                    if (additionalDatas[i].required === true) {
                        for (let j = 0; j <= selectedOptions.length - 1; j++) {
                            if (selectedOptions[j].objectE.idGroupAdditional === additionalDatas[i].idGroupAdditional) {
                                found = true;
                            }
                        }
                        canEnable = found;
                        if (canEnable === false)
                            return;
                    }
                }
                //verifico se tem quantidade minima maior que zero... se tiver só habilita o botão quando a soma for igual à qtd minima    
                let arr = selectedOptions.sort((a, b) => (a.objectE.idGroupAdditional < b.objectE.idGroupAdditional) ? -1
                    : ((a.objectE.idGroupAdditional > b.objectE.idGroupAdditional) ? 1 : 0));
                for (let i = 0; i <= arr.length - 1; i++) {
                    let sum = 0;
                    if (arr[i].objectE.minQuantity > 0) {
                        for (let j = 0; j <= arr.length - 1; j++) {
                            if (arr[i].objectE.idGroupAdditional === arr[j].objectE.idGroupAdditional) {
                                sum = sum + arr[j].qtd;
                            }
                        }
                        if (sum < arr[i].objectE.minQuantity) {
                            canEnable = false;
                            break;
                        }
                    }
                }
                if (canEnable === false)
                    return;
            }
        }
        else {
            // console.log('ENABLE '+item.enable)
            // if (item.enable === true)
            canEnable = true;
        }
        // console.log(canEnable)
        return setAddButtonEnabled(canEnable);

    }, [additionalDatas, selectedOptions]);

    
    return (
        <ItemModalContext.Provider
            value={
                {
                    handleSetSizeButtonSelected,
                    handleSetListAssemblyItensSelected,
                    addOption, 
                    verifyConditionsADatas,
                    handleSetSelectedOptions,
                    handleSetTextAdditional,
                    textAdditional, 
                    addButtonEnabled,
                    selectedOptions,
                    listAssemblyItensSelected,
                    sizeButtonSelected,
                    assembledItensFilled
                }
            }
        >
            {children}
        </ItemModalContext.Provider>
    )

}

export function useModalItemManager() {
    const context = useContext(ItemModalContext)

    return context
}