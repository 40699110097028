import styled, { css } from "styled-components";

export const PageContainer = styled.div`
	padding: 0.5rem 0.5rem 0.5rem 4.5rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	max-width: 320px;
	@media (min-width: 640px) {
		max-width: 640px;
	}
	@media (min-width: 720px) {
		max-width: 720px;
	}
	@media (min-width: 1080px) {
		max-width: 1080px;
	}
	@media (min-width: 1280px) {
		max-width: 1280px;
	}
	@media (min-width: 1536px) {
		max-width: 1536px;
	}
`;

export const Button = styled.button`
	width: 100%;
	min-height: 40px;
	padding: 0.5rem 1rem;
	border: none;
	border-radius: var(--border-radius);
	font-size: 1.1rem;
	color: var(--gray-50);
	background-color: var(--mainTheme) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	:hover {
		opacity: 0.8;
	}

	:disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}

	${(props) => props.maxW && css`max-width: ${props.maxW}px;`}
	${(props) => props.isLoading && css`animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;`}
`;

export const Input = styled.input`
	width: 100%;
	padding: 0.5rem 1rem;
	border-radius: var(--border-radius);
	border: 1px solid var(--gray-200);
`;

export const TextArea = styled.textarea`
	width: 100%;
	padding: 0.5rem 1rem;
	border-radius: var(--border-radius);
	border: 1px solid var(--gray-200);
`;

export const ButtonPagination = styled.button`
	padding: 0.5rem;
	border-radius: 5px;
	background: #2e9699;
	color: #fff;
	width: 100%;
	max-width: 300px;
	text-align: center;
	cursor: pointer;
	border: none;
	margin: 0 auto;

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}
`;

export const Box = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	${(props) =>
		props.flexDir &&
		css`
			flex-direction: ${props.flexDir};
		`};

	${(props) =>
		props.gap &&
		css`
			gap: ${props.gap};
		`};
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--gray-200);
`;
