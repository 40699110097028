// ---- Imports ---- //
import { FaXmark } from "react-icons/fa6";
import styled from "styled-components";

// ---- Style ---- //
const Container = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
	padding: 0.2rem 0.2rem 0.1rem 0.2rem;
	border-radius: 8px;
	color: var(--gray-400);
	transition: color 0.2s linear;

	:hover {
		cursor: pointer;
		opacity: 0.6;
		color: var(--gray-100);
		background-color: var(--gray-400);
	}
`;

/** Main Component
 * Observation: This component will be in the top right corner, referent to the relative parent
 * @param {function} toClose - The function to be called when the button is clicked
 */
const XCloseButton = ({ toClose }) => {
  return (
    <Container onClick={toClose}>
      <FaXmark size={28} />
    </Container>
  );
};

// ---- Exports ---- //
export { XCloseButton };
