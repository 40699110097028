import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	margin-top: 1rem;

	form {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.validityAndType {
		display: flex;
		width: 95%;
		align-items: flex-end;
		justify-content: center;
		max-width: 700px;
		margin: 2rem 0;
		gap: 10px;

		div {
			flex: 1;
		}

		@media (max-height: 655px) {
			margin: 1.5rem 0;
		}
	}

	.safe {
		display: flex;
		align-items: center;
		color: green;
		margin-top: 0.2rem;

		svg {
			font-size: 1.2rem;
		}

		p {
			font-size: 0.7rem;
			font-weight: 500;
		}
	}

	h3 {
		margin: 1.2rem 0;
		color: #535251;
	}
`;

export const TypeCard = styled.div`
	box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 8px 16px rgb(0 0 0 / 8%);
	position: relative;
	border-radius: 10px;

	select {
		font-size: 1rem;
		width: 100%;
		background: #ffff;
		padding: 1rem;
		color: gray;
		border-radius: 10px;
		border: 1px solid #d3d3d3;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	&:after {
		content: "expand_more";
		font-family: "material icons";
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		color: gray;
		pointer-events: none;
	}

	@media (max-height: 670px) {
		height: 40px;
	}
`;

export const ContentInput = styled.div`
	width: 95%;
	max-width: 700px;

	p {
		padding: 0 0 0.5rem 0;
		color: #535251;
		font-size: 0.92rem;
		font-weight: 900;
		position: relative;
		display: inline-block;
	}

	.cvvExplanation {
		svg {
			position: absolute;
			top: -13px;
			right: -13px;
			font-size: 16px;
			color: #535251;
		}
		&::before {
			content: "Código no verso do seu cartão";
			padding: 1rem;
			width: 9rem;
			background: #393939;
			color: #fff;
			text-align: left;
			border-radius: 0.3rem;
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(105%, -106%);
			opacity: 0;
			transition: opacity 0.3s ease;
			pointer-events: none;
		}

		&:hover::before {
			opacity: 1;
		}
	}
	@media (max-height: 670px) {
		height: 40px;
		margin-bottom: 20px;
	}

	& + & {
		margin-top: 2rem;
	}

	@media (max-height: 655px) {
		& + & {
			margin-top: 1.5rem;
		}
	}
`;

export const Input = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	border-radius: 10px;
	border: 1px solid var(--colorBgHeader);
	transition: all 0.3s ease;
	box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 8px 16px rgb(0 0 0 / 8%);

	img {
		height: 25px;
		margin: 0 1rem;
	}

	input {
		color: gray;
		flex: 1;
		font-size: 1rem;
		border: none;
		padding: 1rem;
		border-radius: 10px;

		@media (max-height: 670px) {
			height: 40px;
		}
	}
`;

export const Button = styled.button`
	width: 95%;
	max-width: 700px;

	padding: 1rem;
	background: var(--colorButton);
	color: var(--colorLabel);
	border-radius: 10px;
	margin-top: 2rem;
	transition: all 0.3s ease;
	border: none;

	display: flex;
	justify-content: center;

	&:hover {
		filter: brightness(0.9);
		cursor: pointer;
	}

	pointer-events: ${(props) => (props.enable ? "none" : "all")};
	opacity: ${(props) => (props.enable ? 0.7 : 1)};

	@media (max-height: 655px) {
		margin-top: 1rem;
	}
`;
