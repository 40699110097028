import styled, { css } from "styled-components";

export const Container = styled.div`
    padding: 20px 0;
    width: 98%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

export const CouponTitle = styled.div`
    margin-left: 3px;
    display: flex;
    align-items: center;

    svg{
        color: var(--colorButton);
        font-size: 1.5rem;
        margin-right: 15px;
        transition: all 0.3s ease-in-out;
    }

    ${props => props.turnIcon && css`
        svg{
            transform: rotateZ(-45deg);
            margin-right: 10px;
        }
    `}
`

export const ChooseCoupon = styled.span`
    margin-right: 5px;
    color: var(--colorButton);
    font-size: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }

    ${props => props.remove && css`
        color: #CC0000;
    `}
`