import { useHistory } from 'react-router'
import { GoLocation } from 'react-icons/go'
import { useIndividualMarketPlace } from '../../../../../pages/individualMarketPlace/individualMarketPlaceContext';

import { Container } from './style';

// import moment from 'moment';

export function StoreCard({ storeDatas }) {
    const { storesBusinessDatas } = useIndividualMarketPlace()
    const history = useHistory()

    // function verifyingWorkingTime(day, business){
    //   if(day === 1) return business.hrAberturaSeg
    //   if(day === 2) return business.hrAberturaTer
    //   if(day === 3) return business.hrAberturaQua
    //   if(day === 4) return business.hrAberturaQui
    //   if(day === 5) return business.hrAberturaSex
    //   if(day === 6) return business.hrAberturaSab
    //   if(day === 7) return business.hrAberturaDom
    // }

    // function notOpenToday(day, business){
    //   if(day === 1) return business.segNaoAbre
    //   if(day === 2) return business.terNaoAbre
    //   if(day === 3) return business.quaNaoAbre
    //   if(day === 4) return business.quiNaoAbre
    //   if(day === 5) return business.sexNaoAbre
    //   if(day === 6) return business.sabNaoAbre
    //   if(day === 7) return business.domNaoAbre
    // }

    // function isCurrentlyClosed(day, data, business){
    //   if(day === 1) return (!business.isOpenBusiness && (data >= business.hrAberturaSeg  && data <= business.hrFechamentoSeg))
    //   if(day === 2) return (!business.isOpenBusiness && (data >= business.hrAberturaTer  && data <= business.hrFechamentoTer))
    //   if(day === 3) return (!business.isOpenBusiness && (data >= business.hrAberturaQua  && data <= business.hrFechamentoQua))
    //   if(day === 4) return (!business.isOpenBusiness && (data >= business.hrAberturaQuin  && data <= business.hrFechamentoQui))
    //   if(day === 5) return (!business.isOpenBusiness && (data >= business.hrAberturaSex  && data <= business.hrFechamentoSex))
    //   if(day === 6) return (!business.isOpenBusiness && (data >= business.hrAberturaSab  && data <= business.hrFechamentoSab))
    //   if(day === 7) return (!business.isOpenBusiness && (data >= business.hrAberturaDom  && data <= business.hrFechamentoDom))
    // }

  return(
      <Container 
        data-tooltip={`${storeDatas.country + ' - ' + storeDatas.state + ' - ' + storeDatas.city}`}
        colorBgHeader={storesBusinessDatas.colorBgHeader} 
        colorLabel={storesBusinessDatas.colorLabel}
        onClick={() => history.push('/menu/' + storeDatas.nameBusiness)}
      >

      <div className="business">
        <div className="image">
            <img src={storeDatas.linkProfile} alt={storeDatas.nameBusiness}/>
            {/* {
              storeDatas.isOpenBusiness ?
              <></> :
              <div>
                <p>Fechado</p>
              </div>
            } */}
        </div>

        {/* {
          !storeDatas.isOpenBusiness ?
            (
              notOpenToday(moment().isoWeekday(), storeDatas) ? 
              <span>Hoje não abre</span> 
              :
              isCurrentlyClosed(moment().isoWeekday(), moment().format('HH:mm'),storeDatas) ? 
                <span>
                  Fechada no momento
                </span>
              :
                <span>
                  Abrirá às {verifyingWorkingTime(moment().isoWeekday(), storeDatas)}
                </span>
            )
            :
            <span>Aberto</span>

        } */}
      </div>

        <div className="line"></div>

        <div className="text">
            <pre>{storeDatas.name}</pre>
            <div>
                <GoLocation />
                <p>
                  {storeDatas.country} - {storeDatas.state} - {storeDatas.city} - {storeDatas.neighborhood}
                </p>
            </div>
        </div>

    </Container>
  )
}
