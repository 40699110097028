import styled from "styled-components";

export const Container = styled.div`
    margin-top: 1rem;
    width: 100%;
    height: 100%;

    .title{
        font-weight: 600;
        margin-bottom: 1rem;
    }

   .linkInfo{
        display: block;

        & + .linkInfo {
            margin-top: 1rem;
       }

       p {
            display: flex;
            text-align: center;
            font-weight: normal;

            svg{
                margin-right: 0.5rem;
            }
        }
    }

    .contacts{
        .contact{
            display: flex;
            width: 100%;
            align-items: center;
            color: #535251;
            
            .button{
                font-weight: normal;
                font-size: 0.8rem;
                margin-left: 1rem;
                cursor: pointer;
            }
        }
    }

    .address{
        margin-top: 5rem;
        
        a{
            color: #535251;
        }
    }
`;