import styled, { css } from "styled-components";

export const Container = styled.section`
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: transparent;
	transition: all 0.3s ease;
	color: #fff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	z-index: 50;

	${(props) =>
		!props.isScrollOnTop &&
		css`
			background: #fff;
			z-index: 50;
			transform: translate3D(0px, 0px, 0px);
			box-shadow: rgb(43 83 135 / 8%) 0px 3px 8px 0px;
			color: #000;
		`}
`;

export const Content = styled.div`
	max-width: 1200px;
	width: 100%;
	height: 100%;
	padding: 1rem;
	margin: 0 auto;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;

	img {
		height: 3rem;
		@media (max-width: 610px) {
			height: 2.8rem;
		}
		@media (min-width: 768px) {
			order: 1;
		}
	}
	.search-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		gap: 0.5rem;
		@media (min-width: 768px) {
			flex: 1;
			min-width: 200px;
			max-width: 500px;
			order: 2;
		}
		.user-icons {
			display: flex;
			gap: 0.5rem;
			.icon {
				color: white;
				cursor: pointer;
				padding: 0.5rem;
				font-size: 2.5rem;
				background-color: rgb(209, 57, 124);
				border-radius: 8px;
				&:hover {
					background-color: rgb(209, 57, 124, 0.8);
				}
			}
		}
		.search {
			flex: 1;
			width: 100%;
			position: relative;
			.icon {
				position: absolute;
				top: 0.5rem;
				left: 0.5rem;
				color: rgb(209, 57, 124);
			}
			input {
				width: 100%;
				max-width: 500px;
				border-radius: 4px;
				border: unset;
				font-size: 1rem;
				padding: 0.5rem 0.5rem 0.5rem 2.3rem;
				border: 1px solid #ccc;
				@media (max-width: 768px) {
					max-width: unset;
				}
			}
		}
	}

	.buttons {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		gap: 0.5rem;

		@media (min-width: 768px) {
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			order: 3;
		}

		div {
			display: flex;
			gap: 0.5rem;
			@media (min-width: 768px) {
				gap: 1rem;
			}
		}

		button {
			background: var(--brand-700);
			border: none;
			border-radius: 5px;
			color: #fff;
			font-weight: 500;
			transition: filter 0.3s;
			padding: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;
			font-size: 0.8rem;
			@media (min-width: 768px) {
				padding: 0.7rem 1.2rem;
			}

			&:hover {
				filter: brightness(0.9);
			}
		}

		.buttonLocation {
			font-size: 0.7rem;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 6.5rem;
			gap: 3px;

			p {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			svg {
				font-size: 0.9rem;
				min-width: 0.9rem;
				transition: transform 0.3s;
			}

			&:hover {
				svg:last-child {
					transform: translateX(5px);
				}
			}
		}
	}
`;
