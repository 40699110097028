import styled from "styled-components";

export const Container = styled.div`
     h1{
        margin-bottom: 1px;
    }
`

export const Content = styled.div`
    background: #FFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    .fill{
        height: 3rem;
        width: 100%;
    }

`