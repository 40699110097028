import { useState } from 'react'
import {useHistory} from 'react-router-dom'
import { api } from '../../../../services/api'
import { MainLoader } from '../../../loaders/mainLoader'
import { InputGeneric } from '../../../Forms/InputGeneric'
import { FaRegEnvelope } from 'react-icons/fa'
import { Button } from '../../../Button'
import { toast } from 'react-toastify'
import { Logo } from '../../../Logo'

import { Container } from './style'


export function RecoverByEmail() {
    const history = useHistory();
    const [emailRecover, setEmailRecover] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const customId = 110;
  
    async function handleSendEmailRecover (event) {
      event.preventDefault();
      setIsLoading(true)

        try{
            if(emailRecover === '')
                throw new Error("Preencha o campo Email")
            const response = await api.post('/forgotPassword/' + emailRecover)

            const { message, success } = response.data

            setIsLoading(false)
            if (success === 1){
                toast.success(message, {toastId: customId});
                history.push('/login')

            }else{
                throw new Error(message)
            }
        }catch(error){
            setIsLoading(false)
            toast.error(error.message, {toastId: customId})
        }
    }


    return (
        <Container>
            {
                isLoading 
                ? <MainLoader title={"Enviando email..."}/> 
                : 
                <form onSubmit={handleSendEmailRecover}>
                <Logo />

                <InputGeneric 
                    value={emailRecover}
                    onChange={event => setEmailRecover(event.target.value)}
                    icon={<FaRegEnvelope />}
                    placeholder="Digite seu email"
                    filterNumbers={false}
                />

                <Button type="submit">
                    Enviar
                </Button>
                </form>
            } 
        </Container>
    )
}