import { Container, Modal, Backdrop, Title } from "./style";
import { Steps } from "./components/steps";
import { FaXmark } from "react-icons/fa6";
import { SpaceLoader } from "../loaders/spaceLoader";

export function SmallModal({
  title,
  toClose,
  children,
  stepsComponents,
  onNextStep,
  onPrevStep,
  onFinish,
  loading,
}) {
  return (
    <Container>
      <Modal className="scroll">
        <FaXmark className="close" onClick={toClose} />
        <Title>{title}</Title>
        {loading ? (
          <SpaceLoader />
        ) : (
          <>
            {stepsComponents && stepsComponents.length > 0 && (
              <Steps
                stepsComponents={stepsComponents}
                onNextStep={onNextStep}
                onPrevStep={onPrevStep}
                onFinish={onFinish}
              />
            )}
            {!stepsComponents && children}
          </>
        )}
      </Modal>
      <Backdrop onClick={toClose} />
    </Container>
  );
}
