import { useIndividualMarketPlace } from '../../../../pages/individualMarketPlace/individualMarketPlaceContext'
import { useCallback, useEffect} from 'react'
import { FaSearch } from 'react-icons/fa'

import { Container, Content, ContainerNameInput, ButtonSearch } from "./style"

export function Header() {
    const { storesBusinessDatas, handleSearchStore,searchTerm,setSearchTerm } = useIndividualMarketPlace()

    const clickEnter = useCallback(event => {
        document.removeEventListener("keydown", clickEnter, false)
        if(searchTerm.trim() !== '' && event.keyCode === 13){
            handleSearchStore(searchTerm)
            // setSearchTerm('')
        }
    }, [handleSearchStore, searchTerm])


    useEffect(() =>{
        document.addEventListener("keydown", clickEnter, false)
    }, [clickEnter])


    return(
        <Container colorBgHeader={storesBusinessDatas.colorBgHeader} colorLabel={storesBusinessDatas.colorLabel}>

            <Content>
                <img src={storesBusinessDatas.linkProfile} alt="logo" />
                
                
                <ContainerNameInput>
                    
                    <h1>{storesBusinessDatas.name}</h1>

                    <div className="inputSearchCity">
                        <input 
                            type="text" 
                            placeholder="Buscar cidade ou CEP"
                            value={searchTerm}
                            onChange={event => setSearchTerm(event.target.value)}
                        />
                        <ButtonSearch  
                            colorBgHeader={storesBusinessDatas.colorBgHeader} 
                            colorLabel={storesBusinessDatas.colorLabel}
                            onClick={() => {
                                if(searchTerm.trim() !== ''){
                                    handleSearchStore(searchTerm)
                                    // setSearchTerm('')
                                }
                            }}
                        >
                            <FaSearch />
                        </ButtonSearch>
                    </div>

                </ContainerNameInput>
           </Content>

            {/* <TotalNumberStores>
                <p>{storesBusinessDatas.countBusiness}</p>
                <p>
                    {
                        storesBusinessDatas.countBusiness === 1 ?
                        'Loja'
                        :
                        'Lojas'
                    }
                </p>
            </TotalNumberStores> */}

        </Container>
    )
}