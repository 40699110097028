import { useEffect, useState } from "react";
import { useOrderManagerContext } from "../../context";
import { Button, Input, InputSelect } from "./style";
import { getVaults } from "../../requests/vault/getVaults";
import { toast } from "react-toastify";

export function ChoseAmount({ company, handleGoBack }) {
  const { handleOpenCashRegister, handleOpenAllCashRegister, cashRegisterIsLoading } =
    useOrderManagerContext();

  const [vaults, setVaults] = useState([]);
  const [currentVaultId, setCurrentVaultId] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();

    if (company) {
      await handleOpenCashRegister({
        companyId: company.cdEmpresa,
        vaultId: currentVaultId,
        amount: event.target.amount.value,
      });
    } else {
      await handleOpenAllCashRegister({ amount: event.target.amount.value });
    }

    handleGoBack();
  }

  function handleChoseVault(event) {
    const vaultId = event.target.value;
    setCurrentVaultId(vaultId);
  }

  useEffect(() => {
    if (!company) return;
    getVaults({ company: company.cdEmpresa })
      .then(setVaults)
      .catch(() => {
        toast.error("Erro ao buscar os cofres");
      });
  }, [company]);

  return (
    <>
      <Button className="right" onClick={handleGoBack}>
        Voltar
      </Button>

      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="amount">Valor inicial do caixa</label>
          <Input type="text" id="amount" required />
        </div>

        {company ? (
          <div>
            <label htmlFor="origin">Origem do valor</label>
            <InputSelect required onChange={handleChoseVault}>
              <option value="" disabled selected>
                Selecione uma origem
              </option>
              {vaults.map((vault) => (
                <option key={vault.idCofre} value={vault.idCofre}>
                  {vault.dsCofre}
                </option>
              ))}
            </InputSelect>
          </div>
        ) : (
          <h3>Será usado o cofre padrão das Empresas</h3>
        )}

        <Button className="large" disabled={cashRegisterIsLoading}>
          Confirmar
        </Button>
      </form>
    </>
  );
}
