import { useState, useEffect, useCallback } from 'react';
import {createContext, useContext} from 'react'
import { toast } from 'react-toastify';
import { api } from '../../../services/api'

export const IndividualMarketPlaceContext = createContext();

export function IndividualMarketPlaceProvider({ children }){
    const [storesBusinessDatas, setStoresBusinessDatas] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const [selectedCountry, setSelectedCountry] = useState(0)

    const [datasSelectedCountry, setDatasSelectedCountry] = useState({})
    const [datasSelectedState, setDatasSelectedState] = useState(null)

    const [searchResults, setSearchResults] = useState({ business: [] })
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchStore = useCallback( async (searchTerm) =>{

      setIsLoading(true)

      let cep = searchTerm.replace(/\D/g, '');
      let response = ''

      let validaCep = /^[0-9]{8}$/;

      if(validaCep.test(cep)){
          response = await fetch('https://viacep.com.br/ws/'+searchTerm+'/json/')
          .then((res) => {
            return res.json()
          })
          .then((json) => {
            return json.localidade
          })
      }
      
      let city = response !== '' ? response : searchTerm
      
      await api.get(`/getAllBusinessMarketPlace/${window.location.pathname.split('/')[2]}/?searchTerm=${city}`).then(response => {

        setSearchResults(response.data)
        if(response.data.business.length <= 0){
          toast.warning('Nenhuma loja encontrada')
        }
      })

      setIsLoading(false)

    }, [])
    

    const handleSelectState = useCallback(async (idState) => {
      setIsLoading(true)
     
      await api.get(`/getAllBusinessMarketPlace/${window.location.pathname.split('/')[2]}/?idState=${idState}`).then(response => {
          setDatasSelectedState(response.data)
        if(response.data.business.length <= 0){
          toast.warning('Nenhuma loja encontrada')
        }
      })

      setIsLoading(false)
    }, [])

    useEffect(() => {
      async function fetchData(){
        if(!localStorage.getItem('@Inoveclube:location')){
              navigator.geolocation.getCurrentPosition(async function(position){
                let coords = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
                const responseCity = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${coords.latitude}+${coords.longitude}&key=${process.env.REACT_APP_OPENCAGE_KEY}`)
                .then((response) => {
                  return response.json()
                })
                .then((json) => {
                  return json
                });

                // console.log(responseCity.results[0].components.city)
                setSearchTerm(responseCity.results[0].components.city)

                await api.get(`/getAllBusinessMarketPlace/${window.location.pathname.split('/')[2]}/?searchTerm=${responseCity.results[0].components.city}`).then(response => {
                  setSearchResults(response.data)
                  if(response.data.business.length <= 0){
                    toast.warning('Nenhuma loja encontrada')
                  }
                })
            },
            function(error){
              console.log('Localização não aceita')
            })
        }
        else{
          try {
            setSearchTerm(JSON.parse(localStorage.getItem('@Inoveclube:location'))?.description.slice(0,-3))

            await api.get(`/getAllBusinessMarketPlace/${window.location.pathname.split('/')[2]}/?searchTerm=${JSON.parse(localStorage.getItem('@Inoveclube:location'))?.description.slice(0,-3)}`).then(response => {
              setSearchResults(response.data)
              if(response.data.business.length <= 0){
                toast.warning('Nenhuma loja encontrada')
              }
            })
            .catch((error) =>{
              console.log(error)
            })
          } 
          catch (error) {
            console.log(error)
          }
        }

      }
      fetchData();
    },[])
   
    useEffect(() =>{
        async function FetchData(){
            try{
                await api.get('/getStatesMarketPlace/' + window.location.pathname.split('/')[2]).then(response => {
                  setStoresBusinessDatas(response.data)
                })
                setIsLoading(false)
            }catch{
                setIsLoading(false)
            }
        }
       FetchData()
    }, [])
   


    useEffect(() => {
      if(storesBusinessDatas.idBusiness !== undefined){
        setDatasSelectedCountry(storesBusinessDatas.countries[selectedCountry])
      }
    }, [storesBusinessDatas, selectedCountry])
   

   
  return(
      <IndividualMarketPlaceContext.Provider  
        value={
          { 
            isLoading,
            selectedCountry,
            storesBusinessDatas,
            datasSelectedCountry,
            datasSelectedState,
            searchTerm,
            setStoresBusinessDatas,
            setSelectedCountry,
            handleSearchStore,
            setSearchResults,
            handleSelectState,
            setDatasSelectedState,
            setSearchTerm,
            searchResults
          }
        }
      >
         { children }
      </IndividualMarketPlaceContext.Provider>
   )
}


export function useIndividualMarketPlace() {
   const context = useContext(IndividualMarketPlaceContext)

   if(!context) {
    throw new Error('useIndividual must be used within an Provider');
  } 

   return context
}