import styled from "styled-components";

export const ToPrint = styled.div`
	display: none;
	@media print {
		@page {
			margin: 1px;
			size: 80mm auto;
		}
		display: unset;
		width: 100%;
		background-color: white;
		font-family: monospace;
		text-transform: uppercase;
	}
	.emphasis {
		font-weight: 800;
		font-size: 0.9rem;
	}
	.emphasis-borded {
		font-weight: 800;
		font-size: 1rem;
		display: block;
		text-align: center;
		border-top: 1px dashed;
		border-bottom: 1px dashed;
		padding: 5px 0;
	}
	.heading {
		border-top: 1px dashed;
		border-bottom: 1px dashed;
		padding: 0.5rem 0;
		display: flex;
		flex-direction: column;
		.center {
			text-align: center;
		}
		div {
			span:first-child {
				margin-right: 5px;
			}
		}
	}
	.content {
		padding: 0.5rem 0;
		display: flex;
		flex-direction: column;
		font-size: 1rem;
		.grid {
			display: grid;
			grid-template-columns: 30px auto;
			span {
				margin: 2px 0;
			}
			:first-child {
				margin: 5px 0;
			}
			span:first-child {
				text-align: center;
			}
		}
		.spaced {
			margin-left: 20px;
		}
	}
	.border {
		border-bottom: 1px dashed;
	}
	.infos {
		padding: 5px;
		display: flex;
		flex-direction: column;
		div span {
			font-size: 0.7rem;
		}
	}
	.grid-head {
		border-top: 1px dashed;
		font-size: 0.7rem;
		font-weight: 600;
		display: grid;
		padding: 5px 0;
		grid-template-columns: 11% 42% 8% 13% 13% 13%;
	}
	.grid-body {
		font-size: 0.7rem;
		display: grid;
		padding: 5px 0;
		grid-template-columns: 11% 42% 8% 13% 13% 13%;
	}
	.prices {
		display: flex;
		flex-direction: column;
		padding: 10px;
		border-top: 1px dashed;
		border-bottom: 1px dashed;
		div {
			display: grid;
			grid-template-columns: 35% 40% 25%;
		}
	}
	.payment {
		display: flex;
		flex-direction: column;
		padding: 10px;
	}
	.signature {
		text-align: center;
		border-top: 1px solid black;
		margin: 25px 0 10px 0;
	}
	.footer {
		border-top: 1px dashed;
		padding: 20px 0;
		display: flex;
		gap: 5px;
		flex-direction: column;
		text-align: center;
	}
`;
