// import { Container } from './styles';
import { Link } from 'react-router-dom'
import { BiChevronRight } from 'react-icons/bi'

export function LinkButton({ 
    icon, 
    title, 
    route, 
    chevron = true,
    clickFunction,
    logOut = false
}) {
    return <Link 
            to={`/${route}`} 
            onClick={() => clickFunction ? clickFunction() : ''}
        >
                <div style={logOut ? {marginTop: '1.5rem'} : {}}>
                    <div>
                        {icon}
                        <p>{title}</p>
                    </div>
                    {chevron ? <BiChevronRight /> : <></>}
                </div>
            </Link>
}
