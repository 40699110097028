import { Container, ListItem } from './style';

export function StatesCardsList({ datasSelectedCountry, handleSelectState }) {
   
  return (
    <Container>
     
        {
            datasSelectedCountry.states.map((state) => (  
                
                <ListItem 
                    key={state.idState}
                    link={state.link}
                    onClick={() => handleSelectState(state.idState)}
                >
                    <div className="location">
                        <div // title={state.count + ' Lojas'} 
                        >
                        </div>
                    </div>
                    <div className="state">
                        <p>{state.state}</p>
                    </div>
                </ListItem>
                
            ))
        }
          
    </Container>
  )
}

