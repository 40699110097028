import { api } from "./api";
import { getCoupon } from "../Utils/coupons";

export const saveLogClube = async ({
  nameBusiness,
  idStatus,
  token, 
  idProduct=null, 
  idOrcamento=null, 
  isTakeaway=false, 
  isTable=false
}) => {
  let coupon = localStorage.getItem("@Inoveclube:coupon"); 
  let isCoupon = false;

  if(coupon){
    const couponResponse = await getCoupon(nameBusiness, coupon);
    
    if(couponResponse){
      isCoupon = true;
      coupon = couponResponse.idCoupon;
    }
  }

  await api.post('/logsClube',
  {
    nameBusiness: nameBusiness,
    idStatus: idStatus,
    idProduct: idProduct,
    idOrcamento: idOrcamento,
    isTakeaway,
    isTable,
    isCoupon,
    idCoupon: coupon
  },
  {
    headers: {
        token: token
    }
  })
}
