import styled from "styled-components"

export const Content = styled.div`
    height: 6.5rem;
    width: 6.5rem;
    background-image: url(${localStorage.getItem('@Inoveclube:imageBusiness') ? localStorage.getItem('@Inoveclube:imageBusiness') : "https://s3.sa-east-1.amazonaws.com/www.inoveclube.com/public/logo.png"});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    border-radius: 0.7rem;
`