import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom'

import { Container } from './style';


export function HeaderUserSession({ route }) {
    return(
        <Container>
            <Link to={route}><AiOutlineArrowLeft /></Link>
            <h1>Meus endereços</h1>
        </Container>
    )
}
