import { useState } from "react";
import { useHistory } from "react-router";

import { toast } from "react-toastify";

import { inovePagarme } from "../../../../../services/pagarmeApi";

export function useRegisterCard() {
	const [loadingCardInformations, setLoadingCardInformations] = useState(false);

	const sanitizingCardNumbers = (str) => str?.replace(/[^0-9]/g, "");
	const history = useHistory();

	async function createPagarmeCard({ customer_id, data }) {
		try {
			const token = localStorage.getItem("@Inoveclube:token");
			const expDateSanitized = sanitizingCardNumbers(data.date);

			const object = {
				customer_id,
				cardType: data.type,
				token,
				card: {
					number: sanitizingCardNumbers(data.number),
					holder_name: data.name,
					exp_month: Number(expDateSanitized.slice(0, 2)),
					exp_year: Number(expDateSanitized.slice(2)),
					options: {
						verify_card: true,
					},
					cvv: data.cvv,
				},
				alternativeAddress:
					localStorage.getItem("@Inoveclube:nameBusiness") || "teste111",
			};

			const response = await inovePagarme.post("/createCard", object, {
				headers: {
					"Content-Type": "application/json",
					token,
				},
			});

			const { idCard, brand } = response.data;

			let dataObj = {
				idPayment: 1000,
				icon: brand.toLowerCase(),
				description: `${brand} ••••${object.card.number.slice(-4)}`,
				idCard: idCard,
				type: "online",
			};

			localStorage.setItem("@Inoveclube:onlinepmtd", JSON.stringify(dataObj));
			setLoadingCardInformations(false);

			history.goBack();
			toast.success("Cartão cadastrado com sucesso");
		} catch (error) {
			setLoadingCardInformations(false);

			if (error.response.status === 404) toast.error("Cheque os dados do cartão");
			else toast.error("Algo deu errado, verifique se o cartão já está cadastrado");
		}
	}

	async function gettingIdPersonPagarme(data) {
		setLoadingCardInformations(true);
		try {
			const response = await inovePagarme.get(
				`/getIdPersonPagarme/${localStorage.getItem("@Inoveclube:token")}`
			);

			const customer_id = response.data;

			await createPagarmeCard({ customer_id, data: data });
		} catch (error) {
			if (error.response) {
				setLoadingCardInformations(false);
				toast.error(error.response.data.message);
				return;
			}

			toast.error(error);
		} finally {
			setLoadingCardInformations(false);
		}
	}

	return {
		loadingCardInformations,
		gettingIdPersonPagarme,
	};
}
