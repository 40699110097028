import { StoreCard } from '../storeCard';

import { Container } from './style';

export function StoresFromSearchResults({ searchResults }) {
  return(
      <Container>

          {searchResults.map(store =>(
              <StoreCard storeDatas={store} />
          ))}
    
      </Container>
  )
}

