import styled from "styled-components";

export const Container = styled.div`
    h1{
        margin-bottom: 2px;
    }
`

export const Content = styled.div`
    background: #FFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto 70px auto;

    .logButton{
        position: fixed;
        bottom: 0;
        background: #FFF;
        width: 90%;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        svg{
            min-height: 3rem;
            font-size: 3rem;
            color: var(--colorButton);
            cursor: pointer;
            margin-bottom: 5px;
        }
    }
`
export const AddressesNotFound = styled.p`
    font-weight: 500;
    padding: 30px;
    margin: 0 auto;
`