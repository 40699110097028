import { useState } from "react"
import { toast } from "react-toastify"
import { formatMoney } from '../../../../../Utils/formatMoney'

import { Container, Content } from "./style"


export function ChangeMoneyModal({ setPaymentMethodsModalOpened, setChangeMoneyModalOpened, 
                                verifyChange, handleSetChangeValue }) {

    const [changeInput, setChangeInput] = useState(false);
    const [valueInputChange, setValueInputChange] = useState(0);



    return(
        <Container>
            <Content>
                {
                changeInput ?
                    <>
                        <span onClick={() => {
                                setChangeMoneyModalOpened(false);
                            }}>&#10005;</span>
                        <p>Troco para:</p>
                        <div className="changeValueContainer">
                            <p>Valor total da compra: </p>
                            <p>{ formatMoney(verifyChange) }</p>
                        </div>
                        <input
                            type="number" 
                            placeholder="R$ 0,00"
                            maxLength={10}
                            onChange={event => setValueInputChange(event.target.value)}
                        >
                        </input>
                        <button onClick={() => {
                            
                            if(valueInputChange < verifyChange){
                                toast.error(`Valor mínimo permitido: 
                                    ${ formatMoney(verifyChange) }`
                                )
                            }
                            else{
                                handleSetChangeValue(valueInputChange);
                                setChangeMoneyModalOpened(false);
                                setPaymentMethodsModalOpened(false);
                            }         
                        }}>Confirmar</button>
                    </>
                    :
                    <>
                        <p>Precisa de Troco?</p>
                        <div className="containerButtons">
                            <button className="confirmChangeButton" onClick={() => {
                                setChangeInput(true);
                            }}>
                                Sim
                            </button>
        
                            <button className="deniedChangeButton" onClick={() => {
                                handleSetChangeValue(0);
                                setChangeMoneyModalOpened(false);
                                setPaymentMethodsModalOpened(false);
                            }}>
                                Não
                            </button>
                        </div>
                    </>
                }
            </Content>
        </Container>
    )
}