import { useState } from 'react';
import { Header } from '../../components/pagesComponents/profilePage/header';
import { FirstUserSection } from '../../components/pagesComponents/profilePage/firstUserSection';
import { SecondUserSection } from '../../components/pagesComponents/profilePage/secondUserSection';
import { MenuBar } from '../../components/pagesComponents/profilePage/menuBar'
import { ModalBusinessInformations } from '../../components/pagesComponents/menuPage/modalBusinessInformations'

import { Container, Content } from './style';

export function Profile() {
    const [infoOpen, setInfoOpen] = useState(false)

    return(
        <Container>
            <ModalBusinessInformations 
                open={infoOpen}
                closeModal={setInfoOpen}
            />

            <Header />

            <Content>
                <FirstUserSection />
                <SecondUserSection />

                  
                {
                localStorage.getItem('@Inoveclube:nameBusiness') ?
                    <MenuBar 
                        setInfoOpen={setInfoOpen}
                    />
                    :<></>
                }
            </Content>

        </Container>
    )
}
