import styled, { css } from "styled-components";

export const Container = styled.div`
	padding: 20px 0 20px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #565656;

	svg {
		font-size: 1.5rem;
		cursor: pointer;
	}

	.radioButtonOn {
		color: #454545;
	}

	.num-counter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 20%;

		span {
			font-size: 14px;
			padding: 0;
		}

		svg {
			font-size: 18px;
		}

		.plus {
			color: #40e0d0;
		}

		.minus {
			color: black;
			opacity: 0.2;
			${(props) =>
				props.qtde > 0 &&
				css`
					color: red;
					opacity: 1;
				`};
		}
	}

	.description {
		width: 60%;
	}

	.price {
		width: 20%;
		font-size: 12px;
	}

	p {
		font-size: 14px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 1rem;
		img {
			width: 3rem;
		}
	}
`;
