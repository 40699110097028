import { Container } from "./style";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import allIlustration from "../../../../../../src/assets/select-all.png";
SwiperCore.use([Navigation]);

export function Filters({
	filters,
	getStores,
	type,
	setSectionLoading,
	idSelectedFilter,
}) {
	return (
		<Container>
			<h2>Conheça nossas lojas!</h2>
			<Swiper
				className="swiper-container"
				slidesPerView={"auto"}
				spaceBetween={16}
				style={{ padding: "1.5rem 0" }}
			>
				{type === "businessPark" && (
					<SwiperSlide
						className="swiper-wrapper"
						onClick={() => {
							setSectionLoading("stores");
							getStores(-1, 1);
						}}
					>
						<div className="slide">
							<img
								src={
									type === "businessPark"
										? allIlustration
										: "https://inovesystem.s3.sa-east-1.amazonaws.com/countries/brasil.png"
								}
								alt=""
							/>
							<p className="title">Todos</p>
						</div>
					</SwiperSlide>
				)}
				{filters.map((filter, idx) => {
					return (
						<SwiperSlide
							key={idx}
							className={`swiper-wrapper ${
								filter.id === idSelectedFilter ? "selected" : ""
							}`}
							onClick={() => {
								setSectionLoading("stores");
								getStores(filter.id, 1);
							}}
						>
							<div className="slide">
								<img src={filter.image} alt="" />
								<p className="title">{filter.title}</p>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</Container>
	);
}
