import { createContext, useState, useEffect, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { api } from "../services/api";

export const MenuContext = createContext();

export function MenuProvider({ children }) {
	// #Estado e função do modal de imagem do produto
	const [imageModal, setImageModal] = useState({ show: false, src: "" });

	const handleSetImageModal = useCallback((object) => {
		setImageModal(object);
	}, []);

	// #Estados e funções do item selecionado
	const [item, setItem] = useState({
		code: 0,
		src: "",
		name: "",
		cost: 0,
		enable: "",
		sizes: [],
		show: false,
		qtdMax: 0,
	});
	const [additionalPriceInProduct, setAdditionalPriceInProduct] = useState(0);
	const [additionalDatas, setAdditionalDatas] = useState([]);
	const [isLoadingAdditionalProduct, setIsLoadingAdditionalProduct] = useState(true);

	const [productsWithGroups, setProductsWithGroups] = useState([]);

	const handleSetItem = useCallback((object) => {
		setItem(object);
	}, []);

	const addAdditionalPriceToProduct = useCallback((price) => {
		setAdditionalPriceInProduct(price);
	}, []);

	const fetchAdditionalData = useCallback(async (itemCode, nameBusiness) => {
		try {
			await api
				.get(`/getAdditionalProduct/${nameBusiness}/${itemCode}`)
				.then((response) => {
					// console.log(response.data)
					setAdditionalDatas(response.data);
				});
		} catch (error) {
			toast.error("Recarregue a página");
		}

		setIsLoadingAdditionalProduct(false);
	}, []);

	useEffect(() => {
		if (item.show === false) {
			setIsLoadingAdditionalProduct(true);
		}
	}, [item]);

	// #Estados e funções do carrinho de compras
	const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);

	const handleSetIsBuyModalOpen = useCallback((boolean) => {
		setIsBuyModalOpen(boolean);
	}, []);

	// #Estado e funções do contador do produto individual
	const [addCounterProduct, setAddCounterProduct] = useState(1);

	const handleAddCountProduct = useCallback(() => {
		if (item.promotions.length) {
			for (const promotion of item.promotions) {
				if (addCounterProduct + 1 === promotion.qtdMinima) {
					setItem({ ...item, promotionValue: promotion.vlPreco });
					break;
				}
			}
		}
		setAddCounterProduct(addCounterProduct + 1);
	}, [addCounterProduct, item]);

	const handleSubCountProduct = useCallback(() => {
		if (item.promotions.length) {
			// Get the previous promotion
			const promotion = item.promotions
				.filter((promotion) => promotion.qtdMinima < addCounterProduct)
				.sort((a, b) => b.qtdMinima - a.qtdMinima)[0];

			if (promotion) {
				setItem({ ...item, promotionValue: promotion.vlPreco });
			} else {
				setItem({ ...item, promotionValue: null });
			}
		}

		addCounterProduct > 1
			? setAddCounterProduct(addCounterProduct - 1)
			: setAddCounterProduct(1);
	}, [addCounterProduct, item]);

	useEffect(() => {
		if (item.show === false) {
			setAddCounterProduct(1);
		}
	}, [item]);

	return (
		<MenuContext.Provider
			value={{
				additionalDatas,
				fetchAdditionalData,
				item,
				handleSetItem,
				isLoadingAdditionalProduct,
				isBuyModalOpen,
				handleSetIsBuyModalOpen,
				addCounterProduct,
				handleAddCountProduct,
				handleSubCountProduct,
				imageModal,
				handleSetImageModal,
				additionalPriceInProduct,
				addAdditionalPriceToProduct,
				setAdditionalDatas,
				setIsLoadingAdditionalProduct,
				productsWithGroups,
				setProductsWithGroups,
			}}
		>
			{children}
		</MenuContext.Provider>
	);
}

export function useMenu() {
	const context = useContext(MenuContext);

	if (!context) {
		throw new Error("useMenu must be used within an MenuProvider");
	}

	return context;
}
