import React, { useContext, createContext, useCallback, useState } from 'react'
import { useAuth } from './AuthContext'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { api } from '../services/api'


const RegisterContext = createContext();

export const RegisterProvider = ({ children }) => {
    const [updateAddress, setUpdateAddress] = useState(localStorage.getItem('@Inoveclube:updateAddress') ? JSON.parse(localStorage.getItem('@Inoveclube:updateAddress')) : { initialState: false, address: {} })
    const [missingToFillInput, setMissingToFillInput] = useState(false)
    const [modalAcceptOpen, setModalAcceptOpen] = useState(false)
    const history= useHistory()
    const { signOut } = useAuth()
    const customId = 100

    const handleModalAcceptOpen = useCallback(() =>{
        setModalAcceptOpen(!modalAcceptOpen)
    },[modalAcceptOpen, setModalAcceptOpen])

    const handleUpdateAddress = useCallback((object) => {
        setUpdateAddress(object)
    }, [])


    const handleFirstPageRegisterData = useCallback(async (object) => {
        try {
            const response = await api.post('/savePerson', object);
            const { token, message, success } = response.data;

            if (success === 0)
                throw new Error(message)

            localStorage.setItem('@Inoveclube:token', token)

           
            if(localStorage.getItem('@Inoveclube:onlyTableModality') || localStorage.getItem('@Inoveclube:type') === 'takeaway') {
                window.location.href=`${process.env.REACT_APP_URL}shopping`
                
                // localStorage.removeItem('@Inoveclube:onlyTableModality')

                return
            }
            localStorage.setItem('@Inoveclube:signUp', true) //salva no localStorage q ta cadastrando
            history.push('/registeraddress')  //puxa para a pagina de registrar
        } catch (error) {
            handleModalAcceptOpen()
            if(error.response){
                toast.error(error.response.data.message)
            }else{
                toast.error(error.message, { toastId: customId })
            } 
        }

    }, [history, handleModalAcceptOpen])

    const handleSecondPageRegisterData = useCallback(async (object) => {
        try {
            if (object.state === "" || object.city === "" || object.neighborhood === "" || object.street === "" || object.number === "" || object.zipCode === ""){
                setMissingToFillInput(true)
                throw new Error("Preencha os campos obrigatórios")
            }
            if (updateAddress.initialState === true) {
                await api.patch('/updateAddress', object,{
                    headers: {
                      'Content-Type': 'application/json',
                      token: localStorage.getItem('@Inoveclube:token')
                    }
                  })
                setUpdateAddress({ initialState: false, address: {} });
                localStorage.setItem('@Inoveclube:updateAddress', JSON.stringify({ initialState: false, address: {} }))
                toast.success("Endereço alterado com sucesso")
                if(localStorage.getItem('@Inoveclube:shopping')){
                    history.push('/shopping')
                }else{
                    history.push('/address')
                }
            }
            else {
                if ( localStorage.getItem('@Inoveclube:signUp') ) {
                    // console.log(localStorage.getItem('@Inoveclube:signUp'))
                    const response = await api.post('/saveAddress', object, {
                        headers: {
                          'Content-Type': 'application/json',
                          token: localStorage.getItem('@Inoveclube:token')
                        }
                      })
                    const { message, success } = response.data
                    if (success === 0) {
                        throw new Error(message);
                    }else if(localStorage.getItem('@Inoveclube:menu')){
                        localStorage.removeItem('@Inoveclube:signUp') 
                        window.location.href=`${process.env.REACT_APP_URL}shopping`
                    }else{
                        localStorage.removeItem('@Inoveclube:signUp')
                        window.location.href=`${process.env.REACT_APP_URL}profile`
                    }

                } else {
                    await api.post('/saveAddress', object, {
                        headers: {
                          'Content-Type': 'application/json',
                          token: localStorage.getItem('@Inoveclube:token')
                        }
                      })
                    toast.success("Endereço salvo com sucesso")
                    if(localStorage.getItem('@Inoveclube:shopping')){
                        history.push('/shopping')
                        localStorage.removeItem('@Inoveclube:shopping')
                    }else{
                        history.push('/address')
                    }
                }
            }
        } catch (error) {
            if(error.response){
                if(error.response.status === 401 || error.response.status === 500){
                    signOut()
                    toast.warning('Sua sessão foi expirada')
                }else{
                  toast.error(error.response.data.message, { toastId: customId })
                }
              }else{
                toast.error(error.message, { toastId: customId })
              }        
            
        }
    }, [history, updateAddress, signOut])


    return (
        <RegisterContext.Provider value={{ handleFirstPageRegisterData, handleSecondPageRegisterData, handleUpdateAddress, handleModalAcceptOpen, updateAddress, modalAcceptOpen, missingToFillInput }}>
            {children}
        </RegisterContext.Provider>
    );
}

export function useRegister() {
    const context = useContext(RegisterContext);

    if (!context) {
        throw new Error('useRegister must be used within an RegisterProvider');
    }

    return context;
}
