import styled from "styled-components"

export const Container = styled.div`
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const Content = styled.div`
    height: 38rem;
    width: 20rem;
    background: #FFF;
    border-radius: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #535354;

    .warningIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        color: #F6BE00;
    }

    p{
        text-align: center;
    }

    .listItens{
        overflow-y: auto;
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }

    .buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0.2rem;
        gap: 1rem;

        button{
            border: none;
            border-radius: 5px;
            padding: 0.5rem;
            flex: 1;
            color: #FFF;
        }

        .confirmButton{
            background: green;
        }

        .rejectButton{
            background: #cc0000;
        }
    }

    @media (max-width: 600px){
        height: 90%;
        width: 90%;
    }
`