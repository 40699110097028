import { Container } from "./style";
import { SpaceLoader } from "../../../../../components/loaders/spaceLoader";
import { api } from "../../../../../services/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export function ContactModal({ setIsContantModalOpen, backgroundColor, ownerEmail }) {
	const [loadiong, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
		ownerEmail: ownerEmail,
	});

	const sendEmail = async () => {
		setLoading(true);
		try {
			await api.post("/sendEmail", formData);
			toast.success("E-mail enviado com sucesso!");
			setIsContantModalOpen(false);
		} catch (error) {
			console.log(error);
			toast.error("Erro ao enviar e-mail!");
		} finally {
			setLoading(false);
		}
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		setFormData((prevFormData) => {
			return { ...prevFormData, ownerEmail: ownerEmail };
		});
	}, [ownerEmail]);

	return (
		<Container>
			<div
				className="overlay"
				onClick={() => {
					setIsContantModalOpen(false);
				}}
			/>
			<div className="modal">
				{loadiong ? (
					<div className="loading-wrapper">
						<SpaceLoader />
					</div>
				) : (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							sendEmail();
						}}
					>
						<legend>Entre em contato!</legend>
						<div>
							<label htmlFor="name">Nome</label>
							<input
								type="text"
								name="name"
								id="name"
								required
								value={formData.name}
								onChange={handleInputChange}
							/>
						</div>
						<div>
							<label htmlFor="email">E-mail</label>
							<input
								type="email"
								name="email"
								id="email"
								required
								value={formData.email}
								onChange={handleInputChange}
							/>
						</div>
						<div>
							<label htmlFor="message">Deixe sua mensagem!</label>
							<textarea
								name="message"
								id="message"
								rows={4}
								value={formData.message}
								onChange={handleInputChange}
							/>
						</div>
						<button
							style={{
								backgroundColor: backgroundColor
									? backgroundColor
									: "var(--gray-200)",
							}}
						>
							Enviar
						</button>
					</form>
				)}
			</div>
		</Container>
	);
}
