import { BiCurrentLocation } from 'react-icons/bi'
import { api } from '../../services/api'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { BsPlusCircleFill } from 'react-icons/bs'
import { FaEdit } from 'react-icons/fa'
import { useAuth } from '../../context/AuthContext'
import { useRegister } from '../../context/RegisterContext'

import { Container, Content, Header } from './style'


export function AddressShoppingModal({ listAddressPerson, setListAddressPerson, setAddressShoppingModalOpened, setChangeValue, changeValue, setAddressPersonSelected }){
    const { signOut } = useAuth()
    const history = useHistory()

    const { handleUpdateAddress } = useRegister();

    async function getAddressUpdated(){
        await api.get('/getAddressesPerson/' + localStorage.getItem('@Inoveclube:token'), {
            headers: {
                token: localStorage.getItem('@Inoveclube:token')
            }
        }).then(response => {
            setListAddressPerson(response.data)
        });
    }


    return(
        <Container>
            <Header>
                <AiOutlineArrowLeft onClick={() => { setAddressShoppingModalOpened(false) }}/>
                <h1>Endereço de entrega</h1>
            </Header>

            <div className="scrollingArea">
                <Content>
                    {
                    listAddressPerson.length > 0 ?
                        listAddressPerson.map(address =>(
                            address.selected ?
                            <div 
                                key={address.idAddress} 
                                className="cardAddress selected" 
                            >
                                <div onClick={() => { setAddressShoppingModalOpened(false) }} className="textAddress">

                                    <BiCurrentLocation />
                                    <div >
                                        <span>{address.neighborhood}</span>
                                        <p>{address.street}, {address.number}, {address.city}-{address.state}</p>
                                    </div>
                                </div>

                                {
                                    localStorage.getItem('@Inoveclube:wholesale') === 'false' && 
                                    <div className="editAddress" onClick={async () => {
                                            await handleUpdateAddress({initialState: true, address: address }) 
                                            localStorage.setItem('@Inoveclube:updateAddress', JSON.stringify({initialState: true, address: address }) )
                                            localStorage.setItem('@Inoveclube:shopping', true)
                                            history.push('/registeraddress')
                                        }}>
                                        <FaEdit />
                                    </div>  
                                }

                            </div>
                            :
                            <div 
                                key={address.idAddress}
                                className="cardAddress" 
                            >
                           <div className="textAddress"   onClick={ async () => {
                                    if(changeValue > 0){
                                        setChangeValue(0)
                                        toast.warning("Selecione novamente o troco")
                                    }
                                    let object = {
                                        token: localStorage.getItem('@Inoveclube:token'),
                                        idAddress: address.idAddress,
                                        street: address.street,
                                        number: address.number,
                                        complement: address.complement,
                                        zipCode: address.zipCode,
                                        reference: address.reference,
                                        neighborhood: address.neighborhood,
                                        idCity: address.idCity,
                                        selected: true,
                                    }
                                    try{
                                        await api.patch('/updateAddress', object,{
                                            headers: {
                                                'Content-Type': 'application/json',
                                                token: localStorage.getItem('@Inoveclube:token')
                                            }
                                        })
                                        getAddressUpdated()
                                        setAddressPersonSelected(address)
                                        setAddressShoppingModalOpened(false)
                                    }catch(error){
                                        if(error.response.status === 401 || error.response.status === 500){
                                            signOut()
                                            toast.warning('Sua sessão foi expirada')
                                        }else{
                                            toast.error(error.response.data.message)
                                        }
                                    }
                                

                                }
                            }>

                                <BiCurrentLocation />
                                <div >
                                    <span>{address.neighborhood}</span>
                                    <p>{address.street}, {address.number}, {address.city}-{address.state}</p>
                                </div>
                            </div>
                            {
                                localStorage.getItem('@Inoveclube:wholesale') === 'false' && 
                                <div className="editAddress" onClick={async () => {
                                            await handleUpdateAddress({initialState: true, address: address }) 
                                            localStorage.setItem('@Inoveclube:updateAddress', JSON.stringify({initialState: true, address: address }) )
                                            localStorage.setItem('@Inoveclube:shopping', true)
                                            history.push('/registeraddress')
                                    }}>
                                    <FaEdit />
                                </div>  
                            }

                        </div>
                    ))
                    :<></>
                }
                    
                    {
                        localStorage.getItem('@Inoveclube:wholesale') === 'false' && 
                        <div className="buttonContainer">
                            <BsPlusCircleFill onClick={() => {
                                localStorage.setItem('@Inoveclube:shopping', true)
                                localStorage.removeItem('@Inoveclube:updateAddress')
                                history.push('/registeraddress')
                            }}/>
                        </div>
                    }

                    <div className="fill"></div>
                </Content>
            </div>
        </Container>
    )
}