import { useState } from 'react'
import { FaLock, FaAngleLeft } from 'react-icons/fa'
import { InputGeneric } from '../../../Forms/InputGeneric'
import { api } from '../../../../services/api'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { Button } from '../../../Button'

import { Container } from './style'


export function EditPasswordForm({ handleChangePasswordFormVisible }) {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassowrd] = useState('')

    const history = useHistory()

    async function onSubmit () {
        let obj = {
            password: newPassword,
            oldPassword: currentPassword
        }

        try{
            if(newPassword !== newPassword.replace(/[^a-zA-Z0-9 ]/g, ""))
                throw new Error("Não são permitidos caracteres especiais")
            if(currentPassword === '' || confirmNewPassword === '' || newPassword === '')
                throw new Error("Preencha todos os campos");
            if(newPassword !== confirmNewPassword)
                throw new Error("As senhas não coincidem")

            const response = await api.patch('/updatePasswordPerson', obj, {
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem('@Inoveclube:token')
                }
            })
            const { message, success } = response.data
            
            if(success === 0){
                throw new Error(message)
            }else{
                toast.success(message)
                history.push('/profile')
            }        

        }catch(error){
            toast.error('Algo deu errado, tente novamente')
        }
    }

    
    return (
        <Container>
            <div className='utilArea'>
           
                <form onSubmit={onSubmit}>
                        <InputGeneric 
                            value={currentPassword}
                            onChange={e => setCurrentPassword(e.target.value)}
                            visibility={true}
                            type="password"
                            icon={<FaLock />}
                            placeholder="Senha atual"
                        />

                        <InputGeneric 
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            visibility={true}
                            type="password"
                            icon={<FaLock />}
                            placeholder="Nova senha"
                        />

                        <InputGeneric 
                            value={confirmNewPassword}
                            onChange={e => setConfirmNewPassowrd(e.target.value)}
                            visibility={true}
                            type="password"
                            icon={<FaLock />}
                            placeholder="Confirme nova senha"
                        />
                        
                        <strong onClick={handleChangePasswordFormVisible}>
                            <FaAngleLeft /> Voltar
                        </strong>

                        <Button type="submit">
                            ALTERAR
                        </Button>
                </form>

            </div>
        </Container>
    )
}
