import styled, { css } from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    display: none;

    ${props => props.open && css`
        display: flex;
    `}

    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
`

export const Content = styled.div`
    height: 90%;
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;

    background: #FFF;
    border-radius: 10px;
    overflow-y: auto;

    color: ${props => props.color ? props.color : ''};
    font-weight: 700;
    font-size: 1.2rem;

    @media (max-width: 600px) {
        height: 100%;
        border-radius: 0;
    }


    h4{
        text-align: center;
        margin: 2.5rem 0 1rem 0;
        font-size: 1.4rem;
    }

    span{
        position: absolute;
        top: 5px;
        right: 5px;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 40px;
        width: 40px;
        color: rgb(135, 134, 139);
        font-size: 1.3rem;
        border-radius: 5px;
        transition: all 0.3s ease 0s;

        &:hover{
            background: rgb(200, 200, 200);
            color: rgb(255, 255, 255);
            cursor: pointer;
        }
    }

    .socialMedias{
        position: absolute;
        left: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        font-size: 1.5rem;
        margin-top: 1.5rem;
    }
`

export const ButtonsNavigate = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;

    button{
        background: transparent;
        border: 0;
        font-size: 1rem;
        cursor: pointer;
        color: ${props => props.color ? props.color : ''};
    }

    .active{
        font-weight: bold;
    }
`;

