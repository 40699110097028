import styled from "styled-components";

export const Container = styled.div`
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`;

export const Logo = styled.div`
    height: 3.5rem;
    width: 3.5rem;
    background-image: url(${localStorage.getItem('@Inoveclube:imageBusiness') ? localStorage.getItem('@Inoveclube:imageBusiness') : "https://s3.sa-east-1.amazonaws.com/www.inoveclube.com/public/logo.png"});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 4px;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    border-radius: 0.3rem;

    @media (max-height: 654px){
        position: initial;
    }
`

export const InputContainer = styled.div`
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 65%;

    @media (max-width: 786px){
        height: 85%;
    }
    @media (max-height: 654px){
        height: 85%;
    }
   
`

export const InputLog = styled.div`
    width: 75%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
    margin-bottom: 10px;
    padding: 12px;
    border: 1.5px solid ${props => props.alertUserToFillInput ? 'red' : 'var(--colorBorderInput)'};
    border-radius: 0.25rem;

    .visibility{

        @media (min-width: 960px){
            cursor: pointer;
        }
    }

    .visibilityOff{
        color: var(--colorButton);
        
        @media (min-width: 960px){
            cursor: pointer;
        }
    }

    input{
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        color: black;
        border: none;
    }
    svg{
        margin-right: 5px;
        color: gray;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .logButton{
        height: 50px;
        width: 150px;
        background-color: var(--colorButton);
        color: var(--colorButtonBottom);
        margin-bottom: 15px;
        font-weight: 500;
        box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);

    }


`;