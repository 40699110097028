import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9910 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);

	.flex {
		display: flex;
		gap: 1rem;
	}
`;

export const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9911;
`;

export const Modal = styled.div`
	position: fixed;
	z-index: 9912;
	background-color: var(--gray-100);
	border-radius: 8px;
	padding: 1rem;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-height: 80%;
	max-width: 100%;
	position: relative;
	animation: modal 0.2s linear;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@keyframes modal {
		from {
			opacity: 0;
			transform: translateY(50px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@media (min-width: 640px) {
		max-width: 90%;
	}

	@media (min-width: 1024px) {
		max-width: 80%;
	}
`;

export const Title = styled.h1`
	text-align: center;
	font-size: 1.5rem;
	margin: 0 2rem;
`;

export const DialogHeader = styled.div`
`;

export const DialogBody = styled.div`
	flex: 1;
	overflow: auto;
	// Scrollbar styles
	scrollbar-width: auto;
	scrollbar-color: var(--gray-100);
	scrollbar-track-color: var(--gray-200);
	&::-webkit-scrollbar-track {
		background-color: var(--gray-50);
	}
	&::-webkit-scrollbar {
		width: 5px;
		height: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: var(--gray-300);
		border-radius: 4px;
	}
`;

export const DialogFooter = styled.div`
`;
