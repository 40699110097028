import styled from "styled-components";

export const Container = styled.div`
    flex: auto;
    font-size: 1.5rem;
    font-weight: 900;
    color: #393939;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg{
        margin-top: 1rem;
        font-size: 2rem;
    }
`