import styled,{keyframes, css} from "styled-components";

const animationOpenModal = keyframes`
    from{
        transform: translateY(200%);
    }
    to{
        transform: translateY(0%);
    }
`;

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.6s;

    animation: ${animationOpenModal} ease-in-out 0.6s;

    ${props => !props.active && css`
        transform: translateY(200%);
    `}
`;

export const Content = styled.div`
    width: 95%;
    max-width: 300px;
    background-color: #fff;
    box-shadow: 0px 40px 40px -20px rgba(0,0,0,0.25);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    transition: all 0.5s;
    animation: ${animationOpenModal} ease-in-out 0.5s;

    ${props => !props.active && css`
        transform: translateY(200%);
    `}

    ${props => props.colorBg && css`
        background-color: ${props.colorBg};
    `}
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 130px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Main = styled.div`
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #fff;

    .header{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        span{
            color: #696969;
            margin-top: 0.5rem;
        }
    }

    strong{
        font-size: 1.3rem;
        color: #696969;
    }

    .button{
        background-color: ${props => props.colorBg};
        color: ${props => props.colorFont};
        width: 100%;
        max-width: 200px;
        height: 30px;
        margin-top: 1rem;
        font-size: 1.08rem;
        border: none;
        border-radius: 4px;
    }
`;

export const ContainerRules = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .details{
        display: flex;
        flex-direction: column;
        align-items: center;

        span{
            text-align: center;
            color: #696969;
            margin-top: 0.3rem;
        }

        .obs{
            text-align: justify;
            font-size: 0.9rem;
            margin-top: 0.5rem;
        }
        
        button{
            background: none;
            border: none;
            max-width: 100px;
            font-weight: bold;
            margin-top: 1rem;
            color: ${props => props.colorFont};
            display: flex;
            align-items: center;

            svg{
                transition: all 0.3s;

                ${props => props.openRules && css`
                    transform: rotate(-90deg);
                `}
            }
        }
    }

    .rules{
        width: 90%;
        max-height: 0px;
        border-radius: 4px;
        margin: 0.8rem 0;
        overflow: auto;
        transition: all 0.3s;

        textarea{
            width: 100%;
            height: 100%;
            border: none;
            resize: none;
            padding: 0.5rem 1rem;
            background: none;
            white-space: pre-wrap;
            
            &::-webkit-scrollbar{
                display: none;
            }

            &:disabled{
                cursor: auto;
                opacity: 1;
                color: ${props => props.colorFont};
            }
        }

        ${props => props.openRules && css`
            border: 1px solid #ddd;
            max-height: 150px;
        `}

        &::-webkit-scrollbar{
            display: none;
        }
    }
`;

