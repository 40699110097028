import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`