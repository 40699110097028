import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr, 2fr;
    overflow: hidden;

    button{
        border: none;
        border-radius: 5px;
    }

    h1{
        margin-bottom: 2px;
    }

    @media (max-width: 800px){
        grid-template-columns: 1fr;
        grid-template-rows: 2fr;
        height: 100vh;
        width: 100vw;
    }

    @media (max-height: 654px){
        grid-template-columns: 1fr;
        grid-template-rows: 2fr;
        height: 100%;
        width: 100%;
    }
`