import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: 5rem;
	background-color: var(--gray-300);
	position: fixed;
	z-index: 20;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	.content {
		max-width: 1200px;
		margin: 0 auto;
		height: 100%;
		padding: 0 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			width: 4rem;
			border-radius: 8px;
		}
		nav {
			.menu-icon {
				font-size: 2rem;
			}
			ul {
				position: fixed;
				top: 5rem;
				left: 0;
				width: 100%;
				padding: 1rem;
				list-style: none;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				overflow: hidden;
				li {
					width: 100%;
					font-weight: 500;
					a {
						display: block;
						padding: 1rem;
						width: 100%;
						height: 100%;
					}
				}
				.modal-link {
					cursor: pointer;
					padding: 0.5rem 1rem;
					text-align: center;
					border-radius: 4px;
					filter: brightness(1.5);
				}
				.social-link {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
				&.open {
					transform: translateX(0);
					transition: transform 0.3s ease-in-out;
				}
				&.close {
					transform: translateX(+100%);
					transition: transform 0.3s ease-in-out;
				}
			}
		}
	}

	@media (min-width: 768px) {
		.content {
			nav {
				flex: 1;
				.menu-icon {
					display: none;
				}
				ul {
					position: static;
					padding: 0;
					display: flex;
					flex-direction: row;
					gap: 0.5rem;
					opacity: 1;
					align-items: center;
					justify-content: flex-end;
					li {
						width: auto;
						padding: 0;
						transition: opacity 0.15s ease-out;
					}
					.modal-link {
						font-size: 0.9rem;
					}
					li:hover {
						opacity: 0.75;
					}
					li:active {
						opacity: 0.5;
					}
					&.open {
						transform: translateX(0);
					}
					&.close {
						transform: translateX(0);
					}
				}
			}
		}
	}

	@media (min-width: 768px) {
		.content {
			padding: 0 2rem;
		}
	}
`;
