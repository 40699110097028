import './style.css'

export function GeneralLoader(){
    return(      
     
            <div className="loader-container-general">
                <div className="loader-general">
                </div>
            </div>
      
       
    )
}

