import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
`

export const Content = styled.div`
    height: 20rem;
    width: 20rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: #FFF;
    position: relative;

    .changeValueContainer{
        text-align: center;
        p{
            font-size: 0.8rem;
            color: gray;

            &:nth-child(2){
                color: #006400;
            }
        }

    }

    .containerButtons{
        display: flex;
        align-items: center;
        color:  #FFF;
        .confirmChangeButton{
            background: #006400;
            margin-right: 20px;
        }

        .deniedChangeButton{
            background: #c00;
        }

    }


    p{
        font-size: 1.5rem;
    }

    button{
        border: none;
        padding: 20px 40px 20px 40px;
        background: var(--colorButton);
        color: var(--colorButtonBottom);
        border-radius: 0.3rem;
    }

    input{
        padding: 10px;
        color: gray;
        
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance: textfield;
        }
    }


    span{
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        font-size: 1.2rem;
    }
`