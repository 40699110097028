import { useState } from 'react';
import { Container, Content } from './style'

export function ModalTextAdditional({ setIsModalTextAdditionalOpen, bgcolor, handleSetTextAdditional, textAdditional }) {
    const [modalTextAdditional, setModalTextAdditional] = useState(textAdditional !== '' ? textAdditional : '');

    return(
        <Container>
            <Content bgcolor={bgcolor}> 
                <span onClick={() => {
                    setIsModalTextAdditionalOpen(false);
                }}>&#10005;</span>
                <p>Observações Adicionais</p>
                <div className="container-input">
                    <input 
                        type="text" 
                        placeholder="digite aqui" 
                        maxLength={100}
                        value={modalTextAdditional}
                        autoFocus
                        onChange={event => setModalTextAdditional(event.target.value)}
                    />

                    <button onClick={() => {
                        handleSetTextAdditional(modalTextAdditional)
                        setIsModalTextAdditionalOpen(false)
                    }}>Ok</button>
                </div>
            </Content>
        </Container>
    )
}