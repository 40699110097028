import styled from "styled-components";

export const Container = styled.div` 
    h1{
        margin-bottom: 2px;
    }

    .containerCards {
        max-width: 800px;
        margin: 10px auto;

        & + .containerCards {
            margin-top: 5rem;
        }
    }

    .logButton{
        position: fixed;
        bottom: ${props => props.hasCard ? 0 : 40+'%'};
        background: #FFF;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        
        /* svg{
            min-height: 3rem;
            font-size: 3rem;
            color: var(--colorButton);
            cursor: pointer;
            margin-bottom: 5px;
        } */

        button{
            margin-bottom: 5px;
            min-height: 2rem;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            background-color: var(--colorButton);
            color: #fff;
            border: 0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
`

export const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`