import { api } from "../../../../services/api";

export async function postVault({ company, vaultId, amount, token }) {
  try {
    await api.post(`/cofre/${company}`, {
      token,
      vaultId,
      amount,
    });
  } catch (error) {
    throw error;
  }
}
