import { useRef, useEffect, useMemo } from "react";
import { useOrderNotification } from "../../services/hooks/useOrderNotification";
import { useHistory } from "react-router-dom";

import {
	FaTrashAlt,
	FaSearch,
	FaShoppingCart,
	FaHome,
	FaUserAlt,
	// FaInfo,
	FaClipboardList,
	FaMotorcycle,
	FaAngleDown,
} from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import { useMenu } from "../../context/MenuContext";
import { formatMoney } from "../../Utils/formatMoney";

import { Content, Container, Search, MenuBar } from "./style";

import moment from "moment";

export function Header({
	searchTerm,
	businessDatas,
	handleSearchChange,
	handleClearSearch,
	listBuying,
	setInfoOpen,
	feeDelivery,
}) {
	const { data, isLoading } = useOrderNotification();

	const { handleSetIsBuyModalOpen } = useMenu();
	const inputRef = useRef(null);

	const history = useHistory();

	useEffect(() => {
		if (searchTerm) {
			inputRef.current.focus();
		}
	}, [searchTerm]);

	const totalOrdersWaitingPayment = useMemo(() => {
		if (isLoading || !data) {
			return 0;
		}

		const firstOrderWaitingPayment =
			data.lastOrder && data.lastOrder.status === "Waiting Payment"
				? data.lastOrder
				: null;
		const othersOrdersWaitingPayment =
			(data.genericDatas &&
				data.genericDatas.filter((item) => item.status === "Waiting Payment")) ||
			[];

		const arr = firstOrderWaitingPayment
			? [firstOrderWaitingPayment, ...othersOrdersWaitingPayment]
			: [...othersOrdersWaitingPayment];

		return arr.length;
	}, [data, isLoading]);

	function verifyOpenTime(data) {
		if (data === 1) return businessDatas.openingTimeMon;
		if (data === 2) return businessDatas.openingTimeTue;
		if (data === 3) return businessDatas.openingTimeWed;
		if (data === 4) return businessDatas.openingTimeThu;
		if (data === 5) return businessDatas.openingTimeFri;
		if (data === 6) return businessDatas.openingTimeSat;
		if (data === 7) return businessDatas.openingTimeSun;
	}

	function checkNearestTime(data1, data2) {
		const dateNow = new Date(businessDatas.currentTime);

		const hour1 = data1.split(":");
		const hour2 = data2.split(":");

		const newDate1 = new Date(
			dateNow.getFullYear(),
			dateNow.getMonth(),
			dateNow.getDate(),
			hour1[0],
			hour1[1]
		);
		const newDate2 = new Date(
			dateNow.getFullYear(),
			dateNow.getMonth(),
			dateNow.getDate(),
			hour2[0],
			hour2[1]
		);

		if (dateNow < newDate1) {
			return data1;
		} else if (dateNow > newDate1 && dateNow > newDate2 && newDate1 > newDate2) {
			return data2;
		} else if (dateNow > newDate1 && dateNow > newDate2) {
			return data1;
		} else {
			return data2;
		}
	}

	function getDayWeek(data) {
		if (data === 1)
			return businessDatas.openingTimeMon2 === businessDatas.closingTimeMon2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeMon,
						businessDatas.openingTimeMon2
				  );
		if (data === 2)
			return businessDatas.openingTimeTue2 === businessDatas.closingTimeTue2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeTue,
						businessDatas.openingTimeTue2
				  );
		if (data === 3)
			return businessDatas.openingTimeWed2 === businessDatas.closingTimeWed2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeWed,
						businessDatas.openingTimeWed2
				  );
		if (data === 4)
			return businessDatas.openingTimeThu2 === businessDatas.closingTimeThu2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeThu,
						businessDatas.openingTimeThu2
				  );
		if (data === 5)
			return businessDatas.openingTimeFri2 === businessDatas.closingTimeFri2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeFri,
						businessDatas.openingTimeFri2
				  );
		if (data === 6)
			return businessDatas.openingTimeSat2 === businessDatas.closingTimeSat2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeSat,
						businessDatas.openingTimeSat2
				  );
		if (data === 7)
			return businessDatas.openingTimeSun2 === businessDatas.closingTimeSun2
				? verifyOpenTime(data)
				: checkNearestTime(
						businessDatas.openingTimeSun,
						businessDatas.openingTimeSun2
				  );
	}

	function isCurrentlyClosed(day, data) {
		if (day === 1)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeMon &&
				data <= businessDatas.closingTimeMon
			);
		if (day === 2)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeTue &&
				data <= businessDatas.closingTimeTue
			);
		if (day === 3)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeWed &&
				data <= businessDatas.closingTimeWed
			);
		if (day === 4)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeThu &&
				data <= businessDatas.closingTimeThu
			);
		if (day === 5)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeFri &&
				data <= businessDatas.closingTimeFri
			);
		if (day === 6)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeSat &&
				data <= businessDatas.closingTimeSat
			);
		if (day === 7)
			return (
				!businessDatas.isOpenBusiness &&
				data >= businessDatas.openingTimeSun &&
				data <= businessDatas.closingTimeSun
			);
	}

	const deliveryFee = feeDelivery > 0 ? `${formatMoney(feeDelivery)}` : `Grátis`;

	return (
		<>
			<Container>
				<Content
					logoImage={businessDatas.linkProfile}
					colorBg={businessDatas.colorBgHeader}
					colorFont={businessDatas.colorLabel}
				>
					<header>
						<div className="logo"></div>

						<div className="informations">
							<div className="address">
								<h1>{businessDatas.name}</h1>
								<p>
									{businessDatas.street}, {businessDatas.number},{" "}
									{businessDatas.city}-{businessDatas.state}
								</p>
								{/* <p>{businessDatas.city}-{businessDatas.state}</p>    */}
								{businessDatas.minValue > 0 ? (
									<p>
										Pedido mínimo{" "}
										{formatMoney(businessDatas.minValue)}
									</p>
								) : (
									<></>
								)}
								<p className="additional">
									{businessDatas.dadosAdicionais}
								</p>
							</div>

							<div className="opened">
								{businessDatas.isOpenBusiness ? (
									<div className="open">
										<div />
										<h4>{businessDatas.businessHours}h</h4>
									</div>
								) : (
									<div className="closed">
										<div />
										<h4>
											{businessDatas.notOpenToday
												? "Hoje não abre"
												: isCurrentlyClosed(
														moment().isoWeekday(),
														moment().format("HH:mm")
												  )
												? "Fechado no momento"
												: `Abrirá às ${
														businessDatas.habilitaFuncionamentoDoisTurnos
															? getDayWeek(
																	moment().isoWeekday()
															  )
															: verifyOpenTime(
																	moment().isoWeekday()
															  )
												  }`}
										</h4>
									</div>
								)}

								<p
									className="businessHours"
									onClick={() => setInfoOpen(true)}
								></p>

								<p
									onClick={() => setInfoOpen(true)}
									className="deliveryTime"
								>
									<MdWatchLater /> {businessDatas.minTimeDelivery} a{" "}
									{businessDatas.maxTimeDelivery}min
								</p>

								{feeDelivery !== null &&
									feeDelivery !== undefined &&
									feeDelivery !== "" && (
										<p className="deliveryFee">
											<FaMotorcycle />
											{deliveryFee}
										</p>
									)}
								<button
									className="button"
									onClick={() => setInfoOpen(true)}
								>
									<FaAngleDown /> Ver mais
								</button>
							</div>
						</div>
					</header>

					<Search>
						<input
							type="search"
							placeholder="Buscar produto"
							value={searchTerm}
							onChange={handleSearchChange}
							ref={inputRef}
						/>

						{searchTerm.length !== "" && searchTerm.trim() !== "" ? (
							<FaTrashAlt onClick={handleClearSearch} />
						) : (
							<FaSearch />
						)}
					</Search>
				</Content>
			</Container>

			{businessDatas.useOnlineOrder && (
				<MenuBar
					colorBg={businessDatas.colorBgBottom}
					colorFont={businessDatas.colorButtonBottom}
				>
					<FaHome />

					<div
						className="item-bar"
						onClick={() => {
							handleSetIsBuyModalOpen(true);
						}}
					>
						{listBuying !== undefined ? (
							<div className="items-shopping">{listBuying.length}</div>
						) : (
							<></>
						)}

						<FaShoppingCart />
					</div>

					<div className="item-bar" onClick={() => history.push("/orders")}>
						{totalOrdersWaitingPayment > 0 && (
							<div className="items-shopping">
								{totalOrdersWaitingPayment}
							</div>
						)}
						<FaClipboardList />
					</div>

					<FaUserAlt
						onClick={() =>
							localStorage.getItem("@Inoveclube:token")
								? (window.location.href = `${process.env.REACT_APP_URL}profile`)
								: (window.location.href = `${process.env.REACT_APP_URL}login`)
						}
					/>
				</MenuBar>
			)}
		</>
	);
}
