import styled from "styled-components";

export const Container = styled.div`
	padding: 1.5rem 1rem;
	display: flex;
	align-items: center;
	transition: border 0.2s;
	border-radius: 4px;
	width: 18rem;
	cursor: pointer;

	@media (max-width: 600px) {
		width: 100%;
	}

	&:hover {
		background: var(--brand-red-100);
	}

	.green {
		color: green;
	}

	.image {
		height: 4rem;
		width: 4rem;
		min-width: 4rem;
		min-height: 4rem;
		border-radius: 50%;
		overflow: hidden;
		border: 1px solid rgba(125, 125, 125, 0.1);
		position: relative;
		margin-right: 1rem;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}

		div {
			position: absolute;
			top: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.4);
			height: 100%;
			width: 100%;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-weight: 500;
		}
	}

	.text {
		flex: auto;
		overflow: hidden;

		.name {
			font-weight: 500;
			font-size: 0.75rem;
			color: #717171;
			margin-bottom: 0.3rem;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
			white-space: nowrap;
		}

		.sector {
			font-size: 0.65rem;
			color: #717171;
			margin-bottom: 0.5rem;
		}

		span {
			font-size: 0.7rem;
			color: #717171;
			font-weight: 500;
		}
		div {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
	}
`;
