import { useEffect, useState } from "react";
import { Container } from "./style";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

export function Navbar({ header, setIsContantModalOpen, setOwnerEmail }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [redirectLinks, setRedirectLinks] = useState([]);
	const [modalLinks, setModalLinks] = useState([]);
	const [socialLinks, setSocialLinks] = useState([]);

	useEffect(() => {
		setRedirectLinks(header.links.filter((link) => link.type === "external"));
		setModalLinks(header.links.filter((link) => link.type === "contact"));
		setSocialLinks(header.links.filter((link) => link.type === "social"));
	}, [header.links]);

	return (
		<Container
			style={{
				backgroundColor: `${
					header.backgroundColor ? header.backgroundColor : "var(--gray-500)"
				}`,
			}}
		>
			<div className="content">
				{header.logo && <img className="logo" src={header.logo} alt="" />}
				<nav>
					{isMenuOpen ? (
						<AiOutlineClose
							className="menu-icon"
							style={{
								color: header.textColor
									? header.textColor
									: "var(--gray-300)",
							}}
							onClick={() => {
								setIsMenuOpen(false);
							}}
						/>
					) : (
						<AiOutlineMenu
							className="menu-icon"
							style={{
								color: header.textColor
									? header.textColor
									: "var(--gray-300)",
							}}
							onClick={() => {
								setIsMenuOpen(true);
							}}
						/>
					)}
					<ul
						className={`${isMenuOpen ? "open" : "close"}`}
						style={{
							backgroundColor: `${
								header.backgroundColor
									? header.backgroundColor
									: "var(--gray-500)"
							}`,
						}}
					>
						{redirectLinks.map((link, idx) => {
							return (
								<li
									key={idx}
									style={{
										color: header.textColor
											? header.textColor
											: "var(--gray-300)",
									}}
								>
									<a target="_blank" rel="noreferrer" href={link.url}>
										{link.title}
									</a>
								</li>
							);
						})}
						{socialLinks.map((link, idx) => {
							return (
								<li
									key={idx}
									style={{
										color: header.textColor
											? header.textColor
											: "var(--gray-300)",
									}}
								>
									<a
										target="_blank"
										rel="noreferrer"
										href={link.url}
										className="social-link"
									>
										{link.title}
									</a>
								</li>
							);
						})}
						{modalLinks.map((link, idx) => {
							return (
								<li
									key={idx}
									className="modal-link"
									style={{
										color: header.textColor
											? header.textColor
											: "var(--gray-300)",
										backgroundColor: `${
											header.backgroundColor
												? header.backgroundColor
												: "var(--gray-500)"
										}`,
									}}
									onClick={() => {
										setOwnerEmail(link.url);
										setIsContantModalOpen(true);
									}}
								>
									{link.title}
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		</Container>
	);
}
