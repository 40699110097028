import { api } from "../services/api";

/**
 * Obtém informações sobre um cupom com base no nome da empresa e descrição
 * Funções responsáveis em validar os cupons
 * @param {string} nameBusiness -> nome da empresa
 * @param {string} description -> descrição do cupom
*/

async function getCoupon(nameBusiness, description){
    try {
        const response = await api.get(`/getCouponInfoWithDescription/${nameBusiness}/${description}`);
        return response.data;
    } 
    catch (error) {
        return undefined;    
    }
}

export {getCoupon};