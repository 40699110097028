import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatMoney } from "../../../Utils/formatMoney";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CartBuyModal } from "../CartBuyModal";
import { Additional } from "./Additional";
import { AssemblyModal } from "./assemblyModal";
import { GeneralLoader } from "../../../components/loaders/generalLoader";
import { useMenu } from "../../../context/MenuContext";
import { useModalItemManager } from "../../ItemModalContext";
import { ModalTextAdditional } from "./modalTextAdditional";
import { toast } from "react-toastify";
import {
	AiOutlineArrowLeft,
	AiOutlinePlusCircle,
	AiOutlineMinusCircle,
} from "react-icons/ai";

import {
	Container,
	Header,
	Additionals,
	ContainerSizes,
	ButtonSize,
	ContainerToAssemble,
	ButtonAssemblyItem,
} from "./style";

import { saveLogClube } from "../../../services/logClube";

export function ItemModal({ businessDatas, listBuying, handleSetListBuying }) {
	const {
		additionalDatas,
		handleSetItem,
		item,
		handleAddCountProduct,
		handleSubCountProduct,
		addCounterProduct,
		handleSetIsBuyModalOpen,
		isLoadingAdditionalProduct,
	} = useMenu();

	const {
		verifyConditionsADatas,
		addButtonEnabled,
		selectedOptions,
		handleSetSelectedOptions,
		handleSetSizeButtonSelected,
		listAssemblyItensSelected,
		sizeButtonSelected,
		handleSetListAssemblyItensSelected,
		assembledItensFilled,
		textAdditional,
		handleSetTextAdditional,
	} = useModalItemManager();

	const [assemblyModalOpened, setAssemblyModalOpened] = useState(false);
	const [assemblyListItemSelected, setAssemblyListItemSelected] = useState(0);
	const [isModalTextAdditionalOpen, setIsModalTextAdditionalOpen] = useState(false);

	const handleSetAssembledItens = useCallback(
		(obj) => {
			const arrayItens = [...listAssemblyItensSelected];
			arrayItens[assemblyListItemSelected] = obj;

			handleSetListAssemblyItensSelected(arrayItens);
		},
		[
			listAssemblyItensSelected,
			assemblyListItemSelected,
			handleSetListAssemblyItensSelected,
		],
	);

	useEffect(() => {
		verifyConditionsADatas();
	}, [verifyConditionsADatas]);

	useEffect(() => {
		if (item.show === false) setIsModalTextAdditionalOpen(false);
	}, [item]);

	const containerRef = useRef(null);
	const [blinkingObligate, setBlinkingObligate] = useState(false);

	const listAdditionalRefs = useMemo(() => {
		return [];
	}, []);

	function handleGoToObligateAdditionals() {
		setBlinkingObligate(true);
		containerRef.current.scrollTo({
			top: listAdditionalRefs[0] - 100,
			behavior: "smooth",
		});
	}

	useEffect(() => {
		if (blinkingObligate)
			setTimeout(() => {
				setBlinkingObligate(false);
			}, 3000);
	}, [blinkingObligate]);

	useEffect(() => {
		if (item.show === false)
			while (listAdditionalRefs.length) {
				listAdditionalRefs.pop();
			}
	}, [item, listAdditionalRefs]);

	return (
		<>
			<CartBuyModal
				listBuying={listBuying}
				handleSetListBuying={handleSetListBuying}
				businessDatas={businessDatas}
			/>
			{item.show ? (
				<Container
					item={item.src}
					bgcolor={businessDatas.colorButton}
					addButtonEnabled={addButtonEnabled}
					colorLabel={businessDatas.colorLabel}
				>
					<Header>
						<AiOutlineArrowLeft
							onClick={() => {
								handleSetSizeButtonSelected(0);
								handleSetTextAdditional("");
								handleSetSelectedOptions([]);
								handleSetListAssemblyItensSelected([]);
								handleSetItem({ code: 0, show: false });
							}}
						/>
						<h1>Detalhes do item</h1>
					</Header>
					<div className="scrollingArea" ref={containerRef}>
						<div className="item-container">
							<div className="image" />
							<div className="text">
								<h3>{item.name}</h3>
								<span>
									<span style={{ textDecoration: "line-through", marginRight: "10px" }}>
										{item.promotionValue &&
											formatMoney(item.cost, businessDatas.formatoMoeda)}
									</span>
									{formatMoney(
										item.promotionValue ?? item.cost,
										businessDatas.formatoMoeda,
									)}
								</span>
							</div>

							{businessDatas.useOnlineOrder && (
								<div className="num-counter">
									<AiOutlineMinusCircle
										onClick={() => {
											handleSubCountProduct();
										}}
									/>
									<span>{addCounterProduct}</span>
									<AiOutlinePlusCircle
										onClick={() => {
											const qtdMax = item.qtdMax ? item.qtdMax : 0;

											if (addCounterProduct < qtdMax) handleAddCountProduct();
										}}
									/>
								</div>
							)}
						</div>

						{item.assembled ? (
							<>
								<div className="line-additionals">Selecione o tamanho</div>
								<ContainerSizes>
									{item.sizes.map((size, index) => (
										<ButtonSize
											key={size.idPackSize}
											bgcolor={businessDatas.colorButton}
											selected={sizeButtonSelected === index}
											onClick={() => handleSetSizeButtonSelected(index)}
										>
											{size.description}
										</ButtonSize>
									))}
								</ContainerSizes>
								<div className="line-additionals">Monte seu produto</div>
								<ContainerToAssemble>
									{businessDatas.typeCalcAssembledProduct === "ADD" ? (
										<span>O valor do produto é a soma dos itens</span>
									) : businessDatas.typeCalcAssembledProduct === "EXPENSIVE" ? (
										<span>O valor do produto é o item mais caro</span>
									) : businessDatas.typeCalcAssembledProduct === "AVG" ? (
										<span>O valor do produto é a média dos itens</span>
									) : (
										<></>
									)}
									{listAssemblyItensSelected.map((obj, index) => (
										<ButtonAssemblyItem
											key={index}
											bgcolor={businessDatas.colorBorderInput}
											filled={listAssemblyItensSelected[`${index}`].filled}
											onClick={() => {
												setAssemblyModalOpened(true);
												setAssemblyListItemSelected(index);
											}}
										>
											{obj.name !== "" ? <p>{obj.name}</p> : <p>Item {index + 1}</p>}

											<MdKeyboardArrowDown />
										</ButtonAssemblyItem>
									))}
								</ContainerToAssemble>
								{assemblyModalOpened ? (
									<AssemblyModal
										composition={item.sizes[sizeButtonSelected].composition}
										setAssemblyModalOpened={setAssemblyModalOpened}
										handleSetAssembledItens={handleSetAssembledItens}
										businessDatas={businessDatas}
									/>
								) : (
									<></>
								)}
							</>
						) : (
							<></>
						)}

						<div className="line-additionals">Observações</div>
						{isLoadingAdditionalProduct ? (
							<GeneralLoader />
						) : (
							<Additionals>
								{additionalDatas.map((element) => (
									<>
										<Additional
											key={element.idGroupAdditional}
											description={element.name}
											image={element.image}
											add={element.additionals}
											max={element.maxQuantity}
											min={element.minQuantity}
											obligate={element.required}
											ilimited={element.unlimited}
											objectE={element}
											listAdditionalRefs={listAdditionalRefs}
											blinkingObligate={blinkingObligate}
											businessDatas={businessDatas}
										/>
									</>
								))}

								{businessDatas.useOnlineOrder && (
									<div className="container-add">
										<p className="title-modal">Observações Adicionais</p>
										{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
										<div
											className="input-button"
											onClick={() => setIsModalTextAdditionalOpen(true)}
										>
											{textAdditional === "" ? (
												<p>Ex: sem cebola</p>
											) : (
												<p>{textAdditional}</p>
											)}
										</div>
										{isModalTextAdditionalOpen ? (
											<ModalTextAdditional
												handleSetTextAdditional={handleSetTextAdditional}
												textAdditional={textAdditional}
												setIsModalTextAdditionalOpen={setIsModalTextAdditionalOpen}
												bgcolor={businessDatas.colorButton}
											/>
										) : (
											<></>
										)}
									</div>
								)}
							</Additionals>
						)}
					</div>

					<div>
						<div className="button-container">
							{businessDatas.useOnlineOrder ? (
								<button
									type="button"
									onClick={() => {
										saveLogClube({
											nameBusiness: localStorage.getItem("@Inoveclube:nameBusiness"),
											idStatus: 3,
											token: localStorage.getItem("@Inoveclube:token"),
											isTakeaway: localStorage.getItem("@Inoveclube:type") === "takeaway",
											isTable: localStorage.getItem("@Inoveclube:type") === "table",
										});
										if (assembledItensFilled > 0 && item.assembled) {
											if (addButtonEnabled) {
												//console.log('aqui1')
												//console.log(item)
												handleSetListBuying([
													...listBuying,
													{
														src: item.src,
														name: item.name,
														cost: item.cost,
														code: item.code,
														promotions: item.promotions,
														size:
															item.assembled === true
																? item.sizes[sizeButtonSelected].idPackSize
																: 0,
														sizeDescription:
															item.assembled === true
																? item.sizes[sizeButtonSelected].description
																: "",
														selectedOptions: selectedOptions.reverse(),
														addCounterProduct,
														textAdditional,
														listAssemblyItensSelected,
														assembledItensFilled,
														promotionValue: item.promotionValue,
														qtdMax: item.qtdMax,
													},
												]);
												localStorage.setItem(
													`@Inoveclube:listBuying${window.location.pathname.split("/")[2]}`,
													JSON.stringify([
														...listBuying,
														{
															src: item.src,
															name: item.name,
															cost: item.cost,
															code: item.code,
															promotions: item.promotions,
															size:
																item.assembled === true
																	? item.sizes[sizeButtonSelected].idPackSize
																	: 0,
															sizeDescription:
																item.assembled === true
																	? item.sizes[sizeButtonSelected].description
																	: "",
															selectedOptions: selectedOptions.reverse(),
															addCounterProduct,
															textAdditional,
															listAssemblyItensSelected,
															assembledItensFilled,
															promotionValue: item.promotionValue,
															qtdMax: item.qtdMax,
														},
													]),
												);

												handleSetSizeButtonSelected(0);
												handleSetListAssemblyItensSelected([]);
												handleSetSelectedOptions([]);
												handleSetTextAdditional("");
												handleSetIsBuyModalOpen(true);
												handleSetItem({
													code: 0,
													show: false,
												});
											} else {
												toast.error("🚫 Selecione as opções obrigatórias");
												handleGoToObligateAdditionals();
											}
										} else if (assembledItensFilled === 0 && item.assembled) {
											toast.info("Selecione um item para continuar");
										} else {
											//AQUI
											// console.log('AQUI2')
											//console.log(item)
											if (addButtonEnabled) {
												handleSetListBuying([
													...listBuying,
													{
														src: item.src,
														name: item.name,
														cost: item.cost,
														code: item.code,
														promotions: item.promotions,
														size:
															item.assembled === true
																? item.sizes[sizeButtonSelected].idPackSize
																: 0,
														sizeDescription:
															item.assembled === true
																? item.sizes[sizeButtonSelected].description
																: "",
														selectedOptions: selectedOptions.reverse(),
														addCounterProduct,
														textAdditional,
														listAssemblyItensSelected,
														assembledItensFilled,
														promotionValue: item.promotionValue,
														qtdMax: item.qtdMax,
													},
												]);
												localStorage.setItem(
													`@Inoveclube:listBuying${window.location.pathname.split("/")[2]}`,
													JSON.stringify([
														...listBuying,
														{
															src: item.src,
															name: item.name,
															cost: item.cost,
															code: item.code,
															promotions: item.promotions,
															size:
																item.assembled === true
																	? item.sizes[sizeButtonSelected].idPackSize
																	: 0,
															sizeDescription:
																item.assembled === true
																	? item.sizes[sizeButtonSelected].description
																	: "",
															selectedOptions: selectedOptions.reverse(),
															addCounterProduct,
															textAdditional,
															listAssemblyItensSelected,
															assembledItensFilled,
															promotionValue: item.promotionValue,
															qtdMax: item.qtdMax,
														},
													]),
												);

												handleSetSizeButtonSelected(0);
												handleSetListAssemblyItensSelected([]);
												handleSetSelectedOptions([]);
												handleSetTextAdditional("");
												handleSetIsBuyModalOpen(true);
												handleSetItem({
													code: 0,
													show: false,
												});
											} else {
												toast.error("🚫 Selecione as opções obrigatórias");
												handleGoToObligateAdditionals();
											}
										}
									}}
								>
									Adicionar
								</button>
							) : (
								<button
									type="button"
									onClick={() => {
										handleSetSizeButtonSelected(0);
										handleSetTextAdditional("");
										handleSetSelectedOptions([]);
										handleSetListAssemblyItensSelected([]);
										handleSetItem({ code: 0, show: false });
									}}
								>
									Voltar
								</button>
							)}
						</div>
					</div>
				</Container>
			) : (
				<></>
			)}
		</>
	);
}
