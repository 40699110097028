import { useMenu } from "../context/MenuContext";

import { ModalBusinessInformations } from "../components/pagesComponents/menuPage/modalBusinessInformations";
import { SaleCoupon } from "../components/pagesComponents/menuPage/saleCoupon";
import { CouponBanner } from "../components/pagesComponents/menuPage/couponBanner";
import { WholesaleLine } from "../components/pagesComponents/menuPage/WholesaleLine";
import { Header } from "./Header";
import { MenuOne } from "./MenuOne";
import { MenuTwo } from "./MenuTwo";
import { MainLoader } from "../components/loaders/mainLoader";
import { ImSad } from "react-icons/im";
import { useEffect, useState, useCallback } from "react";
import { api } from "../services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { ItemModalProvider } from "./ItemModalContext";

import { Found } from "./MenuOne/Card/style";

import { saveLogClube } from "../services/logClube";

import { DrawModal } from "./DrawModal";
import { getCoupon } from "../Utils/coupons";
// import { customEvent } from '../Utils/facebookPixelEvent'

export function Menu() {
	const history = useHistory();

	const { productsWithGroups, setProductsWithGroups, handleSetIsBuyModalOpen } =
		useMenu();

	const nameBusiness = window.location.pathname.split("/")[2];
	const couponDescription =
		window.location.pathname.split("/")[3] === "coupon"
			? window.location.pathname.split("/")[4]
			: undefined;

	const token = localStorage.getItem("@Inoveclube:token")
		? localStorage.getItem("@Inoveclube:token")
		: "";
	const isWholesale = localStorage.getItem("@Inoveclube:wholesale");

	const [saleCoupon, setSaleCoupon] = useState({});
	const [saleCouponVisible, setSaleCouponVisible] = useState(false);

	const [businessDatas, setBusinessDatas] = useState({});

	const [listProducts, setListProducts] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const [loadProducts, setLoadProducts] = useState(false);
	const [loadingBusinessInformations, setLoadingBusinessInformations] = useState(false);
	const [loadingFeeDelivery, setLoadingFeeDelivery] = useState(true);

	const [listBuying, setListBuying] = useState(
		() => JSON.parse(localStorage.getItem(`@Inoveclube:listBuying${nameBusiness}`)) || [],
	);
	const [infoOpen, setInfoOpen] = useState(false);
	const [couponBanner, setCouponBanner] = useState(undefined);
	const [feeDelivery, setFeeDelivery] = useState("");

	const [firstRound, setFirstRound] = useState(null);
	const [secondRound, setSecondRound] = useState(null);

	const [acitivesSweepstakes, setActiveSweepstakes] = useState([]);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleClearSearch = useCallback(() => {
		setSearchTerm("");
	}, []);

	const handleSetListBuying = useCallback((list) => {
		setListBuying(list);
	}, []);

	function getCouponBanner(data) {
		const arrCoupons = data.filter((item) => {
			return !item.secret;
		});

		if (arrCoupons.length === 0) return;

		const minValue = Math.min(
			...arrCoupons.map(({ discountValue, typeDiscount }) => {
				return typeDiscount === 0 ? discountValue : 0;
			}),
		);

		if (minValue === 0) {
			setCouponBanner({ discountValue: 0 });
			return;
		}

		let coupon = arrCoupons.filter((coupon) => {
			return coupon.active === true && coupon.discountValue === minValue;
		});

		setCouponBanner(...coupon);
	}

	useEffect(() => {
		if (
			localStorage.getItem("@Inoveclube:type") &&
			localStorage.getItem("@Inoveclube:type") === "table"
		) {
			if (
				window.location.pathname.split("/")[3] !==
				localStorage.getItem("@Inoveclube:type")
			) {
				localStorage.removeItem("@Inoveclube:listBuying");
				setListBuying([]);
			}
		}
	}, []);

	useEffect(() => {
		async function loadDraw() {
			try {
				const response = await api.get(`/getActiveDraw/${nameBusiness}`);
				setActiveSweepstakes(response.data.results);
			} catch (error) {
				toast.error(error.message);
			}
		}
		loadDraw();
	}, [nameBusiness]);

	useEffect(() => {
		async function fetchData() {
			await api
				.get(`/getBusiness/${nameBusiness}/${localStorage.getItem("@Inoveclube:token")}`)
				.then((response) => {
					if (Object.keys(response.data).length === 0) {
						history.push("/profile");
						return;
					}

					localStorage.setItem("@Inoveclube:idPixelGoogle", response.data.idPixelGoogle);
					localStorage.setItem(
						"@Inoveclube:idPixelMeta",
						JSON.stringify(response.data.idPixelMeta),
					);
					localStorage.setItem("@Inoveclube:wholesale", response.data.userCanAccessMenu);

					setBusinessDatas(response.data);

					///Calculando os fretes
					async function loadFeeDelivery() {
						if (response.data.typeFeeDelivery.toUpperCase() === "B") {
							try {
								const response = await api.get(
									`/validateNeighborhood/${localStorage.getItem("@Inoveclube:nameBusiness")}`,
									{
										headers: {
											token: localStorage.getItem("@Inoveclube:token"),
										},
									},
								);
								if (response.data.status === true) {
									setFeeDelivery(response.data.feeDelivery);
									setLoadingFeeDelivery(false);
								}
							} catch (error) {
								setFeeDelivery("");
								setLoadingFeeDelivery(false);
							}
						} else if (response.data.typeFeeDelivery.toUpperCase() === "KM") {
							try {
								const response = await api.post(
									"/getFeeDeliveryForKm",
									{
										business: localStorage.getItem("@Inoveclube:nameBusiness"),
										destiny: {},
									},
									{
										headers: {
											"Content-Type": "application/json",
											token: localStorage.getItem("@Inoveclube:token"),
										},
									},
								);
								if (response.data.success === 0) {
									throw new Error(response.data.error);
								}
								setFeeDelivery(response.data.valueFeeDelivery);
								setLoadingFeeDelivery(false);
							} catch (error) {
								//console.log(error)
								setFeeDelivery("");
								setLoadingFeeDelivery(false);
							}
						}
					}
					loadFeeDelivery();

					if (response.data.useOnlineOrder) {
						localStorage.setItem("@Inoveclube:colorLabel", response.data.colorLabel);
						localStorage.setItem("@Inoveclube:colorButton", response.data.colorButton);
						localStorage.setItem(
							"@Inoveclube:colorTransversalBar",
							response.data.colorTransversalBar,
						);
						localStorage.setItem(
							"@Inoveclube:colorBorderInput",
							response.data.colorBorderInput,
						);
						localStorage.setItem("@Inoveclube:colorShadow", response.data.colorShadow);
						localStorage.setItem(
							"@Inoveclube:colorButtonBottom",
							response.data.colorButtonBottom,
						);
						localStorage.setItem(
							"@Inoveclube:colorBgHeader",
							response.data.colorBgHeader,
						);
						localStorage.setItem(
							"@Inoveclube:neighborhoods",
							JSON.stringify(response.data.neighborhoods),
						);
						localStorage.setItem("@Inoveclube:imageBusiness", response.data.linkProfile);

						async function fetchData() {
							try {
								const arrFranchises = [];
								await api
									.get(
										`/getCoupons/?business=${response.data.idBusiness}&franchiseList=${arrFranchises}&getAll=0`,
										{
											headers: {
												token: localStorage.getItem("@Inoveclube:token"),
											},
										},
									)
									.then((response) => {
										getCouponBanner(response.data);
									});
							} catch (error) {
								console.log(error);
								toast.error("Erro interno, recarregue a página");
							}
						}
						fetchData();
					}

					document.title = response.data.name
						.toLowerCase()
						.replace(/(^|\couponBanner)\S/g, (l) => l.toUpperCase());
					document.getElementById("favicon").href = response.data.linkProfile;
					localStorage.setItem("@Inoveclube:numberBusiness", response.data.phoneNumber);
					localStorage.setItem("@Inoveclube:idCity", response.data.idCity);
					localStorage.setItem("@Inoveclube:state", response.data.state);
					localStorage.setItem(
						"@Inoveclube:street",
						`${response.data.street}, ${response.data.number}, ${response.data.neighborhood}`,
					);
					localStorage.setItem(
						"@Inoveclube:maps",
						`https://www.google.com.br/maps/place/
						${response.data.street},+
						${response.data.number},+
						${response.data.neighborhood},+
						${response.data.city},+
						${response.data.state}`,
					);
					localStorage.setItem(
						"@Inoveclube:socialMedias",
						JSON.stringify({
							whatsapp: response.data.whatsapp,
							instagram: response.data.instagram,
							facebook: response.data.facebook,
						}),
					);

					const objFirstRound = {
						openMon: response.data.openingTimeMon,
						closeMon: response.data.closingTimeMon,
						openTue: response.data.openingTimeTue,
						closeTue: response.data.closingTimeTue,
						openWed: response.data.openingTimeWed,
						closeWed: response.data.closingTimeWed,
						openThu: response.data.openingTimeThu,
						closeThu: response.data.closingTimeThu,
						openFri: response.data.openingTimeFri,
						closeFri: response.data.closingTimeFri,
						openSat: response.data.openingTimeSat,
						closeSat: response.data.closingTimeSat,
						openSun: response.data.openingTimeSun,
						closeSun: response.data.closingTimeSun,
					};
					setFirstRound(objFirstRound);

					if (response.data.habilitaFuncionamentoDoisTurnos) {
						let objSecondRound = {
							openMon: response.data.openingTimeMon2,
							closeMon: response.data.closingTimeMon2,
							openTue: response.data.openingTimeTue2,
							closeTue: response.data.closingTimeTue2,
							openWed: response.data.openingTimeWed2,
							closeWed: response.data.closingTimeWed2,
							openThu: response.data.openingTimeThu2,
							closeThu: response.data.closingTimeThu2,
							openFri: response.data.openingTimeFri2,
							closeFri: response.data.closingTimeFri2,
							openSat: response.data.openingTimeSat2,
							closeSat: response.data.closingTimeSat2,
							openSun: response.data.openingTimeSun2,
							closeSun: response.data.closingTimeSun2,
						};
						setSecondRound(objSecondRound);
					}

					localStorage.setItem(
						"@Inoveclube:daysOpen",
						JSON.stringify(response.data.daysOpen),
					);
				});
			setLoadingBusinessInformations(true);
		}
		fetchData();
	}, [nameBusiness, history]);

	useEffect(() => {
		const arrayProducts = [];
		productsWithGroups.map((element) =>
			element.products.map((product) => {
				arrayProducts.push(product.name);
				setListProducts(arrayProducts);
				return true;
			}),
		);
	}, [productsWithGroups]);

	useEffect(() => {
		const results = listProducts.filter((product) =>
			product
				.toLowerCase()
				.normalize("NFD")
				.replace(/[^a-zA-Z\s]/g, "")
				.includes(
					searchTerm
						.toLowerCase()
						.normalize("NFD")
						.replace(/[^a-zA-Z\s]/g, ""),
				),
		);

		setSearchResults(results);
	}, [listProducts, searchTerm]);

	useEffect(() => {
		if (
			window.location.pathname.split("/")[3] !== undefined &&
			window.location.pathname.split("/")[3] !== "" &&
			window.location.pathname.split("/")[3] !== "coupon"
		) {
			if (window.location.pathname.split("/")[3] === "takeaway") {
				localStorage.setItem("@Inoveclube:type", window.location.pathname.split("/")[3]);
				localStorage.removeItem("@Inoveclube:typeNumber");
				localStorage.removeItem("@Inoveclube:onlyTableModality");
			} else if (window.location.pathname.split("/")[3] === "sweepstakes") {
				localStorage.setItem("@Inoveclube:type", window.location.pathname.split("/")[3]);
				localStorage.removeItem("@Inoveclube:typeNumber");
				localStorage.removeItem("@Inoveclube:onlyTableModality");
			} else {
				localStorage.setItem("@Inoveclube:type", window.location.pathname.split("/")[3]);
				localStorage.setItem(
					"@Inoveclube:typeNumber",
					window.location.pathname.split("/")[4],
				);
				localStorage.setItem("@Inoveclube:onlyTableModality", true);
			}
		} else if (window.location.pathname.split("/")[3] === "coupon") {
			// localStorage.setItem(
			// 	`@Inoveclube:cupom${nameBusiness}`,
			// 	JSON.stringify(window.location.pathname.split("/")[4])
			// );
		} else {
			localStorage.removeItem("@Inoveclube:type");
			localStorage.removeItem("@Inoveclube:typeNumber");
			localStorage.removeItem("@Inoveclube:onlyTableModality");
			localStorage.removeItem(`@Inoveclube:cupom${nameBusiness}`);
		}
		localStorage.setItem("@Inoveclube:nameBusiness", nameBusiness);
	}, [nameBusiness]);

	useEffect(() => {
		async function handleLogClube() {
			if (window.location.pathname.split("/")[3] !== "coupon") {
				const isTakeaway =
					window.location.pathname.split("/")[3] &&
					window.location.pathname.split("/")[3] === "takeaway";

				const isTable = window.location.pathname.split("/")[3] === "table";
				saveLogClube({
					nameBusiness: localStorage.getItem("@Inoveclube:nameBusiness"),
					idStatus: 1,
					token: localStorage.getItem("@Inoveclube:token"),
					isTakeaway,
					isTable,
				});
			}
		}
		handleLogClube();
	}, []);

	useEffect(() => {
		async function fetchData() {
			const [responseProductWithGroups, responseAdditionalProducts] = await Promise.all([
				api.get(
					`/getProductsWithGroups/${nameBusiness}/${localStorage.getItem(
						"@Inoveclube:type",
					)}`,
				),
				api.get(`/getAdditionalProducts/${nameBusiness}`),
			]);

			const productsWhitMinValue = responseProductWithGroups.data.map((item) => {
				item.products = item.products.map((prod) => {
					let minValue = 0;
					const product = responseAdditionalProducts.data.additionals.find(
						(addtional) => addtional.cdProduto === prod.idProduct,
					);

					if (product) minValue = product.minVlPreco;

					return {
						...prod,
						minValue,
					};
				});

				return {
					...item,
				};
			});

			setProductsWithGroups(productsWhitMinValue);
			setLoadProducts(true);
		}
		fetchData();
	}, [nameBusiness, token, setProductsWithGroups]);

	useEffect(() => {
		async function fetchData() {
			if (couponDescription === undefined || couponDescription === "") return;
			const couponResponse = await getCoupon(nameBusiness, couponDescription);

			if (couponResponse) {
				setSaleCoupon(couponResponse);
				if (couponResponse.idCoupon !== undefined) {
					localStorage.setItem(
						`@Inoveclube:cupom${nameBusiness}`,
						JSON.stringify(couponResponse),
					);

					setSaleCouponVisible(true);
				} else localStorage.removeItem("@Inoveclube:cupom");
			} else {
				localStorage.removeItem("@Inoveclube:cupom");
			}

			saveLogClube({
				nameBusiness: localStorage.getItem("@Inoveclube:nameBusiness"),
				idStatus: 1,
				token: localStorage.getItem("@Inoveclube:token"),
			});
		}
		fetchData();
	}, [nameBusiness, couponDescription]);

	useEffect(() => {
		if (localStorage.getItem("@Inoveclube:choseOrderType")) {
			handleSetIsBuyModalOpen(true);
		}
	}, [handleSetIsBuyModalOpen]);

	if (searchResults.length === 0 && searchTerm.trim() !== "") {
		return (
			<>
				<ModalBusinessInformations
					open={infoOpen}
					closeModal={setInfoOpen}
					firstRound={firstRound}
					secondRound={secondRound}
				/>

				{isWholesale === "true" && <WholesaleLine />}

				<Header
					searchTerm={searchTerm}
					businessDatas={businessDatas}
					handleSearchChange={handleSearchChange}
					handleClearSearch={handleClearSearch}
					listBuying={listBuying}
					setInfoOpen={setInfoOpen}
				/>
				{productsWithGroups.length > 0 ? (
					<Found>
						Produto não encontrado
						<ImSad />
					</Found>
				) : (
					<></>
				)}
			</>
		);
	}
	if (loadProducts && loadingBusinessInformations) {
		return (
			<>
				{!saleCouponVisible && (
					<DrawModal
						businessDatas={businessDatas}
						active={acitivesSweepstakes.length > 0}
						draw={acitivesSweepstakes[0]}
						message={"Estamos realizando um sorteio"}
						submessage={"Finalize a compra para poder participar!"}
					/>
				)}

				{businessDatas.idBusiness !== undefined ? (
					<ItemModalProvider>
						<ModalBusinessInformations
							open={infoOpen}
							closeModal={setInfoOpen}
							firstRound={firstRound}
							secondRound={secondRound}
						/>

						<SaleCoupon
							saleCouponVisible={saleCouponVisible && couponBanner !== undefined}
							saleCoupon={saleCoupon}
						/>

						<CouponBanner
							couponValue={couponBanner?.discountValue}
							couponExistis={couponBanner !== undefined}
						/>

						{isWholesale === "true" && <WholesaleLine />}

						<Header
							searchTerm={searchTerm}
							businessDatas={businessDatas}
							handleSearchChange={handleSearchChange}
							handleClearSearch={handleClearSearch}
							listBuying={listBuying}
							setInfoOpen={setInfoOpen}
							feeDelivery={feeDelivery}
							loadingFeeDelivery={loadingFeeDelivery}
						/>

						{productsWithGroups.length !== 0 ? (
							businessDatas.typeGroup === 0 ? (
								<MenuOne
									productsWithGroups={productsWithGroups}
									searchTerm={searchTerm}
									businessDatas={businessDatas}
									searchResults={searchResults}
									listBuying={listBuying}
									infoOpen={infoOpen}
									couponBanner={couponBanner}
									handleSetListBuying={handleSetListBuying}
								/>
							) : (
								<MenuTwo
									productsWithGroups={productsWithGroups}
									searchTerm={searchTerm}
									businessDatas={businessDatas}
									searchResults={searchResults}
									listBuying={listBuying}
									infoOpen={infoOpen}
									handleSetListBuying={handleSetListBuying}
								/>
							)
						) : (
							<div
								style={{
									height: "70vh",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									color: "gray",
									fontSize: "0.9rem",
								}}
							>
								<h1>nenhum</h1>
								<h1 style={{ marginBottom: "1rem" }}>produto listado</h1>
								<ImSad style={{ fontSize: "3rem" }} />
							</div>
						)}
					</ItemModalProvider>
				) : (
					<h1 style={{ color: "var(--colorButton)" }}>404 not found</h1>
				)}
			</>
		);
	}
	return <MainLoader title={"Buscando produtos"} />;
}
