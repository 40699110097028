import styled, {css} from 'styled-components';

export const ContainerList = styled.div`
    width: 100%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);   
    padding: 10px;
    margin-bottom: 10px;
`;

export const Container = styled.div`
    width: 100%;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    ${props => props.height && css`
        max-height: ${props.height};
    `}
`

export const CardList = styled.div`
    display: flex;

    .image{
        min-height: 50px;
        min-width: 75px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        ${props => props.productImage && css`
            background-image: url("${props.productImage}");
        `}
       
        @media (min-width: 800px){
            cursor: pointer;
        }
        
    }

    .text{
        margin-left: 10px;
        flex: auto;
        text-align: left;

        h3{
            font-size: 13px;
            margin-bottom: 8px;

       }

       h4{
            color: grey;
            font-size: 12px;
            margin-bottom: 8px;
            text-align: justify;
       }

        span{
            font-size: 13px;
            color: #006400;
           
        }

        .last-price{
            text-decoration: line-through;
            color: gray;
            font-size: 13px;
            padding-right: 5px;
        }
        @media (min-width: 800px){
            cursor: pointer;
        }
    
    }
`;

export const CardContainer = styled.div`
    height: 150px;
    width: 97%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
    background-position: center;
    background-size: cover;
    color: white;

    ${props => props.image && css`
        background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 0.9) ), url("${props.image}");
    `}


    h2{
        padding-bottom: 22px;
        font-size: 15px;
    }

    @media (min-width: 800px){
        cursor: pointer;
    }

`;

export const Found = styled.div`
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: gray;

    svg{
        margin-top: 10px;
        font-size: 40px;
    }

`
