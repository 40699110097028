import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import { Link, useHistory } from 'react-router-dom'
import { BiLockAlt } from 'react-icons/bi'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useCallback, useState } from 'react';

import { Container, Header, Logo, InputContainer, ButtonsContainer } from './style';


export function RecoverPasswordContent() {
    const token = window.location.pathname.split('/')[2];
    const [passwordRecover, setPasswordRecover] = useState('');
    const [confirmPasswordRecover, setConfirmPasswordRecover] = useState('');
    const history = useHistory();
    const customId = 130;

    const handlePasswordRecover = useCallback(async () =>{
        if(passwordRecover === confirmPasswordRecover){
            try{
                const response = await api.post('/updatePassword', {token: token, password: passwordRecover})

                const { success, message } = response.data

                if(success === 1){
                    toast.success(message, {toastId: customId})
                    history.push('/login')
                }else{
                    throw new Error(message)
                }
                
            }catch(error){
                toast.error(error.message, {toastId: customId})
            }
        }else{
            toast.error('As senhas não coindidem', {toastId: customId})
        }
        
    },[token, confirmPasswordRecover, passwordRecover, history])


    return(
        <Container>
            <Header>
                <Link to="/login"><AiOutlineArrowLeft/></Link>
                <h1>Alterar senha</h1>
            </Header>
    
        <InputContainer>
            <Logo></Logo>
            
            <div className="input-log"><BiLockAlt/>
                <input 
                type="text" 
                placeholder="Nova senha"
                value={passwordRecover}
                onChange={e => setPasswordRecover(e.target.value)}
                >
                </input>
            </div>
            <div className="input-log"><BiLockAlt/>
                <input 
                type="text" 
                placeholder="Repita a senha"
                value={confirmPasswordRecover}
                onChange={e => setConfirmPasswordRecover(e.target.value)}
                >
                </input>
            </div>
            <ButtonsContainer>
                <button 
                className="logButton"
                onClick={() => handlePasswordRecover()}
                >
                Alterar
                </button>
            </ButtonsContainer> 
        </InputContainer>

        </Container>
    )
}
