import styled from "styled-components";

export const Container = styled.section`
	width: 98%;
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 1rem;
	user-select: none;
	background-color: #f2f2f2;
	padding: 0.8rem;

	h3 {
		font-size: 1rem;
		margin-bottom: 1rem;
	}

	.swiper-wrapper {
		display: flex;
		max-width: max-content;
	}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: column;
	width: 7rem;
	cursor: pointer;
	background-color: #fff;
	padding: 0.8rem;

	.img-container {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		img {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			object-fit: cover;
		}
	}

	.details {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		span {
			margin-top: 5px;
			font-size: 0.85rem;
			text-align: center;

			&:nth-child(2) {
				font-size: 0.7rem;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				line-height: 1rem; /* fallback */
				min-height: 2rem; /* fallback */
				-webkit-line-clamp: 2; /* number of lines to show */
				-webkit-box-orient: vertical;
			}
		}
	}
`;