import { IndividualMarketPlaceProvider } from './individualMarketPlaceContext'
import { MarketContent } from './marketContent'

export function IndividualMarketPlace() {
  return (
    <IndividualMarketPlaceProvider>
        <MarketContent />
    </IndividualMarketPlaceProvider>
  )
}
