import styled from "styled-components"

export const Container = styled.div`
    background: var(--colorBgHeader);
    border-radius: 0 25px 25px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    img{
        height: 100px;
        border-radius: 10px;
    }

    @media (max-width: 650px){
        display: none;
    }
`