import { BsCreditCard } from 'react-icons/bs'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RiBankLine } from 'react-icons/ri'
import { MdAttachMoney } from 'react-icons/md'

import { Container } from './style';


const listIconsDeliveryPayment = [
    { type: 0, icon: <FaMoneyBillAlt /> },
    { type: 2, icon: <BsCreditCard /> },
    { type: 9, icon: <RiBankLine /> }
]


export function DeliveryPayment({
    businessDatas, 
    handleAddPaymentMethod,  
    setPaymentMethodsModalOpened, 
    setChangeMoneyModalOpened,
    handleSetChangeValue
}) {
    
    function findIcon(type) {
        const index = listIconsDeliveryPayment.findIndex(elem => elem.type === type)

        return index !== -1 ? listIconsDeliveryPayment[index].icon : <MdAttachMoney />
    }

    function handleSelectPaymentMethod(paymentsMethod) {
        let object = { 
            idPayment: paymentsMethod.idPayment, 
            icon: paymentsMethod.type, 
            description: paymentsMethod.description,
            type: 'delivery',
            idCard: '', 
        } 

        handleAddPaymentMethod(object)

        if(localStorage.getItem('@Inoveclube:nameBusiness')){
            localStorage.setItem(
                '@Inoveclube:deliverypmtd' + localStorage.getItem('@Inoveclube:nameBusiness'), 
                JSON.stringify(object)
            )
        }

        if(paymentsMethod.type === 0) {
            setChangeMoneyModalOpened(true)
            return
        }

        handleSetChangeValue(0)
        setPaymentMethodsModalOpened(false)
    }

    
    return(

        <Container>
            {
                businessDatas.paymentsMethods !== undefined &&
                    businessDatas.paymentsMethods.map(paymentsMethod => (
                            <div key={paymentsMethod.idPayment} onClick={() => {handleSelectPaymentMethod(paymentsMethod)}}>

                                {findIcon(paymentsMethod.type)}

                                <p>{paymentsMethod.description}</p>
                            </div> 
                ))
            }
        </Container>
    )
}
