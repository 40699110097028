import styled from "styled-components"

export const Container = styled.div`
    width: 75%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    div + div{
        margin-top: 1.2rem;
    }

    button{
        margin-top: 1.2rem;
    }
`

export const Logo = styled.div`
    height: 7rem;
    width: 7rem;
    background-image: url("https://s3.sa-east-1.amazonaws.com/www.inoveclube.com/public/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    border-radius: 0.7rem;
`