import React from 'react';
import {useHistory} from 'react-router-dom';

import {Container, Content, Header, Main} from './styles';
import {FaTimes, FaPlus} from 'react-icons/fa';

import cardImage from '../../../../../images/cartao-credito.png';

export function ModalRegisterCard({onClose = () => {}}) {

    const history = useHistory();

  return (
    <Container>
        <Content>
            <Header>
                <button onClick={onClose}>
                    <FaTimes/>
                </button>
            </Header>

            <img src={cardImage} alt="Imagem de uma mão segurando um cartão de crédito" />

            <Main>
                <div className="description">
                    <strong>Adicione seu cartão de crédito!</strong>
                    <p>Basta clicar no <b>Botão</b> "<b>adicionar</b> cartão".</p>
                </div>

                <button onClick={() => history.push('/cardregister')}>
                    <FaPlus/> Adicionar Cartão
                </button>
            </Main>
        </Content>
    </Container>
  )
}
