import styled from "styled-components"

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    display: grid;
    grid-template-columns: 1fr 1.4fr;
    grid-template-rows: 1fr;
    overflow: auto;

    @media (max-width: 650px){
        grid-template-columns: 1fr;
    }
`