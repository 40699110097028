import { Container } from "./style"

export function Test() {


  return(
    <Container>
        <button>baixar</button>
    </Container>
  )
}

