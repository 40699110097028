import styled, {css} from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: white;
    display: flex;
    flex-direction: column;

    h1{
        margin-bottom: 2px;
    }

    .button-container{
        background-color: white;
        padding-top: 4px;
        max-width: 600px;
        margin: 0 auto;

        .close-all{
            color: gray;
            display: flex;
            justify-content: center;
            align-items: center;
            ${props => props.bgcolor && css`
                border: 1px solid ${props.bgcolor + '80'};
            `}
            background: #FFF;
            width: 98%;
            margin: 0 auto;
            padding: 15px 0 15px 0;
            border-radius: 4px;
            font-size: 12px;
        }

        .price-end{
            display: flex;
            width: 98%;
            margin: 0 auto;
            align-items: center;
            justify-content: space-around;
            padding-top: 10px;
            margin-bottom: 10px;

            .total{
                width: 25%;
                margin-right: 20px;
                p{
                    color: gray;
                }

                span{
                    font-weight: 500;
                    font-size: 1rem;
                }
            }

            button{
                border: none;
                color: var(--colorButtonBottom);
                border-radius: 0.3rem;
                width: 75%;
                padding: 15px 0 15px 0;
                font-size: 1rem;
                ${props => props.bgcolor && css`
                    background: ${props.bgcolor};
                `}
            }

        }
    }

    .products-container{
        flex: auto;
        overflow-y: auto;

        
        &::-webkit-scrollbar-track {
            background-color: #D3D3D3;
            border-radius: 4px;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 4px;
        }

        scrollbar-width: thin;
        scrollbar-color: gray;
        scrollbar-track-color: #D3D3D3;
    }

    .cartEmpty{
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: gray;

        h1{
            font-size: 1rem;
        }

        svg{
            font-size: 3rem;
            margin-top: 10px;
        }
    }

`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    padding: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    margin-bottom: 5px;

    svg{
        margin-right: 20px;
        font-size: 20px;
        color: gray;
        @media (min-width: 800px){
            cursor: pointer;
        }
    }
`;

export const BuyContainer = styled.div`
    display: flex;
    padding: 10px;
    max-width: 600px;
    margin: auto;
    border-bottom: ${props => props.editing ? '2px solid green' : '0.5px solid rgba(0, 0, 0, 0.1)'};

    .image{
        height: 70px;
        width: 25%;;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        ${props => props.item && css`
            background-image: url("${props.item}");
        `}
    }

    .align{
        display: flex;
        align-items: center;
        width: ${props => props.item === '' ? '100%' : '75%'};
        padding-left: ${props => props.item === '' ? '3.5%' : '5px'};
            .text{
                width: ${props => props.item === '' ? '77%' : '70%'};
                h3{
                    font-size: 13px;
                }
                .calc{
                    font-size: 13px;
                    color: gray ;
                }

                .value{
                    font-size: 15px;
                    color: green ;
                }
                .additional-container{
                    display: flex;
                    flex-direction: column;
                    .additional{
                        padding: 0;
                        text-align: left;
                        font-size: 12px;
                        color: gray;
                        word-break: break-word;
                    }
                }
        }

        .buttonsEditClear{
            min-width: ${props => props.item === '' ? '23%' : '30%'};;
            color: gray;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;

            svg:first-child{
                font-size: 1.4rem;
            }

            svg{
                font-size: 1.6rem;
            }

            @media (min-width: 800px){
            cursor: pointer;
            }
        }

        @media (max-width: 320px){
                .text{
                width: 80%;
                padding-left: 10%;
                h3{
                    font-size: 12px;
                    margin-bottom: 13px;
                }
                .calc{
                    font-size: 12px;
                    color: gray ;
                }

                .value{
                    font-size: 14px;
                    color: green ;
                }
            }

            svg{
                font-size: 1.6rem;
            }
        }
    }

`;