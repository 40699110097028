import React, {useState, useMemo} from 'react';

import {FaCheck} from 'react-icons/fa';
import {Container, Content, StatusList, ButtonsContainer} from './styles';
import { toast } from 'react-toastify';

export function Filters({
    typeFilters = [], 
    typeOrdersSelected = [],
    isOpen, 
    onClose,
    setTypeOrdersSelected
}) {

    const [status, setStatus] = useState(typeOrdersSelected);

    const allChecked = useMemo(() => {
        return typeFilters.every((item) => {
            return status.some((value) => value === item.type)
        });
    }, [typeFilters, status]);

    
    function onAddItem(value){
        const hasStatus = status.some((item) => item === value);

        if(hasStatus){
            onRemoveItem(value);
            return
        }   

        setStatus((arr) => [...arr, value]);
    }

    function onRemoveItem(value){
        const newArr = status.filter((item) => {
            return item !== value;
        });

        setStatus(newArr)
    }

    function addAll(){
        if(allChecked){
            setStatus([]);
            return
        }

        const arr = typeFilters.map((item) => {
            return item.type
        });

        setStatus(arr);
    }

    function selected(){
        if(status.length === 0){
            toast.info('Por favor, selecione pelo menos um filtro!');
            return
        }

        setTypeOrdersSelected(status);
        onClose();
    }



  if(!isOpen) return null

  return (
    <Container isOpen={isOpen}>
        <Content isOpen={isOpen}>
            <strong>Status</strong>

            <StatusList>
                <li onClick={addAll}>
                    <span>Todos</span>

                    <button>
                        {
                            allChecked && <FaCheck/>
                        }
                    </button>
                </li>
                {
                    typeFilters.map((item, index) => {

                        const isSelected = status.some((value) => value === item.type)

                        return (
                            <li key={index} onClick={() => onAddItem(item.type)}>
                                <span>{item.value}</span>

                                <button>
                                    {
                                        isSelected && <FaCheck/>
                                    }
                                </button>
                            </li>
                        )
                    })
                }
            </StatusList>
       </Content>
       

       <ButtonsContainer>
            <div className="buttons">
                <button onClick={selected}>
                    Selecionar
                </button>
            </div>
       </ButtonsContainer>
    </Container>
  )
}
