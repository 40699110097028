import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaMotorcycle } from "react-icons/fa";
import { GiShoppingBag } from "react-icons/gi";
import { SiAirtable } from "react-icons/si";
import { IoIosArrowForward } from "react-icons/io";
import { FaMapMarkedAlt, FaWalking } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AddressShoppingModal } from "./addressShoppingModal";
import { toast } from "react-toastify";
import { GeneralLoader } from "../components/loaders/generalLoader";
import { api } from "../services/api";
import { MainLoader } from "../components/loaders/mainLoader";
import { NeighborhoodsAlowedModal } from "./neighborhoodsAlowedModal";
import { useRegister } from "../context/RegisterContext";
import { useAuth } from "../context/AuthContext";
import { formatMoney } from "../Utils/formatMoney";

import { CouponsButton } from "../components/pagesComponents/shoppingPage/couponsButton";
import { UserCouponsModal } from "../components/pagesComponents/shoppingPage/userCouponsModal";
import { ProgressBarCoupon } from "../components/pagesComponents/shoppingPage/progressBarCoupon";
import { ButtonToSelectPaymentForm } from "../components/pagesComponents/shoppingPage/ButtonToSelectPaymentForm";
import { QrCodeScreen } from "../components/pagesComponents/shoppingPage/QrCodeScreen";
import { QuestionModal } from "./questionModal";
import { ShoppingProvider } from "./shoppingContext";
import { useHistory } from "react-router-dom";

import { PaymentMethodsModal } from "../components/pagesComponents/shoppingPage/paymentMethodsModal";
import { SpaceLoader } from "../components/loaders/spaceLoader";
import { FaLocationCrosshairs } from "react-icons/fa6";

import { DrawModal } from "../menus/DrawModal";

import { saveLogClube } from "../services/logClube";

import {
	Container,
	Header,
	Content,
	RadioButtonShopping,
	ButtonsContainer,
	InputAddressArea,
	FixedShoppingInformations,
	FinishShoppingArea,
	WarningPage,
	ContainerLoader,
} from "./style";
import moment from "moment";

export function ShoppingScreen() {
	const { handleUpdateAddress } = useRegister();
	const [businessDatas, setBusinessData] = useState({
		paymentsMethods: [],
		modalities: [],
	});
	const [shoppingButtonSelected, setShoppingButtonSelected] = useState("");
	const [listBuying] = useState(
		localStorage.getItem(
			"@Inoveclube:listBuying" + localStorage.getItem("@Inoveclube:nameBusiness"),
		)
			? JSON.parse(
					localStorage.getItem(
						"@Inoveclube:listBuying" + localStorage.getItem("@Inoveclube:nameBusiness"),
					),
				)
			: [],
	);

	const [cartItens] = useState(
		listBuying.map((elem) => {
			let optionsArray = [];
			let compositionArray = [];
			elem.selectedOptions.map((option) =>
				optionsArray.push({
					idAdditional: option.cdOption,
					name: option.obsDescription,
					price: option.price,
					quantity: option.qtd,
				}),
			);
			elem.listAssemblyItensSelected.map((comp) =>
				compositionArray.push({
					idProduct: comp.idProduct,
					name: comp.name,
					price: comp.price,
					quantity: elem.assembledItensFilled === 1 ? 1 : 1 / elem.assembledItensFilled,
				}),
			);
			return {
				idProduct: elem.code,
				name: elem.name,
				price: elem.cost,
				quantity: elem.addCounterProduct,
				textAdditional: elem.textAdditional,
				idPackSize: elem.size,
				options: optionsArray,
				composition: compositionArray,
				promotionValue: elem.promotionValue,
			};
		}),
	);

	const [listAddressPerson, setListAddressPerson] = useState([]);
	const [addressPersonSelected, setAddressPersonSelected] = useState({});
	const [isLoadingAddressPerson, setIsLoadingAddressPerson] = useState(true);
	const [isEnableInputSelectAddress, setIsEnableInputSelectAddress] = useState(false);
	const [addressShoppingModalOpened, setAddressShoppingModalOpened] = useState(false);

	const { signOut } = useAuth();
	const history = useHistory();

	const [isLoadingBusiness, setIsLoadingBusiness] = useState(true);
	const [isEnableInputSelectPayment, setIsEnableInputSelectPayment] = useState(false);
	const [paymentMethodsModalOpened, setPaymentMethodsModalOpened] = useState(false);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
	const [changeValue, setChangeValue] = useState(0);
	const [dtAgendamento, setDtAgendamento] = useState(null);
	const [qrCodeScreen, setQrCodeScreen] = useState({
		visible: false,
		messageType: 0,
	});

	const [localAddress, setLocalAddress] = useState("");
	const [typeFeeDelivery, setTypeFeeDelivery] = useState("");
	const [feeDelivery, setFeeDelivery] = useState(-1);
	const [vlPagarmeService] = useState(0);
	//setVlPagarmeService
	const [distance, setDistance] = useState("");
	const [loadingFeeDelivery, setLoadingFeeDelivery] = useState(false);
	const [neighborhoodsAlowed, setNeighborhoodsAlowed] = useState([]);
	const [neighborhoodsAlowedModal, setNeighborhoodsAlowedModal] = useState(false);

	const [buttonFinishedDisable, setButtonFinishedDisable] = useState(false);

	const [typeNumberDecrypted, setTypeNumberDecrypted] = useState("");

	const [statusActive, setStatusActive] = useState(
		localStorage.getItem("@Inoveclube:type") === "table" ||
			localStorage.getItem("@Inoveclube:type") === "takeaway"
			? true
			: false,
	);

	const [breathButton, setBreathButton] = useState(false);

	const [openModalQuestion, setOpenModalQuestion] = useState(false);

	const [acitivesSweepstakes, setActiveSweepstakes] = useState([]);

	const buttonPaymentRef = useRef(null);

	//coupon
	const couponClear = useMemo(() => {
		return {
			idCoupon: -1,
			description: "",
			rule: -1,
			typeDiscount: -1,
			discountValue: 0,
			minValue: 0,
			index: -1,
		};
	}, []);

	const [feeDeliveryWithCoupon, setFeeDeliveryWithCoupon] = useState(-1);
	const [couponSelectedDatas, setCouponSelectedDatas] = useState(
		localStorage.getItem(
			`@Inoveclube:cupom${localStorage.getItem("@Inoveclube:nameBusiness")}`,
		)
			? JSON.parse(
					localStorage.getItem(
						`@Inoveclube:cupom${localStorage.getItem("@Inoveclube:nameBusiness")}`,
					),
				)
			: couponClear,
	);
	const [totalWithCoupon, setTotalWithCoupon] = useState(-1);
	const [progressCouponBar, setProgressCouponBar] = useState(false);
	const [discountCouponCalculate, setDiscountCouponCalculate] = useState(0);
	const productsLinkedToCoupon = couponSelectedDatas?.productsSelectedsToLink;
	let someProductLinkedIsInCart = productsLinkedToCoupon?.some((product) =>
		listBuying.find((productCart) => productCart.code === product.idProduct),
	);

	const total = localStorage.getItem(
		"@Inoveclube:listBuying" + localStorage.getItem("@Inoveclube:nameBusiness"),
	)
		? JSON.parse(
				localStorage.getItem(
					"@Inoveclube:listBuying" + localStorage.getItem("@Inoveclube:nameBusiness"),
				),
			).reduce((sumTotal, product) => {
				return (
					sumTotal + (product.promotionValue ?? product.cost) * product.addCounterProduct
				);
			}, 0)
		: 0;

	useEffect(() => {
		saveLogClube({
			nameBusiness: localStorage.getItem("@Inoveclube:nameBusiness"),
			idStatus: 4,
			token: localStorage.getItem("@Inoveclube:token"),
			isTakeaway: localStorage.getItem("@Inoveclube:type") === "takeaway",
			isTable: localStorage.getItem("@Inoveclube:type") === "table",
		});
	}, []);

	useEffect(() => {
		const typeStorage = localStorage.getItem("@Inoveclube:type");
		if (typeStorage) {
			setShoppingButtonSelected(typeStorage === "takeaway" ? "takeaway" : "delivery");
		}
	}, []);

	useEffect(() => {
		const typeStorage = localStorage.getItem("@Inoveclube:type");
		const deliveryPayment = JSON.parse(
			localStorage.getItem(
				`@Inoveclube:deliverypmtd${localStorage.getItem("@Inoveclube:nameBusiness")}`,
			),
		);

		if (typeStorage && deliveryPayment) {
			if (typeStorage === "takeaway" && deliveryPayment.idCard === "") {
				localStorage.removeItem(
					`@Inoveclube:deliverypmtd${localStorage.getItem("@Inoveclube:nameBusiness")}`,
				);
			}
		}
	}, []);

	const handleAddPaymentMethod = useCallback((object) => {
		setPaymentMethodSelected(object);
		localStorage.removeItem("@Inoveclube:try");
	}, []);

	const handleSetChangeValue = useCallback((value) => {
		setChangeValue(value);
	}, []);

	const handleModifyChange = useCallback(() => {
		if (changeValue > 0 && feeDelivery !== -1) {
			if (
				changeValue <
				(feeDeliveryWithCoupon !== -1
					? total + feeDelivery - feeDeliveryWithCoupon
					: total + feeDelivery)
			) {
				toast.warning("Troco insuficiente, insira novamente");
				setChangeValue(0);
			}
		}
	}, [changeValue, feeDelivery, total, feeDeliveryWithCoupon]);

	function handleSubCanalVenda() {
		const subLink = localStorage.getItem("@Inoveclube:type");

		if (subLink) {
			return subLink === "table" ? 4 : 3;
		} else {
			return shoppingButtonSelected === "delivery" ? 2 : 1;
		}
	}

	async function handleSendOrder(consumeInStore = false) {
		const currentTry = localStorage.getItem("@Inoveclube:try") || 0;
		const timeTry = localStorage.getItem("@Inoveclube:timeTry");

		if (currentTry >= 3 && moment().diff(timeTry, "minutes") < 5) {
			toast.error(
				"Você atingiu o limite de tentativas, tente novamente em 5 minutos ou selecione outra forma de pagamento!",
			);
			return;
		}

		if (paymentMethodSelected?.idPayment !== 1000 && couponSelectedDatas.onlyOnlinePay) {
			toast.info(
				"Este cupom não aceita esse tipo de pagamento, por favor, selecione algum pagamento online.",
			);
			return;
		}

		setButtonFinishedDisable(true);

		let idSorteio = null;
		let idAddress = null;

		if (!businessDatas.needAddress && !localAddress.idAddress) {
			const { data } = await api.post(
				"/saveAddress",
				{
					...localAddress,
					token: localStorage.getItem("@Inoveclube:token"),
				},
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("@Inoveclube:token"),
					},
				},
			);
			idAddress = data.idAddress;
		}

		let object = {
			token: localStorage.getItem("@Inoveclube:token"),
			nameBusiness: localStorage.getItem("@Inoveclube:nameBusiness"),
			shoppingType: shoppingButtonSelected,
			idSubCanalVenda: handleSubCanalVenda(),
			valueType: localStorage.getItem("@Inoveclube:type") ? typeNumberDecrypted : "",
			address: businessDatas.needAddress
				? {
						...addressPersonSelected,
						idAddress: addressPersonSelected.idAddress ?? idAddress,
					}
				: {
						...localAddress,
						idAddress: localAddress.idAddress ?? idAddress,
					},
			cartItens: cartItens,
			paymentMethod: {
				idPayment: paymentMethodSelected?.idPayment,
				description: paymentMethodSelected?.description,
				idCard: paymentMethodSelected?.idCard,
				isPix: paymentMethodSelected?.pix || false,
				changeValue: changeValue,
			},
			totalValue: total,
			isWholesale: localStorage.getItem("@Inoveclube:wholesale"),
			feeDelivery: shoppingButtonSelected === "delivery" ? feeDelivery : 0,
			idCoupon:
				couponSelectedDatas.idCoupon !== -1 && progressCouponBar === false
					? couponSelectedDatas.idCoupon
					: 0,
			discount: total < couponSelectedDatas.minValue ? 0 : discountCouponCalculate,
			distance: distance,
			vlPagarmeService: vlPagarmeService,
			isTakeaway: localStorage.getItem("@Inoveclube:type") === "takeaway",
			isTable: localStorage.getItem("@Inoveclube:type") === "table",
			scheduleTime: JSON.parse(localStorage.getItem("@Inoveclube:agendamento")),
			isToSchedule: localStorage.getItem("@Inoveclube:agendamento") ? true : false,
			consumeInStore,
		};

		try {
			if (localStorage.getItem("@Inoveclube:type") !== "table") {
				if (paymentMethodSelected === null) {
					setBreathButton(true);
					window.scroll({
						top: buttonPaymentRef.current.offsetTop,
						behavior: "smooth",
					});
					throw new Error("Selecione um método de pagamento");
				} else if (
					shoppingButtonSelected === "delivery" &&
					addressPersonSelected.idAddress === undefined &&
					businessDatas.needAddress === true
				)
					throw new Error("Selecione um endereço para entrega");
				else if (shoppingButtonSelected === "delivery" && feeDelivery === -1)
					throw new Error(
						"Não é possível continuar a compra pois a empresa não atende sua localidade",
					);
			} else {
				if (typeNumberDecrypted === "") throw new Error("Impossível realizar a compra");
			}

			const nameBusiness = localStorage.getItem("@Inoveclube:nameBusiness");
			const responseDraw = await api.get(`/getActiveDraw/${nameBusiness}`);

			if (
				localStorage.getItem("@Inoveclube:type") &&
				localStorage.getItem("@Inoveclube:type") === "sweepstakes"
			) {
				if (responseDraw.data && responseDraw.data.results.length > 0) {
					idSorteio = responseDraw.data.results[0].idSorteio;
				}
			}

			const response = await api.post(
				"/saveOrder",
				{
					...object,
					idSorteio,
				},
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("@Inoveclube:token"),
					},
				},
			);

			const { message, success } = response.data;

			if (success === 0) {
				const currentTry = localStorage.getItem("@Inoveclube:try") || 0;
				localStorage.setItem("@Inoveclube:try", Number(currentTry) + 1);
				throw new Error(message);
			} else {
				toast.success(message);

				await saveLogClube({
					nameBusiness: localStorage.getItem("@Inoveclube:nameBusiness"),
					idStatus: 5,
					token: localStorage.getItem("@Inoveclube:token"),
					isTakeaway: localStorage.getItem("@Inoveclube:type") === "takeaway",
					isTable: localStorage.getItem("@Inoveclube:type") === "table",
				});

				if (responseDraw.data.results.length > 0) {
					setButtonFinishedDisable(false);
					setActiveSweepstakes(responseDraw.data.results);
				} else {
					localStorage.removeItem("@Inoveclube:try");
					localStorage.setItem(
						"@Inoveclube:timeTry",
						moment().format("YYYY-MM-DD HH:mm:ss"),
					);
					localStorage.removeItem(
						"@Inoveclube:listBuying" + localStorage.getItem("@Inoveclube:nameBusiness"),
					);
					localStorage.removeItem("@Inoveclube:type");
					localStorage.removeItem("@Inoveclube:typeNumber");
					localStorage.removeItem(
						`@Inoveclube:cupom${localStorage.getItem("@Inoveclube:nameBusiness")}`,
					);
					history.push("/orders");
				}
			}
		} catch (error) {
			const currentTry = localStorage.getItem("@Inoveclube:try") || 0;
			localStorage.setItem("@Inoveclube:try", Number(currentTry) + 1);
			localStorage.setItem("@Inoveclube:timeTry", moment().format("YYYY-MM-DD HH:mm:ss"));

			setButtonFinishedDisable(false);

			setTimeout(() => {
				setBreathButton(false);
			}, 4550);

			if (error.response) {
				if (error.response.status === 401 || error.response.status === 500) {
					signOut();
					toast.warning("Sua sessão foi expirada");
				} else {
					toast.error(error.response.data.message);
				}
			} else {
				toast.error(error.message);
			}
		}
	}

	const detectHistory = useCallback(() => {
		if (
			addressShoppingModalOpened ||
			paymentMethodsModalOpened ||
			neighborhoodsAlowedModal
		) {
			setAddressShoppingModalOpened(false);
			setPaymentMethodsModalOpened(false);
			setNeighborhoodsAlowedModal(false);
		}
	}, [addressShoppingModalOpened, paymentMethodsModalOpened, neighborhoodsAlowedModal]);

	useEffect(() => {
		if (
			addressShoppingModalOpened ||
			paymentMethodsModalOpened ||
			neighborhoodsAlowedModal
		) {
			window.history.pushState({ id: 1 }, null, null);
		}
	}, [addressShoppingModalOpened, paymentMethodsModalOpened, neighborhoodsAlowedModal]);

	useEffect(() => {
		if (
			addressShoppingModalOpened ||
			paymentMethodsModalOpened ||
			neighborhoodsAlowedModal
		) {
			window.addEventListener("popstate", detectHistory);
		}

		return () => window.removeEventListener("popstate", detectHistory);
	}, [
		detectHistory,
		addressShoppingModalOpened,
		paymentMethodsModalOpened,
		neighborhoodsAlowedModal,
	]);

	useEffect(() => {
		async function fetchData() {
			await api
				.get(
					`/getBusiness/${localStorage.getItem(
						"@Inoveclube:nameBusiness",
					)}/${localStorage.getItem("@Inoveclube:token")}`,
				)
				.then((response) => {
					const onlinePayment = JSON.parse(
						localStorage.getItem("@Inoveclube:onlinepmtd"),
					);
					const deliveryPayment = JSON.parse(
						localStorage.getItem(
							`@Inoveclube:deliverypmtd${localStorage.getItem(
								"@Inoveclube:nameBusiness",
							)}`,
						),
					);
					const deliveryPaymentExists = response.data.paymentsMethods.some(
						(payment) => payment.idPayment === deliveryPayment?.idPayment,
					);
					setTypeFeeDelivery(response.data.typeFeeDelivery);

					if (onlinePayment && response.data.usePaymentOnline) {
						setPaymentMethodSelected(onlinePayment);
						setBusinessData(response.data);
						return;
					}

					if (deliveryPaymentExists) setPaymentMethodSelected(deliveryPayment);
					else
						localStorage.removeItem(
							"@Inoveclube:pmtd" + localStorage.getItem("@Inoveclube:nameBusiness"),
						);

					setBusinessData(response.data);
				});

			setIsLoadingBusiness(false);
		}

		localStorage.removeItem(
			`@Inoveclube:pmtd${localStorage.getItem("@Inoveclube:nameBusiness")}`,
		);

		if (localStorage.getItem("@Inoveclube:nameBusiness")) fetchData();
	}, []);

	useEffect(() => {
		if (localStorage.getItem("@Inoveclube:wholesale") === "true") {
			setFeeDelivery(0);
			setStatusActive(true);
		}
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (localStorage.getItem("@Inoveclube:typeNumber")) {
				const typeNumber = localStorage.getItem("@Inoveclube:typeNumber");
				const nameBusiness = localStorage.getItem("@Inoveclube:nameBusiness");
				const type = localStorage.getItem("@Inoveclube:type");

				try {
					const response = await api.get(
						`/verifyType/${typeNumber}/${nameBusiness}/${type}`,
					);
					const { success, result } = response.data;

					if (success === 1) {
						setTypeNumberDecrypted(result);
					} else {
						if (localStorage.getItem("@Inoveclube:type") === "table") {
							toast.error("Número da mesa inválido");
							setQrCodeScreen({ visible: true, messageType: 0 });
						} else {
							toast.error("Comanda inválida");
							setQrCodeScreen({ visible: true, messageType: 1 });
						}
					}
				} catch (error) {
					toast.error(error.message);
					history.push("/menu/" + localStorage.getItem("@Inoveclube:nameBusiness"));
				}
			}
		}

		fetchData();
	}, [history]);

	useEffect(() => {
		if (businessDatas.idBusiness && businessDatas.modalities.length > 0) {
			const modality = businessDatas.modalities[0].type;

			if (
				businessDatas.modalities.length === 1 &&
				(modality === "table" || modality === "takeaway") &&
				!localStorage.getItem("@Inoveclube:type")
			) {
				if (modality === "table") setQrCodeScreen({ visible: true, messageType: 0 });
				else setQrCodeScreen({ visible: true, messageType: 1 });
			}

			setShoppingButtonSelected(
				localStorage.getItem("@Inoveclube:type")
					? localStorage.getItem("@Inoveclube:type") === "table"
						? localStorage.getItem("@Inoveclube:type")
						: "takeaway"
					: businessDatas.modalities[0].type,
			);
		}
	}, [businessDatas]);

	useEffect(() => {
		async function fetchData() {
			try {
				await api
					.get("/getAddressesPerson/" + localStorage.getItem("@Inoveclube:token"), {
						headers: {
							token: localStorage.getItem("@Inoveclube:token"),
						},
					})
					.then((response) => {
						const addressSelected = response.data.filter((item) => {
							return item.selected;
						});
						setAddressPersonSelected(addressSelected[0] || {});
						setListAddressPerson(response.data);
					});
				setIsLoadingAddressPerson(false);
			} catch (error) {
				if (error.response.status === 401 || error.response.status === 500) {
					signOut();
					toast.warning("Sua sessão foi expirada");
				} else {
					toast.error(error.response.data.message);
					history.push("/profile");
				}
			}
		}
		fetchData();
	}, [history, signOut]);

	const fetchDataValidateNeighborhood = useCallback(async () => {
		if (businessDatas.needAddress === true) {
			setLoadingFeeDelivery(true);
			try {
				const response = await api.get(
					"/validateNeighborhood/" + localStorage.getItem("@Inoveclube:nameBusiness"),
					{
						headers: {
							token: localStorage.getItem("@Inoveclube:token"),
						},
					},
				);

				if (response.data.status === true) {
					setFeeDelivery(response.data.feeDelivery);
					setNeighborhoodsAlowed([]);
					setNeighborhoodsAlowedModal(false);
					setLoadingFeeDelivery(false);
					setStatusActive(true);
				} else {
					setNeighborhoodsAlowed(response.data.neighborhoods);
					setNeighborhoodsAlowedModal(true);
					setFeeDelivery(-1);
					setStatusActive(false);
					setLoadingFeeDelivery(false);
				}
			} catch (error) {
				if (error.response.status === 401 || error.response.status === 500) {
					signOut();
					setStatusActive(false);
					toast.warning("Sua sessão foi expirada");
				} else {
					toast.error(error.response.data.message);
					setStatusActive(false);
					history.push("/profile");
				}
			}
		}
	}, [history, signOut, businessDatas]);

	const apiCageSearchCep = useCallback(async (position) => {
		setLoadingFeeDelivery(true);
		const lat = position.coords.latitude;
		const long = position.coords.longitude;

		try {
			const opencagedata = await fetch(
				`https://api.opencagedata.com/geocode/v1/json?q=${lat}+${long}&key=${process.env.REACT_APP_OPENCAGE_KEY}&language=pt-BR`,
			);

			const address = await opencagedata.json();

			const { data: addressFromApi } = await api.get(
				`/getAddressesPerson/${localStorage.getItem("@Inoveclube:token")}`,
			);

			const addressFound = addressFromApi.find(
				(item) => item?.street === address?.results[0]?.components?.road?.toUpperCase(),
			);

			if (addressFound) {
				setLocalAddress({
					street: address.results[0].components.road,
					number: "",
					neighborhood: address.results[0].components.suburb,
					city: address.results[0].components.city,
					state: address.results[0].components.state,
					complement: "",
					reference: "",
					region: address.results[0].components.region,
					state_code: address.results[0].components.state_code,
					country_code: address.results[0].components.country_code,
					lat,
					long,
					...addressFound,
				});
			} else {
				setLocalAddress({
					street: address.results[0].components.road,
					number: "",
					neighborhood: address.results[0].components.suburb,
					city: address.results[0].components.city,
					state: address.results[0].components.state,
					complement: "",
					reference: "",
					region: address.results[0].components.region,
					state_code: address.results[0].components.state_code,
					country_code: address.results[0].components.country_code,
					lat,
					long,
				});
			}

			const getFeeDeliveryForKm = await api.post(
				"/getFeeDeliveryForKm",
				{
					business: localStorage.getItem("@Inoveclube:nameBusiness"),
					destiny: {
						rua: address.results[0].components.road,
						numero: "",
						bairro: address.results[0].components.suburb,
						cidade: address.results[0].components.city,
						estado: address.results[0].components.state,
					},
				},
				{
					headers: {
						"Content-Type": "application/json",
						token: localStorage.getItem("@Inoveclube:token"),
					},
				},
			);

			if (getFeeDeliveryForKm.data.success === 0) {
				throw new Error(getFeeDeliveryForKm.data.message);
			}

			setDistance(getFeeDeliveryForKm.data.distance);
			setFeeDelivery(getFeeDeliveryForKm.data.valueFeeDelivery);
			setStatusActive(true);

			// check if the user addess exists in the list of addresses
		} catch (error) {
			toast.info(`Localização inválida. ${error}`);
		} finally {
			setLoadingFeeDelivery(false);
		}
	}, []);

	const getUserLocale = useCallback(async () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				apiCageSearchCep(position);
			},
			(error) => {
				console.log(error);
				toast.error(
					"Localização inválida, verifique a ativação da localização do seu dispositivo e tente novamente",
				);
			},
			{ enableHighAccuracy: true, maximumAge: 30000, timeout: 10000 },
		);
	}, [apiCageSearchCep]);

	const fetchDataFeeDeliveryKm = useCallback(async () => {
		if (businessDatas.needAddress === true) {
			if (Object.keys(addressPersonSelected).length > 0) {
				setLoadingFeeDelivery(true);
				try {
					const response = await api.post(
						"/getFeeDeliveryForKm",
						{
							business: localStorage.getItem("@Inoveclube:nameBusiness"),
							destiny: {
								rua: addressPersonSelected.street,
								numero: addressPersonSelected.number,
								bairro: addressPersonSelected.neighborhood,
								cidade: addressPersonSelected.city,
								estado: addressPersonSelected.state,
							},
						},
						{
							headers: {
								"Content-Type": "application/json",
								token: localStorage.getItem("@Inoveclube:token"),
							},
						},
					);

					if (response.data.success === 0) {
						throw new Error(response.data.message);
					}

					setDistance(response.data.distance);
					setFeeDelivery(response.data.valueFeeDelivery);
					setStatusActive(true);
				} catch (error) {
					setFeeDelivery(-1);
					toast.error(error?.message);
					setLoadingFeeDelivery(false);
					setStatusActive(false);
				} finally {
					setLoadingFeeDelivery(false);
				}
			}
		}
	}, [businessDatas, addressPersonSelected]);

	///Calculando os fretes
	useEffect(() => {
		async function load() {
			if (typeFeeDelivery.toUpperCase() === "B") {
				await fetchDataValidateNeighborhood();
			} else if (
				typeFeeDelivery.toUpperCase() === "KM" ||
				typeFeeDelivery.toUpperCase().indexOf("K") >= 0
			) {
				if (businessDatas.needAddress === true) {
					await fetchDataFeeDeliveryKm();
				} else {
					await getUserLocale();
				}
			}
		}

		if (
			localStorage.getItem("@Inoveclube:wholesale") === "false" &&
			localStorage.getItem("@Inoveclube:type") !== "table"
		) {
			if (shoppingButtonSelected === "delivery" && addressShoppingModalOpened === false) {
				load();
			}
		}
	}, [
		typeFeeDelivery,
		fetchDataFeeDeliveryKm,
		fetchDataValidateNeighborhood,
		shoppingButtonSelected,
		addressShoppingModalOpened,
		getUserLocale,
		businessDatas.needAddress,
	]);

	useEffect(() => {
		if (localStorage.getItem("@Inoveclube:shopping")) {
			localStorage.removeItem("@Inoveclube:shopping");
			setAddressShoppingModalOpened(true);
		}
		localStorage.removeItem("@Inoveclube:saveAllowedAddress");
		localStorage.removeItem("@Inoveclube:menu");
		document.body.style.overflow = "auto";
	}, [handleUpdateAddress]);

	useEffect(() => {
		if (!isLoadingBusiness && businessDatas.paymentsMethods !== undefined)
			setIsEnableInputSelectPayment(true);
		else setIsEnableInputSelectPayment(false);
	}, [businessDatas, isLoadingBusiness, isEnableInputSelectPayment]);

	useEffect(() => {
		if (isLoadingAddressPerson === false) setIsEnableInputSelectAddress(true);
	}, [listAddressPerson, isLoadingAddressPerson, isEnableInputSelectAddress]);

	useEffect(() => {
		addressShoppingModalOpened === true || paymentMethodsModalOpened === true
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "auto");
	}, [addressShoppingModalOpened, paymentMethodsModalOpened]);

	//coupom
	useEffect(() => {
		if (couponSelectedDatas.rule === 1 && progressCouponBar === false) {
			if (!!productsLinkedToCoupon?.length && !someProductLinkedIsInCart) {
				setFeeDeliveryWithCoupon(-1);

				return;
			}

			couponSelectedDatas.typeDiscount === 0
				? setFeeDeliveryWithCoupon(
						couponSelectedDatas.discountValue > feeDelivery
							? feeDelivery
							: couponSelectedDatas.discountValue,
					)
				: setFeeDeliveryWithCoupon(
						feeDelivery * (couponSelectedDatas.discountValue / 100),
					);
		} else setFeeDeliveryWithCoupon(-1);
	}, [
		couponSelectedDatas,
		feeDelivery,
		progressCouponBar,
		productsLinkedToCoupon,
		someProductLinkedIsInCart,
	]);

	useEffect(() => {
		const verifyingShopType = shoppingButtonSelected === "delivery" ? feeDelivery : 0;

		if (couponSelectedDatas.rule === 0 && progressCouponBar === false) {
			if (!!productsLinkedToCoupon?.length && !someProductLinkedIsInCart) {
				setTotalWithCoupon(-1);

				return;
			}

			couponSelectedDatas.typeDiscount === 0
				? setTotalWithCoupon(
						total - couponSelectedDatas.discountValue <= 0
							? verifyingShopType
							: total - couponSelectedDatas.discountValue + verifyingShopType,
					)
				: setTotalWithCoupon(
						total - (total * couponSelectedDatas.discountValue) / 100 + verifyingShopType,
					);
		} else if (couponSelectedDatas.rule === 2 && progressCouponBar === false) {
			if (!!productsLinkedToCoupon?.length && !someProductLinkedIsInCart) {
				setTotalWithCoupon(-1);

				return;
			}

			couponSelectedDatas.typeDiscount === 0
				? setTotalWithCoupon(
						total + verifyingShopType - couponSelectedDatas.discountValue <= 0
							? 0
							: total + verifyingShopType - couponSelectedDatas.discountValue,
					)
				: setTotalWithCoupon(
						total +
							verifyingShopType -
							((total + verifyingShopType) * couponSelectedDatas.discountValue) / 100,
					);
		} else setTotalWithCoupon(-1);
	}, [
		couponSelectedDatas,
		feeDelivery,
		total,
		progressCouponBar,
		shoppingButtonSelected,
		productsLinkedToCoupon,
		someProductLinkedIsInCart,
	]);

	useEffect(() => {
		if (couponSelectedDatas.minValue > 0 && couponSelectedDatas.minValue > total)
			setProgressCouponBar(true);
		else setProgressCouponBar(false);
	}, [couponSelectedDatas, total]);

	useEffect(() => {
		if (couponSelectedDatas.rule === 1 && shoppingButtonSelected === "takeaway") {
			setCouponSelectedDatas(couponClear);
			localStorage.removeItem("@Inoveclube:cupom");
		}
	}, [couponSelectedDatas, shoppingButtonSelected, couponClear]);

	useEffect(() => {
		if (!!productsLinkedToCoupon?.length && !someProductLinkedIsInCart) {
			setCouponSelectedDatas(couponClear);
			localStorage.removeItem(
				`@Inoveclube:cupom${localStorage.getItem("@Inoveclube:nameBusiness")}`,
			);
			toast.warn(
				"⚠️ Selecione um dos produtos nas regras do cupom para validar seu desconto",
			);
		}
	}, [productsLinkedToCoupon, someProductLinkedIsInCart, couponClear]);

	const deliveryDiscountValue = useCallback(() => {
		const moneyValue =
			couponSelectedDatas.discountValue > feeDelivery
				? feeDelivery
				: couponSelectedDatas.discountValue;
		const percentValue = feeDelivery * (couponSelectedDatas.discountValue / 100);

		setDiscountCouponCalculate(
			couponSelectedDatas.typeDiscount === 0 ? moneyValue : percentValue,
		);
	}, [couponSelectedDatas, feeDelivery]);

	const productsDiscountValue = useCallback(() => {
		const moneyValue =
			couponSelectedDatas.discountValue > total
				? total
				: couponSelectedDatas.discountValue;
		const percentValue = total * (couponSelectedDatas.discountValue / 100);

		setDiscountCouponCalculate(
			couponSelectedDatas.typeDiscount === 0 ? moneyValue : percentValue,
		);
	}, [couponSelectedDatas, total]);

	const totalDiscountValue = useCallback(() => {
		const shopType = shoppingButtonSelected === "delivery" ? feeDelivery : 0;
		const moneyValue =
			couponSelectedDatas.discountValue > total + shopType
				? total + shopType
				: couponSelectedDatas.discountValue;
		const percentValue = (total + shopType) * (couponSelectedDatas.discountValue / 100);

		setDiscountCouponCalculate(
			couponSelectedDatas.typeDiscount === 0 ? moneyValue : percentValue,
		);
	}, [couponSelectedDatas, feeDelivery, shoppingButtonSelected, total]);

	const changeCouponDiscountValue = useCallback(() => {
		if (couponSelectedDatas.rule === 1 && shoppingButtonSelected === "delivery")
			deliveryDiscountValue();
		else if (couponSelectedDatas.rule === 0) productsDiscountValue();
		else if (couponSelectedDatas.rule === 2) totalDiscountValue();
		else setDiscountCouponCalculate(0);
	}, [
		couponSelectedDatas,
		shoppingButtonSelected,
		deliveryDiscountValue,
		productsDiscountValue,
		totalDiscountValue,
	]);

	useEffect(() => {
		changeCouponDiscountValue();
	}, [changeCouponDiscountValue]);

	const calculateTotal = useMemo(() => {
		if (localStorage.getItem("@Inoveclube:type")) {
			return totalWithCoupon !== -1 ? totalWithCoupon : total;
		}

		if (shoppingButtonSelected === "delivery") {
			return totalWithCoupon !== -1
				? totalWithCoupon
				: feeDeliveryWithCoupon !== -1
					? total + feeDelivery - feeDeliveryWithCoupon
					: total + feeDelivery;
		}

		return totalWithCoupon !== -1 ? totalWithCoupon : total;
	}, [
		shoppingButtonSelected,
		feeDelivery,
		feeDeliveryWithCoupon,
		total,
		totalWithCoupon,
	]);

	const priceInReal = useMemo(() => {
		if (!calculateTotal) return null;

		if (businessDatas.valueInReal && businessDatas.valueInReal > 0) {
			return calculateTotal * businessDatas.valueInReal;
		}

		return null;
	}, [businessDatas, calculateTotal]);

	function handleClickFinish() {
		if (statusActive || shoppingButtonSelected === "takeaway") {
			if (businessDatas.minValue > 0 && total < businessDatas.minValue) {
				toast.error(
					`O valor mínimo de compra neste estabelecimento é: ${formatMoney(
						businessDatas.minValue,
						businessDatas.formatoMoeda,
					)}`,
				);
			} else {
				if (
					businessDatas.isOpenBusiness ||
					localStorage.getItem("@Inoveclube:agendamento")
				) {
					if (shoppingButtonSelected === "takeaway") {
						if (
							businessDatas.perguntarParaLevar &&
							businessDatas.perguntarEntregaNoBalcao
						) {
							setOpenModalQuestion(true);
						} else if (
							businessDatas.perguntarParaLevar &&
							!businessDatas.perguntarEntregaNoBalcao
						) {
							handleSendOrder(false);
						} else if (
							!businessDatas.perguntarParaLevar &&
							businessDatas.perguntarEntregaNoBalcao
						) {
							handleSendOrder(true);
						} else {
							setOpenModalQuestion(true);
						}
					} else {
						handleSendOrder();
					}
				} else {
					toast.info("O estabelecimento encontra-se fechado no momento");
				}
			}
		} else {
			toast.error("Empresa não atende a este endereço");
		}
	}

	useEffect(() => {
		setDtAgendamento(JSON.parse(localStorage.getItem("@Inoveclube:agendamento")));
	}, []);

	if (qrCodeScreen.visible)
		return (
			<QrCodeScreen
				color={businessDatas?.colorButton}
				messageType={qrCodeScreen.messageType}
			/>
		);

	if (
		localStorage.getItem(
			`@Inoveclube:listBuying${localStorage.getItem("@Inoveclube:nameBusiness")}`,
		)
	) {
		return (
			<Container>
				<Header>
					<AiOutlineArrowLeft
						onClick={() => {
							localStorage.getItem("@Inoveclube:type")
								? history.push(
										`/menu/${localStorage.getItem(
											"@Inoveclube:nameBusiness",
										)}/${localStorage.getItem("@Inoveclube:type")}/${localStorage.getItem(
											"@Inoveclube:typeNumber",
										)}`,
									)
								: history.push(
										`/menu/${localStorage.getItem("@Inoveclube:nameBusiness")}`,
									);
						}}
					/>
					<h1>Finalizar Pedido</h1>
				</Header>
				<div className="headerFill" />

				<Content>
					{isLoadingBusiness === false ? (
						<ButtonsContainer>
							{localStorage.getItem("@Inoveclube:type") &&
							localStorage.getItem("@Inoveclube:type") !== "sweepstakes" ? (
								localStorage.getItem("@Inoveclube:type") === "table" ? (
									<RadioButtonShopping>
										<SiAirtable />
										<p>Mesa {typeNumberDecrypted}</p>
									</RadioButtonShopping>
								) : localStorage.getItem("@Inoveclube:type") === "takeaway" ? (
									<RadioButtonShopping isActive={shoppingButtonSelected === "takeaway"}>
										<GiShoppingBag />
										<p>Retirada</p>
									</RadioButtonShopping>
								) : (
									<RadioButtonShopping>
										<GiShoppingBag />
										<p>Nº {typeNumberDecrypted}</p>
									</RadioButtonShopping>
								)
							) : (
								businessDatas.modalities.map((modalitie, index) =>
									modalitie.type === "delivery" ? (
										<RadioButtonShopping
											key={index}
											onClick={() => {
												setShoppingButtonSelected("delivery");
												handleModifyChange();
											}}
											isActive={shoppingButtonSelected === "delivery"}
										>
											<FaMotorcycle />
											<p>{modalitie.description}</p>
										</RadioButtonShopping>
									) : modalitie.type === "takeaway" ? (
										<RadioButtonShopping
											key={modalitie.type}
											onClick={() => {
												setShoppingButtonSelected("takeaway");
												handleModifyChange();
											}}
											isActive={shoppingButtonSelected === "takeaway"}
										>
											<GiShoppingBag />
											<p>Retirada</p>
										</RadioButtonShopping>
									) : (
										<></>
									),
								)
							)}
						</ButtonsContainer>
					) : (
						<GeneralLoader />
					)}

					{localStorage.getItem("@Inoveclube:type") === "table" ? (
						<></>
					) : shoppingButtonSelected === "delivery" && businessDatas.needAddress ? (
						<InputAddressArea isEnableInputSelectAddress={isEnableInputSelectAddress}>
							<div
								className="buttonModalAddress"
								onClick={() => {
									setAddressShoppingModalOpened(true);
									setFeeDelivery(-1);
								}}
							>
								<FaMapMarkedAlt className="mapLocation" />
								{addressPersonSelected.street !== undefined ? (
									<div>
										<p>Entregar em</p>
										<h4>
											{addressPersonSelected.street}, {addressPersonSelected.number}
										</h4>
										<p>
											{addressPersonSelected.neighborhood}{" "}
											{addressPersonSelected.complement !== ""
												? `- ${addressPersonSelected.complement}`
												: ""}
										</p>
										<p>cep: {addressPersonSelected.zipCode}</p>
									</div>
								) : (
									<p>Escolha o endereço</p>
								)}

								<IoIosArrowForward />
							</div>
						</InputAddressArea>
					) : shoppingButtonSelected === "takeaway" ? (
						<InputAddressArea>
							<div className="businessAddress">
								<FaWalking />
								<div>
									<p>Retirar em</p>
									<h4>
										{businessDatas.street}, {businessDatas.number}
									</h4>
									<p>
										{businessDatas.neighborhood} - {businessDatas.phoneNumber}
									</p>
								</div>
							</div>
						</InputAddressArea>
					) : (
						businessDatas.needAddress === false && (
							<InputAddressArea>
								<div className="businessAddress">
									<FaLocationCrosshairs />
									<div>
										<p>Usando localização atual</p>
									</div>
								</div>
							</InputAddressArea>
						)
					)}

					{addressShoppingModalOpened ? (
						<AddressShoppingModal
							listAddressPerson={listAddressPerson}
							setListAddressPerson={setListAddressPerson}
							setAddressShoppingModalOpened={setAddressShoppingModalOpened}
							setChangeValue={setChangeValue}
							changeValue={changeValue}
							setAddressPersonSelected={setAddressPersonSelected}
						/>
					) : (
						<></>
					)}

					{neighborhoodsAlowedModal ? (
						<NeighborhoodsAlowedModal
							neighborhoodsAlowed={neighborhoodsAlowed}
							setNeighborhoodsAlowedModal={setNeighborhoodsAlowedModal}
							setAddressShoppingModalOpened={setAddressShoppingModalOpened}
						/>
					) : (
						<></>
					)}

					{dtAgendamento && (
						<p
							style={{
								marginTop: "20px",
								fontWeight: "500",
							}}
						>
							Agendamento para:{" "}
							{moment(dtAgendamento).locale("pt-BR").format("DD/MM/YYYY HH:mm")}
						</p>
					)}

					<FixedShoppingInformations>
						<div className="tableProductsShopp">
							{listBuying.map((item, index) => (
								<div key={index}>
									<p>
										<span>{item.addCounterProduct}</span> {item.name}
									</p>
									{item.listAssemblyItensSelected.map((obs, idx) =>
										obs.filled ? (
											<p key={idx} className="obsStyle">
												{item.assembledItensFilled === 1
													? "1"
													: "1/" + item.assembledItensFilled}{" "}
												{obs.name}
											</p>
										) : (
											<></>
										),
									)}
									{item.selectedOptions.map((obs, i) => (
										<p key={i} className="obsStyle">
											{obs.qtd}X {obs.obsDescription}
										</p>
									))}
									{item.textAdditional !== "" ? (
										<p className="obsStyle">obs: {item.textAdditional}</p>
									) : (
										<></>
									)}
								</div>
							))}
							<p className="totalShopping">
								{formatMoney(total, businessDatas.formatoMoeda)}
							</p>
						</div>

						<div
							className="buttonAddShopping"
							onClick={() => {
								localStorage.getItem("@Inoveclube:type")
									? history.push(
											`/menu/${localStorage.getItem(
												"@Inoveclube:nameBusiness",
											)}/${localStorage.getItem(
												"@Inoveclube:type",
											)}/${localStorage.getItem("@Inoveclube:typeNumber")}`,
										)
									: history.push(
											`/menu/${localStorage.getItem("@Inoveclube:nameBusiness")}`,
										);
							}}
						>
							Adicionar mais itens
						</div>

						{localStorage.getItem("@Inoveclube:type") === "table" ? (
							<></>
						) : (
							<ButtonToSelectPaymentForm
								paymentMethodSelected={paymentMethodSelected} //state que pega os dados do cartao
								breathButton={breathButton}
								isEnableInputSelectPayment={isEnableInputSelectPayment}
								changeValue={changeValue}
								ref={buttonPaymentRef}
								onClick={() => setPaymentMethodsModalOpened(true)}
							/>
						)}

						{paymentMethodsModalOpened && (
							<PaymentMethodsModal
								setPaymentMethodsModalOpened={setPaymentMethodsModalOpened}
								businessDatas={businessDatas}
								handleAddPaymentMethod={handleAddPaymentMethod}
								verifyChange={
									shoppingButtonSelected === "delivery"
										? feeDeliveryWithCoupon !== -1
											? total + feeDelivery - feeDeliveryWithCoupon
											: total + feeDelivery
										: total
								}
								handleSetChangeValue={handleSetChangeValue}
								disabledPaymentOffline={
									localStorage.getItem("@Inoveclube:type") === "takeaway"
								}
							/>
						)}

						{/* início componentização */}
						<ShoppingProvider>
							<CouponsButton
								couponSelectedDatas={couponSelectedDatas}
								setCouponSelectedDatas={setCouponSelectedDatas}
								couponClear={couponClear}
							/>

							<UserCouponsModal
								setCouponSelectedDatas={setCouponSelectedDatas}
								shoppingButtonSelected={shoppingButtonSelected}
							/>
						</ShoppingProvider>

						<ProgressBarCoupon
							progressCouponBar={progressCouponBar}
							total={total}
							minValue={couponSelectedDatas.minValue}
						/>
						<div className="grayLineDivide" />

						{loadingFeeDelivery ? (
							<ContainerLoader>
								<SpaceLoader colorLoader="var(--colorFontsCardsDashboard)" />
							</ContainerLoader>
						) : (
							<div className="tablePricesShopp">
								<div>
									<p>Subtotal</p>
									<p>{formatMoney(total, businessDatas.formatoMoeda)}</p>
								</div>

								{localStorage.getItem("@Inoveclube:type") === "table" ||
								localStorage.getItem("@Inoveclube:type") === "takeaway" ? (
									<></>
								) : shoppingButtonSelected === "delivery" ? (
									<div>
										<p>Taxa de entrega</p>
										{feeDelivery === 0 ? (
											<span>Grátis</span>
										) : (
											<span>
												{feeDelivery === -1 ? (
													<></>
												) : (
													formatMoney(feeDelivery, businessDatas.formatoMoeda)
												)}
											</span>
										)}
									</div>
								) : (
									<></>
								)}

								{!!productsLinkedToCoupon?.length && !someProductLinkedIsInCart ? (
									<></>
								) : (
									couponSelectedDatas.idCoupon !== -1 &&
									!progressCouponBar && (
										<div>
											<p>Cupom</p>
											<span>
												-
												{formatMoney(discountCouponCalculate, businessDatas.formatoMoeda)}
											</span>
										</div>
									)
								)}

								<div>
									<h1>Total</h1>
									<h2>{formatMoney(calculateTotal, businessDatas.formatoMoeda)}</h2>
								</div>

								{priceInReal && priceInReal > 0 && (
									<div>
										<p />
										<span>({formatMoney(priceInReal)})</span>
									</div>
								)}
							</div>
						)}
					</FixedShoppingInformations>

					<FinishShoppingArea buttonFinishedDisable={buttonFinishedDisable}>
						<div className="container">
							<div className="textTotal">
								<span>Total</span>
								<p>{formatMoney(calculateTotal, businessDatas.formatoMoeda)}</p>
							</div>

							<div
								disabled={
									shoppingButtonSelected !== "takeaway"
										? loadingFeeDelivery
											? true
											: !statusActive
										: false
								}
								className="buttonShopp"
								onClick={handleClickFinish}
								onKeyDown={handleClickFinish}
							>
								Finalizar
							</div>
						</div>
					</FinishShoppingArea>

					<div className="marginDiv" />
				</Content>

				{acitivesSweepstakes && acitivesSweepstakes.length > 0 && (
					<DrawModal
						businessDatas={businessDatas}
						active={acitivesSweepstakes.length > 0}
						draw={acitivesSweepstakes[0]}
						navigate={() => {
							localStorage.removeItem(
								`@Inoveclube:listBuying${localStorage.getItem(
									"@Inoveclube:nameBusiness",
								)}`,
							);
							localStorage.removeItem("@Inoveclube:type");
							localStorage.removeItem("@Inoveclube:typeNumber");
							localStorage.removeItem(
								`@Inoveclube:cupom${localStorage.getItem("@Inoveclube:nameBusiness")}`,
							);
							history.push("/orders");
						}}
						message={"Obrigado por participar do sorteio"}
						submessage={`O sorteio acontecerá no dia ${moment(
							acitivesSweepstakes[0].dtSorteio,
						)
							.utcOffset(0)
							.format("DD/MM/YYYY HH:mm")}`}
						goodLuck="Boa sorte!"
					/>
				)}

				{openModalQuestion && (
					<QuestionModal
						sendOrder={handleSendOrder}
						onClose={() => setOpenModalQuestion(false)}
					/>
				)}

				{buttonFinishedDisable ? <MainLoader title={"Realizando pedido"} /> : <></>}
			</Container>
		);
	}

	return (
		<WarningPage>
			<div>Você não está realizando uma compra</div>
			<button
				type="button"
				onClick={() => {
					history.push("/");
				}}
			>
				Acessar inoveclube.com
			</button>
		</WarningPage>
	);
}
