import styled, { css } from 'styled-components'

export const Container = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;    
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const Content = styled.div`
    height: 20rem;
    width: 20rem;
    background: #FFF;
    position: relative;

   

    .container-input{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    p{
        margin-bottom: 100px;
        text-align: center;
        padding: 1.5rem 0 0 0;
        font-size: 1.3rem;
    }

    input{
        width: 95%;
        border: 1px solid black;
        padding: 10px;
    }

    button{
        border: none;
        padding: 20px 40px 20px 40px;
        margin-top: 20px;
        color: white;
        border-radius: 0.25rem;
        ${props => props.bgcolor && css`
            background: ${props.bgcolor};
        `}
    }

    span{
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 1.2rem;
        cursor: pointer;
    }
`