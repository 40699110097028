import { IoWarning } from 'react-icons/io5'

import { Container, Content } from './style'

export function ConfirmToShop({ openConfirmToShop, setOpenConfirmToShop, assemblingReorder }) {
    function handleCloseModal() {
        setOpenConfirmToShop({ open: false, inactiveProducts: [] })
    }

    function handleGoToShop() {
        assemblingReorder()
    }

    if(openConfirmToShop.open){
        return(
            <Container>
                <Content>
                    <div className='warningIcon'>
                        <IoWarning />
                    </div>
                    
                    <p>Os itens a seguir sofreram alterações:</p>
    
                    <div className='listItens'>
                        {
                            openConfirmToShop.inactiveProducts.map(product => (
                                <strong key={product.idProduct}>
                                    {product.name}
                                </strong>
                            ))
                        }
                    </div>
    
                    <p>Deseja continuar sem eles?</p>
    
                    <div className='buttons'>
                        <button 
                            className='confirmButton'
                            onClick={handleGoToShop}
                        >
                            Sim
                        </button>

                        <button 
                            className='rejectButton'
                            onClick={handleCloseModal}
                        >
                            Não
                        </button>
                    </div>
                </Content>
            </Container>
        )
    }
    else
        return <></>
}
