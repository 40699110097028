import styled, { css } from 'styled-components'

export const Container = styled.div`
    color: gray;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & + &{
        margin-left: 1rem;
    }

    @media (min-width: 960px){
        cursor: pointer;
    }

    pre{
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0.4rem;
        transition: all 0.3s;
    }

    .line{
        height: 2px;
        width: 100%;
        background: ${props => props.colorButton};
        transition: all 0.3s;
        opacity: 0;
    }

    
    ${props => props.active && css`
        color: ${props.colorButton};

        .line{
           opacity: 1;
        }
    `}
`