import styled, { css } from 'styled-components'

export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto 1rem auto;
    
    h3{       
        font-size: 1rem;
        padding: 0.8rem 0 0.8rem 0;    
    }



    .containerProducts{
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 0 auto;

        ${props => props.searchTerm !== '' && css`
            max-width: 600px;
        `}

        span{
            color: rgb(0, 100, 0);
        }
    }

    @media (max-width: 1228px){
        width: 95%;
    }
`

export const CardProduct = styled.div`
    display: flex;
    padding: 0.8rem 0.5rem 0.8rem 0.5rem;
    margin: 0.5rem;
    border-bottom: 1px solid #F7F7F7;
    border-radius: 4px;
    min-width: 30%;

    @media (min-width: 960px){
        cursor: pointer;
    }

    @media (max-width: 968px){
        min-width: 45%;
    }

    @media (max-width: 596px){
        min-width: 90%;
    }
    

    ${props => props.searchTerm !== '' && css`
        width: 100%;
    `}

    .image{
        min-height: 3.5rem;
        min-width: 4.5rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        ${props => props.linkImage && css`
            background-image: url("${props.linkImage}");

        `}
    }

    .text{
        margin-left: 0.8rem;
        flex: auto;

        p{
            font-size: 0.85rem;
            font-weight: 900;
        }

        .product{
            display: flex;
            flex-direction: column;
            
        }

        .additional{
            color: #131313;
            max-width: 80%;
        }
    }
`