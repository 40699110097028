import styled from "styled-components"

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	list-style: none;

	li {
		padding: 0.5rem;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		border-bottom: 1px solid var(--gray-200);

		button {
			border: none;
			background-color: var(--brand-500);
			color: var(--gray-100);
			padding: 0.3rem 2rem;
			border-radius: 8px;
		}

		&:hover {
			background-color: var(--gray-200);
		}
	}
`
export const Advice = styled.p`
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 1rem;
`
