import { useState } from "react";
import { useCardForm } from "./hooks/useCardForm";
import { useRegisterCard } from "./hooks/useRegisterCard";

import { AiFillSafetyCertificate, AiOutlineQuestionCircle } from "react-icons/ai";

import { DotTypingLoader } from "../../../loaders/dotTypingLoader";

import cvvImg from "../../../images/cvv.png";
import { Container, Button, ContentInput, Input, TypeCard } from "./style";

import ReactInputMask from "react-input-mask";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

export function CardForm() {
	const {
		handleSubmit,
		register,
		cardCvv,
		cardExpirationDate,
		cardHolderName,
		cardNumber,
	} = useCardForm();

	const { loadingCardInformations, gettingIdPersonPagarme } = useRegisterCard();

	const [cardFocused, setCardFocused] = useState("");

	async function onSubmit(data) {
		await gettingIdPersonPagarme(data);
	}

	return (
		<Container>
			<Cards
				cvc={cardCvv || ""}
				expiry={cardExpirationDate || ""}
				name={cardHolderName || ""}
				number={cardNumber || ""}
				focused={cardFocused || ""}
				acceptedCards={[
					"visa",
					"mastercard",
					"elo",
					"amex",
					"discover",
					"jcb",
					"hipercard",
				]}
			/>

			<div className="safe">
				<AiFillSafetyCertificate />
				<p>Compra Segura</p>
			</div>

			<h3>Detalhes do cartão</h3>

			<form onSubmit={handleSubmit(onSubmit)}>
				<ContentInput>
					<p>Número do Cartão</p>

					<Input>
						<ReactInputMask
							placeholder="0000 0000 0000 0000"
							mask="9999 9999 9999 9999"
							{...register("number")}
							onFocus={() => setCardFocused("number")}
						/>
					</Input>
				</ContentInput>

				<div className="validityAndType">
					<ContentInput>
						<p>Validade</p>

						<Input>
							<ReactInputMask
								placeholder="MM/AA"
								mask="99/99"
								{...register("date")}
								onFocus={() => setCardFocused("expiry")}
							/>
						</Input>
					</ContentInput>

					<TypeCard>
						<select {...register("type")}>
							<option value="credit_card">Crédito</option>
						</select>
					</TypeCard>
				</div>

				<ContentInput>
					<p>Nome do cartão</p>

					<Input>
						<input
							placeholder="Seu nome"
							{...register("name")}
							onFocus={() => setCardFocused("name")}
						/>
					</Input>
				</ContentInput>

				<ContentInput>
					<p className="cvvExplanation">
						CVV <AiOutlineQuestionCircle />
					</p>

					<Input>
						<input
							placeholder="CVV"
							maxLength={4}
							{...register("cvv")}
							onFocus={() => setCardFocused("cvc")}
						/>

						<img src={cvvImg} alt="CVV" />
					</Input>
				</ContentInput>

				<Button enable={loadingCardInformations} type="submit">
					{loadingCardInformations ? <DotTypingLoader /> : "Cadastrar"}
				</Button>
			</form>
		</Container>
	);
}
