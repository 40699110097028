import { TransversalBar } from '../../components/pagesComponents/transversalBar'
import { LoginContent } from '../../components/pagesComponents/loginPage';
 
import { Container } from './style';

export function Login() {
    return(
        <Container>
            <TransversalBar />
            <LoginContent />
        </Container>
    )
}
