import styled from 'styled-components'

export const Container = styled.div`
    padding: 20px;
    margin: 1rem 5px;
    display: flex;
    max-width: 22rem;
    border: 1px solid rgba( 125, 125, 125, 0.1);
    align-items: center;
    transition: all 0.2s;
    border-radius: 4px;
    position: relative;

    &::before,
    &::after{
        position: absolute;
        --opacity: 0;
        --tooltip-color: ${props => props.colorBgHeader ? props.colorBgHeader : '#000'};
        top: -.25rem;
        left: 50%;
        transform: translateX(-50%) translateY(var(--translate-y, 0));
        opacity: var(--opacity);
        transition: opacity 0.3s;
    }

    &::before{
        --translate-y: calc(-100% - 10px);
        content: attr(data-tooltip);
        padding: 1rem;
        width: max-content;
        max-width: 100%;
        background: var(--tooltip-color);
        color: ${props => props.colorLabel ? props.colorLabel : '#FFF'};
        text-align: center;
        border-radius: .3rem;
    }

    &::after{
        content: '';
        --translate-y: calc(-1 * 10px);
        border: 10px solid transparent;
        border-top-color: var(--tooltip-color);
    }

    &:hover::before,
    &:hover::after{
        --opacity: 1;
    }


    @media (min-width: 960px){
        cursor: pointer;
    }

    &:hover{
        border: 1px solid #dbdad9;
    }

    .business{
        display: flex;
        flex-direction: column;
        align-items: center;

        span{
            font-size: 0.8rem;
            text-align: center;
        }
    }

    .image{
        height: 3.2rem;
        width: 3.2rem;
        min-width: 3.2rem;
        min-height: 3.2rem;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid rgba( 125, 125, 125, 0.1);
        position: relative;

        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        div{
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-weight: 500;
            font-size: 0.7rem;
        }
    }

    .line{
        width: 1px;
        height: 5rem;
        background: rgba( 125, 125, 125, 0.1);
        margin: 0 1.2rem;
    }

    .text{
        flex: auto;
        overflow-x: hidden;

        pre{
            font-weight: 700;
            font-size: 0.95rem;
            margin-bottom: 0.3rem;
        }

        p{
            font-size: 0.85rem;
        }

        div{
            display: flex;
            align-items: center;
            margin-bottom: 0.3rem;
            svg{
                margin-right: 0.2rem;
                min-width: 1rem;
                min-height: 1rem;
            }
        }
    }


    @media (max-width: 427px){
        width: 100%;
        margin: 1rem 0;
    }

    @media (max-width: 338px){
        flex-direction: column;

        .line{
            width: 5rem;
            height: 1px;
            margin: 1.5rem 0;
        }
    }
`